import React, { Fragment } from "react";
import Humanize from "humanize-plus";

import Typography from "@ui-kit/Typography";
import { countFacilitiesByType } from "@selectors/facility";
import { useMapStore } from "@store/MapStore";
import { Models } from "@services/api";

export const NearbySchools: React.FC = () => {
  const [{ facilities }] = useMapStore();
  const counters = countFacilitiesByType(facilities);

  if (
    !counters[Models.FacilityTypeID.DaycareCenter] &&
    !counters[Models.FacilityTypeID.PrivateSchool] &&
    !counters[Models.FacilityTypeID.PreSchool]
  ) {
    return null;
  }

  return (
    <Typography data-test="nearby-schools-display" gutterBottom>
      <Typography variant="inherit" bolded>
        Nearby schools:
      </Typography>{" "}
      {counters[Models.FacilityTypeID.DaycareCenter] > 0 && (
        <Fragment>
          {counters[Models.FacilityTypeID.DaycareCenter]} childcare{" "}
          {Humanize.pluralize(
            counters[Models.FacilityTypeID.DaycareCenter],
            "center",
          )}
          {counters[Models.FacilityTypeID.PrivateSchool] > 0 &&
          counters[Models.FacilityTypeID.PreSchool] > 0
            ? ", "
            : (counters[Models.FacilityTypeID.PrivateSchool] > 0 ||
                counters[Models.FacilityTypeID.PreSchool] > 0) &&
              " & "}
        </Fragment>
      )}
      {counters[Models.FacilityTypeID.PreSchool] > 0 && (
        <Fragment>
          {counters[Models.FacilityTypeID.PreSchool]}{" "}
          {Humanize.pluralize(
            counters[Models.FacilityTypeID.PreSchool],
            "preschool",
          )}
          {counters[Models.FacilityTypeID.PrivateSchool] > 0 && " & "}
        </Fragment>
      )}
      {counters[Models.FacilityTypeID.PrivateSchool] > 0 && (
        <Fragment>
          {counters[Models.FacilityTypeID.PrivateSchool]} private{" "}
          {Humanize.pluralize(
            counters[Models.FacilityTypeID.PrivateSchool],
            "school",
          )}
        </Fragment>
      )}
      {" found nearby"}
    </Typography>
  );
};
