import React, { useMemo } from "react";
import get from "lodash/get";
import { useFormContext, UseFormMethods } from "react-hook-form";

import Checkbox from "@material-ui/core/Checkbox";

import {
  ControlledMultilineInputField,
  ControlledInputField,
} from "@ui-kit/InputFields";
import { Spacer } from "@ui-kit/Spacer";
import Typography from "@ui-kit/Typography";
import { LinkOrFile } from "@components/LinkOrFile";

import { useCss } from "./styles";
import { EditMealsAndNutritionProps } from "./types";
import { ServerError } from "@models/common";
import { FacilityNutritionInfo } from "@models/FacilityNutritionInfo";
import { ControlledBooleanField } from "@ui-kit/BooleanField";

const isFieldOfFacilityMealsInfo = (field: string) => {
  return (
    field === "nutritionInfo.sampleMenuFile" ||
    field === "nutritionInfo.costFrom" ||
    field === "nutritionInfo.costTo"
  );
};

export const processServerErrors = (
  errors: ServerError[],
  setError: UseFormMethods["setError"],
): boolean => {
  let errorHandled = false;

  errors.forEach((e) => {
    const path = e.source.pointer?.split("/").filter(Boolean).join(".");

    if (!path) {
      return false;
    }

    if (isFieldOfFacilityMealsInfo(path)) {
      setError(path, {
        message: e.title,
        type: "manual",
      });
      errorHandled = true;
      return true;
    }
  });

  return errorHandled;
};

export const EditMealsAndNutritionView: React.FC<EditMealsAndNutritionProps> =
  ({ facilityView, setFacilityView, isClaim, isShortClaim }) => {
    const nutritionInfo = useMemo(
      () => FacilityNutritionInfo.fromDto(facilityView.nutritionInfo),
      [],
    );

    const { control, errors } = useFormContext();
    const css = useCss();

    const updateView = () => {
      setFacilityView((prev) => ({
        ...prev,
        nutritionInfo: nutritionInfo.toDto(),
      }));
    };

    const getRadioItem = (label: string, name: keyof FacilityNutritionInfo) => (
      <ControlledBooleanField
        key={`nutritionInfo.${name}`}
        name={`nutritionInfo.${name}`}
        label={label}
        required={isClaim}
        defaultValue={nutritionInfo[name]}
        onChange={(event) => {
          nutritionInfo[name] = (event.target.value === "yes") as never;
          updateView();
        }}
      />
    );

    return (
      <div data-test="facility-meals-and-nutritions-editor">
        {!isShortClaim && (
          <>
            <LinkOrFile
              label="Sample menu"
              file={nutritionInfo.sampleMenuFile}
              onChange={(file) => {
                nutritionInfo.sampleMenuFile = file;
                updateView();
              }}
              formData={{
                control,
                error: get(errors, "nutritionInfo.sampleMenuFile")?.message,
                name: "nutritionInfo.sampleMenuFile",
              }}
              data-test="sampleMenuFile-uploader"
            />

            <Spacer size="medium" />
          </>
        )}

        {getRadioItem("Outside food allowed", "outsideFoodAllowed")}

        {!isShortClaim && (
          <>
            {getRadioItem("Peanut free facility", "peanutFreeFacility")}
            {getRadioItem("Gluten free facility", "glutenFreeFacility")}

            <Spacer size="medium" />
          </>
        )}

        {getRadioItem("Meals served", "mealsServed")}
        {nutritionInfo.mealsServed && (
          <>
            {getRadioItem("Breakfast", "mealsServedBreakfast")}
            {getRadioItem("Morning snack", "mealsServedMorningSnack")}
            {getRadioItem("Lunch", "mealsServedLunch")}
            {getRadioItem("Afternoon snack", "mealsServedAfternoonSnack")}
          </>
        )}

        {!isShortClaim && (
          <>
            <ControlledMultilineInputField
              defaultValue={nutritionInfo.mealsServedDescription}
              onChange={(event) => {
                nutritionInfo.mealsServedDescription = event.target.value;
                updateView();
              }}
              placeholder="About meals served"
              name="nutritionInfo.mealsServedDescription"
            />

            <Spacer size="medium" />

            <Typography bolded paragraph>
              Meal plan
            </Typography>
            <div
              css={css.checkRow}
              onClick={() => {
                nutritionInfo.mealsCatered = !nutritionInfo.mealsCatered;
                updateView();
              }}
              data-test="mealsCatered-check"
            >
              <Checkbox
                color="primary"
                checked={!!nutritionInfo.mealsCatered}
              />
              <Typography>Meals are catered</Typography>
            </div>
          </>
        )}

        <div
          css={css.checkRow}
          onClick={() => {
            nutritionInfo.costIsIncludedInTuition =
              !nutritionInfo.costIsIncludedInTuition;
            updateView();
          }}
          data-test="costIsIncludedInTuition-check"
        >
          <Checkbox
            color="primary"
            checked={!!nutritionInfo.costIsIncludedInTuition}
          />
          <Typography>Included in price of tuition</Typography>
        </div>

        {!isShortClaim && (
          <>
            <div css={css.mealPlanWrapper}>
              <ControlledInputField
                label="From"
                useCurrencyAdornment
                variant="integer"
                maxLength={5}
                disabled={!!nutritionInfo.costIsIncludedInTuition}
                noZero
                onChange={(event) => {
                  nutritionInfo.costFrom = event.target.value;
                  updateView();
                }}
                defaultValue={nutritionInfo.costFrom?.value}
                name="nutritionInfo.costFrom"
              />

              <ControlledInputField
                label="To"
                useCurrencyAdornment
                variant="integer"
                maxLength={5}
                disabled={!!nutritionInfo.costIsIncludedInTuition}
                noZero
                onChange={(event) => {
                  nutritionInfo.costTo = event.target.value;
                  updateView();
                }}
                defaultValue={nutritionInfo.costTo?.value}
                name="nutritionInfo.costTo"
              />
            </div>
          </>
        )}
      </div>
    );
  };
