import React, { Fragment } from "react";
import { Controller, useFormContext, UseFormMethods } from "react-hook-form";
import isNil from "lodash/isNil";

import { Input, OutlinedInput } from "@ui-kit/InputFields";
import { Spacer } from "@ui-kit/Spacer";

import { RadioItem } from "../components/RadioItem";
import { EditVirtualTourProps } from "./types";
import Typography from "@ui-kit/Typography";
import { LinkOrFile } from "@components/LinkOrFile";
import { Models } from "@services/api";
import { useCss } from "./styles";
import { ServerError } from "@models/common";
import { trimEventValue } from "@helpers/trimEventValue";

export type FormFields = {
  videoTourUrl: string;
  vrTourUrl: string;
  facultyUrl: string;
  staffUrl: string;
  visitsUrl: string;
  videoCallsUrl: string;
  brochureFile: string;
  floorplanFile: string;
};

const isFieldOfFacilityToursInfo = (field: string) => {
  return (
    field === "videoTourUrl" ||
    field === "vrTourUrl" ||
    field === "facultyUrl" ||
    field === "staffUrl" ||
    field === "visitsUrl" ||
    field === "videoCallsUrl" ||
    field === "brochureFile" ||
    field === "floorplanFile"
  );
};

export const processServerErrors = (
  errors: ServerError[],
  setError: UseFormMethods["setError"],
): boolean => {
  let errorHandled = false;

  errors.forEach((e) => {
    const paths = e.source.pointer?.split("/");

    if (!paths) {
      return false;
    }

    if (isFieldOfFacilityToursInfo(paths[2])) {
      setError(paths[2], {
        message: e.title,
        type: "manual",
      });
      errorHandled = true;
      return true;
    }
  });

  return errorHandled;
};

export const EditVirtualTourView: React.FC<EditVirtualTourProps> = ({
  facilityView,
  setFacilityView,
  isClaim,
  isShortClaim,
}) => {
  const css = useCss();
  const { control, errors, watch } = useFormContext<FormFields>();

  const getRadioItem = (
    label: string,
    id: keyof Models.FacilityToursInfo,
    parentId?: keyof Models.FacilityToursInfo,
  ) => (
    <RadioItem
      control={control}
      id={id as string}
      label={label}
      error={errors[id as keyof typeof errors]?.message}
      required={(isClaim && !parentId) || !!watch(`${parentId as string}`)}
      defaultValue={
        isNil(facilityView.toursInfo?.[id])
          ? ""
          : facilityView.toursInfo?.[id]
          ? "yes"
          : "no"
      }
      onChange={(event) => {
        setFacilityView((prev) => ({
          ...prev,
          toursInfo: {
            ...prev.toursInfo,
            [id]: event.target.value === "yes",
          },
        }));
      }}
    />
  );

  return (
    <div>
      {!isShortClaim && (
        <Fragment>
          <Typography variant="h5" align="center" css={css.sectionTitle}>
            On-demand tours
          </Typography>
          <Spacer size="small" />
        </Fragment>
      )}

      <Controller
        render={({ onChange, value, name }) => (
          <Input
            value={value}
            name={name}
            error={errors.videoTourUrl?.message}
            label="School video tour: paste link"
            data-test="videoTourUrl-field"
            onChange={(event) => {
              setFacilityView((prev) => ({
                ...prev,
                toursInfo: {
                  ...prev.toursInfo,
                  videoTourUrl: event.target.value.trim(),
                },
              }));
              onChange(trimEventValue(event));
            }}
          />
        )}
        defaultValue={facilityView.toursInfo?.videoTourUrl || ""}
        name="videoTourUrl"
      />

      <Controller
        render={({ onChange, value, name }) => (
          <Input
            value={value}
            name={name}
            error={errors.vrTourUrl?.message}
            data-test="vrTourUrl-field"
            label="360-degree tour: paste link"
            onChange={(event) => {
              setFacilityView((prev) => ({
                ...prev,
                toursInfo: {
                  ...prev.toursInfo,
                  vrTourUrl: event.target.value.trim(),
                },
              }));
              onChange(trimEventValue(event));
            }}
          />
        )}
        defaultValue={facilityView.toursInfo?.vrTourUrl || ""}
        name="vrTourUrl"
      />

      {!isShortClaim && (
        <Fragment>
          <Controller
            render={({ onChange, value, name }) => (
              <Input
                value={value}
                name={name}
                error={errors.facultyUrl?.message}
                placeholder="Message from faculty: paste link"
                data-test="facultyUrl-field"
                label="Message from faculty"
                onChange={(event) => {
                  setFacilityView((prev) => ({
                    ...prev,
                    toursInfo: {
                      ...prev.toursInfo,
                      facultyUrl: event.target.value.trim(),
                    },
                  }));
                  onChange(trimEventValue(event));
                }}
              />
            )}
            defaultValue={facilityView.toursInfo?.facultyUrl || ""}
            name="facultyUrl"
          />

          <Controller
            render={({ onChange, value, name }) => (
              <Input
                value={value}
                name={name}
                error={errors.staffUrl?.message}
                placeholder="Staff bios & pics: paste link"
                data-test="staffUrl-field"
                label="Staff bios & pics"
                onChange={(event) => {
                  setFacilityView((prev) => ({
                    ...prev,
                    toursInfo: {
                      ...prev.toursInfo,
                      staffUrl: event.target.value.trim(),
                    },
                  }));
                  onChange(trimEventValue(event));
                }}
              />
            )}
            defaultValue={facilityView.toursInfo?.staffUrl || ""}
            name="staffUrl"
          />

          <Spacer size="medium" />

          <LinkOrFile
            label="Virtual school brochure"
            file={facilityView.toursInfo?.brochureFile}
            onChange={(file) => {
              setFacilityView((prev) => ({
                ...prev,
                toursInfo: {
                  ...prev.toursInfo,
                  brochureFile: file,
                },
              }));
            }}
            formData={{
              control,
              error: errors.brochureFile?.message,
              name: "brochureFile",
            }}
            data-test="brochureFile-linkOrLink"
          />

          <Spacer size="small" />

          <LinkOrFile
            label="Facility floor plan"
            file={facilityView.toursInfo?.floorplanFile}
            onChange={(file) => {
              setFacilityView((prev) => ({
                ...prev,
                toursInfo: {
                  ...prev.toursInfo,
                  floorplanFile: file,
                },
              }));
            }}
            formData={{
              control,
              error: errors.floorplanFile?.message,
              name: "floorplanFile",
            }}
            data-test="floorplanFile-linkOrLink"
          />
        </Fragment>
      )}

      {!isShortClaim && (
        <Fragment>
          <Spacer size="medium" />

          <Typography variant="h5" align="center" css={css.sectionTitle}>
            Video calls
          </Typography>

          <Spacer size="small" />

          {getRadioItem("Video calls", "videoCallsAvailable")}
          {facilityView.toursInfo?.videoCallsAvailable && (
            <Fragment>
              {getRadioItem("Zoom", "videoCallsZoom", "videoCallsAvailable")}
              {getRadioItem(
                "Facetime",
                "videoCallsFacetime",
                "videoCallsAvailable",
              )}
              {getRadioItem(
                "Whatsapp",
                "videoCallsWhatsup",
                "videoCallsAvailable",
              )}
              {getRadioItem(
                "Google duo",
                "videoCallsGoogleDuo",
                "videoCallsAvailable",
              )}
            </Fragment>
          )}

          <Controller
            render={({ onChange, ...controllerProps }) => (
              <Input
                {...controllerProps}
                error={errors.videoCallsUrl?.message}
                placeholder="Paste calendar link"
                label="Appointment calendar link"
                onChange={(event) => {
                  setFacilityView((prev) => ({
                    ...prev,
                    toursInfo: {
                      ...prev.toursInfo,
                      videoCallsUrl: event.target.value.trim(),
                    },
                  }));
                  onChange(trimEventValue(event));
                }}
              />
            )}
            defaultValue={facilityView.toursInfo?.videoCallsUrl || ""}
            name="videoCallsUrl"
          />

          <OutlinedInput
            value={facilityView.toursInfo?.videoCallsDescription || ""}
            onChange={(event) => {
              setFacilityView((prev) => ({
                ...prev,
                toursInfo: {
                  ...prev.toursInfo,
                  videoCallsDescription: event.target.value,
                },
              }));
            }}
            multiline
            placeholder="About video calls"
            rowsMax="6"
            data-test="videoCallsDescription-field"
            maxChars={750}
          />
        </Fragment>
      )}

      {!isShortClaim && (
        <Fragment>
          <Spacer size="medium" />

          <Typography variant="h5" align="center" css={css.sectionTitle}>
            In-person tours
          </Typography>

          <Spacer size="small" />

          {getRadioItem("In-person tours", "visitsAvailable")}
          {facilityView.toursInfo?.visitsAvailable && (
            <Fragment>
              {getRadioItem(
                "Daytime tours",
                "visitsAtDaytime",
                "visitsAvailable",
              )}
              {getRadioItem(
                "Evening tours",
                "visitsAtEvening",
                "visitsAvailable",
              )}
              {getRadioItem(
                "Weekend tours",
                "visitsAtWeekends",
                "visitsAvailable",
              )}
            </Fragment>
          )}

          <Controller
            render={({ onChange, ...controllerProps }) => (
              <Input
                {...controllerProps}
                error={errors.visitsUrl?.message}
                placeholder="Paste calendar link"
                label="Appointment calendar link"
                onChange={(event) => {
                  setFacilityView((prev) => ({
                    ...prev,
                    toursInfo: {
                      ...prev.toursInfo,
                      visitsUrl: event.target.value.trim(),
                    },
                  }));
                  onChange(trimEventValue(event));
                }}
              />
            )}
            defaultValue={facilityView.toursInfo?.visitsUrl || ""}
            name="visitsUrl"
          />

          <OutlinedInput
            value={facilityView.toursInfo?.visitsDescription || ""}
            onChange={(event) => {
              setFacilityView((prev) => ({
                ...prev,
                toursInfo: {
                  ...prev.toursInfo,
                  visitsDescription: event.target.value,
                },
              }));
            }}
            multiline
            placeholder="About in-person tours"
            rowsMax="6"
            data-test="visitsDescription-field"
            maxChars={750}
          />
        </Fragment>
      )}
    </div>
  );
};
