import { Models } from "@services/api";
import { t } from "@i18n";

export class BookingConfig {
  static getEligibilityName(eligibility?: Models.BookingEligibility) {
    if (!eligibility) {
      return null;
    }

    return t(`manageWeekendCarePage.eligibility.title.${eligibility}`);
  }

  static getEligibilityDescription(
    eligibility?: Models.BookingEligibility,
    description?: string,
  ) {
    if (!eligibility) {
      return null;
    }

    return description || null;
  }

  static getCancellationDescription(
    cancellation?: Models.BookingCancellationPeriodHours,
  ) {
    if (!cancellation) {
      return null;
    }

    if (cancellation === Models.BookingCancellationPeriodHours.NotSupported) {
      return "submitted reservations cannot be cancelled";
    }
    if (cancellation === Models.BookingCancellationPeriodHours.Anytime) {
      return "reservations can be cancelled at anytime";
    }

    let hours = 24;

    switch (cancellation) {
      case Models.BookingCancellationPeriodHours.Hours48:
        hours = 48;
        break;
      case Models.BookingCancellationPeriodHours.Hours72:
        hours = 72;
        break;
    }

    return `cancellations must be made at least ${hours}hrs prior to start time. To take affect, cancellations must be submitted online.`;
  }
}
