import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const useCss = (): Record<Classes, Interpolation<ITheme>> => ({
  buttons: {
    display: "flex",
    marginTop: 13,
    width: "100%",

    "& button": {
      flex: 1,

      "& + button": {
        marginLeft: 8,
      },
    },
  },

  overviewHeadline: {
    fontWeight: 600,

    "&.withOverview": {
      marginBottom: 15,
      paddingBottom: 15,
    },
  },
});
