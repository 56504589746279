import React, { Fragment } from "react";

import Typography from "@ui-kit/Typography";
import { useDeepDivePanelStore } from "@hooks";
import { isPublicSchool, getAvgClassSize } from "@selectors";

interface ClassroomsProps {
  gutterBottom?: boolean;
}

export const Classrooms: React.FC<ClassroomsProps> = ({
  gutterBottom = true,
}) => {
  const [{ facility }] = useDeepDivePanelStore();

  if (isPublicSchool(facility)) {
    return null;
  }

  return (
    <Fragment>
      <Typography gutterBottom data-test="classrooms-display">
        <Typography variant="inherit" bolded>
          № of classrooms:
        </Typography>{" "}
        {facility.operatingInfo.numberOfClassrooms || "---"}
      </Typography>

      {getAvgClassSize(facility) !== null && (
        <Fragment>
          <Typography gutterBottom data-test="avg-class-size-display">
            <Typography variant="inherit" bolded>
              Avg. class size:
            </Typography>{" "}
            {getAvgClassSize(facility)} students
          </Typography>
          <Typography gutterBottom={gutterBottom} italic>
            Average class size may not reflect class size variations between
            specific age groups.
          </Typography>
        </Fragment>
      )}
    </Fragment>
  );
};
