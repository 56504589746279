import React, { useState, useEffect } from "react";
import clsx from "clsx";
import qs from "query-string";
import Rating from "react-rating";
import { useHistory, useLocation } from "react-router-dom";
import StarIcon from "@material-ui/icons/StarRounded";

import { ROUTES } from "@constants";
import { RATINGS, RATINGS_IDS } from "@constants/ratings";

import { OutlinedInput } from "@ui-kit/InputFields";
import { useDeepDivePanelStore, usePrevious } from "@hooks";
import { ReactGAEventWait } from "@helpers/ga";
import { FACILITY_API, Models, FACILITY_REVIEWS_API } from "@services/api";
import Button from "@ui-kit/Button";
import Typography from "@ui-kit/Typography";
import { EditFacilitySuccessStep } from "@components/EditFacilitySuccessStep";
import { useUserStore } from "@store/UserStore";

import Dialog from "@ui-kit/Dialog";
import { createCss } from "./styles";

const RatingModal: React.FC = () => {
  const { search } = useLocation();
  const history = useHistory();
  const [{ user }] = useUserStore();
  const prevUser = usePrevious(user);
  const [{ facility }, { setFacility }] = useDeepDivePanelStore();
  const [step, setStep] = useState(1);
  const [fetching, setFetching] = useState(false);
  const [state, setState] = useState(() => {
    const initialState = {
      comment: "",
      headline: "",
      ratings: RATINGS.reduce((acc, rate) => {
        acc[rate.id] = 0;

        return acc;
      }, {} as { [index: string]: number }),
    };

    return initialState;
  });

  const css = createCss();

  const { ratings, comment, headline } = state;

  useEffect(() => {
    void (async () => {
      if (!facility.id || !user) {
        return;
      }

      const {
        data: { data },
      } = await FACILITY_REVIEWS_API.facilityReviewsIndex({
        facilityId: facility.id,
        userId: -1,
      });

      const myReview = data[0];

      if (myReview) {
        setState({
          comment: myReview.comment?.trim() || "",
          headline: myReview.headline?.trim() || "",
          ratings: RATINGS.reduce((acc, rate) => {
            acc[rate.id] =
              (myReview[rate.id as keyof Models.FacilityReview] as number) || 0;

            return acc;
          }, {} as { [index: string]: number }),
        });
      }
    })();
  }, []);

  useEffect(() => {
    if (!prevUser && user) {
      void sendRating();
    }
  }, [user]);

  function isEmptyRatings(rates: { [key: string]: number }) {
    let flag = true;

    Object.keys(rates).forEach((key) => {
      if (rates[key] > 0) {
        flag = false;

        return false;
      }
    });

    return flag;
  }

  async function sendRating() {
    try {
      setFetching(true);
      ReactGAEventWait({
        action: "Submit",
        category: "ReviewForm",
      });

      await FACILITY_REVIEWS_API.facilityReviewsCreate({
        facilityReview: {
          ...RATINGS.reduce((acc, rate) => {
            acc[rate.id] = ratings[rate.id] || null;

            return acc;
          }, {} as { [index: string]: number | null }),
          comment: comment !== "" ? comment : null,
          facilityId: facility.id,
          headline: headline !== "" ? headline : null,
        } as Models.FacilityReview,
      });

      setFacility(
        (
          await FACILITY_API.facilitiesByIdGet({
            id: facility.id,
          })
        ).data,
      );

      ReactGAEventWait({
        action: "SubmitSuccess",
        category: "ReviewForm",
      });

      setStep(2);
    } catch (error) {
      ReactGAEventWait({
        action: "SubmitError",
        category: "ReviewForm",
      });

      throw error;
    } finally {
      setFetching(false);
    }
  }

  function checkAuthorization() {
    if (!user) {
      history.replace({
        pathname: ROUTES.SIGNUP_FORM,
        search: qs.stringify({
          joinedFrom: Models.UserJoinedFrom.SubmitFacilityRating,
          addressCountry: facility.address.country,
        }),
      });
    } else {
      void sendRating();
    }
  }

  return (
    <Dialog
      open
      onClose={() => {
        history.replace({
          pathname: `/map/${facility.id}`,
          search,
        });
      }}
      protectQuit={step === 1}
      tier={facility.subscriptionTier}
      data-test="rating-dialog"
      title="Facility rating"
    >
      {step === 2 && (
        <div css={css.root} data-test="rating-form">
          <EditFacilitySuccessStep />
        </div>
      )}
      {step === 1 && (
        <div css={css.root} data-test="rating-form">
          <Typography bolded paragraph>
            Add a headline
          </Typography>

          <OutlinedInput
            value={headline}
            onChange={(event) => {
              const newState = {
                ...state,
                headline: event.target.value,
              };

              setState(newState);
            }}
            multiline
            placeholder="What's most important to know?"
            rowsMax="6"
            maxChars={50}
            data-test="headline-field"
          />

          <Typography bolded variant="body1" paragraph>
            Add a review
          </Typography>

          <OutlinedInput
            value={comment}
            onChange={(event) => {
              const newState = {
                ...state,
                comment: event.target.value,
              };

              setState(newState);
            }}
            multiline
            placeholder="Share your experience"
            rowsMax="6"
            maxChars={750}
            data-test="overview-field"
          />

          {RATINGS.map((rate) => {
            if (
              rate.id === RATINGS_IDS.Meals &&
              facility.nutritionInfo?.mealsServed === false
            ) {
              return null;
            }
            if (rate.id === RATINGS_IDS.Booking && !facility.bookingEnabled) {
              return null;
            }

            return (
              <div
                key={`rating-${rate.id}`}
                css={css.ratingItem}
                data-test={`rating-${rate.id}`}
              >
                <Typography css={css.title} bolded variant="body1">
                  {rate.name}
                  <Typography
                    variant="inherit"
                    display="inline"
                    color={
                      Boolean(ratings[rate.id])
                        ? ratings[rate.id] >= 7
                          ? "inherit"
                          : "error"
                        : "inherit"
                    }
                    css={css.counterDisplay}
                    className={clsx({
                      active: Boolean(ratings[rate.id]),
                    })}
                  >
                    {ratings[rate.id]}/10
                  </Typography>
                </Typography>
                <div css={css.ratingStars} data-test="rating-stars">
                  <Rating
                    emptySymbol={
                      <StarIcon css={css.starIcon} className="empty" />
                    }
                    fullSymbol={
                      <StarIcon css={css.starIcon} className="full" />
                    }
                    fractions={2}
                    initialRating={ratings[rate.id] / 2}
                    onClick={(value) => {
                      if (ratings[rate.id] / 2 === value) {
                        const newState = {
                          ...state,
                          ratings: {
                            ...state.ratings,
                            [rate.id]: 0,
                          },
                        };

                        setState(newState);
                      } else {
                        const newState = {
                          ...state,
                          ratings: {
                            ...state.ratings,
                            [rate.id]: value * 2,
                          },
                        };

                        setState(newState);
                      }
                    }}
                  />
                </div>
              </div>
            );
          }).filter(Boolean)}

          <Button
            variant="contained"
            fullWidth
            size="large"
            color="primary"
            disabled={isEmptyRatings(state.ratings)}
            onClick={checkAuthorization}
            name="submit"
            css={css.submitButton}
            loading={fetching}
          >
            Save
          </Button>
        </div>
      )}
    </Dialog>
  );
};

export default RatingModal;
