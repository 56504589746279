import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const useCss = (): Record<Classes, Interpolation<ITheme>> => ({
  root: (theme) => ({
    [theme.mediaRequests.notMobile]: {
      width: 400,
    },
  }),

  filtersWrapper: {
    padding: 0,
  },

  filtersList: (theme) => ({
    width: "100%",

    "& > li:not([disabled])": {
      cursor: "pointer",

      "&:hover": {
        backgroundColor: theme.palette.divider,
      },
    },
  }),

  icon: {
    marginRight: 15,
  },

  filterItem: {
    alignItems: "center",
    display: "flex",
  },

  favoriteIcon: (theme) => ({
    color: theme.palette.error.main,
  }),

  filtersCheck: {
    padding: 0,
  },

  clearPlaceholder: {
    visibility: "hidden",

    "& .inner": {
      padding: "0 0 15px",
    },
  },

  clearContent: (theme) => ({
    backgroundColor: theme.palette.background.default,
    marginTop: -16,
    position: "fixed",
    zIndex: 10,

    "& .inner": {
      padding: "10px 0 15px",
    },
  }),

  mainFilterButton: {
    justifyContent: "center",
    paddingLeft: 10,
    textTransform: "initial",

    "&:not(:last-child)": {
      marginBottom: 10,
    },
  },

  verifiedSign: {
    flexShrink: 0,
    height: 24,
    marginRight: 7,
    width: 24,
  },

  sponsorFiltersContainer: {
    display: "flex",
    flexWrap: "wrap",
  },

  sponsorFilter: (theme) => ({
    border: "1px solid transparent",
    cursor: "pointer",
    padding: 3,
    width: "50%",

    "& img": {
      width: "100%",
    },

    "&:hover": {
      borderColor: theme.palette.primary.main,
    },

    "&.active": {
      borderColor: "#e69e14",
    },
  }),

  localSponsorFilter: {
    fontWeight: 600,
    marginBottom: 16,
    textAlign: "center",
  },

  childcareWrapper: (theme) => ({
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    marginBottom: 8,

    "& .item": {
      alignItems: "center",
      cursor: "pointer",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      marginBottom: 8,
      position: "relative",
      width: "33.3333%",

      "&.button": {
        padding: "0 15px",
        width: "66.6666%",
      },

      "& .name": {
        color: theme.palette.primary.main,
        fontSize: 13,
        fontWeight: 600,
        position: "absolute",
        transform: "translateY(5px)",
      },

      "& svg": {
        height: 60,
        width: 60,
      },
    },
  }),

  sectionTitle: {
    backgroundColor: "rgba(0, 0, 0, .06)",
    padding: 7,
  },

  tiersContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },

  tier: (theme) => ({
    borderRadius: 5,
    cursor: "pointer",
    fontSize: 14,
    fontWeight: 600,
    marginTop: 10,
    padding: "10px 5px",
    textAlign: "center",
    width: "calc(50% - 8px)",

    "&.ivy": {
      backgroundColor: "#d4af37",
      color: "white",
    },

    "&.pro": {
      backgroundColor: theme.palette.text.primary,
      color: "white",
    },
  }),

  filterSelected: {
    position: "absolute",
    right: 5,
    top: "50%",
    transform: "translateY(-50%)",
  },

  materialRadioGroupRoot: {
    flexDirection: "row",
    marginBottom: 16,
    justifyContent: "center",
  },
});
