import React, { useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import loadable from "@loadable/component";
import track from "react-tracking";

import {
  DeepDivePanelStoreProvider,
  DeepDivePanelComponentsMap,
} from "@hooks/useDeepDivePanelStore";
import { DeepDivePanelProps } from "./types";
import { retry } from "@utils";
import { DeepDivePanelInner, MobilePanelInner } from "./index";
import { useMapStore } from "@store/MapStore";
import { FACILITY_API } from "@services/api";
import { useViewport } from "@hooks/useViewport";

const EditFacilityInfoForm = loadable(() =>
  retry(() => import("../../components/EditFacilityInfoForm")),
);
const EditDocumentsPage = loadable(() =>
  retry(() => import("../../pages/DeepDivePanel/EditDocuments")),
);
const EditMealsAndNutrition = loadable(() =>
  retry(() => import("../../pages/DeepDivePanel/EditMealsAndNutrition")),
);
const EditFeaturesAndAmenities = loadable(() =>
  retry(() => import("../../pages/DeepDivePanel/EditFeaturesAndAmenities")),
);
const EditProgramsAndServices = loadable(() =>
  retry(() => import("../../pages/DeepDivePanel/EditProgramsAndServices")),
);
const EditOperationalDetails = loadable(() =>
  retry(() => import("../../pages/DeepDivePanel/EditOperationalDetails")),
);
const EditTuitionAndFees = loadable(() =>
  retry(() => import("../../pages/DeepDivePanel/EditTuitionAndFees")),
);

const loadableMap: DeepDivePanelComponentsMap = {
  EditDocumentsPage,
  EditFacilityInfoForm,
  EditFeaturesAndAmenities,
  EditMealsAndNutrition,
  EditOperationalDetails,
  EditProgramsAndServices,
  EditTuitionAndFees,
};

interface IParams {
  facilityId: string;
}

const DeepDivePanelContainer: React.FC<Omit<DeepDivePanelProps, "facility">> = (
  props,
) => {
  const history = useHistory();
  const { facilityId } = useParams<IParams>();
  const { isMobile } = useViewport();
  const [{ activeFacility }, { setActiveFacility }] = useMapStore();

  const fetchFacility = useCallback(
    async (id: string) => {
      try {
        const facilityResponse = await FACILITY_API.facilitiesByIdGet({
          id,
        });

        if (facilityResponse) {
          setActiveFacility(facilityResponse.data);
        }
      } catch (error) {
        history.replace("/map");
      }
    },
    [setActiveFacility],
  );

  useEffect(() => {
    if (facilityId) {
      if (facilityId !== activeFacility?.id) {
        void fetchFacility(facilityId);
      }
    } else {
      setActiveFacility(null);
    }

    return () => setActiveFacility(null);
  }, [facilityId]);

  const Component = isMobile ? MobilePanelInner : DeepDivePanelInner;

  if (!activeFacility) {
    return null;
  }

  return (
    <DeepDivePanelStoreProvider
      initialState={{
        facility: activeFacility,
      }}
      components={loadableMap}
    >
      <Component facility={activeFacility} {...props} />
    </DeepDivePanelStoreProvider>
  );
};

export default track({
  page: "DDP",
})(DeepDivePanelContainer);
