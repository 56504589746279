import React from "react";

import Typography from "@ui-kit/Typography";
import { getConfigAgeRange } from "@selectors/weekend-care-config";
import { Spacer } from "@ui-kit/Spacer";
import { createCss } from "../styles";
import { BookingConfig } from "@models/BookingConfig";
import { Models } from "@services/api";

interface EligibilitySectionProps {
  bookingConfig: Models.BookingConfig;
}

export const EligibilitySection: React.FC<EligibilitySectionProps> = ({
  bookingConfig,
}) => {
  const css = createCss();

  return (
    <>
      <Spacer size="sm" />

      <Typography variant="h5" bolded align="center" css={css.sectionTitle}>
        Attendance eligibility
      </Typography>

      <Spacer size="sm" />

      <Typography gutterBottom>
        <Typography bolded variant="inherit">
          Age eligibility:
        </Typography>{" "}
        {getConfigAgeRange(bookingConfig)}
      </Typography>

      <Typography gutterBottom>
        <Typography bolded variant="inherit">
          Open to:
        </Typography>{" "}
        {BookingConfig.getEligibilityName(bookingConfig.eligibility)}
      </Typography>
      <Typography>
        {BookingConfig.getEligibilityDescription(
          bookingConfig.eligibility,
          bookingConfig.serviceEligibilityDescription,
        )}
      </Typography>
    </>
  );
};
