import { createUseStyles } from "react-jss";

import { ITheme } from "@theme";

const styles = (theme: ITheme) => ({
  container: {
    position: "relative",
    width: "100%",

    [theme.mediaRequests.notMobile]: {
      width: 400,
    },
  },

  footer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 30,

    "& .small-button": {
      width: 140,
    },
  },

  footerLink: {
    marginBottom: 25,
    marginTop: 25,
    textAlign: "center",
  },

  pinInput: {
    border: `2px solid ${theme.palette.text.secondary}`,
    borderRadius: 6,
    color: "black",
    display: "block",
    fontSize: 36,
    height: 65,
    letterSpacing: "10px",
    margin: "0 auto",
    outline: "none",
    textAlign: "center",
    width: 200,

    "&.error": {
      borderColor: theme.palette.error.main,
    },

    "&:focus": {
      borderColor: theme.palette.primary.main,
    },
  },

  resend: {
    margin: "0 auto",
    marginTop: 15,
    opacity: 0.6,
    textAlign: "center",

    "&.active": {
      color: theme.palette.primary.main,
      cursor: "pointer",
      opacity: 1,
    },
  },

  pinError: {
    color: theme.palette.error.main,
    margin: "0 auto",
    marginTop: 5,
    textAlign: "center",
  },

  roleWarning: {
    marginTop: 10,
  },

  step: {
    marginTop: 20,
  },

  boostButton: {
    fontSize: "1rem",
    marginTop: 10,
    textAlign: "center",
  },

  boostSeparator: {
    display: "block",
    margin: "30px auto 15px",
    width: 300,
  },

  boostTextContainer: {
    [theme.mediaRequests.notMobile]: {
      padding: "0 30px",
    },
  },

  claimed: {
    display: "flex",
    fontWeight: 600,
    justifyContent: "center",
    marginBottom: 10,

    "& img": {
      height: 24,
      marginRight: 7,
      width: 24,
    },
  },

  successWrapper: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    marginBottom: 15,
    marginTop: 20,

    "& .icon": {
      alignItems: "center",
      backgroundColor: theme.palette.success.main,
      borderRadius: "50%",
      color: "white",
      display: "flex",
      height: 24,
      justifyContent: "center",
      marginRight: 10,
      width: 24,

      "& svg": {
        fontSize: 20,
      },
    },
  },
});

export default createUseStyles<ITheme, keyof ReturnType<typeof styles>>(
  styles,
  { name: "ClaimFacilityForm" },
);
