import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import cloneDeep from "lodash/cloneDeep";

import { useCss } from "../styles";
import Typography from "@ui-kit/Typography";
import { useDeepDivePanelStore } from "@hooks";
import Button from "@ui-kit/Button";
import { Spacer } from "@ui-kit/Spacer";
import {
  EditMediaUrlsView,
  processServerErrors,
} from "@pages/DeepDivePanel/EditMediaUrls";
import { LinksFormProps } from "./types";

export const LinksForm: React.FC<LinksFormProps> = ({ onSuccess }) => {
  const [{ facility }, { saveFacility }] = useDeepDivePanelStore();
  const [facilityView, setFacilityView] = useState(cloneDeep(facility));
  const methods = useForm();
  const { handleSubmit, formState, setError } = methods;
  const css = useCss();

  useEffect(() => {
    setFacilityView(cloneDeep(facility));
  }, [facility]);

  const submit = handleSubmit(async () => {
    try {
      await saveFacility(facilityView);
      onSuccess();
    } catch (error) {
      const errors = error?.response?.data?.errors;

      if (errors) {
        processServerErrors(errors, setError);
      }

      throw error;
    }
  });

  return (
    <>
      <Typography variant="h4" bolded align="center" css={css.sectionTitle}>
        Photo galleries
      </Typography>

      <FormProvider {...methods}>
        <EditMediaUrlsView
          facilityView={facilityView}
          setFacilityView={setFacilityView}
        />
      </FormProvider>

      <Spacer />
      <Button
        variant="contained"
        color="secondary"
        size="large"
        disabled={!formState.isDirty}
        loading={formState.isSubmitting}
        fullWidth
        onClick={submit}
      >
        Save links
      </Button>
    </>
  );
};
