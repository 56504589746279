import Humanize from "humanize-plus";

import { programsTypesMap } from "@constants/programs";
import { Models } from "@services/api";

type GetGradeNameSettings = Partial<{
  withOrdinal: boolean;
  short: boolean;
}>;

export const getGradeName = (
  grade: string | null | undefined,
  options: GetGradeNameSettings = {},
): string => {
  const settings: GetGradeNameSettings = {
    short: true,
    withOrdinal: false,
    ...options,
  };

  if (!grade) {
    return "";
  }

  if (programsTypesMap[grade]) {
    if (settings.short) {
      return programsTypesMap[grade].shortTitle;
    } else {
      return programsTypesMap[grade].title;
    }
  }

  if (settings.withOrdinal) {
    return `${Humanize.ordinal(parseInt(grade, 10))} grade`;
  }

  return grade;
};

export const getFullGradeStringForCard = (
  facility: Models.FacilityCard,
  shortName = false,
  shortGrade = false,
): string => {
  let endingGrade = getGradeName(facility.operatingInfoOngoingGradesTo, {
    short: shortName,
    withOrdinal: true,
  });

  if (!facility.operatingInfoOngoingGradesFrom) {
    return endingGrade;
  }

  if (programsTypesMap[facility.operatingInfoOngoingGradesFrom]) {
    if (!endingGrade) {
      return (
        programsTypesMap[facility.operatingInfoOngoingGradesFrom][
          shortName ? "shortTitle" : "title"
        ] + " -"
      );
    }

    if (shortGrade) {
      endingGrade = endingGrade.replace(" grade", "");
    }

    return (
      programsTypesMap[facility.operatingInfoOngoingGradesFrom][
        shortName ? "shortTitle" : "title"
      ] + ` - ${endingGrade}`
    );
  }

  return `${getGradeName(facility.operatingInfoOngoingGradesFrom, {
    withOrdinal: true,
  }).replace(" grade", "")} - ${endingGrade}`;
};

export const getFullGradeString = (
  facility: Models.Facility,
  shortName = false,
  shortGrade = false,
): string => {
  let endingGrade = getGradeName(facility.operatingInfo.ongoingGradesTo, {
    short: shortName,
    withOrdinal: true,
  });

  if (!facility.operatingInfo.ongoingGradesFrom) {
    return endingGrade;
  }

  if (programsTypesMap[facility.operatingInfo.ongoingGradesFrom]) {
    if (!endingGrade) {
      return (
        programsTypesMap[facility.operatingInfo.ongoingGradesFrom][
          shortName ? "shortTitle" : "title"
        ] + " -"
      );
    }

    if (shortGrade) {
      endingGrade = endingGrade.replace(" grade", "");
    }

    return (
      programsTypesMap[facility.operatingInfo.ongoingGradesFrom][
        shortName ? "shortTitle" : "title"
      ] + ` - ${endingGrade}`
    );
  }

  return `${getGradeName(facility.operatingInfo.ongoingGradesFrom, {
    withOrdinal: true,
  }).replace(" grade", "")} - ${endingGrade}`;
};
