import React from "react";
import MaterialListItem, {
  ListItemProps as MaterialListItemProps,
} from "@material-ui/core/ListItem";

const ListItem: React.FC<MaterialListItemProps> = ({ style, ...restProps }) => {
  return (
    <MaterialListItem
      style={{
        paddingBottom: 4,
        paddingTop: 4,
        ...style,
      }}
      {...(restProps as any)}
    />
  );
};

ListItem.defaultProps = {
  button: false,
};

export default ListItem;
