import React from "react";
import numeral from "numeral";

import Typography from "@ui-kit/Typography";
import { useDeepDivePanelStore } from "@hooks";

interface CurrentCapacityProps {
  gutterBottom?: boolean;
}

export const CurrentCapacity: React.FC<CurrentCapacityProps> = ({
  gutterBottom,
}) => {
  const [{ facility }] = useDeepDivePanelStore();

  if (
    !facility.operatingInfo.enrollmentCapacity ||
    !facility.operatingInfo.currentEnrollment
  ) {
    return null;
  }

  return (
    <Typography
      gutterBottom={gutterBottom}
      data-test="currentEnrollment-display"
    >
      <Typography variant="inherit" bolded>
        Current enrollment:
      </Typography>{" "}
      {numeral(
        facility.operatingInfo.currentEnrollment /
          facility.operatingInfo.enrollmentCapacity,
      ).format("0%")}
    </Typography>
  );
};
