import React from "react";
import numeral from "numeral";

import Typography from "@ui-kit/Typography";

import { useDeepDivePanelStore } from "@hooks";

export const HeatIndex: React.FC = () => {
  const [{ facility }] = useDeepDivePanelStore();

  if (!facility.countyWeather?.heatIndex) {
    return null;
  }

  return (
    <Typography data-test="heat-index-display">
      <Typography variant="inherit" bolded>
        Heat index:
      </Typography>{" "}
      {facility.countyWeather.heatIndex < 0.01
        ? "less than 1%"
        : numeral(facility.countyWeather.heatIndex).format("0%")}{" "}
      (% of days during the yr when temperature can be expected to exceed 77°)
    </Typography>
  );
};
