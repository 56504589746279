import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import qs from "query-string";

import Button from "@ui-kit/Button";
import { createCss } from "./styles";
import {
  useReservationStore,
  ReservationSteps,
} from "@hooks/useReservationStore";
import { useDeepDivePanelStore } from "@hooks/useDeepDivePanelStore";
import { Models } from "@services/api";

const ErrorStep: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const [
    { error },
    { getSuggestedShifts, setStep, setSelectedShifts, setError },
  ] = useReservationStore();
  const [{ facility }, { reloadFacility }] = useDeepDivePanelStore();
  const css = createCss();

  if (!error) {
    return null;
  }

  const { code, detail, meta } = error;

  function goToStep() {
    let step: ReservationSteps;
    const params = qs.parse(location.search);

    switch (code) {
      case "payment_error":
        step = "select-payment";
        break;
      case "child_error":
        step = "child-select";
        break;
      case "booking_slot_terms_changed":
      case "booking_shifts_not_found":
        void getSuggestedShifts(undefined, true);
        setSelectedShifts([]);
        step = "shift-select";
        break;
      case "booking_not_eligible":
        step = "eligibility";
        break;
      case "booking_slot_not_available":
        if (
          meta?.slot?.unavailabilityReason ===
          Models.BookingConfigSuggestedShiftUnavailabilityReason.AgeNotServed
        ) {
          step = "eligibility";
        } else {
          void getSuggestedShifts(undefined, true);
          setSelectedShifts([]);
          step = "shift-select";
        }
        break;
      default:
        void getSuggestedShifts(undefined, true);
        setSelectedShifts([]);
        step = "shift-select";
    }

    setStep(step);
    setError(null);
    reloadFacility();
    params.step = step;
    history.replace({
      search: qs.stringify(params),
    });
  }

  return (
    <div css={css.flowContainer} data-test="error-step">
      <div>
        <div css={css.title}>Reservation error</div>
        {code === "payment_error" && (
          <div css={css.messageBlock}>
            <p>Payment could not be processed.</p>
            {!!detail && <p className="v-red">Error: {detail}</p>}
          </div>
        )}
        {code === "child_error" && (
          <div css={css.messageBlock}>
            <p className="v-red">Error: {error.title}</p>
          </div>
        )}
        {code === "booking_shifts_not_found" && (
          <div css={css.messageBlock}>
            <p>Service is no longer available.</p>
            <p>Your cc has not been charged</p>
          </div>
        )}
        {code === "booking_slot_not_available" && (
          <div css={css.messageBlock}>
            <p>
              {meta?.slot?.unavailabilityReason ===
                Models.BookingConfigSuggestedShiftUnavailabilityReason
                  .AgeNotServed && "Ages-served has changed."}
              {meta?.slot?.unavailabilityReason ===
                Models.BookingConfigSuggestedShiftUnavailabilityReason
                  .AgeGroupFull && "No available spots."}
              {meta?.slot?.unavailabilityReason ===
                Models.BookingConfigSuggestedShiftUnavailabilityReason
                  .TooLate && "Session already in progress."}
            </p>
            <p>Your cc has not been charged.</p>
          </div>
        )}
        {code === "booking_slot_terms_changed" && (
          <div css={css.messageBlock}>
            {meta?.priceIsChanged && <p>Service cost has changed.</p>}
            {meta?.timeIsChanged && <p>Service time has changed.</p>}
            <p>Your cc has not been charged</p>
          </div>
        )}
        {code === "booking_not_eligible" && (
          <div css={css.messageBlock}>
            <p>Attendance eligibility has changed.</p>
            <p>Your cc has not been charged</p>
          </div>
        )}
        {code === "unknown" && (
          <div css={css.messageBlock}>
            <p>Something went wrong.</p>
            <p>Your cc has not been charged</p>
          </div>
        )}
      </div>
      <Button
        variant="contained"
        color={
          facility.subscriptionTier === Models.SubscriptionTier.Ivy
            ? "secondary"
            : "black"
        }
        fullWidth
        size="large"
        onClick={goToStep}
        css={css.button}
        data-test="rebook-btn"
      >
        Rebook
      </Button>
    </div>
  );
};

export default ErrorStep;
