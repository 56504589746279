import React, { useState, useEffect, useRef, useCallback } from "react";
import isEqual from "lodash/isEqual";
import Helmet from "react-helmet";
import * as Sentry from "@sentry/react";
import clsx from "clsx";
import qs from "query-string";
import track from "react-tracking";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import {
  Switch,
  Route,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import FiltersComponent from "@components/Filters";
import { PointsOfInterestLegendDialog } from "@components/PoisLegendDialog";
import throttle from "lodash/throttle";
import { Map, Marker, GeoJSONSource } from "mapbox-gl";
import CircularProgress from "@material-ui/core/CircularProgress";
import NearMeIcon from "@material-ui/icons/NearMe";
import TuneIcon from "@material-ui/icons/Tune";

import useStyles from "./styles";
import DeepDivePanel from "@components/DeepDivePanel/container";
import META from "./meta";
import { globalConfig, MAPBOX_MAP_STYLE_URL } from "@constants";
import log from "loglevel";
import { usePrevious } from "@hooks";
import FacilityTooltip from "@components/FacilityCard";
import Header from "@components/Header";
import { MapStoreProvider, useMapStore } from "@store/MapStore";
import { useAppStore } from "@store/AppStore";
import FindToClaimDialog from "@pages/FindToClaimDialog";
import { MapLegend } from "@components/Map/Legend";
import { MapNotLoadedWarning } from "@components/Map/MapNotLoadedWarning";

import { getSchoolsBounds } from "@helpers/getSchoolsBounds";
import { EnrollmentResponseStoreProvider } from "@store/EnrollmentResponseStore";
import { EmptySearchDialog } from "./EmptySearch";
import { must } from "@utils/must";
import { filterParams } from "@store/MapStore/filterParams";
import { useViewport } from "@hooks/useViewport";
import { SearchAreaButton } from "./SearchAreaAction";
import { UserLocationError } from "./UserLocationError";
import { getRadiusCoordinates } from "./getRadiusCoordinates";
import { toggleNeighborhoodLayer } from "./toggleNeighborhoodLayer";
import { getBoundsPadding } from "./getBoundsPadding";
import {
  circleRadiusLayer,
  facilityPointLayer,
  getCircleRadiusSourceData,
} from "./sources";
import { createFacilitiesSourceData } from "./createFacilitiesSourceData";
import { facilityInBounds } from "./facilityInBounds";

interface IParams {
  facilityId?: string;
}

export const MapView: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [{ loader }] = useAppStore();
  const viewport = useViewport();
  const [
    {
      filtersState,
      neighbourhoodsShown,
      showPoisLegendModal,
      activeFacility,
      showFindToClaimDialog,
      showPoIs,
      hoveredFacilityId,
      districtShown,
      emptySearchVisible,
      isLoading,
      shouldFitMap,
      facilities,
      facilityList,
      searchMiles,
      searchMilesRef,
      anchorMapCenter,
      anchorMapCenterRef,
      globalFiltersAreActive,
      filters,
      secondFiltersSequence,
      userPositionFetching,
      shouldReloadData,
    },
    {
      openFilters,
      closeFilters,
      setShowPoisLegendModal,
      setShowFindToClaimDialog,
      setHoveredFacilityId,
      setDistrictShown,
      setFacilityList,
      filterFacilities,
      setEmptySearchVisible,
      setSearchAreaButtonVisible,
      fetchData,
      setAnchorMapCenter,
      setFilters,
      setUserPositionFetching,
      setNotLoadedWarningOpened,
    },
  ] = useMapStore();
  const { search } = location;
  const { facilityId } = useParams<IParams>();
  const [userCoordinates, setUserCoordinates] = useState<any | null>(null);
  const [userMarker, setUserMarker] = useState<Marker | null>(null);
  const [showUserLocationError, setShowUserLocationError] = useState(false);
  const [inited, setInited] = useState(false);

  const mapRef = useRef<Map>();
  const deviceRef = useRef(viewport);
  const activeFacilityRef = useRef(activeFacility);
  const hoveredFacilityIdRef = useRef(hoveredFacilityId);
  const currentLocationRef = useRef(location);
  const prevLocation = usePrevious(location);
  const classes = useStyles();

  currentLocationRef.current = location;

  useEffect(() => {
    if (globalConfig.LOAD_MAP) {
      void initMapView();
    }

    return () => {
      window.removeEventListener("resize", handleWindowResize);
      window.removeEventListener("orientationchange", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (!mapRef.current) {
      return;
    }

    mapRef.current.resize();

    if (shouldReloadData.current) {
      shouldReloadData.current = false;
      void fetchData();
    } else {
      const filtered = filterFacilities(facilities);
      setFacilityList(filtered);
      if (!filtered.length) {
        setEmptySearchVisible(true);
      }
    }
  }, [filters]);

  useEffect(() => {
    if (!userCoordinates || !mapRef.current) {
      return;
    }

    if (!userMarker) {
      const el = document.createElement("div");
      el.className =
        "mapboxgl-user-location-dot mapboxgl-marker mapboxgl-marker-anchor-center";

      void import("mapbox-gl").then((MapBoxGl) => {
        setUserMarker(
          new MapBoxGl.Marker(el)
            .setLngLat([userCoordinates.longitude, userCoordinates.latitude])
            .addTo(must(mapRef.current)),
        );
      });
    } else {
      userMarker.setLngLat([
        userCoordinates.longitude,
        userCoordinates.latitude,
      ]);
    }
  }, [userCoordinates]);

  useEffect(() => {
    if (!mapRef.current) {
      return;
    }

    void fetchData().then(() => {
      if (neighbourhoodsShown) {
        toggleNeighborhoods();
      }
    });
    mapRef.current.setCenter(anchorMapCenter);
    setDistrictShown(false);

    if (facilityId) {
      mapRef.current.setZoom(15);
    }
  }, [anchorMapCenter]);

  useEffect(() => {
    if (!inited) {
      return;
    }

    if (globalFiltersAreActive.current || isLoading) {
      removeRadiusCircle();
    } else {
      createRadiusCircle();
    }
  }, [filters, isLoading]);

  useEffect(() => {
    if (!mapRef.current || !activeFacility) {
      return;
    }

    if (!facilityInBounds(mapRef.current, activeFacility)) {
      setAnchorMapCenter({
        lat: activeFacility.address.location.lat,
        lng: activeFacility.address.location.lon,
      });
    }
  }, [activeFacility?.id]);

  useEffect(() => {
    if (!mapRef.current) {
      return;
    }

    mapRef.current.setLayoutProperty(
      "districts",
      "visibility",
      districtShown ? "visible" : "none",
    );
  }, [districtShown]);

  useEffect(() => {
    toggleNeighborhoods();
  }, [neighbourhoodsShown]);

  useEffect(() => {
    if (!inited) {
      return;
    }

    if (globalFiltersAreActive.current) {
      if (shouldFitMap.current) {
        fitAllPins();
        shouldFitMap.current = false;
      }
    }

    (window as any).facilitiesOnMap = (facilityList || []).length;
  }, [facilityList, inited]);

  useEffect(() => {
    if (!inited) {
      return;
    }

    if (!globalFiltersAreActive.current) {
      createRadiusCircle();
    }
  }, [searchMiles]);

  useEffect(() => {
    updateFacilitiesSource();
  }, [facilityId, facilityList, inited]);

  useEffect(() => {
    if (!mapRef.current) {
      return;
    }

    mapRef.current.setLayoutProperty(
      "pois",
      "visibility",
      showPoIs ? "visible" : "none",
    );

    if (showPoIs && mapRef.current.getZoom() < 10) {
      mapRef.current.setZoom(10);
    }
  }, [showPoIs]);

  useEffect(() => {
    activeFacilityRef.current = activeFacility;
  }, [activeFacility?.id]);

  useEffect(() => {
    deviceRef.current = viewport;
  }, [viewport]);

  useEffect(() => {
    hoveredFacilityIdRef.current = hoveredFacilityId;
  }, [hoveredFacilityId]);

  useEffect(() => {
    if (!prevLocation) {
      return;
    }

    const prevParams = qs.parse(prevLocation.search);
    const params = qs.parse(search);

    if (filterParams.find((f) => !isEqual(prevParams[f], params[f]))) {
      setFilters(params);
      closeFilters();
    }

    if (
      params.lng &&
      params.lat &&
      (prevParams.lng !== params.lng || prevParams.lat !== params.lat)
    ) {
      setAnchorMapCenter({
        lat: parseFloat(params.lat as string),
        lng: parseFloat(params.lng as string),
      });
    }
  }, [location]);

  function initMap() {
    log.info("Mapbox init started");

    return new Promise<void>((resolveMapInit) => {
      void import("mapbox-gl").then((MapBoxGl) => {
        if (!MapBoxGl.supported()) {
          alert("Your browser does not support WebGL");

          resolveMapInit();
          return;
        }

        const map = new MapBoxGl.Map({
          accessToken: globalConfig.MAPBOX_PUBLIC_KEY,
          center: anchorMapCenterRef.current,
          container: "map",
          style: MAPBOX_MAP_STYLE_URL,
          zoom: activeFacility ? 15 : deviceRef.current.isMobile ? 12 : 13,
        });

        map.on("error", (e) => Sentry.captureException(e.error));

        mapRef.current = map;
        (window as any).map = map;

        if (!deviceRef.current.isMobile) {
          map.addControl(new MapBoxGl.NavigationControl());
        }
        /* istanbul ignore next */
        map.on("load", () => {
          log.info("Mapbox loaded");

          map.on("mouseenter", "unclustered-point", (e) => {
            if (deviceRef.current.isMobile) {
              return;
            }

            map.getCanvas().style.cursor = "pointer";
            setHoveredFacilityId(
              e.features?.[0].properties?.facilityId || null,
            );
          });

          map.on("mouseleave", "unclustered-point", () => {
            if (deviceRef.current.isMobile) {
              return;
            }

            map.getCanvas().style.cursor = "";
            setHoveredFacilityId(null);
          });

          map.on("mousemove", "unclustered-point", (e) => {
            if (deviceRef.current.isMobile) {
              return;
            }

            const id = e.features?.[0].properties?.facilityId || null;

            if (id !== hoveredFacilityIdRef.current) {
              setHoveredFacilityId(id);
            }
          });

          map.on("click", "unclustered-point", (e) => {
            e.originalEvent.cancelBubble = true;

            const id: string = e.features?.[0].properties?.facilityId;

            history.push({
              pathname: `/map/${id}`,
              search: currentLocationRef.current.search,
            });
          });

          map.on("mouseenter", "pois", () => {
            if (deviceRef.current.isMobile) {
              return;
            }
            map.getCanvas().style.cursor = "pointer";
          });

          map.on("mouseleave", "pois", () => {
            if (deviceRef.current.isMobile) {
              return;
            }
            map.getCanvas().style.cursor = "";
          });

          map.on("click", "pois", (e) => {
            e.originalEvent.cancelBubble = true;

            setShowPoisLegendModal(true);
          });

          map.on("dragstart", () => {
            setSearchAreaButtonVisible(false);
            setEmptySearchVisible(false);
          });

          map.on("dragend", () => {
            setSearchAreaButtonVisible(true);
          });

          map.on("movestart", () => {
            if (hoveredFacilityIdRef.current) {
              setHoveredFacilityId(null);
            }

            removeRadiusCircle();
          });

          map.on("moveend", () => {
            if (!globalFiltersAreActive.current) {
              createRadiusCircle();
            }
          });

          map.on("click", (e) => {
            if (e.originalEvent.cancelBubble) {
              return;
            }

            if (deviceRef.current.isMobile) {
              if (activeFacilityRef.current) {
                history.push({
                  pathname: "/map",
                  search: currentLocationRef.current.search,
                });
              }
            }
          });

          resolveMapInit();
          log.info("Mapbox init finished");
        });

        window.addEventListener("resize", handleWindowResize);
        window.addEventListener("orientationchange", handleWindowResize);
        /* istanbul ignore next */
        navigator?.geolocation?.getCurrentPosition(
          (position) => {
            setUserCoordinates(position.coords);
          },
          () => null,
          {
            maximumAge: 0,
            timeout: 5000,
          },
        );
      });
    });
  }

  const getInitialData = async () => {
    log.info("Map data load started");
    await fetchData();
    log.info("Map data load finished");
  };

  async function initMapView() {
    log.info("MapView init started");

    setTimeout(() => {
      setNotLoadedWarningOpened(true);
    }, 15000);

    try {
      await Promise.all([initMap(), getInitialData()]);

      createRadiusCircle();

      if (
        !globalFiltersAreActive.current &&
        !facilityId &&
        searchMilesRef.current === 5
      ) {
        mapRef.current?.setZoom(viewport.isMobile ? 10 : 11);
      }

      if (globalFiltersAreActive.current) {
        fitAllPins();
      }

      toggleNeighborhoods();
    } finally {
      mapRef.current?.addSource("facilities", {
        data: createFacilitiesSourceData([]),
        type: "geojson",
      });

      mapRef.current?.addLayer(facilityPointLayer);

      setInited(true);
      log.info("MapView inited");
    }
  }

  function handleWindowResize() {
    mapRef.current?.resize();
  }

  const toggleNeighborhoods = () => {
    if (!mapRef.current) {
      return;
    }

    toggleNeighborhoodLayer(
      mapRef.current,
      neighbourhoodsShown,
      anchorMapCenterRef.current,
      searchMilesRef.current,
    );
  };

  function updateFacilitiesSource() {
    if (!mapRef.current) {
      return;
    }

    const source: any = mapRef.current.getSource("facilities");

    if (source) {
      source.setData(createFacilitiesSourceData(facilityList, facilityId));
    }
  }

  /* istanbul ignore next */
  function removeRadiusCircle() {
    if (!mapRef.current || !mapRef.current.getSource("circle_radius_s")) {
      return;
    }

    mapRef.current.removeLayer("circle_radius_l_l");
    mapRef.current.removeSource("circle_radius_s");
  }
  /* istanbul ignore next */
  function fitAllPins() {
    const bounds = getBounds();

    if (!bounds) {
      return;
    }

    mapRef.current?.fitBounds(
      [
        [bounds.left, bounds.bottom],
        [bounds.right, bounds.top],
      ],
      {
        linear: true,
        maxZoom: 15,
        padding: getBoundsPadding(!!facilityId, viewport.isMobile),
      },
    );
  }
  /* istanbul ignore next */
  const createRadiusCircle = throttle(() => {
    if (globalFiltersAreActive.current || !mapRef.current || isLoading) {
      return;
    }

    const coords = getRadiusCoordinates(
      mapRef.current.getCenter(),
      searchMilesRef.current,
    );
    const sourceData = getCircleRadiusSourceData(coords);

    const circleRadiusSource: GeoJSONSource = mapRef.current.getSource(
      "circle_radius_s",
    ) as any;

    if (circleRadiusSource) {
      circleRadiusSource.setData(sourceData);
    } else {
      mapRef.current.addSource("circle_radius_s", {
        data: sourceData,
        type: "geojson",
      });

      mapRef.current.addLayer(circleRadiusLayer);
    }
  }, 200);

  function getBounds() {
    if (!facilityList) {
      return null;
    }

    return getSchoolsBounds(facilityList);
  }

  /* istanbul ignore next */
  function renderFacilityTooltip() {
    if (!hoveredFacilityId || viewport.isMobile || !mapRef.current) {
      return null;
    }

    const facility = facilityList?.find((f) => f.id === hoveredFacilityId);

    if (!facility) {
      return null;
    }

    return (
      <FacilityTooltip
        facility={facility}
        coords={mapRef.current.project([
          facility.address.location.lon,
          facility.address.location.lat,
        ])}
      />
    );
  }

  /* istanbul ignore next */
  const getCurrentCoordinates = useCallback(() => {
    setUserPositionFetching(true);
    navigator?.geolocation?.getCurrentPosition(
      (position) => {
        const params = qs.parse(search);

        setUserPositionFetching(false);
        if (
          params.lat !== position.coords.latitude.toString() ||
          params.lng !== position.coords.longitude.toString()
        ) {
          history.push({
            pathname: "/map",
            search: qs.stringify({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
              ...filters.secondary,
            }),
          });
        } else {
          mapRef?.current?.setCenter(anchorMapCenterRef.current);
        }
      },
      () => {
        setShowUserLocationError(true);
        setUserPositionFetching(false);
      },
      {
        maximumAge: 0,
        timeout: 5000,
      },
    );
  }, [setShowUserLocationError, setUserPositionFetching, search]);

  function renderMapBlock() {
    return (
      <div className={classes.mapContainer}>
        <div id="map" className={classes.map} />

        {renderFacilityTooltip()}

        {isLoading && !loader && (
          <div
            className={clsx(classes.mapLoader, {
              opacity: true,
              padding: !!facilityId && !viewport.isMobile,
            })}
            data-test="map-loader"
          >
            <CircularProgress />
          </div>
        )}

        {emptySearchVisible && <EmptySearchDialog />}
        <SearchAreaButton map={mapRef.current} />

        {inited && !viewport.isMobile && (
          <MapLegend onMyLocationClick={getCurrentCoordinates} />
        )}

        {viewport.isMobile && (
          <div
            className={clsx(
              "mapboxgl-ctrl-bottom-right",
              classes.myLocationControl,
            )}
          >
            <div className="mapboxgl-ctrl mapboxgl-ctrl-group">
              <button onClick={() => openFilters()}>
                <TuneIcon />
              </button>
              <button onClick={() => setShowPoisLegendModal(true)}>
                <NearMeIcon />
              </button>
              <button
                onClick={getCurrentCoordinates}
                disabled={userPositionFetching}
              >
                {userPositionFetching ? (
                  <CircularProgress size={16} />
                ) : (
                  <MyLocationIcon />
                )}
              </button>
            </div>
          </div>
        )}

        {showUserLocationError && (
          <UserLocationError onClose={() => setShowUserLocationError(false)} />
        )}
      </div>
    );
  }

  return (
    <div className={classes.pageWrapper} data-test="map-page">
      <Helmet {...META} />
      <Header />

      {filtersState.opened && <FiltersComponent />}
      {/* {notLoadedWarningOpened && !inited && <MapNotLoadedWarning />} */}
      {showPoisLegendModal && <PointsOfInterestLegendDialog />}
      {showFindToClaimDialog && (
        <FindToClaimDialog onClose={() => setShowFindToClaimDialog(false)} />
      )}

      <div className={classes.desktopMapWrapper}>
        {renderMapBlock()}
        <Switch>
          <Route
            path="/map/:facilityId"
            render={() => (
              <DeepDivePanel getCurrentLocation={getCurrentCoordinates} />
            )}
          />
        </Switch>
      </div>
    </div>
  );
};

const WrappedMap: React.FC = () => (
  <MapStoreProvider>
    <EnrollmentResponseStoreProvider>
      <MapView />
    </EnrollmentResponseStoreProvider>
  </MapStoreProvider>
);

WrappedMap.displayName = "Map";

export default track({
  page: "Map",
})(WrappedMap);
