import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useHistory, useLocation, Redirect } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import isFunction from "lodash/isFunction";
import qs from "query-string";

import Dialog from "@ui-kit/Dialog";
import { useCss } from "./styles";
import { EditFacilityDialogProps } from "./types";
import { ROUTES } from "@constants";
import { useDeepDivePanelStore, usePrevious } from "@hooks";
import { ReactGAEventWait } from "@helpers/ga";
import Button from "@ui-kit/Button";
import { useUserStore } from "@store/UserStore";
import { Success } from "./Success";
import { Models } from "@services/api";
import { goToFormError } from "@helpers/goToFormError";
import { Spacer } from "@ui-kit/Spacer";
import { useNotificationStore } from "@store/NotificationStore";
import { useMapStore } from "@store/MapStore";
import { isAdmin } from "@selectors";

export function EditFacilityDialog<F>({
  children,
  name,
  title,
  viewPath,
  onClose,
  processErrors,
}: React.PropsWithChildren<EditFacilityDialogProps>): JSX.Element {
  const [, { setUnknownErrorNotification, setNotification }] =
    useNotificationStore();
  const { search } = useLocation();
  const history = useHistory();
  const methods = useForm<F>();
  const [{ user }] = useUserStore();
  const prevUser = usePrevious(user);
  const [{ facility }] = useDeepDivePanelStore();
  const [, { saveFacility }] = useMapStore();
  const [facilityView, setFacilityView] = useState(cloneDeep(facility));
  const [showSuccessfullView, setShowSuccessfullView] = useState(false);
  const css = useCss();
  const { handleSubmit, formState, watch, setError, trigger } = methods;

  useEffect(() => {
    if (!prevUser && user) {
      void saveChanges();
    }
  }, [user]);

  if (!facility.isEditable && !isAdmin(user)) {
    return <Redirect to={{ pathname: `/map/${facility.id}`, search }} />;
  }

  function checkAuthorization() {
    if (!user) {
      history.replace({
        pathname: ROUTES.SIGNUP_FORM,
        search: qs.stringify({
          joinedFrom: Models.UserJoinedFrom.EditFacilityProfile,
          addressCountry: facility.address.country,
        }),
      });
    } else {
      void saveChanges();
    }
  }

  const saveChanges = handleSubmit(async () => {
    try {
      await saveFacility(facilityView);

      ReactGAEventWait({
        action: "SubmitSuccess",
        category: "EditFacility",
        label: name,
      });

      setShowSuccessfullView(true);
    } catch (error) {
      let errorHandled = false;

      const errors = error?.response?.data?.errors;

      if (errors) {
        if (
          isFunction(processErrors) &&
          processErrors(errors, setError, watch)
        ) {
          errorHandled = true;
          goToFormError();
        } else {
          setNotification({
            message: errors[0].title,
            type: "error",
          });
          return;
        }
      }

      if (!errorHandled) {
        setUnknownErrorNotification();
        throw error;
      }
    }
  });

  const submit = () => {
    void trigger().then((isValid) => {
      if (isValid) {
        checkAuthorization();
      } else {
        goToFormError();
      }
    });
  };

  const handleClose = () => {
    if (isFunction(onClose)) {
      onClose();
    } else {
      history.replace({
        pathname: `/map/${facility.id}/edit`,
        search,
      });
    }
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      protectQuit={!showSuccessfullView}
      data-test="edit-facility-dialog"
      tier={facility.subscriptionTier}
      title={title}
    >
      {showSuccessfullView ? (
        <div css={css.root}>
          <Spacer size="medium" />
          <Success viewPath={viewPath} onDone={handleClose} />
        </div>
      ) : (
        <div css={css.root}>
          <FormProvider {...methods}>
            {children(facilityView, setFacilityView)}
          </FormProvider>

          <Button
            color="primary"
            size="large"
            variant="contained"
            css={css.submitButton}
            fullWidth
            onClick={submit}
            name="submit"
            loading={formState.isSubmitting}
            disabled={formState.isSubmitSuccessful}
          >
            Save changes
          </Button>
        </div>
      )}
    </Dialog>
  );
}
