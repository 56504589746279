import React from "react";
import clsx from "clsx";

import MyLocationIcon from "@material-ui/icons/MyLocation";
import TuneIcon from "@material-ui/icons/Tune";
import NearMeIcon from "@material-ui/icons/NearMe";

import useStyles from "./styles";
import { useMapStore } from "@store/MapStore";
import { OperationalDetails } from "../OperationalDetails";
import { CountySpotlight } from "../CountySpotlight";
import { FacilityOverview } from "../Overview";
import { FacilityControls } from "../FacilityControls";
import { WeekendCareSection } from "../WeekendCare";
import { RatingBlock } from "../RatingBlock";
import MainInfo from "@components/DeepDivePanel/MainInfo";

import { useDeepDivePanelStore } from "@hooks";
import { VirtualTourSection } from "../VirtualTourSection";
import { Models } from "@services/api";
import { useViewport } from "@hooks/useViewport";
import { isUSA } from "@selectors/facility";

interface IProps {
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  getCurrentLocation: () => void;
}

const Highlights: React.FC<IProps> = ({
  onClose,
  open,
  onOpen,
  getCurrentLocation,
}) => {
  const [{ facility }] = useDeepDivePanelStore();
  const { isMobile } = useViewport();
  const [, { openFilters, setShowPoisLegendModal }] = useMapStore();

  const classes = useStyles();

  return (
    <div
      id="deep-dive-panel-content"
      className={classes.root}
      css={[
        facility.subscriptionTier === Models.SubscriptionTier.Ivy && {
          backgroundColor: "#f6ce0e",
        },
        isMobile &&
          facility.subscriptionTier === Models.SubscriptionTier.Pro && {
            backgroundColor: "#e8e8e8",
          },
        !isMobile &&
          facility.subscriptionTier === Models.SubscriptionTier.Pro && {
            backgroundColor: "white",
          },
      ]}
    >
      {isMobile && !open && (
        <div
          className={clsx(
            "mapboxgl-ctrl-bottom-right",
            classes.directionsControl,
          )}
        >
          <div className="mapboxgl-ctrl mapboxgl-ctrl-group">
            <button onClick={() => openFilters()}>
              <TuneIcon />
            </button>
            <button onClick={() => setShowPoisLegendModal(true)}>
              <NearMeIcon />
            </button>
            <button onClick={getCurrentLocation}>
              <MyLocationIcon />
            </button>
          </div>
        </div>
      )}

      <MainInfo onClose={onClose} open={open} onOpen={onOpen} />
      <FacilityControls />
      <FacilityOverview />
      {facility.bookingActive && <WeekendCareSection />}
      <VirtualTourSection />
      <RatingBlock />
      <OperationalDetails />
      {isUSA(facility) && <CountySpotlight />}
      {!facility.bookingActive && <WeekendCareSection />}
    </div>
  );
};

export default Highlights;
