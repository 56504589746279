import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  radioLabel: {
    flex: 1,
    marginRight: 10,
  },

  radioContainer: {
    alignItems: "center",
    flexDirection: "row",
    marginBottom: 10,
    width: "100%",
  },

  radio: {
    paddingBottom: 4,
    paddingTop: 4,
  },

  group: {
    flexDirection: "row",

    "& label:last-child": {
      marginRight: 0,
    },
  },
});
