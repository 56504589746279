import { createUseStyles } from "react-jss";

import { ITheme } from "@theme";
import { OverviewProps } from "./props";

const styles = (theme: ITheme) => ({
  root: {
    width: "100%",

    "&.hasMore:not(.opened)": {
      marginBottom: -20,
    },
  },

  overviewMoreTrigger: (props: OverviewProps) => ({
    backgroundColor:
      props.color === "black"
        ? "rgba(27, 27, 30, .6)"
        : "rgba(249, 248, 247, .6)",
    color: props.color === "black" ? "white" : theme.palette.secondary.main,
    display: "flex",
    position: "relative",

    "&:not(.opened)": {
      paddingTop: 10,
      position: "relative",
      top: -20,
    },
  }),

  overview: (props: OverviewProps) => ({
    maxHeight: props.height || 70,
    overflow: "hidden",
    position: "relative",
    transition: "max-height 0.5s cubic-bezier(0, 1, 0, 1)",
    whiteSpace: "pre-wrap",

    "&.opened": {
      maxHeight: "none",
      overflow: "visible",
      transition: "max-height 1s ease-in-out",
    },

    "&.italic": {
      fontStyle: "italic",
    },
  }),
});

export default createUseStyles(styles, { name: "Overview" });
