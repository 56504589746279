import { GeoJSONSourceRaw } from "mapbox-gl";

import { Models } from "@services/api";
import { isPrivateSchool, isPreSchool, isOshcSchool } from "@selectors";

export const createFacilitiesSourceData = (
  list: Models.FacilityCard[] | null,
  activeFacilityId?: string | undefined,
): GeoJSONSourceRaw["data"] => {
  return {
    features: (list || []).map((f) => ({
      geometry: {
        coordinates: [f.address.location.lon, f.address.location.lat],
        type: "Point",
      },
      properties: {
        color: isPrivateSchool(f)
          ? "pin-dark-blue"
          : isPreSchool(f)
          ? "pin-green"
          : isOshcSchool(f)
          ? "pin-black"
          : "pin-pink",
        facilityId: f.id,
        order: f.id === activeFacilityId ? 1 : 0,
        size: activeFacilityId === f.id ? 0.8 : 0.6,
      },
      type: "Feature",
    })),
    type: "FeatureCollection",
  };
};
