import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  root: {
    "&:not(:first-of-type)": {
      "& $header": {
        marginTop: 15,
      },
    },
  },

  header: {
    alignItems: "flex-start",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 24,
  },

  name: {
    wordBreak: "break-word",
  },

  details: {
    marginBottom: 10,
    position: "relative",
  },

  detailedReview: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 40px",
  },

  date: {
    flexShrink: 0,
    paddingLeft: 15,
  },
});
