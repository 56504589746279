import React, { useMemo } from "react";
import clsx from "clsx";
import { useFormContext, UseFormMethods } from "react-hook-form";
import keyBy from "lodash/keyBy";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";

import FormControlLabel from "@ui-kit/FormControlLabel";
import {
  ControlledInputField,
  ControlledMultilineInputField,
} from "@ui-kit/InputFields";
import { ControlledSelectField } from "@ui-kit/Select";
import { paymentPeriodTypes } from "@constants/tuition";
import Checkbox from "@material-ui/core/Checkbox";
import useStyles from "./styles";
import { Models } from "@services/api";
import MultipleSelect from "@ui-kit/MultipleSelect";
import { formatDiscountValue } from "@selectors";
import { Spacer } from "@ui-kit/Spacer";
import Typography from "@ui-kit/Typography";
import { LinkOrFile } from "@components/LinkOrFile";
import { TUITION_WITHDRAWAL } from "@constants/tuition-withdrawal";
import { TUITION_WAITLIST } from "@constants/tuition-waitlist";
import { ServerError } from "@models/common";
import { FacilityTuitionInfo } from "@models/FacilityTuitionInfo";

interface IProps {
  facilityView: Models.Facility;
  setFacilityView: React.Dispatch<React.SetStateAction<Models.Facility>>;
  isClaim?: boolean;
  isShortClaim?: boolean;
}

const DISCOUNT_ITEMS = [
  {
    id: Models.DiscountID._5,
    name: "5%",
  },
  {
    id: Models.DiscountID._10,
    name: "10%",
  },
  {
    id: Models.DiscountID._15,
    name: "15%",
  },
  {
    id: Models.DiscountID._20,
    name: "20%",
  },
  {
    id: Models.DiscountID._25,
    name: "25%",
  },
];

const isFieldOfFacilityTuitionInfo = (field: string) => {
  return (
    field === "tuitionInfo.waitlistDescription" ||
    field === "tuitionInfo.withdrawalPolicyNoticePeriod" ||
    field === "tuitionInfo.withdrawalPolicyDescription" ||
    field === "tuitionInfo.waitlistFee" ||
    field === "tuitionInfo.waitlistProvided" ||
    field === "tuitionInfo.feesDescription" ||
    field === "tuitionInfo.feesLatePickUp" ||
    field === "tuitionInfo.feesActivity" ||
    field === "tuitionInfo.feesEarlyDropOff" ||
    field === "tuitionInfo.feesSupplies" ||
    field === "tuitionInfo.feesEnrollmentDeposit" ||
    field === "tuitionInfo.feesRegistration" ||
    field === "tuitionInfo.feesApplication" ||
    field === "tuitionInfo.discountsDescription" ||
    field === "tuitionInfo.discountsCorporateValue" ||
    field === "tuitionInfo.discountsMilitaryValue" ||
    field === "tuitionInfo.costPeriod" ||
    field === "tuitionInfo.costTo" ||
    field === "tuitionInfo.costFrom" ||
    field === "tuitionInfo.costDescription" ||
    field === "tuitionInfo.scheduleFile" ||
    field === "tuitionInfo.discountsSiblings" ||
    field === "tuitionInfo.discountsMilitary" ||
    field === "tuitionInfo.discountsCorporate" ||
    field === "tuitionInfo.discountsSiblingsValue"
  );
};

export const processServerErrors = (
  errors: ServerError[],
  setError: UseFormMethods["setError"],
): boolean => {
  let errorHandled = false;

  errors.forEach((e) => {
    const path = e.source.pointer?.split("/").filter(Boolean).join(".");

    if (!path) {
      return false;
    }

    if (isFieldOfFacilityTuitionInfo(path)) {
      setError(path, {
        message: e.title,
        type: "manual",
      });
      errorHandled = true;
      return true;
    }
  });

  return errorHandled;
};

const discountsMap = keyBy(DISCOUNT_ITEMS, "id");

const EditFacilityForm: React.FC<IProps> = ({
  facilityView,
  setFacilityView,
  isClaim,
  isShortClaim,
}) => {
  const tuitionInfo = useMemo(
    () => FacilityTuitionInfo.fromDto(facilityView.tuitionInfo),
    [],
  );
  const { control, errors } = useFormContext();
  const classes = useStyles();

  const updateView = () => {
    setFacilityView((prev) => ({
      ...prev,
      tuitionInfo: tuitionInfo.toDto(),
    }));
  };

  return (
    <div data-test="facility-operating-details-editor">
      {!isShortClaim && (
        <>
          <LinkOrFile
            label="Tuition schedule"
            file={tuitionInfo.scheduleFile}
            onChange={(file) => {
              tuitionInfo.scheduleFile = file;
              updateView();
            }}
            formData={{
              control,
              error: errors.scheduleFile?.message,
              name: "scheduleFile",
            }}
          />

          <Spacer size="medium" />
        </>
      )}

      <ControlledSelectField
        defaultValue={tuitionInfo.costPeriod}
        name="tuitionInfo.costPeriod"
        items={paymentPeriodTypes}
        labelProp="title"
        label="Tuition"
        formControlClasses={{
          root: classes.tuitionSelect,
        }}
        required={isClaim}
        onChange={(event) => {
          tuitionInfo.costPeriod = event.value.id;
          updateView();
        }}
      />

      {facilityView.tuitionInfo.costPeriod &&
        facilityView.tuitionInfo.costPeriod !== Models.TuitionTypeID.Free && (
          <div className={classes.agesWrapper}>
            <ControlledInputField
              required
              defaultValue={tuitionInfo.costFrom?.value}
              name="tuitionInfo.costFrom"
              label="From"
              formControlClasses={{
                root: classes.tuitionInput,
              }}
              useCurrencyAdornment
              variant="integer"
              maxLength={5}
              error={errors.costFrom?.message}
              noZero
              onChange={(event) => {
                tuitionInfo.costFrom = event.target.value;
                updateView();
              }}
            />

            <ControlledInputField
              defaultValue={tuitionInfo.costTo?.value}
              required
              name="tuitionInfo.costTo"
              label="To"
              formControlClasses={{
                root: classes.tuitionInput,
              }}
              useCurrencyAdornment
              variant="integer"
              maxLength={5}
              error={errors.costTo?.message}
              noZero
              onChange={(event) => {
                tuitionInfo.costTo = event.target.value;
                updateView();
              }}
            />
          </div>
        )}

      <Spacer size="small" />

      {!isShortClaim && (
        <>
          <ControlledMultilineInputField
            defaultValue={tuitionInfo.costDescription}
            onChange={(event) => {
              tuitionInfo.costDescription = event.target.value;
              updateView();
            }}
            placeholder="Tuition includes / excludes ..."
            name="tuitionInfo.costDescription"
          />

          <Spacer size="small" />
        </>
      )}

      {!isShortClaim && (
        <>
          <Typography
            variant="h5"
            align="center"
            className={classes.sectionTitle}
          >
            Tuition discounts
          </Typography>

          <Spacer size="medium" />

          <div className={classes.discountRow}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!tuitionInfo.discountsSiblings}
                  onChange={() => {
                    tuitionInfo.discountsSiblings =
                      !tuitionInfo.discountsSiblings;
                    updateView();
                  }}
                  value="edit-sibling-discount"
                  data-test="edit-sibling-discount"
                  color="primary"
                />
              }
              label="Sibling discount"
            />
            <MultipleSelect
              onChange={(event) => {
                tuitionInfo.discountsSiblingsValue = event.target.value.map(
                  (d) => d.id,
                );
                updateView();
              }}
              controlClassname="control"
              className="select"
              disabled={!tuitionInfo.discountsSiblings}
              items={DISCOUNT_ITEMS}
              itemLabelProp="name"
              value={
                tuitionInfo.discountsSiblingsValue?.map(
                  (d) => discountsMap[d],
                ) || []
              }
              renderValue={(selected) =>
                formatDiscountValue(selected.map((d) => d.id))
              }
              name="tuitionInfo.discountsSiblingsValue"
            />
          </div>
          <div className={classes.discountRow}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!tuitionInfo.discountsMilitary}
                  onChange={() => {
                    tuitionInfo.discountsMilitary =
                      !tuitionInfo.discountsMilitary;
                    updateView();
                  }}
                  value="edit-military-discount"
                  data-test="edit-military-discount"
                  color="primary"
                />
              }
              label="Military discount"
            />
            <MultipleSelect
              onChange={(event) => {
                tuitionInfo.discountsMilitaryValue = event.target.value.map(
                  (d) => d.id,
                );
                updateView();
              }}
              disabled={!tuitionInfo.discountsMilitary}
              controlClassname="control"
              className="select"
              items={DISCOUNT_ITEMS}
              itemLabelProp="name"
              value={
                tuitionInfo.discountsMilitaryValue?.map(
                  (d) => discountsMap[d],
                ) || []
              }
              renderValue={(selected) =>
                formatDiscountValue(selected.map((d) => d.id))
              }
              name="tuitionInfo.discountsMilitaryValue"
            />
          </div>
          <div className={classes.discountRow}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!tuitionInfo.discountsCorporate}
                  onChange={() => {
                    tuitionInfo.discountsCorporate =
                      !tuitionInfo.discountsCorporate;
                    updateView();
                  }}
                  value="edit-corporate-discount"
                  data-test="edit-corporate-discount"
                  color="primary"
                />
              }
              label="Corporate discount"
            />
            <MultipleSelect
              onChange={(event) => {
                tuitionInfo.discountsCorporateValue = event.target.value.map(
                  (d) => d.id,
                );
                updateView();
              }}
              controlClassname="control"
              className="select"
              disabled={!tuitionInfo.discountsCorporate}
              items={DISCOUNT_ITEMS}
              itemLabelProp="name"
              value={
                tuitionInfo.discountsCorporateValue?.map(
                  (d) => discountsMap[d],
                ) || []
              }
              renderValue={(selected) =>
                formatDiscountValue(selected.map((d) => d.id))
              }
              name="tuitionInfo.discountsCorporateValue"
            />
          </div>

          <ControlledMultilineInputField
            defaultValue={tuitionInfo.discountsDescription}
            onChange={(event) => {
              tuitionInfo.discountsDescription = event.target.value;
              updateView();
            }}
            placeholder="Describe any additional fees ..."
            name="tuitionInfo.discountsDescription"
          />

          <Spacer size="small" />
        </>
      )}

      {!isShortClaim && (
        <>
          <Typography
            variant="h5"
            align="center"
            className={classes.sectionTitle}
          >
            Fees schedule
          </Typography>

          <Spacer size="medium" />
        </>
      )}

      <div className={classes.commonFeesRow}>
        <FormControl
          error={!!errors.applicationFee?.message}
          className={clsx({
            "form-error": !!errors.applicationFee?.message,
          })}
        >
          <FormLabel className={classes.commonFeesLabel}>
            <Typography variant="inherit" required={isClaim}>
              Application fee:
            </Typography>
          </FormLabel>
        </FormControl>

        <ControlledInputField
          name="tuitionInfo.feesApplication"
          defaultValue={tuitionInfo.feesApplication?.value}
          required={isClaim}
          onChange={(event) => {
            tuitionInfo.feesApplication = event.target.value;
            updateView();
          }}
          formControlClasses={{
            root: classes.commonFeesInput,
          }}
          useCurrencyAdornment
          variant="integer"
          maxLength={5}
        />
      </div>

      {!isShortClaim && (
        <>
          <div className={classes.commonFeesRow}>
            <FormLabel className={classes.commonFeesLabel}>
              Registration fee:
            </FormLabel>

            <ControlledInputField
              name="tuitionInfo.feesRegistration"
              defaultValue={tuitionInfo.feesRegistration?.value}
              onChange={(event) => {
                tuitionInfo.feesRegistration = event.target.value;
                updateView();
              }}
              formControlClasses={{
                root: classes.commonFeesInput,
              }}
              useCurrencyAdornment
              variant="integer"
              maxLength={5}
            />
          </div>

          <div className={classes.commonFeesRow}>
            <FormLabel className={classes.commonFeesLabel}>
              Enrollment deposit:
            </FormLabel>

            <ControlledInputField
              name="tuitionInfo.feesEnrollmentDeposit"
              defaultValue={tuitionInfo.feesEnrollmentDeposit?.value}
              onChange={(event) => {
                tuitionInfo.feesEnrollmentDeposit = event.target.value;
                updateView();
              }}
              formControlClasses={{
                root: classes.commonFeesInput,
              }}
              useCurrencyAdornment
              variant="integer"
              maxLength={5}
            />
          </div>

          <div className={classes.commonFeesRow}>
            <FormLabel className={classes.commonFeesLabel}>
              Supplies fee:
            </FormLabel>

            <ControlledInputField
              name="tuitionInfo.feesSupplies"
              defaultValue={tuitionInfo.feesSupplies?.value}
              onChange={(event) => {
                tuitionInfo.feesSupplies = event.target.value;
                updateView();
              }}
              formControlClasses={{
                root: classes.commonFeesInput,
              }}
              useCurrencyAdornment
              variant="integer"
              maxLength={5}
            />
          </div>

          <div className={classes.commonFeesRow}>
            <FormLabel className={classes.commonFeesLabel}>
              Activity fee:
            </FormLabel>

            <ControlledInputField
              name="tuitionInfo.feesActivity"
              defaultValue={tuitionInfo.feesActivity?.value}
              onChange={(event) => {
                tuitionInfo.feesActivity = event.target.value;
                updateView();
              }}
              formControlClasses={{
                root: classes.commonFeesInput,
              }}
              useCurrencyAdornment
              variant="integer"
              maxLength={5}
            />
          </div>

          <div className={classes.commonFeesRow}>
            <FormLabel className={classes.commonFeesLabel}>
              Early drop-off fee:
            </FormLabel>

            <ControlledInputField
              name="tuitionInfo.feesEarlyDropOff"
              defaultValue={tuitionInfo.feesEarlyDropOff?.value}
              onChange={(event) => {
                tuitionInfo.feesEarlyDropOff = event.target.value;
                updateView();
              }}
              formControlClasses={{
                root: classes.commonFeesInput,
              }}
              useCurrencyAdornment
              variant="integer"
              maxLength={5}
            />
          </div>

          <div className={classes.commonFeesRow}>
            <FormLabel className={classes.commonFeesLabel}>
              Late pick-up fee:
            </FormLabel>

            <ControlledInputField
              name="tuitionInfo.feesLatePickUp"
              defaultValue={tuitionInfo.feesLatePickUp?.value}
              onChange={(event) => {
                tuitionInfo.feesLatePickUp = event.target.value;
                updateView();
              }}
              formControlClasses={{
                root: classes.commonFeesInput,
              }}
              useCurrencyAdornment
              variant="integer"
              maxLength={5}
            />
          </div>

          <Spacer />

          <ControlledMultilineInputField
            defaultValue={tuitionInfo.feesDescription}
            onChange={(event) => {
              tuitionInfo.feesDescription = event.target.value;
              updateView();
            }}
            placeholder="Describe any additional fees ..."
            name="tuitionInfo.feesDescription"
          />

          <Spacer size="small" />
        </>
      )}

      {!isShortClaim && (
        <>
          <Typography
            variant="h5"
            align="center"
            className={classes.sectionTitle}
          >
            Waitlist information
          </Typography>

          <Spacer size="medium" />
        </>
      )}

      {isShortClaim && <Spacer size="small" />}

      <ControlledSelectField
        name="tuitionInfo.waitlistProvided"
        defaultValue={tuitionInfo.waitlistProvided}
        items={TUITION_WAITLIST}
        labelProp="name"
        label="Waitlist"
        formControlClasses={{
          root: classes.tuitionSelect,
        }}
        required={isClaim}
        onChange={(event) => {
          tuitionInfo.waitlistProvided = event.value.id;
          updateView();
        }}
      />

      {!isShortClaim && (
        <>
          <Spacer size="small" />

          <div className={classes.commonFeesRow}>
            <FormLabel className={classes.commonFeesLabel}>
              Waitlist fee:
            </FormLabel>

            <ControlledInputField
              name="tuitionInfo.waitlistFee"
              defaultValue={tuitionInfo.waitlistFee?.value}
              onChange={(event) => {
                tuitionInfo.waitlistFee = event.target.value;
                updateView();
              }}
              formControlClasses={{
                root: classes.commonFeesInput,
              }}
              useCurrencyAdornment
              variant="integer"
              maxLength={5}
            />
          </div>

          <Spacer size="small" />

          <ControlledMultilineInputField
            defaultValue={tuitionInfo.waitlistDescription}
            onChange={(event) => {
              tuitionInfo.waitlistDescription = event.target.value;
              updateView();
            }}
            placeholder="Describe any additional fees ..."
            name="tuitionInfo.waitlistDescription"
          />
        </>
      )}

      <Spacer size="small" />

      {!isShortClaim && (
        <>
          <Typography
            variant="h5"
            align="center"
            className={classes.sectionTitle}
          >
            Withdrawal policy
          </Typography>

          <Spacer size="medium" />

          <ControlledSelectField
            name="tuitionInfo.advancedNotice"
            defaultValue={tuitionInfo.withdrawalPolicyNoticePeriod}
            items={TUITION_WITHDRAWAL}
            labelProp="name"
            label="Advance notice"
            formControlClasses={{
              root: classes.tuitionSelect,
            }}
            onChange={(event) => {
              tuitionInfo.withdrawalPolicyNoticePeriod = event.value.id;
              updateView();
            }}
          />

          <Spacer size="small" />

          <ControlledMultilineInputField
            defaultValue={tuitionInfo.withdrawalPolicyDescription}
            onChange={(event) => {
              tuitionInfo.withdrawalPolicyDescription = event.target.value;
              updateView();
            }}
            placeholder="Describe any additional fees ..."
            name="tuitionInfo.withdrawalPolicyDescription"
          />
        </>
      )}
    </div>
  );
};

export default EditFacilityForm;
