import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes, TierProps } from "./types";

export const createCss = (
  props: Partial<TierProps>,
): Record<Classes, Interpolation<ITheme>> => ({
  root: (theme) => ({
    alignItems: "center",
    backgroundColor: props.active ? undefined : "rgba(0, 0, 0, .06)",
    border: props.active
      ? `1px solid ${theme.palette.text.primary}`
      : undefined,
    borderRadius: 5,
    color: props.active
      ? theme.palette.text.primary
      : theme.palette.text.secondary,
    cursor: "pointer",
    display: "flex",
    fontSize: 14,
    fontWeight: 600,
    justifyContent: "center",
    padding: 5,
    textAlign: "center",
    textTransform: "uppercase",

    "& svg": {
      height: 16,
      marginRight: 7,
      width: 16,
    },
  }),
});
