import { createUseStyles } from "react-jss";

export default createUseStyles(
  {
    root: {
      "&.withGap": {
        margin: "16px 0",
      },
    },
  },
  { name: "CustomDivider" },
);
