import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";
import { Models } from "@services/api";

export const useCss = ({
  tier,
}: {
  tier: Models.SubscriptionTier;
}): Record<Classes, Interpolation<ITheme>> => ({
  root: {
    alignItems: "center",
    display: "flex",
    fontSize: 18,
    fontWeight: tier === Models.SubscriptionTier.Community ? 600 : 700,

    "& .icon": {
      color: "inherit",
      height: 24,
      marginRight: 8,
      width: 24,
    },
  },
});
