import React from "react";

import { createCss } from "../styles";
import Typography from "@ui-kit/Typography";
import { Spacer } from "@ui-kit/Spacer";
import { Overview } from "@components/Overview";
import { Models } from "@services/api";

interface PoliciesSectionProps {
  bookingConfig: Models.BookingConfig;
}

export const PoliciesSection: React.FC<PoliciesSectionProps> = ({
  bookingConfig,
}) => {
  const css = createCss();

  if (
    !bookingConfig.dropOffOverview &&
    !bookingConfig.pickUpOverview &&
    !bookingConfig.mealsOverview &&
    !bookingConfig.suppliesOverview &&
    !bookingConfig.activitiesOverview
  ) {
    return null;
  }

  return (
    <>
      <Spacer size="md" />

      <Typography variant="h5" bolded align="center" css={css.sectionTitle}>
        Policies & procedures
      </Typography>

      <Spacer size="sm" />

      {!!bookingConfig?.dropOffOverview && (
        <>
          <Typography bolded>Drop-off:</Typography>
          <Overview
            text={
              <Typography italic>{bookingConfig.dropOffOverview}</Typography>
            }
          />
          <Spacer size="sm" />
        </>
      )}

      {!!bookingConfig.pickUpOverview && (
        <>
          <Typography bolded>Pick-up:</Typography>
          <Overview
            text={
              <Typography italic>{bookingConfig.pickUpOverview}</Typography>
            }
          />
          <Spacer size="sm" />
        </>
      )}

      {!!bookingConfig.mealsOverview && (
        <>
          <Typography bolded>Meals:</Typography>
          <Overview
            text={<Typography italic>{bookingConfig.mealsOverview}</Typography>}
          />
          <Spacer size="sm" />
        </>
      )}

      {!!bookingConfig.suppliesOverview && (
        <>
          <Typography bolded>Supplies:</Typography>
          <Overview
            text={
              <Typography italic>{bookingConfig.suppliesOverview}</Typography>
            }
          />
          <Spacer size="sm" />
        </>
      )}

      {!!bookingConfig.activitiesOverview && (
        <>
          <Typography bolded>Planned activities:</Typography>
          <Overview
            text={
              <Typography italic>{bookingConfig.activitiesOverview}</Typography>
            }
          />
        </>
      )}
    </>
  );
};
