import React from "react";

import Typography from "@ui-kit/Typography";
import { useDeepDivePanelStore } from "@hooks";
import { format_percent } from "@helpers/format_number";

export const AsianStudents: React.FC = () => {
  const [{ facility }] = useDeepDivePanelStore();

  if (!facility.asianRacePercent) {
    return null;
  }

  return (
    <Typography data-test="asian-students-display" gutterBottom>
      <Typography variant="inherit" bolded>
        Asian students:
      </Typography>{" "}
      {facility.asianRacePercent < 0.01
        ? "less than 1%"
        : format_percent(facility.asianRacePercent, { round: true })}
    </Typography>
  );
};
