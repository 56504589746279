import React, { useState, useMemo, useRef, Fragment } from "react";
import { css as emotionCss } from "@emotion/css";
import {
  useFormContext,
  Controller,
  UseFormMethods,
  FieldError,
} from "react-hook-form";
import clsx from "clsx";
import { DateTime } from "luxon";
import FormLabel from "@material-ui/core/FormLabel";
import orderBy from "lodash/orderBy";
import Checkbox from "@material-ui/core/Checkbox";
import isNumber from "lodash/isNumber";
import forEach from "lodash/forEach";

import { Input, OutlinedInput } from "@ui-kit/InputFields";
import { ControlledPhoneInput } from "@ui-kit/PhoneInput";
import useStyles from "./styles";
import Select from "@ui-kit/Select";
import { LANGUAGES, languagesMap } from "@constants/languages";
import Typography from "@ui-kit/Typography";
import { Models } from "@services/api";
import { STATUSES, statusesMap } from "@constants/facility-statuses";
import { email, required } from "@validators";
import {
  DESIGNATIONS,
  designationsMap,
} from "@constants/additional_designations";
import { isPublicSchool } from "@selectors";
import { useDeepDivePanelStore } from "@hooks";
import { EditFacilityContactInfoViewProps } from "./types";
import { ServerError } from "@models/common";
import { generateTimeList } from "@helpers/generateTimeList";
import MultipleSelect from "@ui-kit/MultipleSelect";

export type FormFields = {
  phone: Models.Phone;
  email: string;
  name: string;
  endTime: number | "";
  startTime: number | "";
};

const isFieldOfFacilityContactInfo = (field: string) => {
  return (
    field === "phone" ||
    field === "email" ||
    field === "name" ||
    field === "endTime" ||
    field === "startTime"
  );
};

export const processServerErrors = (
  errors: ServerError[],
  setError: UseFormMethods["setError"],
): boolean => {
  let errorHandled = false;

  errors.forEach((e) => {
    const paths = e.source.pointer?.split("/");

    if (!paths) {
      return false;
    }

    if (isFieldOfFacilityContactInfo(paths[1])) {
      setError(paths[1], {
        message: e.title,
        type: "manual",
      });
      errorHandled = true;
      return true;
    }
  });

  return errorHandled;
};

const timeList = generateTimeList(0, 48);

const ScheduleTemplate = [
  { day: Models.WeekDay.Monday, start: null, end: null },
  { day: Models.WeekDay.Tuesday, start: null, end: null },
  { day: Models.WeekDay.Wednesday, start: null, end: null },
  { day: Models.WeekDay.Thursday, start: null, end: null },
  { day: Models.WeekDay.Friday, start: null, end: null },
];

export const EditContactInfo: React.FC<EditFacilityContactInfoViewProps> = ({
  facilityView,
  setFacilityView,
  state,
  editMode = true,
  onStateChange,
  requiredFields = {},
}) => {
  const [{ facility }] = useDeepDivePanelStore();
  const { control, errors, watch } = useFormContext<FormFields>();
  const [startTimeList] = useState(() => {
    if (
      !facility.schedule.length ||
      !isNumber(facility.schedule[0].start) ||
      timeList.find((t) => t.time === facility.schedule[0].start)
    ) {
      return [...timeList];
    }

    const result = [...timeList];
    let wasInserted = false;
    const item = {
      id: facility.schedule[0].start,
      name: DateTime.local()
        .startOf("day")
        .plus({ seconds: facility.schedule[0].start })
        .toFormat("h:mma")
        .toLowerCase(),
      time: facility.schedule[0].start,
    };

    forEach(result, (t, index) => {
      if (facility.schedule[0].start < t.time) {
        result.splice(index, 0, item);
        wasInserted = true;
        return false;
      }
    });

    if (!wasInserted) {
      result.push(item);
    }

    return result;
  });
  const [endTimeList] = useState(() => {
    if (
      !facility.schedule.length ||
      !isNumber(facility.schedule[0].end) ||
      timeList.find((t) => t.id === facility.schedule[0].end)
    ) {
      return [...timeList];
    }

    const result = [...timeList];
    let wasInserted = false;
    const item = {
      id: facility.schedule[0].end,
      name: DateTime.local()
        .startOf("day")
        .plus({ seconds: facility.schedule[0].end })
        .toFormat("h:mma")
        .toLowerCase(),
      time: facility.schedule[0].end,
    };

    forEach(result, (t, index) => {
      if (facility.schedule[0].end < t.time) {
        result.splice(index, 0, item);
        wasInserted = true;
        return false;
      }
    });

    if (!wasInserted) {
      result.push(item);
    }

    return result;
  });
  const defaultTimeStart = useRef<number | "">(
    isNumber(facilityView.schedule[0]?.start)
      ? facilityView.schedule[0].start
      : "",
  );
  const defaultTimeEnd = useRef<number | "">(
    isNumber(facilityView.schedule[0]?.end) ? facilityView.schedule[0].end : "",
  );
  const classes = useStyles();

  const TimeStartWatch =
    watch("startTime", defaultTimeStart.current) !== ""
      ? parseInt(watch("startTime", defaultTimeStart.current).toString(), 10)
      : "";
  const TimeEndWatch =
    watch("endTime", defaultTimeEnd.current) !== ""
      ? parseInt(watch("endTime", defaultTimeEnd.current).toString(), 10)
      : "";

  const TimeStartList = useMemo(() => {
    return isNumber(TimeEndWatch)
      ? startTimeList.filter((t) => t.time < TimeEndWatch)
      : startTimeList;
  }, [TimeEndWatch, startTimeList]);
  const TimeEndList = useMemo(() => {
    return isNumber(TimeStartWatch)
      ? endTimeList.filter((t) => t.time > TimeStartWatch)
      : endTimeList;
  }, [TimeStartWatch, endTimeList]);

  return (
    <div data-test="facility-contacts-editor">
      {editMode && (
        <Typography variant="body1" align="right">
          Mark Incorrect
        </Typography>
      )}

      <div className={classes.propertyLabel}>
        <FormLabel>
          <Typography variant="inherit" required={requiredFields.name}>
            Name
          </Typography>
        </FormLabel>

        {editMode && (
          <Checkbox
            checked={state.editName}
            onChange={() =>
              onStateChange({ ...state, editName: !state.editName })
            }
            value="editName"
            className={classes.propertyLabelCheck}
            data-test="state-check-editName"
            color="primary"
            disabled={state.editName && facility.name !== facilityView.name}
          />
        )}
      </div>

      <div data-test="editable-item-block" data-name="editName">
        <Typography
          className={clsx(classes.propertyValue, {
            edit: state.editName,
          })}
          onClick={
            editMode
              ? () => onStateChange({ ...state, editName: true })
              : undefined
          }
          data-test="field-name-editName"
        >
          {facility.name}
        </Typography>

        <div
          style={{
            display: state.editName ? undefined : "none",
          }}
        >
          <Controller
            render={({ onChange, name, value }) => (
              <Input
                data-test="name-field"
                error={errors.name?.message}
                name={name}
                value={value}
                onChange={(event) => {
                  setFacilityView((prev) => ({
                    ...prev,
                    name: event.target.value,
                  }));

                  onChange(event);
                }}
              />
            )}
            rules={{
              validate: (value: string) => {
                if (!value) {
                  return "Cannot be blank";
                }

                return true;
              },
              ...(requiredFields.name ? required() : {}),
            }}
            defaultValue={facilityView.name || ""}
            name="name"
            control={control}
          />
        </div>
      </div>

      {isPublicSchool(facilityView) && (
        <Fragment>
          <div className={classes.propertyLabel}>
            <FormLabel>
              <Typography variant="inherit" required={requiredFields.name}>
                School district
              </Typography>
            </FormLabel>

            {editMode && (
              <Checkbox
                checked={state.editDistrict}
                onChange={() =>
                  onStateChange({ ...state, editDistrict: !state.editDistrict })
                }
                value="editDistrict"
                className={classes.propertyLabelCheck}
                data-test="state-check-editDistrict"
                color="primary"
                disabled={
                  state.editDistrict &&
                  facility.schoolDistrict !== facilityView.schoolDistrict
                }
              />
            )}
          </div>

          <Typography
            variant="body1"
            className={clsx(classes.propertyValue, {
              edit: state.editDistrict,
            })}
            onClick={
              editMode
                ? () => onStateChange({ ...state, editDistrict: true })
                : undefined
            }
            data-test="field-name-editDistrict"
          >
            {facility.schoolDistrict}
          </Typography>

          {state.editDistrict && (
            <Input
              value={facilityView.schoolDistrict}
              onChange={(event) => {
                setFacilityView((prev) => ({
                  ...prev,
                  schoolDistrict: event.target.value,
                }));
              }}
              data-test="district-field"
            />
          )}
        </Fragment>
      )}

      <div className={classes.propertyLabel}>
        <FormLabel>Second language</FormLabel>

        {editMode && (
          <Checkbox
            checked={state.editLanguages}
            onChange={() =>
              onStateChange({ ...state, editLanguages: !state.editLanguages })
            }
            value="editLanguages"
            className={classes.propertyLabelCheck}
            data-test="state-check-editLanguages"
            color="primary"
            disabled={
              state.editLanguages &&
              facility.languages[1] !== facilityView.languages[1]
            }
          />
        )}
      </div>

      <Typography
        className={clsx(classes.propertyValue, {
          edit: state.editLanguages,
        })}
        onClick={
          editMode
            ? () => onStateChange({ ...state, editLanguages: true })
            : undefined
        }
        data-test="field-name-editLanguages"
      >
        {facility.languages.length > 1
          ? orderBy(
              LANGUAGES.filter(
                (l) =>
                  facility.languages.includes(l.id) &&
                  l.id !== Models.Languages.English,
              ),
              "filterOrder",
            )
              .map((s) => s.title)
              .join(", ")
          : "add second language"}
      </Typography>

      {state.editLanguages && (
        <MultipleSelect
          value={facilityView.languages.map((l) => languagesMap[l])}
          items={orderBy(
            LANGUAGES.filter((l) => l.id !== Models.Languages.English),
            "filterOrder",
          )}
          itemLabelProp="title"
          renderValue={(selected) =>
            orderBy(
              selected.filter((l) => l.id !== Models.Languages.English),
              "filterOrder",
            )
              .map((s) => s.title)
              .join(", ")
          }
          controlClassname={emotionCss({
            marginTop: 0,
          })}
          name="language"
          onChange={(event) => {
            const value = event.target.value;

            if (value.length > 3) {
              return;
            }

            setFacilityView((prev) => ({
              ...prev,
              languages: value.map((l) => l.id),
            }));
          }}
        />
      )}

      <div className={classes.propertyLabel}>
        <FormLabel>
          <Typography
            variant="inherit"
            required={requiredFields.operatingHours}
          >
            Operating hours
          </Typography>
        </FormLabel>

        {editMode && (
          <Checkbox
            checked={state.editOperatingHours}
            onChange={() =>
              onStateChange({
                ...state,
                editOperatingHours: !state.editOperatingHours,
              })
            }
            value="editOperatingHours"
            className={classes.propertyLabelCheck}
            data-test="state-check-editOperatingHours"
            color="primary"
            disabled={
              state.editOperatingHours &&
              (facility.schedule[0]?.start !==
                facilityView.schedule[0]?.start ||
                facility.schedule[0]?.end !== facilityView.schedule[0]?.end ||
                facility.operatingHoursInfo !== facilityView.operatingHoursInfo)
            }
          />
        )}
      </div>

      <div data-test="editable-item-block" data-name="editOperatingHours">
        <Typography
          className={clsx(classes.propertyValue, {
            edit: state.editOperatingHours,
          })}
          onClick={
            editMode
              ? () => onStateChange({ ...state, editOperatingHours: true })
              : undefined
          }
          data-test="field-name-editOperatingHours"
        >
          {facility.schedule.length
            ? `${
                isNumber(facility.schedule[0].start) &&
                startTimeList.find((t) => t.time === facility.schedule[0].start)
                  ?.name
              } ` +
              `- ${
                isNumber(facility.schedule[0].end) &&
                endTimeList.find((t) => t.time === facility.schedule[0].end)
                  ?.name
              }`
            : "add operating hours"}
        </Typography>

        <div
          style={{
            display: state.editOperatingHours ? undefined : "none",
          }}
        >
          <div className={classes.timeActionsRow}>
            <Input value="Mon - Fri" disabled />

            <Controller
              render={({ onChange, ...controllerProps }) => (
                <Select
                  {...controllerProps}
                  items={TimeStartList}
                  labelProp="name"
                  data-test="startTime-select"
                  error={errors.startTime?.message}
                  onChange={(event) => {
                    const start = event.value.id;

                    if (isNumber(start) && isNumber(TimeEndWatch)) {
                      setFacilityView((prev) => ({
                        ...prev,
                        schedule: ScheduleTemplate.map((s) => ({
                          ...s,
                          end: TimeEndWatch,
                          start,
                        })),
                      }));
                    }

                    onChange(event);
                  }}
                />
              )}
              rules={{
                validate: (value) => {
                  if (requiredFields.operatingHours && value === "") {
                    return required().required;
                  }

                  if (watch("endTime") !== "" && value === "") {
                    return required().required;
                  }
                  return true;
                },
              }}
              defaultValue={defaultTimeStart.current}
              name="startTime"
              control={control}
            />

            <Controller
              render={({ onChange, name, value }) => (
                <Select
                  items={TimeEndList}
                  labelProp="name"
                  data-test="endTime-select"
                  error={errors.endTime?.message}
                  name={name}
                  value={value}
                  onChange={(event) => {
                    const end = event.value.id;

                    if (isNumber(end) && isNumber(TimeStartWatch)) {
                      setFacilityView((prev) => ({
                        ...prev,
                        schedule: ScheduleTemplate.map((s) => ({
                          ...s,
                          end,
                          start: TimeStartWatch,
                        })),
                      }));
                    }

                    onChange(event);
                  }}
                />
              )}
              rules={{
                validate: (value) => {
                  if (requiredFields.operatingHours && value === "") {
                    return required().required;
                  }

                  if (watch("startTime") !== "" && value === "") {
                    return required().required;
                  }
                  return true;
                },
              }}
              defaultValue={defaultTimeEnd.current}
              name="endTime"
              control={control}
            />
          </div>

          <OutlinedInput
            value={facilityView.operatingHoursInfo}
            onChange={(event) => {
              setFacilityView((prev) => ({
                ...prev,
                operatingHoursInfo: event.target.value,
              }));
            }}
            multiline
            placeholder="Describe early drop-off and late pick-up policies, procedures & costs"
            rowsMax="6"
            data-test="operatingHoursInfo-field"
            maxChars={750}
          />
        </div>
      </div>

      <div className={classes.propertyLabel}>
        <FormLabel>
          <Typography variant="inherit" required={requiredFields.phone}>
            Contact phone
          </Typography>
        </FormLabel>

        {editMode && (
          <Checkbox
            checked={state.editPhone}
            onChange={() =>
              onStateChange({ ...state, editPhone: !state.editPhone })
            }
            value="editPhone"
            className={classes.propertyLabelCheck}
            data-test="state-check-editPhone"
            color="primary"
            disabled={
              state.editPhone &&
              facility.phone?.number !== facilityView.phone?.number
            }
          />
        )}
      </div>

      <div data-test="editable-item-block" data-name="editPhone">
        <Typography
          className={clsx(classes.propertyValue, {
            edit: state.editPhone,
          })}
          onClick={
            editMode
              ? () => onStateChange({ ...state, editPhone: true })
              : undefined
          }
          data-test="field-name-editPhone"
        >
          {facility.phone?.number || "add phone #"}
        </Typography>

        <div
          style={{
            display: state.editPhone ? undefined : "none",
          }}
        >
          <ControlledPhoneInput
            error={(errors.phone as FieldError)?.message}
            defaultCountry={facility.address.country as Models.Countries}
            name="phone"
            onChange={(state) => {
              setFacilityView((prev) => ({
                ...prev,
                phone: state[0],
              }));
            }}
            control={control}
            required={requiredFields.phone || Boolean(facility.phone?.number)}
            defaultValue={facilityView.phone}
            disableCountriesSelect
          />
        </div>
      </div>

      <div className={classes.propertyLabel}>
        <FormLabel>
          <Typography variant="inherit" required={requiredFields.email}>
            Contact email
          </Typography>
        </FormLabel>

        {editMode && (
          <Checkbox
            checked={state.editEmail}
            onChange={() =>
              onStateChange({ ...state, editEmail: !state.editEmail })
            }
            value="editEmail"
            className={classes.propertyLabelCheck}
            data-test="state-check-editEmail"
            color="primary"
            disabled={state.editEmail && facility.email !== facilityView.email}
          />
        )}
      </div>

      <div data-test="editable-item-block" data-name="editEmail">
        <Typography
          className={clsx(classes.propertyValue, {
            edit: state.editEmail,
          })}
          onClick={
            editMode
              ? () => onStateChange({ ...state, editEmail: true })
              : undefined
          }
          data-test="field-name-editEmail"
        >
          {facility.email || "add email"}
        </Typography>

        <div
          style={{
            display: state.editEmail ? undefined : "none",
          }}
        >
          <Controller
            render={({ onChange, value, name }) => (
              <Input
                type="email"
                data-test="email-field"
                error={errors.email?.message}
                name={name}
                value={value}
                onChange={(event) => {
                  setFacilityView((prev) => ({
                    ...prev,
                    email: event.target.value,
                  }));

                  onChange(event);
                }}
              />
            )}
            rules={{
              ...email(),
              ...(requiredFields.email || Boolean(facility.email)
                ? required()
                : {}),
            }}
            defaultValue={facilityView.email || ""}
            name="email"
            control={control}
          />
        </div>
      </div>

      <div className={classes.propertyLabel}>
        <FormLabel>Operational status</FormLabel>

        {editMode && (
          <Checkbox
            checked={state.editStatus}
            onChange={() =>
              onStateChange({ ...state, editStatus: !state.editStatus })
            }
            value="editStatus"
            className={classes.propertyLabelCheck}
            data-test="state-check-editStatus"
            color="primary"
            disabled={
              state.editStatus &&
              facility.businessStatus !== facilityView.businessStatus
            }
          />
        )}
      </div>

      <Typography
        className={clsx(classes.propertyValue, {
          edit: state.editStatus,
        })}
        onClick={
          editMode
            ? () => onStateChange({ ...state, editStatus: true })
            : undefined
        }
        data-test="field-name-editStatus"
      >
        {!!facility.businessStatus
          ? statusesMap[facility.businessStatus].name
          : "add operational status"}
      </Typography>

      {state.editStatus && (
        <Select
          value={facilityView.businessStatus || ""}
          items={STATUSES}
          labelProp="name"
          onChange={(event) => {
            setFacilityView((prev) => ({
              ...prev,
              businessStatus: event.value.id || null,
            }));
          }}
          data-test="status-select"
          name="status"
        />
      )}

      <div className={classes.propertyLabel}>
        <FormLabel>Additional designations</FormLabel>

        {editMode && (
          <Checkbox
            checked={state.editDesignations}
            onChange={() =>
              onStateChange({
                ...state,
                editDesignations: !state.editDesignations,
              })
            }
            value="editDesignations"
            className={classes.propertyLabelCheck}
            data-test="state-check-editDesignations"
            color="primary"
            disabled={
              state.editDesignations &&
              facility.additionalDesignations !==
                facilityView.additionalDesignations
            }
          />
        )}
      </div>

      <Typography
        className={clsx(classes.propertyValue, {
          edit: state.editDesignations,
        })}
        onClick={
          editMode
            ? () => onStateChange({ ...state, editDesignations: true })
            : undefined
        }
        data-test="field-name-editDesignations"
      >
        {!facility.additionalDesignations
          ? "None"
          : designationsMap[facility.additionalDesignations].name}
      </Typography>

      {state.editDesignations && (
        <Select
          value={facilityView.additionalDesignations || ""}
          items={[{ id: "", name: "None" }, ...DESIGNATIONS]}
          labelProp="name"
          onChange={(event) => {
            setFacilityView((prev) => ({
              ...prev,
              additionalDesignations:
                (event.value.id as Models.AdditionalDesignations) || null,
            }));
          }}
          data-test="additional-designations-select"
          name="additional-designations"
        />
      )}
    </div>
  );
};
