import React, { useCallback, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

import Tooltip from "@material-ui/core/Tooltip";

import { NewDesignIcons } from "@components/Icons";
import { useDeepDivePanelStore } from "@hooks";
import { createCss } from "./styles";
import { Section } from "../Section";
import Typography from "@ui-kit/Typography";
import { Spacer } from "@ui-kit/Spacer";
import { Models } from "@services/api";
import { TryToClaimDialog } from "@pages/DeepDivePanel/TryToClaimDialog";

export const VirtualTourSection: React.FC = () => {
  const history = useHistory();
  const [{ facility }] = useDeepDivePanelStore();
  const { search } = useLocation();
  const css = createCss();

  const getIcon = useCallback((status: boolean) => {
    return status ? (
      <NewDesignIcons.CheckRounded className="status check" />
    ) : (
      <NewDesignIcons.CloseRounded className="status close" />
    );
  }, []);

  return (
    <div>
      <Section borderTop>
        <Typography variant="h4" css={{ fontWeight: 700 }} align="center">
          Virtual school tour
        </Typography>

        <Spacer size="small" />

        <div
          onClick={() => {
            history.push({
              pathname: `/map/${facility.id}/virtual-tour`,
              search,
            });
          }}
          data-test="tours-info-link"
        >
          <div css={css.indicatorsContainer}>
            <Tooltip title="Video tour" placement="top">
              <div css={css.indicator}>
                <NewDesignIcons.Camera className="icon" />
                {getIcon(!!facility.toursInfo?.videoTourUrl)}
              </div>
            </Tooltip>
            <Tooltip title="360-degree tour" placement="top">
              <div css={css.indicator}>
                <NewDesignIcons.Panorama className="icon" />
                {getIcon(!!facility.toursInfo?.vrTourUrl)}
              </div>
            </Tooltip>
            <Tooltip title="Message from faculty" placement="top">
              <div css={css.indicator}>
                <NewDesignIcons.Megaphone className="icon" />
                {getIcon(!!facility.toursInfo?.facultyUrl)}
              </div>
            </Tooltip>
            <Tooltip title="Staff bios & pics" placement="top">
              <div css={css.indicator}>
                <NewDesignIcons.PersonFolder className="icon" />
                {getIcon(!!facility.toursInfo?.staffUrl)}
              </div>
            </Tooltip>
            <Tooltip title="School brochure" placement="top">
              <div css={css.indicator}>
                <NewDesignIcons.Brochure className="icon" />
                {getIcon(!!facility.toursInfo?.brochureFile.original)}
              </div>
            </Tooltip>
            <Tooltip title="Facility floor plan" placement="top">
              <div css={css.indicator}>
                <NewDesignIcons.Floorplan className="icon" />
                {getIcon(!!facility.toursInfo?.floorplanFile.original)}
              </div>
            </Tooltip>
          </div>

          <div css={css.vrImageContainer}>
            {facility.subscriptionTier === Models.SubscriptionTier.Ivy ? (
              <NewDesignIcons.VrPremium css={css.vrImage} />
            ) : (
              <NewDesignIcons.Vr css={css.vrImage} />
            )}
          </div>
        </div>
      </Section>
    </div>
  );
};
