import React from "react";
import { css as emotionCss } from "@emotion/css";

import Dialog from "@ui-kit/Dialog";
import Button from "@ui-kit/Button";
import { Spacer } from "@ui-kit/Spacer";
import Typography from "@ui-kit/Typography";
import { useMapStore } from "@store/MapStore";
import { Models } from "@services/api";
import { createCss } from "./styles";

export const EmptySearchDialog: React.FC = () => {
  const [{ filterIsActive }, { setEmptySearchVisible, openFilters }] =
    useMapStore();
  const css = createCss();

  return (
    <Dialog
      open
      title="Search"
      onClose={() => setEmptySearchVisible(false)}
      tier={Models.SubscriptionTier.Ivy}
      classes={{
        root: emotionCss({
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent",
          },
        }),
      }}
    >
      <div css={css.root}>
        <Spacer size="small" />
        <Typography align="center">No results found</Typography>

        <Spacer size="medium" />

        <div
          css={css.actions}
          className={!filterIsActive.current ? "center" : undefined}
        >
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={() => setEmptySearchVisible(false)}
            css={css.button}
          >
            Close
          </Button>
          {filterIsActive.current && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                setEmptySearchVisible(false);
                openFilters();
              }}
              css={css.button}
            >
              Open filters
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
};
