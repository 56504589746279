import { createUseStyles } from "react-jss";
import { Interpolation } from "@emotion/react";

import { Classes } from "./types";
import { ITheme } from "@theme";

const styles = (theme: ITheme) => ({
  container: {
    position: "relative",

    [theme.mediaRequests.notMobile]: {
      width: 375,
    },
  },

  paper: {
    [theme.mediaRequests.notMobile]: {
      width: 428,
    },
  },

  topSection: {
    position: "relative",

    "& .content": {
      backgroundColor: theme.palette.background.default,
      position: "fixed",
      transform: "translateY(-16px)",
      zIndex: 10,
    },

    "& .divider": {
      marginTop: 16,
    },

    "& .placeholder": {
      visibility: "hidden",
    },
  },

  sectionTitle: {
    backgroundColor: "rgba(0, 0, 0, .06)",
    padding: 7,
  },
});

export default createUseStyles(styles, { name: "ReservationProgram" });

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  sectionTitle: {
    backgroundColor: "rgba(0, 0, 0, .06)",
    padding: 7,
  },
});
