import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  root: (theme) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 10,
    padding: 10,
  }),
});
