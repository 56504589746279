import { Interpolation, CSSObject } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";
import { Models } from "@services/api";

export const useCss = ({
  tier,
}: {
  tier: Models.SubscriptionTier;
}): Record<Classes, Interpolation<ITheme>> => ({
  root: (theme) => {
    const common: CSSObject = {
      position: "relative",
      width: "100%",
    };

    if (tier === Models.SubscriptionTier.Community) {
      return {
        ...common,
        padding: 15,
      };
    }

    return {
      ...common,
      padding: "10px 15px",

      [theme.mediaRequests.mobile]: {
        padding: "10px 0",
      },
    };
  },

  borderTop: (theme) => ({
    backgroundColor: theme.palette.divider,
    height: 8,
  }),

  content: (theme) => {
    if (tier === Models.SubscriptionTier.Pro) {
      return {
        backgroundColor: "white",
        border: "2px solid #d9d9dd",
        borderRadius: 10,
        boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.2)",
        padding: "24px 20px",

        [theme.mediaRequests.mobile]: {
          border: 0,
          borderRadius: 0,
        },
      };
    }

    if (tier === Models.SubscriptionTier.Ivy) {
      return {
        backgroundColor: "white",
        border: "2px solid white",
        borderRadius: 10,
        boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.2)",
        padding: 15,

        [theme.mediaRequests.mobile]: {
          border: 0,
          borderRadius: 0,
        },
      };
    }

    return {};
  },
});
