import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  item: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: "15px 0",

    "& .field": {
      alignItems: "center",
      display: "flex",

      "& svg": {
        height: 20,
        marginRight: 7,
        width: 20,
      },

      "& .tier + .tier": {
        marginLeft: 8,
      },
    },
  },

  viewAll: {
    marginBottom: 16,
  },

  itemButton: {
    marginLeft: 15,
  },

  actions: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 30,
  },

  facilityNameRow: {
    alignItems: "center",
    display: "flex",
    marginBottom: 7,

    "& .icon": {
      height: 20,
      marginRight: 7,
      width: 20,
    },
  },
});
