import keyBy from "lodash/keyBy";

import { Models } from "@services/api";

export type Brand = {
  name: string;
  country?: string;
  ownership?: string;
  schoolsCount?: string;
  studentsCount?: string;
  id: Models.Logo;
  founded?: number;
  shortName: string;
  rating?: number;
  coverage?: string;
  largestMarket?: string;
  type: "corporation" | "vendor" | "organization";
};

export const BRANDS: Brand[] = [
  // {
  //   coverage: "39 states & DC",
  //   founded: 1969,
  //   id: Models.CorporationID.Kindercare,
  //   largestMarket: "CA",
  //   name: "KinderCare",
  //   ownership: "corporate",
  //   rating: 8.2,
  //   schoolsCount: "1,500+",
  //   shortName: "KinderCare",
  //   studentsCount: "181,000+",
  //   type: "corporation",
  //   country: "US",
  // },
  // {
  //   coverage: "36 states & DC",
  //   founded: 1967,
  //   id: Models.CorporationID.Lcg,
  //   largestMarket: "TX",
  //   name: "Learning Care Group",
  //   ownership: "corporate",
  //   rating: 7.9,
  //   schoolsCount: "900+",
  //   shortName: "L.C.G.",
  //   studentsCount: "135,000+",
  //   type: "corporation",
  //   country: "US",
  // },
  // {
  //   coverage: "37 states & DC",
  //   founded: 1998,
  //   id: Models.CorporationID.Goddard,
  //   largestMarket: "NJ",
  //   name: "Goddard School",
  //   ownership: "franchise",
  //   rating: 8.6,
  //   schoolsCount: "560+",
  //   shortName: "Goddard",
  //   studentsCount: "90,000+",
  //   type: "corporation",
  //   country: "US",
  // },
  // {
  //   coverage: "32 states",
  //   founded: 1982,
  //   id: Models.CorporationID.Primrose,
  //   largestMarket: "TX",
  //   name: "Primrose Schools",
  //   ownership: "franchise",
  //   rating: 8.4,
  //   schoolsCount: "450+",
  //   shortName: "Primrose",
  //   studentsCount: "91,500+",
  //   type: "corporation",
  //   country: "US",
  // },
  // {
  //   coverage: "10 states",
  //   founded: 2002,
  //   id: Models.CorporationID.Littlesunshines,
  //   largestMarket: "MO",
  //   name: "Little Sunshine's",
  //   ownership: "corporate",
  //   schoolsCount: "29+",
  //   shortName: "Little S.",
  //   studentsCount: "3,700+",
  //   type: "corporation",
  //   country: "US",
  // },
  // {
  //   coverage: "38 states & DC",
  //   founded: 1986,
  //   id: Models.CorporationID.Brighthorizons,
  //   largestMarket: "CA",
  //   name: "Bright Horizons",
  //   ownership: "corporate",
  //   rating: 8.4,
  //   schoolsCount: "400+",
  //   shortName: "Bright H.",
  //   studentsCount: "40,000+",
  //   type: "corporation",
  //   country: "US",
  // },
  // {
  //   coverage: "31 states & DC",
  //   founded: 1981,
  //   id: Models.CorporationID.Kiddieacademy,
  //   largestMarket: "TX",
  //   name: "Kiddie Academy",
  //   ownership: "franchise",
  //   rating: 8.4,
  //   schoolsCount: "280+",
  //   shortName: "Kiddie A.",
  //   studentsCount: "55,000+",
  //   type: "corporation",
  //   country: "US",
  // },
  // {
  //   coverage: "23 states",
  //   founded: 1980,
  //   id: Models.CorporationID.Tle,
  //   largestMarket: "NJ",
  //   name: "The Learning Experience",
  //   ownership: "franchise",
  //   rating: 8.4,
  //   schoolsCount: "270+",
  //   shortName: "T.L.E.",
  //   studentsCount: "42,000+",
  //   type: "corporation",
  //   country: "US",
  // },
  // {
  //   coverage: "11 states",
  //   founded: 1988,
  //   id: Models.CorporationID.Childcarenetwork,
  //   largestMarket: "GA & NC",
  //   name: "Childcare Network",
  //   ownership: "corporate",
  //   rating: 7.4,
  //   schoolsCount: "260+",
  //   shortName: "Childcare N.",
  //   studentsCount: "51,000+",
  //   type: "corporation",
  //   country: "US",
  // },
  // {
  //   coverage: "25 states",
  //   founded: 1969,
  //   id: Models.CorporationID.Cadanceacademy,
  //   largestMarket: "TX",
  //   name: "Cadence Education",
  //   ownership: "corporate",
  //   rating: 8.5,
  //   schoolsCount: "215+",
  //   shortName: "Cadence E.",
  //   studentsCount: "42,000+",
  //   type: "corporation",
  //   country: "US",
  // },
  // {
  //   coverage: "18 states",
  //   id: Models.CorporationID.Chesterbrook,
  //   largestMarket: "CA",
  //   name: "Spring Education Group",
  //   ownership: "corporate",
  //   rating: 8.6,
  //   schoolsCount: "210+",
  //   shortName: "S.E.G.",
  //   studentsCount: "37,000+",
  //   type: "corporation",
  //   country: "US",
  // },
  // {
  //   coverage: "16 states",
  //   founded: 1985,
  //   id: Models.CorporationID.Kidsrkids,
  //   largestMarket: "TX",
  //   name: "Kids 'R' Kids",
  //   ownership: "franchise",
  //   rating: 7.5,
  //   schoolsCount: "170+",
  //   shortName: "K'R'K",
  //   studentsCount: "40,000+",
  //   type: "corporation",
  //   country: "US",
  // },
  // {
  //   coverage: "8 states",
  //   founded: 1975,
  //   id: Models.CorporationID.Sunshinehouse,
  //   largestMarket: "SC",
  //   name: "Sunshine House",
  //   ownership: "corporate",
  //   rating: 7.3,
  //   schoolsCount: "100+",
  //   shortName: "Sunshine H.",
  //   studentsCount: "14,500+",
  //   type: "corporation",
  //   country: "US",
  // },
  // {
  //   coverage: "4 states",
  //   founded: 1971,
  //   id: Models.CorporationID.Newhorizonsacademy,
  //   largestMarket: "MN",
  //   name: "New Horizon Academy",
  //   ownership: "corporate",
  //   rating: 7.7,
  //   schoolsCount: "75+",
  //   shortName: "N. H. A.",
  //   studentsCount: "11,000+",
  //   type: "corporation",
  //   country: "US",
  // },
  // {
  //   coverage: "8 states",
  //   founded: 1997,
  //   id: Models.CorporationID.Childrenslighthouse,
  //   largestMarket: "TX",
  //   name: "Children's Lighthouse",
  //   ownership: "franchise",
  //   rating: 7.8,
  //   schoolsCount: "60+",
  //   shortName: "Children's L.",
  //   studentsCount: "14,000+",
  //   type: "corporation",
  //   country: "US",
  // },
  // {
  //   coverage: "12 states",
  //   founded: 1997,
  //   id: Models.CorporationID.Childrenofamerica,
  //   largestMarket: "VA",
  //   name: "Children of America",
  //   ownership: "corporate",
  //   rating: 7.7,
  //   schoolsCount: "60+",
  //   shortName: "C.O.A.",
  //   studentsCount: "9,500+",
  //   type: "corporation",
  //   country: "US",
  // },
  // {
  //   coverage: "5 states",
  //   founded: 1997,
  //   id: Models.CorporationID.Lightbridge,
  //   largestMarket: "NJ",
  //   name: "Lightbridge Academy",
  //   ownership: "franchise",
  //   rating: 8.5,
  //   schoolsCount: "50+",
  //   shortName: "Lightbridge A.",
  //   studentsCount: "8,300+",
  //   type: "corporation",
  //   country: "US",
  // },
  // {
  //   coverage: "14 states",
  //   founded: 1982,
  //   id: Models.CorporationID.Cremedelacreme,
  //   largestMarket: "TX",
  //   name: "Creme de la Creme",
  //   ownership: "corporate",
  //   rating: 8.4,
  //   schoolsCount: "45+",
  //   shortName: "Creme",
  //   studentsCount: "14,500+",
  //   type: "corporation",
  //   country: "US",
  // },
  // {
  //   coverage: "4 states",
  //   founded: 1988,
  //   id: Models.CorporationID.Discoverypoint,
  //   largestMarket: "GA",
  //   name: "Discovery Point",
  //   ownership: "franchise",
  //   rating: 7.7,
  //   schoolsCount: "45+",
  //   shortName: "Discovery P.",
  //   studentsCount: "14,500+",
  //   type: "corporation",
  //   country: "US",
  // },
  // {
  //   coverage: "8 states",
  //   founded: 2012,
  //   id: Models.CorporationID.Bbma,
  //   largestMarket: "GA",
  //   name: "Big Blue Marble Academy",
  //   ownership: "corporate",
  //   rating: 8.7,
  //   schoolsCount: "35+",
  //   shortName: "B.B.M.A",
  //   studentsCount: "6,000+",
  //   type: "corporation",
  //   country: "US",
  // },
  // {
  //   coverage: "5 states",
  //   founded: 1970,
  //   id: Models.CorporationID.Creativeworldschool,
  //   largestMarket: "FL",
  //   name: "Creative World School",
  //   ownership: "franchise",
  //   rating: 7.4,
  //   schoolsCount: "30+",
  //   shortName: "C.W.S.",
  //   studentsCount: "7,000+",
  //   type: "corporation",
  //   country: "US",
  // },
  // {
  //   coverage: "1 state",
  //   founded: 1972,
  //   id: Models.CorporationID.Llegroup,
  //   largestMarket: "VA",
  //   name: "LLE Schools",
  //   ownership: "corporate",
  //   schoolsCount: "27+",
  //   shortName: "LLE",
  //   studentsCount: "4,900+",
  //   type: "corporation",
  //   country: "US",
  // },
  // {
  //   coverage: "9 states",
  //   founded: 2008,
  //   id: Models.CorporationID.Klaschools,
  //   largestMarket: "FL",
  //   name: "KLA Schools",
  //   ownership: "franchise",
  //   schoolsCount: "23+",
  //   shortName: "KLA",
  //   studentsCount: "4,900+",
  //   type: "corporation",
  //   country: "US",
  // },
  // {
  //   coverage: "1 state",
  //   founded: 1979,
  //   id: Models.CorporationID.Steppingstones,
  //   largestMarket: "TX",
  //   name: "Stepping Stone",
  //   ownership: "corporate",
  //   rating: 8.5,
  //   schoolsCount: "20+",
  //   shortName: "Stepping S.",
  //   studentsCount: "3,500+",
  //   type: "corporation",
  //   country: "US",
  // },
  // {
  //   coverage: "2 states",
  //   founded: 1998,
  //   id: Models.CorporationID.O2b,
  //   largestMarket: "FL",
  //   name: "O2B Kids",
  //   ownership: "corporate",
  //   rating: 7.5,
  //   schoolsCount: "20+",
  //   shortName: "O2B",
  //   studentsCount: "4,500+",
  //   type: "corporation",
  //   country: "US",
  // },
  // {
  //   coverage: "6 states",
  //   founded: 2004,
  //   id: Models.CorporationID.Gardner,
  //   largestMarket: "IL",
  //   name: "Gardner School",
  //   ownership: "corporate",
  //   rating: 9,
  //   schoolsCount: "20+",
  //   shortName: "Gardner S.",
  //   studentsCount: "3,750+",
  //   type: "corporation",
  //   country: "US",
  // },
  // {
  //   coverage: "1 state",
  //   founded: 2003,
  //   id: Models.CorporationID.SpanishSchoolhouse,
  //   largestMarket: "TX",
  //   name: "Spanish Schoolhouse",
  //   ownership: "corporate",
  //   rating: 8.7,
  //   schoolsCount: "17+",
  //   shortName: "Spanish S.",
  //   studentsCount: "1,750+",
  //   type: "corporation",
  //   country: "US",
  // },
  // {
  //   id: Models.CorporationID.Safarikid,
  //   name: "Safari kid",
  //   shortName: "Safari K.",
  //   type: "corporation",
  //   country: "US",
  // },
  {
    id: Models.Logo.Acsi,
    name: "Association of Christian Schools International",
    shortName: "ACSI",
    type: "organization",
  },
  {
    id: Models.Logo.Naeys,
    name: "National Association for the Education of Young Children",
    shortName: "NAEYC",
    type: "organization",
  },
  {
    id: Models.Logo.Nais,
    name: "National Association of Independent Schools",
    shortName: "NAIS",
    type: "organization",
  },
  {
    id: Models.Logo.Ncea,
    name: "National Catholic Educational Association",
    shortName: "NCEA",
    type: "organization",
  },
  {
    id: Models.Logo.Ymca,
    name: "Young Men's Christian Association",
    shortName: "YMCA",
    type: "organization",
  },
  {
    id: Models.Logo.Ami,
    name: "Association Montessori International",
    shortName: "AMI",
    type: "organization",
  },
  {
    id: Models.Logo.Ams,
    name: "American Montessori Society",
    shortName: "AMS",
    type: "organization",
  },
  {
    id: Models.Logo.AmsPathway,
    name: "American Montessori Society",
    shortName: "AMS",
    type: "organization",
  },
  {
    id: Models.Logo.CharterSchool,
    name: "Charter schools",
    shortName: "Charter schools",
    type: "organization",
  },
  {
    id: Models.Logo.Montessori,
    name: "Montessori",
    shortName: "Montessori",
    type: "organization",
  },
  // {
  //   id: Models.CorporationID.Ausgoodstart,
  //   name: "Goodstart",
  //   shortName: "Goodstart",
  //   type: "corporation",
  //   country: "AUS",
  // },
  // {
  //   id: Models.CorporationID.Ausg8,
  //   name: "G8 Education",
  //   shortName: "G8",
  //   type: "corporation",
  //   country: "AUS",
  // },
  // {
  //   id: Models.CorporationID.AuscAndK,
  //   name: "Creche and Kindergarten",
  //   shortName: "C&K",
  //   type: "corporation",
  //   country: "AUS",
  // },
  // {
  //   id: Models.CorporationID.Ausaffinity,
  //   name: "Affinity Education",
  //   shortName: "Affinity",
  //   type: "corporation",
  //   country: "AUS",
  // },
  // {
  //   id: Models.CorporationID.Ausguardian,
  //   name: "Guardian Childcare",
  //   shortName: "Guardian",
  //   type: "corporation",
  //   country: "AUS",
  // },
  // {
  //   id: Models.CorporationID.Ausku,
  //   name: "KU",
  //   shortName: "KU",
  //   type: "corporation",
  //   country: "AUS",
  // },
  // {
  //   id: Models.CorporationID.AusthinkChildare,
  //   name: "Think Childcare",
  //   shortName: "Think Childcare",
  //   type: "corporation",
  //   country: "AUS",
  // },
  // {
  //   id: Models.CorporationID.Ausoac,
  //   name: "Only About Children",
  //   shortName: "OAC",
  //   type: "corporation",
  //   country: "AUS",
  // },
  // {
  //   id: Models.CorporationID.Ausbb,
  //   name: "Busy Bees",
  //   shortName: "Busy Bees",
  //   type: "corporation",
  //   country: "AUS",
  // },
  // {
  //   id: Models.CorporationID.Ausecms,
  //   name: "Early Childhood Services",
  //   shortName: "ECMS",
  //   type: "corporation",
  //   country: "AUS",
  // },
  // {
  //   id: Models.CorporationID.Aussparrow,
  //   name: "Sparrow Early Learning",
  //   shortName: "Sparrow",
  //   type: "corporation",
  //   country: "AUS",
  // },
  // {
  //   id: Models.CorporationID.AusladieGowrieTas,
  //   name: "Ladie Gowrie",
  //   shortName: "Ladie Gowrie",
  //   type: "corporation",
  //   country: "AUS",
  // },
  // {
  //   id: Models.CorporationID.Austry,
  //   name: "Sparkways",
  //   shortName: "Sparkways",
  //   type: "corporation",
  //   country: "AUS",
  // },
  // {
  //   id: Models.CorporationID.AusoneTree,
  //   name: "One Tree",
  //   shortName: "One Tree",
  //   type: "corporation",
  //   country: "AUS",
  // },
  // {
  //   id: Models.CorporationID.AusgreenLeaves,
  //   name: "Green Leaves",
  //   shortName: "Green Leaves",
  //   type: "corporation",
  //   country: "AUS",
  // },
  // {
  //   id: Models.CorporationID.AusmontessoriAcademy,
  //   name: "Montessori Academy",
  //   shortName: "Montessori A.",
  //   type: "corporation",
  //   country: "AUS",
  // },
  // {
  //   id: Models.CorporationID.Ausbfm,
  //   name: "Big Fat Smile",
  //   shortName: "BFM",
  //   type: "corporation",
  //   country: "AUS",
  // },
  // {
  //   id: Models.CorporationID.AusstoryHouse,
  //   name: "Story House",
  //   shortName: "Story House",
  //   type: "corporation",
  //   country: "AUS",
  // },
  // {
  //   id: Models.CorporationID.AuscachetHolding,
  //   name: "Cachet Holding",
  //   shortName: "Cachet H.",
  //   type: "corporation",
  //   country: "AUS",
  // },
  // {
  //   id: Models.CorporationID.Ausqlecs,
  //   name: "Queensland Lutheran",
  //   shortName: "QLECS",
  //   type: "corporation",
  //   country: "AUS",
  // },
  // {
  //   id: Models.CorporationID.AusshineBright,
  //   name: "Shine Bright",
  //   shortName: "Shine Bright",
  //   type: "corporation",
  //   country: "AUS",
  // },
  // {
  //   id: Models.CorporationID.AuseAndD,
  //   name: "Explore & Develop",
  //   shortName: "E&D",
  //   type: "corporation",
  //   country: "AUS",
  // },
  // {
  //   id: Models.CorporationID.Ausgenius,
  //   name: "Genius",
  //   shortName: "Genius",
  //   type: "corporation",
  //   country: "AUS",
  // },
  // {
  //   id: Models.CorporationID.Aussdn,
  //   name: "Sydney Day Nursery",
  //   shortName: "SDN",
  //   type: "corporation",
  //   country: "AUS",
  // },
  // {
  //   id: Models.CorporationID.AuskidsClub,
  //   name: "Kids Club",
  //   shortName: "Kids Club",
  //   type: "corporation",
  //   country: "AUS",
  // },
  // {
  //   id: Models.CorporationID.AussteppingStone,
  //   name: "Stepping Stones",
  //   shortName: "Stepping S.",
  //   type: "corporation",
  //   country: "AUS",
  // },
  // {
  //   id: Models.CorporationID.Ausqccs,
  //   name: "Queensland Childcare",
  //   shortName: "QCCS",
  //   type: "corporation",
  //   country: "AUS",
  // },
  // {
  //   id: Models.CorporationID.Ausmayfield,
  //   name: "Mayfield Childcare",
  //   shortName: "Mayfield",
  //   type: "corporation",
  //   country: "AUS",
  // },
  // {
  //   id: Models.CorporationID.Ausedge,
  //   name: "Edge Early Learning",
  //   shortName: "Edge",
  //   type: "corporation",
  //   country: "AUS",
  // },
  // {
  //   id: Models.CorporationID.AusmindChamps,
  //   name: "Mind Champs",
  //   shortName: "Mind Champs",
  //   type: "corporation",
  //   country: "AUS",
  // },
  // {
  //   id: Models.CorporationID.AuskindersPlus,
  //   name: "Community Kinders Plus",
  //   shortName: "CKP",
  //   type: "corporation",
  //   country: "AUS",
  // },
  // {
  //   id: Models.CorporationID.Ausjourney,
  //   name: "Journey Early Learning",
  //   shortName: "Journey",
  //   type: "corporation",
  //   country: "AUS",
  // },
  // {
  //   id: Models.CorporationID.Ausoxanda,
  //   name: "Oxanda Education",
  //   shortName: "Oxanda",
  //   type: "corporation",
  //   country: "AUS",
  // },
  // {
  //   id: Models.CorporationID.AuslittleZacks,
  //   name: "Little Zaks Academy",
  //   shortName: "Little Zaks",
  //   type: "corporation",
  //   country: "AUS",
  // },
  // {
  //   id: Models.CorporationID.AusamigaMontessori,
  //   name: "Amiga Montessori",
  //   shortName: "Amiga",
  //   type: "corporation",
  //   country: "AUS",
  // },
];

export const brandsMap = keyBy(BRANDS, "id");
