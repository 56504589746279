import keyBy from "lodash/keyBy";

import { Models } from "@services/api";

export type Month = {
  id: Models.MonthID;
  title: string;
  shortTitle: string;
  position: number;
};

export const MONTHS: Month[] = [
  {
    id: Models.MonthID.January,
    position: 6,
    shortTitle: "Jan",
    title: "January",
  },
  {
    id: Models.MonthID.February,
    position: 7,
    shortTitle: "Feb",
    title: "February",
  },
  {
    id: Models.MonthID.March,
    position: 8,
    shortTitle: "March",
    title: "March",
  },
  {
    id: Models.MonthID.April,
    position: 9,
    shortTitle: "April",
    title: "April",
  },
  {
    id: Models.MonthID.May,
    position: 10,
    shortTitle: "May",
    title: "May",
  },
  {
    id: Models.MonthID.June,
    position: 11,
    shortTitle: "June",
    title: "June",
  },
  {
    id: Models.MonthID.July,
    position: 12,
    shortTitle: "July",
    title: "July",
  },
  {
    id: Models.MonthID.August,
    position: 1,
    shortTitle: "Aug",
    title: "August",
  },
  {
    id: Models.MonthID.September,
    position: 2,
    shortTitle: "Sept",
    title: "September",
  },
  {
    id: Models.MonthID.October,
    position: 3,
    shortTitle: "Oct",
    title: "October",
  },
  {
    id: Models.MonthID.November,
    position: 4,
    shortTitle: "Nov",
    title: "November",
  },
  {
    id: Models.MonthID.December,
    position: 5,
    shortTitle: "Dec",
    title: "December",
  },
];

export const monthsMap = keyBy(MONTHS, "id");
