import React from "react";

import Typography from "@ui-kit/Typography";

import { useDeepDivePanelStore } from "@hooks";

export const AvgDailyTemp: React.FC = () => {
  const [{ facility }] = useDeepDivePanelStore();

  if (!facility.countyWeather?.avgDailyTemp) {
    return null;
  }

  return (
    <Typography gutterBottom data-test="avg-daily-temperature-display">
      <Typography variant="inherit" bolded>
        Avg daily temperature:
      </Typography>{" "}
      {facility.countyWeather.avgDailyTemp}°
    </Typography>
  );
};
