import React, { Fragment, useState } from "react";
import { useFormContext } from "react-hook-form";

import { useCss } from "../styles";
import Typography from "@ui-kit/Typography";
import Select from "@ui-kit/Select";
import MediaUploader from "@components/PhotoUploader";
import Button from "@ui-kit/Button";
import { Models } from "@services/api";
import { AddMediaViewProps, MediaType } from "./types";
import { mapMediaCategoryName } from "../mapMediaCategoryName";
import { Input } from "@ui-kit/InputFields";
import { Spacer } from "@ui-kit/Spacer";

const EMPTY_FILE = {
  cloudinaryId: "",
  cloudinaryType: "",
  cloudinaryVersion: 0,
  original: "",
};

const typeCategoriesMap: {
  [key in MediaType]: Models.MediaCategory[];
} = {
  photo: [
    Models.MediaCategory.StreetView,
    Models.MediaCategory.Playground,
    Models.MediaCategory.Staff,
    Models.MediaCategory.Meals,
    Models.MediaCategory.Classrooms,
    Models.MediaCategory.InfantClassroom,
    Models.MediaCategory.ToddlerClassroom,
    Models.MediaCategory.ClassroomPreK2,
    Models.MediaCategory.ClassroomPreK3,
    Models.MediaCategory.ClassroomPreK4,
    Models.MediaCategory.ClassroomTk,
    Models.MediaCategory.ClassroomK,
    Models.MediaCategory.SchoolAgeProgram,
    Models.MediaCategory.SummerCamp,
    Models.MediaCategory.ServicePdo,
    Models.MediaCategory.SecurityAndSafety,
  ],
  testimonials: [
    Models.MediaCategory.TestimonialAboutTheSchool,
    Models.MediaCategory.TestimonialServicePdo,
  ],
  video: [
    Models.MediaCategory.VideoAboutTheSchool,
    Models.MediaCategory.VideoServicePdo,
  ],
};

const getLabels = (type: MediaType) => {
  const labels = typeCategoriesMap[type];

  return labels.map((i) => ({
    id: i,
    name: mapMediaCategoryName[i],
  }));
};

const SELECT_ITEMS = [
  {
    id: "photo",
    name: "School photos",
  },
  {
    id: "video",
    name: "School videos",
  },
  {
    id: "testimonials",
    name: "Parent testimonials",
  },
];

export const AddMediaView: React.FC<AddMediaViewProps> = ({
  label,
  media,
  onCancel,
  onChange,
  onLabelChange,
  onSave,
}) => {
  const [type, setType] = useState<MediaType>("photo");
  const { formState } = useFormContext();
  const css = useCss();

  const renderVideoInput = (label: string, name: string) => {
    return (
      <>
        <Spacer size="small" />

        <div css={css.photoRequirements}>
          <Typography>
            <b>Supported video sources:</b> Youtube, Instagram, Facebook, Vimeo,
            OneDrive, Google Drive, Dropbox
          </Typography>
        </div>

        <Input
          value={media?.file.original || ""}
          label={label}
          onChange={(event) => {
            const { value } = event.target;
            onChange({
              file: {
                ...EMPTY_FILE,
                original: value.trim(),
              },
            });
          }}
          name={name}
        />
      </>
    );
  };

  return (
    <div>
      <Select
        items={SELECT_ITEMS}
        value={type}
        onChange={(event) => {
          setType(event.value.id as MediaType);
          onLabelChange("");
        }}
        labelProp="name"
        name="mediaType"
      />

      <Select
        items={getLabels(type)}
        value={label}
        onChange={(event) => onLabelChange(event.value.id)}
        labelProp="name"
        name="mediaLabel"
        label="Choose label"
      />

      {!!label && type === "photo" && (
        <Fragment>
          <Spacer size="small" />

          <div css={css.photoRequirements}>
            <Typography>
              <b>Photo orientation:</b> landscape
            </Typography>
            <Typography>
              <b>Accepted formats:</b> JPEG, PNG
            </Typography>
            <Typography>
              <b>File size:</b> 800x600 plus, up to 5MB
            </Typography>
          </div>

          <div css={css.newPhotoWrapper}>
            <MediaUploader
              model={media}
              classes={{
                root: "uploaderRoot",
              }}
              onUpload={onChange}
              data-test="new-photo-uploader"
              minHeight={600}
              minWidth={800}
              maxSize={5}
            />
          </div>
        </Fragment>
      )}

      {!!label &&
        type === "video" &&
        renderVideoInput("School video: paste link", "schoolVideo")}
      {!!label &&
        type === "testimonials" &&
        renderVideoInput("Video testimonial: paste link", "videoTestimonial")}

      <div css={css.footer}>
        <Button
          color="primary"
          size="large"
          variant="outlined"
          onClick={onCancel}
          disabled={formState.isSubmitting}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          data-test="submit-btn"
          onClick={onSave}
          disabled={!label || !media}
          loading={formState.isSubmitting}
          type="submit"
        >
          Save
        </Button>
      </div>
    </div>
  );
};
