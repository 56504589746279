import React from "react";

import Typography from "@ui-kit/Typography";
import { useDeepDivePanelStore } from "@hooks";
import { getSchoolAgeRange } from "@selectors";

interface AgesServedProps {
  gutterBottom?: boolean;
}

export const AgesServed: React.FC<AgesServedProps> = ({
  gutterBottom = true,
}) => {
  const [{ facility }] = useDeepDivePanelStore();

  return (
    <Typography gutterBottom={gutterBottom} data-test="ages-served-display">
      <Typography variant="inherit" bolded>
        Ages served:
      </Typography>{" "}
      {getSchoolAgeRange(facility) || "---"}
    </Typography>
  );
};
