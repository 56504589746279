import React, { Fragment } from "react";

import { useDeepDivePanelStore } from "@hooks";
import { SchoolYear } from "./SchoolYear";
import { YearsInBusiness } from "./YearsInBusiness";
import { AgesServed } from "./AgesServed";
import { EndingGrade } from "./EndingGrade";
import { EnrollmentCapacity } from "./EnrollmentCapacity";
import { SchoolType } from "./SchoolType";
import { Affiliations } from "./Affiliations";
import { OperatingHours } from "./OperatingHours";
import { SchoolDays } from "./SchoolDays";
import { CurrentCapacity } from "./CurrentCapacity";
import { Classrooms } from "./Classrooms";
import { Section } from "../Section";

import { AsianStudents } from "./AsianStudents";
import { WhiteStudents } from "./WhiteStudents";
import { DiversityScore } from "./DiversityScore";
import { StudentBody } from "./StudentBody";
import { FullTimeTeachers } from "./FullTimeTeachers";
import { StudentTeacherRatio } from "./StudentTeacherRatio";

import { FacultyAndSecurity } from "@components/DeepDivePanel/FacultyAndSecurity";

import Button from "@ui-kit/Button";
import { Spacer } from "@ui-kit/Spacer";
import { Models } from "@services/api";
import { getGoogleMapsSatelliteViewLink } from "@helpers/getGoogleMapsSateliteViewLink";
import { isPrivateSchool } from "@selectors";

export const OperationalDetails: React.FC = () => {
  const [{ facility }] = useDeepDivePanelStore();

  return (
    <Section borderTop>
      <Section.Title>Facility Spotlight</Section.Title>

      <Spacer size="small" />
      <Affiliations />
      <SchoolType />
      <YearsInBusiness gutterBottom={false} />
      <Spacer size="small" />

      <Button
        variant="outlined"
        color={
          facility.subscriptionTier === Models.SubscriptionTier.Ivy
            ? "secondary"
            : "black"
        }
        size="medium"
        fullWidth
        target="_blank"
        href={getGoogleMapsSatelliteViewLink(
          facility.address.location.lon,
          facility.address.location.lat,
        )}
      >
        Satellite view of the school
      </Button>

      <Spacer size="small" />
      <SchoolYear />
      <SchoolDays />
      <OperatingHours />

      <Spacer size="small" />
      <AgesServed />

      <EndingGrade />
      <Spacer size="small" />
      <EnrollmentCapacity />
      <CurrentCapacity />
      <Spacer size="small" />

      <Classrooms />

      {isPrivateSchool(facility) && (
        <Fragment>
          {(!!facility.studentBody ||
            !!facility.fullTimeTeachersCount ||
            !!facility.teacherStudentRatio) && <Spacer size="small" />}
          <StudentBody />
          <FullTimeTeachers />
          <StudentTeacherRatio />
          {(!!facility.asianRacePercent ||
            !!facility.whiteRacePercent ||
            !!facility.minorityPercent) && <Spacer size="small" />}
          <AsianStudents />
          <WhiteStudents />
          <DiversityScore />
        </Fragment>
      )}

      <Spacer size="small" />

      <FacultyAndSecurity />
    </Section>
  );
};
