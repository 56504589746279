import keyBy from "lodash/keyBy";

import { Models } from "@services/api";

export type Weekday = {
  id: Models.WeekDay;
  name: string;
  nameShort: string;
  order: number;
};

export const WEEKDAYS: Weekday[] = [
  {
    id: Models.WeekDay.Monday,
    name: "Monday",
    nameShort: "Mon",
    order: 0,
  },
  {
    id: Models.WeekDay.Tuesday,
    name: "Tuesday",
    nameShort: "Tue",
    order: 1,
  },
  {
    id: Models.WeekDay.Wednesday,
    name: "Wednesday",
    nameShort: "Wed",
    order: 2,
  },
  {
    id: Models.WeekDay.Thursday,
    name: "Thursday",
    nameShort: "Thu",
    order: 3,
  },
  {
    id: Models.WeekDay.Friday,
    name: "Friday",
    nameShort: "Fri",
    order: 4,
  },
  {
    id: Models.WeekDay.Saturday,
    name: "Saturday",
    nameShort: "Sat",
    order: 5,
  },
  {
    id: Models.WeekDay.Sunday,
    name: "Sunday",
    nameShort: "Sun",
    order: 6,
  },
];

export const weekdaysMap = keyBy(WEEKDAYS, "id");
