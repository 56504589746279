/* global JSX */
import React, { Fragment } from "react";
import clsx from "clsx";
import CheckIcon from "@material-ui/icons/Check";
import isFunction from "lodash/isFunction";

import Typography from "@ui-kit/Typography";
import Button from "@ui-kit/Button";
import useStyles from "./styles";
import { ShadowDivider } from "@components/ShadowDivider";

interface IProps {
  onDone?: () => void;
  text?: string;
  content?: JSX.Element;
  buttonText?: string;
  buttonFullWidth?: boolean;
  classes?: Partial<{
    button: string;
  }>;
}

const SuccessStep: React.FunctionComponent<IProps> = ({
  onDone,
  text,
  content,
  buttonText = "Done",
  buttonFullWidth = true,
  classes,
}) => {
  const defaultClasses = useStyles();

  return (
    <div data-test="success-step">
      <div className={defaultClasses.successWrapper}>
        <div className="icon">
          <CheckIcon />
        </div>

        {Boolean(text) && <Typography bolded>{text}</Typography>}
      </div>

      {content}

      {isFunction(onDone) && (
        <Fragment>
          <ShadowDivider />
          <Button
            variant="contained"
            color="primary"
            data-test="done-btn"
            fullWidth={buttonFullWidth}
            className={clsx(defaultClasses.button, classes?.button)}
            onClick={onDone}
            size="large"
          >
            {buttonText}
          </Button>
        </Fragment>
      )}
    </div>
  );
};

export default SuccessStep;
