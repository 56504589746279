import React, { Fragment } from "react";

import Typography from "@ui-kit/Typography";
import { isPrivateSchool, isPublicSchool } from "@selectors";
import { useDeepDivePanelStore } from "@hooks";
import { Spacer } from "@ui-kit/Spacer";
import { isAustralia } from "@selectors/facility";

export const SickChildExclusion: React.FC = () => {
  const [{ facility }] = useDeepDivePanelStore();

  if (
    isPublicSchool(facility) ||
    isPrivateSchool(facility) ||
    isAustralia(facility)
  ) {
    return null;
  }

  if (!facility.jurisdiction) {
    return null;
  }

  return (
    <Fragment>
      <Typography gutterBottom>
        <Typography variant="inherit" bolded>
          Sick child exclusion:
        </Typography>{" "}
        {facility.jurisdiction.sickChildren} fever or higher
      </Typography>
      <Typography italic align="justify">
        Per regulatory requirements, this facility may not permit children{" "}
        exhibiting illness symptoms to remain at the center
      </Typography>
      <Spacer size="small" />
    </Fragment>
  );
};
