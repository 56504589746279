import React, { useState } from "react";
import { useQuery } from "react-query";
import orderBy from "lodash/orderBy";
import clsx from "clsx";
import qs from "query-string";
import { useHistory } from "react-router-dom";
import { useTracking } from "react-tracking";

import { createCss } from "./styles";
import { useMapStore } from "@store/MapStore";
import CircularProgress from "@material-ui/core/CircularProgress";

import MyLocationIcon from "@material-ui/icons/MyLocation";
import NearMeIcon from "@material-ui/icons/NearMe";
import DirectionsWalk from "@material-ui/icons/DirectionsWalk";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import TuneIcon from "@material-ui/icons/Tune";
import { Models, API } from "@services/api";
import AusLogo from "@images/countries/aus.svg";
import UsLogo from "@images/countries/us.svg";

type MapLegendProps = {
  onMyLocationClick: () => void;
};

type CountryChoice = "US" | "AUS";

export const MapLegend: React.FC<MapLegendProps> = ({ onMyLocationClick }) => {
  const { trackEvent } = useTracking();
  const history = useHistory();
  const [
    {
      neighbourhoodsShown,
      districtShown,
      sponsoredFiltersOpened,
      filters,
      filterIsActive,
      userPositionFetching,
    },
    {
      setNeighbourhoodsShown,
      setShowPoisLegendModal,
      openFilters,
      setDistrictShown,
      setSponsoredFiltersOpened,
      setSearchString,
    },
  ] = useMapStore();
  const { data: corporations } = useQuery(
    "corporations",
    API.corporations.getList,
  );
  const css = createCss();
  const [activeCountry, setActiveCountry] = useState<CountryChoice>("US");
  const [sponsorsAnchorEl, setSponsorsAnchorEl] = useState<{
    anchor: HTMLElement;
    item: Models.Corporation;
  } | null>(null);

  const handleFilter = (id: number) => {
    setSearchString("");
    trackEvent({
      action: "Company spotlight search",
      corporation: id,
    });
    history.push({
      search: qs.stringify({
        corporation: id,
      }),
    });
  };

  const handleCountryChange = (id: CountryChoice) => {
    setActiveCountry(id);
    document.getElementById("providers-spotlight")?.scrollTo({
      top: 0,
    });
  };

  return (
    <div css={css.root}>
      <div>
        <div css={css.sponsored}>
          <div
            className={clsx("header", {
              opened: sponsoredFiltersOpened,
            })}
            onClick={() => setSponsoredFiltersOpened(!sponsoredFiltersOpened)}
          >
            Provider spotlight{" "}
            {sponsoredFiltersOpened ? (
              <ArrowDropUpIcon />
            ) : (
              <ArrowDropDownIcon />
            )}
          </div>
          {sponsoredFiltersOpened && (
            <div css={css.countries}>
              <img
                src={UsLogo}
                alt="us"
                css={css.countryIcon}
                onClick={() => handleCountryChange("US")}
                className={clsx({
                  active: activeCountry === "US",
                })}
              />
              <img
                src={AusLogo}
                alt="aus"
                css={css.countryIcon}
                onClick={() => handleCountryChange("AUS")}
                className={clsx({
                  active: activeCountry === "AUS",
                })}
              />
            </div>
          )}
          <div
            id="providers-spotlight"
            className={clsx("body", {
              opened: sponsoredFiltersOpened,
            })}
          >
            {orderBy(corporations, "order")
              ?.filter((s) => s.country === activeCountry && s.displayInSidebar)
              .map((s) => (
                <div
                  className={clsx("item", {
                    active: filters.primary.corporation === s.id,
                    hovered: sponsorsAnchorEl?.item.id === s.id,
                  })}
                  onMouseEnter={(event) => {
                    // if (s.coverage) {
                    //   setSponsorsAnchorEl({
                    //     anchor: event.currentTarget,
                    //     item: s,
                    //   });
                    // }
                    setSponsorsAnchorEl({
                      anchor: event.currentTarget,
                      item: s,
                    });
                  }}
                  onMouseLeave={() => setSponsorsAnchorEl(null)}
                  onClick={() => handleFilter(s.id)}
                  key={s.id}
                  data-test={`corporation-${s.id}`}
                >
                  {s.name}
                </div>
              ))}
          </div>
        </div>
      </div>

      <div>
        <div css={css.directionsControl}>
          <button onClick={() => openFilters()}>
            <TuneIcon />
          </button>
          <button onClick={() => setShowPoisLegendModal(true)}>
            <NearMeIcon />
          </button>
          <button onClick={onMyLocationClick} disabled={userPositionFetching}>
            {userPositionFetching ? (
              <CircularProgress size={16} />
            ) : (
              <MyLocationIcon />
            )}
          </button>
        </div>
        <div>
          <div
            css={css.layerToggler}
            onClick={() => {
              if (!neighbourhoodsShown && districtShown) {
                setDistrictShown(false);
              }

              setNeighbourhoodsShown(!neighbourhoodsShown);
            }}
          >
            <DirectionsWalk />
            {neighbourhoodsShown ? "Hide" : "Show"} neighborhoods
          </div>
        </div>
      </div>
    </div>
  );
};
