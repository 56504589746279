import React, { useEffect } from "react";

import {
  EditContactInfo,
  processServerErrors,
} from "@components/EditFacilityInfoForm/view";
import { EditFacilityContactInfoState } from "@components/EditFacilityInfoForm/types";
import { useDeepDivePanelStore } from "@hooks";
import { Models } from "@services/api";
import { ClaimStep } from "@pages/FacilityClaim/ClaimStep";

interface IProps {
  facilityView: Models.Facility;
  setFacilityView: React.Dispatch<React.SetStateAction<Models.Facility>>;
  onNext: () => void;
  onPrev: () => void;
  editState: EditFacilityContactInfoState;
  onEditState: React.Dispatch<
    React.SetStateAction<EditFacilityContactInfoState>
  >;
}

export const ContactInfoStep: React.FC<IProps> = ({
  editState,
  onEditState,
  facilityView,
  ...props
}) => {
  const [{ facility }] = useDeepDivePanelStore();

  useEffect(() => {
    if (facility.email !== facilityView.email) {
      onEditState((prev) => ({
        ...prev,
        editEmail: true,
      }));
    }
  }, []);

  return (
    <ClaimStep
      {...props}
      facilityView={facilityView}
      processErrors={processServerErrors}
      barText="Step 2 of 4"
      onError={() => {
        document.querySelectorAll(".form-error").forEach((e) => {
          const parent = e.closest('[data-test="editable-item-block"]');
          const name = parent?.getAttribute("data-name");

          if (name) {
            onEditState((prev) => ({
              ...prev,
              [name]: true,
            }));
          }
        });
      }}
    >
      {(view, setView) => (
        <EditContactInfo
          facilityView={view}
          setFacilityView={setView}
          state={editState}
          onStateChange={onEditState}
          requiredFields={{
            email: true,
            name: true,
            operatingHours: true,
            phone: true,
          }}
        />
      )}
    </ClaimStep>
  );
};
