import { Map } from "mapbox-gl";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import { polygon } from "@turf/helpers";

import { Models } from "@services/api";

export const facilityInBounds = (map: Map, facility: Models.Facility) => {
  const bounds = map.getBounds();
  const northEast = bounds.getNorthEast();
  const northWest = bounds.getNorthWest();
  const southEast = bounds.getSouthEast();
  const southWest = bounds.getSouthWest();

  return booleanPointInPolygon(
    [facility.address.location.lon, facility.address.location.lat],
    polygon([
      [
        [northWest.lng, northWest.lat],
        [northEast.lng, northEast.lat],
        [southEast.lng, southEast.lat],
        [southWest.lng, southWest.lat],
        [northWest.lng, northWest.lat],
      ],
    ]),
  );
};
