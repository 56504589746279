import React from "react";
import Popover from "@material-ui/core/Popover";

import Typography from "@ui-kit/Typography";
import useStyles from "./styles.jss";

type NotClaimedSchoolPopoverProps = {
  anchor: HTMLElement | null;
};

export const NotClaimedSchoolPopover: React.FC<NotClaimedSchoolPopoverProps> =
  ({ anchor }) => {
    const classes = useStyles();

    return (
      <Popover
        id={Boolean(anchor) ? "not-claimed-school-popover" : undefined}
        open={Boolean(anchor)}
        anchorEl={anchor}
        disableRestoreFocus
        style={{ pointerEvents: "none" }}
        classes={{
          paper: classes.popover,
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
      >
        <div className={classes.popoverName}>Claim your school</div>
        <div className={classes.popoverContent}>
          <Typography paragraph>Is this your school?</Typography>
          <Typography paragraph>
            Are you looking to attract new students and boost interest in your
            programs? We can help!
          </Typography>
          <Typography paragraph>
            <b>Next step:</b> claim your school
          </Typography>
          <div className={classes.popoverDash} />
        </div>
        <div className={classes.popoverFooter} />
      </Popover>
    );
  };
