import React, { useMemo, Fragment } from "react";

import { SectionProps } from "./types";
import { useCss } from "./styles";
import { useDeepDivePanelStore } from "@hooks/useDeepDivePanelStore";
import { Models } from "@services/api";
import { SectionTitle } from "../SectionTitle";

export const Section: React.FC<SectionProps> & {
  Title: typeof SectionTitle;
} = ({ children, borderTop, styles, ...props }) => {
  const [{ facility }] = useDeepDivePanelStore();
  const css = useMemo(() => {
    return useCss({
      tier: facility.subscriptionTier,
    });
  }, [facility.subscriptionTier]);

  return (
    <Fragment>
      {borderTop &&
        facility.subscriptionTier === Models.SubscriptionTier.Community && (
          <div css={css.borderTop} />
        )}
      <div css={[css.root, styles?.root]} {...props}>
        <div css={[css.content, styles?.content]}>{children}</div>
      </div>
    </Fragment>
  );
};

Section.Title = SectionTitle;
