import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";
import { Models } from "@services/api";

export const useCss = ({
  tier,
}: {
  tier: Models.SubscriptionTier;
}): Record<Classes, Interpolation<ITheme>> => ({
  actionsContainer: {
    display: "flex",
  },

  action: (theme) => ({
    alignItems: "center",
    color: "inherit",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    fontSize: tier === Models.SubscriptionTier.Community ? 14 : 11,
    fontWeight: tier === Models.SubscriptionTier.Community ? 400 : 600,
    textAlign: "center",
    textTransform:
      tier === Models.SubscriptionTier.Community ? "none" : "uppercase",
    width: "20%",

    "&.disabled": {
      color: theme.palette.text.disabled,
      cursor: "default",

      "&:hover": {
        color: theme.palette.text.disabled,
      },
    },

    "@media (hover: hover) and (pointer: fine)": {
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },

    "& svg": {
      height: 28,
      marginBottom: tier === Models.SubscriptionTier.Community ? 3 : 18,
      width: 28,
    },
  }),
});
