import React, { forwardRef } from "react";
import clsx from "clsx";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

import Typography from "@ui-kit/Typography";
import { createCss } from "./styles";
import { BooleanFieldProps } from "./types";

export const BooleanField = forwardRef(
  (
    { name, error, label, required, onChange, value }: BooleanFieldProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const css = createCss();

    return (
      <FormControl
        error={!!error}
        css={css.radioContainer}
        className={clsx({
          "form-error": !!error,
        })}
      >
        {!!label && (
          <FormLabel css={css.radioLabel}>
            <Typography variant="inherit" required={required}>
              {label}
            </Typography>
          </FormLabel>
        )}
        <RadioGroup
          css={css.group}
          data-test={`${name}-boolean-field`}
          name={name}
          value={value}
          onChange={onChange}
          ref={ref}
        >
          <FormControlLabel
            value="yes"
            control={<Radio color="primary" css={css.radio} />}
            label="Yes"
            data-test={`${name}-boolean-field-yes`}
          />
          <FormControlLabel
            value="no"
            control={<Radio color="primary" css={css.radio} />}
            label="No"
            data-test={`${name}-boolean-field-no`}
          />
        </RadioGroup>
      </FormControl>
    );
  },
);

BooleanField.displayName = "BooleanField";
