import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  root: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    fontWeight: 700,
    left: "50%",
    position: "absolute",
    top: 110,
    transform: "translateX(-50%)",
    zIndex: 10,
  },

  cross: (theme) => ({
    alignItems: "center",
    background: "white",
    boxShadow: "0 2px 5px -1px rgba(0, 0, 0, .3)",
    color: theme.palette.primary.main,
    cursor: "pointer",
    display: "flex",
    fontSize: 13,
    height: 32,
    marginLeft: 5,
    padding: 6,
  }),

  label: (theme) => ({
    background: "white",
    boxShadow: "0 2px 5px -1px rgba(0, 0, 0, 0.3)",
    color: theme.palette.primary.main,
    cursor: "pointer",
    fontSize: 15,
    padding: 6,
    whiteSpace: "nowrap",
  }),
});
