import React from "react";
import isNil from "lodash/isNil";

import { getFullGradeString } from "@helpers/grades-helpers";
import Typography from "@ui-kit/Typography";
import { useDeepDivePanelStore } from "@hooks";

export const EndingGrade: React.FC = () => {
  const [{ facility }] = useDeepDivePanelStore();

  if (
    isNil(facility.operatingInfo.ongoingGradesTo) &&
    isNil(facility.operatingInfo.ongoingGradesFrom)
  ) {
    return null;
  }

  return (
    <Typography data-test="ending-grade-display">
      <Typography variant="inherit" bolded>
        Grades served:
      </Typography>{" "}
      {getFullGradeString(facility)}
    </Typography>
  );
};
