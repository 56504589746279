import { createUseStyles } from "react-jss";

import { ITheme } from "@theme";

const styles = (theme: ITheme) => ({
  pageWrapper: {
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    left: 0,
    overflow: "hidden",
    position: "fixed",
    right: 0,
    top: 0,
    zIndex: 10,
  },

  myLocationControl: {
    bottom: "0 !important",
    right: "50% !important",
    transform: "translateX(50%)",
    zIndex: "5 !important",

    "& div": {
      background: "none !important",
      boxShadow: "none !important",
      display: "flex",
      marginRight: "0 !important",

      "& button": {
        backgroundColor: "white",
        borderColor: "rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186)",
        borderRadius: "100% !important",
        borderStyle: "solid",
        borderWidth: 2,
        height: 50,
        outline: "none !important",
        width: 50,

        "&.disabled": {
          "& svg": {
            color: "rgba(0, 0, 0, .3)",
          },
        },

        "&:hover": {
          backgroundColor: "#f2f2f2",
        },

        "& + button": {
          marginLeft: 15,
        },
      },
    },
  },

  desktopMapWrapper: {
    display: "flex",
    height: "100%",
    position: "relative",

    [theme.mediaRequests.mobile]: {
      flexDirection: "column",
    },

    [theme.mediaRequests.notMobile]: {
      height: "calc(100% - 56px)",
      zIndex: 2000,
    },
  },

  mapContainer: {
    position: "relative",

    [theme.mediaRequests.notMobile]: {
      width: "100%",
    },

    [theme.mediaRequests.mobile]: {
      flex: 1,
    },
  },

  map: {
    bottom: 0,
    height: "100%",
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
    width: "100%",
  },

  mapLoader: {
    alignItems: "center",
    backgroundColor: "white",
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
    // zIndex: 99,

    "&.padding": {
      left: 420,
    },

    "&.opacity": {
      backgroundColor: "rgba(255, 255, 255, .8)",
    },
  },
});

export default createUseStyles(styles, { name: "Map" });
