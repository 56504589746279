import React from "react";

import Typography from "@ui-kit/Typography";
import { useDeepDivePanelStore } from "@hooks";
import { format_percent } from "@helpers/format_number";

export const DiversityScore: React.FC = () => {
  const [{ facility }] = useDeepDivePanelStore();

  if (!facility.minorityPercent) {
    return null;
  }

  return (
    <Typography data-test="minority-percent-display">
      <Typography variant="inherit" bolded>
        Diversity score:
      </Typography>{" "}
      {facility.minorityPercent < 0.01
        ? "less than 1%"
        : format_percent(facility.minorityPercent, { round: true })}{" "}
      of the student body is made up of black and/or hispanic students
    </Typography>
  );
};
