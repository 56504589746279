import React from "react";

import { AffiliationsMap } from "@constants/accreditations";
import Typography from "@ui-kit/Typography";
import { useDeepDivePanelStore } from "@hooks";
import { isPublicSchool } from "@selectors";

interface AffiliationsProps {
  gutterBottom?: boolean;
}

export const Affiliations: React.FC<AffiliationsProps> = ({
  gutterBottom = true,
}) => {
  const [{ facility }] = useDeepDivePanelStore();

  if (isPublicSchool(facility)) {
    return null;
  }

  if (!facility.operatingInfo.religiousAffiliation) {
    return null;
  }

  return (
    <Typography gutterBottom={gutterBottom} data-test="affilications-display">
      <Typography variant="inherit" bolded>
        Affiliation:
      </Typography>{" "}
      {AffiliationsMap[facility.operatingInfo.religiousAffiliation].name}
    </Typography>
  );
};
