import React, { useState, useEffect } from "react";
import List from "@material-ui/core/List";
import { useHistory, useLocation } from "react-router-dom";
import orderBy from "lodash/orderBy";

import Dialog from "@ui-kit/Dialog";
import ReviewComponent from "@components/Review";
import { createCss } from "./styles";
import { useDeepDivePanelStore } from "@hooks/useDeepDivePanelStore";
import { FACILITY_REVIEWS_API, Models } from "@services/api";

const ReviewsPage: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { search } = location;
  const [{ facility }] = useDeepDivePanelStore();
  const [inited, setInited] = useState(false);
  const [reviews, setReviews] = useState<Models.FacilityReview[]>([]);

  const css = createCss();

  useEffect(() => {
    void (async () => {
      const {
        data: { data },
      } = await FACILITY_REVIEWS_API.facilityReviewsIndex({
        facilityId: facility.id,
        hasComment: true,
      });

      setReviews(data);
      setInited(true);
    })();
  }, []);

  return (
    <Dialog
      open
      loading={!inited}
      tier={facility.subscriptionTier}
      onClose={() => {
        history.replace({
          pathname: `/map/${facility.id}`,
          search,
        });
      }}
      title="Facility reviews"
    >
      <List css={css.root} data-test="reviews-list">
        {orderBy(reviews, "createdAt", "desc").map((r, i) => (
          <ReviewComponent
            key={`review-${i}`}
            review={r}
            divider={i < reviews.length - 1}
          />
        ))}
      </List>
    </Dialog>
  );
};

export default ReviewsPage;
