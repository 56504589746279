import React, { useMemo, Fragment } from "react";
import { useLocation, useHistory } from "react-router-dom";
import BlockIcon from "@material-ui/icons/Block";

import { NewDesignIcons } from "@components/Icons";
import { statusesMap } from "@constants/facility-statuses";
import { useDeepDivePanelStore } from "@hooks/useDeepDivePanelStore";
import { Spacer } from "@ui-kit/Spacer";
import { Models } from "@services/api";
import { useCss } from "./styles";

export const OperationalStatus: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const [{ facility }] = useDeepDivePanelStore();
  const css = useCss();

  const closed = useMemo(() => {
    return (
      facility.businessStatus === Models.FacilityStatus.Duplicate ||
      facility.businessStatus === Models.FacilityStatus.MovedLocation ||
      facility.businessStatus === Models.FacilityStatus.PermanentlyClosed ||
      facility.businessStatus === Models.FacilityStatus.TemporarilyClosed ||
      facility.businessStatus === Models.FacilityStatus.NotExists
    );
  }, [facility.businessStatus]);

  const openingSoon = useMemo(() => {
    return (
      facility.businessStatus === Models.FacilityStatus.OpeningSoon ||
      facility.businessStatus === Models.FacilityStatus.OpeningQ1 ||
      facility.businessStatus === Models.FacilityStatus.OpeningQ2 ||
      facility.businessStatus === Models.FacilityStatus.OpeningQ3 ||
      facility.businessStatus === Models.FacilityStatus.OpeningQ4 ||
      facility.businessStatus === Models.FacilityStatus.OpeningThisYear ||
      facility.businessStatus === Models.FacilityStatus.OpeningNextYear
    );
  }, [facility.businessStatus]);

  if (closed) {
    return (
      <Fragment>
        <div
          css={[css.notification, css.closedNotification]}
          data-test="maybe-closed-warning"
          onClick={() => {
            history.push({
              pathname: `/map/${facility.id}/edit/contact-info`,
              search,
            });
          }}
        >
          <BlockIcon /> {statusesMap[facility.businessStatus].name}
        </div>
        <Spacer size="small" />
      </Fragment>
    );
  }

  if (openingSoon) {
    return (
      <Fragment>
        <div
          css={[css.notification, css.openingNotification]}
          data-test="opening-soon-notification"
          onClick={() => {
            history.push({
              pathname: `/map/${facility.id}/edit/contact-info`,
              search,
            });
          }}
        >
          <NewDesignIcons.Megaphone />{" "}
          {statusesMap[facility.businessStatus].name}
        </div>
        <Spacer size="small" />
      </Fragment>
    );
  }

  return null;
};
