import React from "react";
import { Controller } from "react-hook-form";
import clsx from "clsx";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

import Typography from "@ui-kit/Typography";
import { useCss } from "./styles";
import { RadioItemProps } from "./types";

export const RadioItem: React.FC<RadioItemProps> = ({
  id,
  error,
  label,
  required,
  onChange: handleChange,
  defaultValue,
  control,
}) => {
  const css = useCss();

  return (
    <FormControl
      key={id}
      error={!!error}
      css={css.radioContainer}
      className={clsx({
        "form-error": !!error,
      })}
    >
      <FormLabel css={css.radioLabel}>
        <Typography variant="inherit" required={required}>
          {label}
        </Typography>
      </FormLabel>
      <Controller
        render={({ onChange, value, name }) => (
          <RadioGroup
            css={css.group}
            data-test={`service-${id}`}
            name={name}
            value={value}
            onChange={(event) => {
              event.persist();
              handleChange(event);
              onChange(event);
            }}
          >
            <FormControlLabel
              value="yes"
              control={<Radio color="primary" css={css.radio} />}
              label="Yes"
              data-test={`${id}-radio-yes`}
            />
            <FormControlLabel
              value="no"
              control={<Radio color="primary" css={css.radio} />}
              label="No"
              data-test={`${id}-radio-no`}
            />
          </RadioGroup>
        )}
        rules={{
          validate: (value: string) => {
            if (required && !value) {
              return "Cannot be blank";
            }

            return true;
          },
        }}
        defaultValue={defaultValue}
        name={id}
        control={control}
      />
    </FormControl>
  );
};
