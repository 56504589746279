import { isFacebookUrl } from "@helpers/socialMedia";
import React, { IframeHTMLAttributes } from "react";
import urlJoin from "url-join";

type KnownSource =
  | "vimeo"
  | "facebook"
  | "instagram"
  | "youtube"
  | "onedrive"
  | "dropbox"
  | "google-drive"
  | "image"
  | "unknown";

interface ProcessResponse {
  type: KnownSource;
  content: React.ReactElement | string | null;
}

const getIFrame = (props: IframeHTMLAttributes<HTMLIFrameElement>) => {
  return (
    <iframe
      width="1240px"
      height="768px"
      frameBorder="0"
      allow="autoplay; fullscreen"
      allowFullScreen
      {...props}
    />
  );
};

export const processVideoSource = (url: string): ProcessResponse => {
  if (/vimeo\.com/.exec(url)) {
    const match = /vimeo\.com\/([0-9]+)/.exec(url);

    return {
      content: getIFrame({
        src: `https://player.vimeo.com/video/${match?.[1]}`,
      }),
      type: "vimeo",
    };
  }

  if (isFacebookUrl(url)) {
    return {
      content: getIFrame({
        src: `https://www.facebook.com/plugins/video.php?href=${url}`,
      }),
      type: "facebook",
    };
  }

  if (/instagram/.exec(url)) {
    return {
      content: getIFrame({
        src: urlJoin(url.split("?")[0], "/embed"),
        width: "768",
      }),
      type: "instagram",
    };
  }

  if (/youtu\.be/.exec(url) || /youtube\.com/.exec(url)) {
    return {
      content: url,
      type: "youtube",
    };
  }

  if (/\.(jpeg|jpg|gif|png)$/.exec(url)) {
    return {
      content: url,
      type: "image",
    };
  }

  if (/drive\.google/.exec(url)) {
    return {
      content: null,
      type: "google-drive",
    };
  }

  if (/dropbox/.exec(url)) {
    return {
      content: null,
      type: "dropbox",
    };
  }

  if (/onedrive/.exec(url)) {
    return {
      content: null,
      type: "onedrive",
    };
  }

  return {
    content: url,
    type: "unknown",
  };
};

export const shouldOpenInNewTab = (response: ProcessResponse): boolean => {
  return (
    response.type === "onedrive" ||
    response.type === "google-drive" ||
    response.type === "dropbox"
  );
};
