import React from "react";

import { schoolTypesMap } from "@constants/school-types";
import Typography from "@ui-kit/Typography";
import { useDeepDivePanelStore } from "@hooks";

export const SchoolType: React.FC = () => {
  const [{ facility }] = useDeepDivePanelStore();

  return (
    <Typography gutterBottom>
      <Typography variant="inherit" bolded>
        Facility type:
      </Typography>{" "}
      {schoolTypesMap[facility.facilityType].title}
    </Typography>
  );
};
