import { createUseStyles } from "react-jss";

import { ITheme } from "@theme";

const styles = (theme: ITheme) => ({
  popover: {
    left: "430px !important",
    width: 380,
  },

  popoverName: {
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.yellow.main,
    fontSize: 20,
    fontWeight: 700,
    padding: 10,
    textAlign: "center",
  },

  popoverContent: {
    padding: "15px 30px",
  },

  popoverDash: {
    backgroundColor: theme.palette.yellow.main,
    borderRadius: 5,
    height: 4,
    margin: "30px auto",
    width: 100,
  },

  popoverFooter: {
    backgroundColor: theme.palette.text.primary,
    height: 20,
  },
});

export default createUseStyles<ITheme, keyof ReturnType<typeof styles>>(
  styles,
  { name: "DDP_NotClaimedSchoolPopover" },
);
