import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  root: (theme) => ({
    [theme.mediaRequests.notMobile]: {
      width: 350,
    },
  }),

  ratingItem: {
    position: "relative",

    "&:not(:last-child)": {
      marginBottom: 20,
    },
  },

  title: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
  },

  counterDisplay: {
    fontSize: 12,
    fontWeight: 400,

    "&.active": {
      fontWeight: 600,
    },
  },

  ratingStars: {
    display: "flex",
    fontSize: 14,
  },

  starIcon: (theme) => ({
    fontSize: 70,

    "&.full": {
      color: "#FFD700",
    },

    "&.empty": {
      color: "grey",
    },

    [theme.mediaRequests.mobile]: {
      fontSize: 60,
    },
  }),

  submitButton: {
    marginTop: 15,
  },

  dialogActions: {
    display: "flex",
    justifyContent: "center",
    marginTop: 45,

    "& svg": {
      marginRight: 8,
    },

    "& button + button": {
      marginLeft: 10,
    },
  },
});
