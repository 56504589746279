import KinderCarePin from "@images/sponsors_logos/kinder_care_pin.png";
import KinderCareDesktop from "@images/sponsors_logos/kinder_care_desktop.png";
import KinderCareMobile from "@images/sponsors_logos/kinder_care_mobile.png";

import SteppingStonesPin from "@images/sponsors_logos/stepping_stones_pin.png";
import SteppingStonesDesktop from "@images/sponsors_logos/stepping_stones_desktop.png";
import SteppingStonesMobile from "@images/sponsors_logos/stepping_stones_mobile.png";

import O2bPin from "@images/sponsors_logos/o2b_kids_pin.png";
import O2bDesktop from "@images/sponsors_logos/o2b_kids_desktop.png";
import O2bMobile from "@images/sponsors_logos/o2b_kids_mobile.png";

import creme_de_la_creme_desktop from "@images/sponsors_logos/creme_de_la_creme_desktop.png";
import creme_de_la_creme_pin from "@images/sponsors_logos/creme_de_la_creme_pin.png";
import creme_de_la_creme_mobile from "@images/sponsors_logos/creme_de_la_creme_mobile.png";

import kiddie_academy_logo_pin from "@images/sponsors_logos/kiddie_academy_pin.png";
import kiddie_academy_logo_desktop from "@images/sponsors_logos/kiddie_academy_desktop.png";
import kiddie_academy_logo_mobile from "@images/sponsors_logos/kiddie_academy_mobile.png";

import brighthorizonts_logo_pin from "@images/sponsors_logos/bright_horizons_pin.png";
import brighthorizonts_logo_desktop from "@images/sponsors_logos/bright_horizons_desktop.png";
import brighthorizonts_logo_mobile from "@images/sponsors_logos/bright_horizons_mobile.png";

import cadence_education_desktop from "@images/sponsors_logos/cadence_education_desktop.png";
import cadence_education_pin from "@images/sponsors_logos/cadence_education_pin.png";
import cadence_education_mobile from "@images/sponsors_logos/cadence_education_mobile.png";

import childcare_network_logo_desktop from "@images/sponsors_logos/childcare_network_desktop.png";
import childcare_network_logo_mobile from "@images/sponsors_logos/childcare_network_mobile.png";
import childcare_network_logo_pin from "@images/sponsors_logos/childcare_network_pin.png";

import childrens_lighthouse_logo_desktop from "@images/sponsors_logos/childrens_lighthouse_desktop.png";
import childrens_lighthouse_logo_mobile from "@images/sponsors_logos/childrens_lighthouse_mobile.png";
import childrens_lighthouse_logo_pin from "@images/sponsors_logos/childrens_lighthouse_pin.png";

import coa_logo_desktop from "@images/sponsors_logos/children_of_america_desktop.png";
import coa_logo_mobile from "@images/sponsors_logos/children_of_america_mobile.png";
import coa_logo_pin from "@images/sponsors_logos/children_of_america_pin.png";

import discovery_point_desktop from "@images/sponsors_logos/discovery_point_desktop.png";
import discovery_point_mobile from "@images/sponsors_logos/discovery_point_mobile.png";
import discovery_point_pin from "@images/sponsors_logos/discovery_point_pin.png";

import cw_logo_desktop from "@images/sponsors_logos/creative_world_school_desktop.png";
import cw_logo_mobile from "@images/sponsors_logos/creative_world_school_mobile.png";
import cw_logo_pin from "@images/sponsors_logos/creative_world_school_pin.png";

import goddard_logo_desktop from "@images/sponsors_logos/goddard_school_desktop.png";
import goddard_logo_mobile from "@images/sponsors_logos/goddard_school_mobile.png";
import goddard_logo_pin from "@images/sponsors_logos/goddard_school_pin.png";

import kids_r_kids_logo_desktop from "@images/sponsors_logos/kids_r_kids_desktop.png";
import kids_r_kids_logo_mobile from "@images/sponsors_logos/kids_r_kids_mobile.png";
import kids_r_kids_logo_pin from "@images/sponsors_logos/kids_r_kids_pin.png";

import spring_education_group_logo_desktop from "@images/sponsors_logos/spring_education_group_desktop.png";
import spring_education_group_logo_mobile from "@images/sponsors_logos/spring_education_group_mobile.png";
import spring_education_group_logo_pin from "@images/sponsors_logos/spring_education_group_pin.png";

import sunshine_house_logo_desktop from "@images/sponsors_logos/sunshine_house_desktop.png";
import sunshine_house_logo_mobile from "@images/sponsors_logos/sunshine_house_mobile.png";
import sunshine_house_logo_pin from "@images/sponsors_logos/sunshine_house_pin.png";

import tle_logo_desktop from "@images/sponsors_logos/the_learning_experience_desktop.png";
import tle_logo_mobile from "@images/sponsors_logos/the_learning_experience_mobile.png";
import tle_logo_pin from "@images/sponsors_logos/the_learning_experience_pin.png";

import primrose_logo_desktop from "@images/sponsors_logos/primrose_desktop.png";
import primrose_logo_pin from "@images/sponsors_logos/primrose_pin.png";
import primrose_logo_mobile from "@images/sponsors_logos/primrose_mobile.png";

import public_school_logo_pin from "@images/sponsors_logos/dept_of_education_pin.png";
import public_school_logo_desktop from "@images/sponsors_logos/dept_of_education_desktop.png";
import public_school_logo_mobile from "@images/sponsors_logos/dept_of_education_mobile.png";

import gardner_school_pin from "@images/sponsors_logos/gardner_school_pin.png";
import gardner_school_mobile from "@images/sponsors_logos/gardner_school_mobile.png";
import gardner_school_desktop from "@images/sponsors_logos/gardner_school_desktop.png";

import learning_care_group_desktop from "@images/sponsors_logos/learning_care_group_desktop.png";
import learning_care_group_mobile from "@images/sponsors_logos/learning_care_group_mobile.png";
import learning_care_group_pin from "@images/sponsors_logos/learning_care_group_pin.png";

import lightbridge_academy_desktop from "@images/sponsors_logos/lightbridge_academy_desktop.png";
import lightbridge_academy_mobile from "@images/sponsors_logos/lightbridge_academy_mobile.png";
import lightbridge_academy_pin from "@images/sponsors_logos/lightbridge_academy_pin.png";

import new_horizon_academy_desktop from "@images/sponsors_logos/new_horizon_academy_desktop.png";
import new_horizon_academy_mobile from "@images/sponsors_logos/new_horizon_academy_mobile.png";
import new_horizon_academy_pin from "@images/sponsors_logos/new_horizon_academy_pin.png";

import public_charter_schools_desktop from "@images/sponsors_logos/public_charter_schools_desktop.jpg";
import public_charter_schools_mobile from "@images/sponsors_logos/public_charter_schools_mobile.jpg";
import public_charter_schools_pin from "@images/sponsors_logos/public_charter_schools_pin.png";

import acsi_desktop from "@images/sponsors_logos/acsi_desktop.png";
import acsi_pin from "@images/sponsors_logos/acsi_pin.png";
import acsi_mobile from "@images/sponsors_logos/acsi_mobile.png";

import naeyc_desktop from "@images/sponsors_logos/naeyc_desktop.png";
import naeyc_mobile from "@images/sponsors_logos/naeyc_mobile.png";
import naeyc_pin from "@images/sponsors_logos/naeyc_pin.png";

import nais_desktop from "@images/sponsors_logos/nais_desktop.png";
import nais_mobile from "@images/sponsors_logos/nais_mobile.png";
import nais_pin from "@images/sponsors_logos/nais_pin.png";

import ncea_desktop from "@images/sponsors_logos/ncea_desktop.png";
import ncea_mobile from "@images/sponsors_logos/ncea_mobile.png";
import ncea_pin from "@images/sponsors_logos/ncea_pin.png";

import the_ymca_desktop from "@images/sponsors_logos/the_ymca_desktop.png";
import the_ymca_mobile from "@images/sponsors_logos/the_ymca_mobile.png";
import the_ymca_pin from "@images/sponsors_logos/the_ymca_pin.png";

import AMI_desktop from "@images/sponsors_logos/AMI_desktop.png";
import AMI_mobile from "@images/sponsors_logos/AMI_mobile.png";
import AMI_pin from "@images/sponsors_logos/AMI_pin.png";

import AMS_desktop from "@images/sponsors_logos/AMS_desktop.png";
import AMS_mobile from "@images/sponsors_logos/AMS_mobile.png";
import AMS_pin from "@images/sponsors_logos/AMS_pin.png";

import AMS_pathway_desktop from "@images/sponsors_logos/AMS_pathway_desktop.png";
import AMS_pathway_mobile from "@images/sponsors_logos/AMS_pathway_mobile.png";
import AMS_pathway_pin from "@images/sponsors_logos/AMS_pathway_pin.png";

import big_blue_marble_desktop from "@images/sponsors_logos/big_blue_marble_desktop.png";
import big_blue_marble_mobile from "@images/sponsors_logos/big_blue_marble_mobile.png";
import big_blue_marble_pin from "@images/sponsors_logos/big_blue_marble_pin.png";

import montessori_schools_desktop from "@images/sponsors_logos/montessori_schools_desktop.png";
import montessori_schools_mobile from "@images/sponsors_logos/montessori_schools_mobile.png";
import montessori_schools_pin from "@images/sponsors_logos/montessori_schools_pin.png";

import private_school_desktop from "@images/sponsors_logos/private_school_desktop.png";
import private_school_mobile from "@images/sponsors_logos/private_school_mobile.png";
import private_school_pin from "@images/sponsors_logos/private_school_pin.png";

import childcare_center_desktop from "@images/sponsors_logos/childcare_center_desktop.png";
import childcare_center_mobile from "@images/sponsors_logos/childcare_center_mobile.png";
import childcare_center_pin from "@images/sponsors_logos/childcare_center_pin.png";

import spanish_schoolhouse_desktop from "@images/sponsors_logos/spanish_schoolhouse_desktop.jpg";
import spanish_schoolhouse_mobile from "@images/sponsors_logos/spanish_schoolhouse_mobile.jpg";
import spanish_schoolhouse_pin from "@images/sponsors_logos/spanish_schoolhouse_pin.jpg";

import little_sunshine_pin from "@images/sponsors_logos/little_sunshine_pin.svg";
import little_sunshine_mobile from "@images/sponsors_logos/little_sunshine_mobile.png";
import little_sunshine_desktop from "@images/sponsors_logos/little_sunshine_desktop.svg";

import lle_pin from "@images/sponsors_logos/lle_pin.jpeg";
import lle_desktop from "@images/sponsors_logos/lle_desktop.jpg";

import safari_kids_desktop from "@images/sponsors_logos/safari_kids_desktop.jpg";
import safari_kids_pin from "@images/sponsors_logos/safari_kids_pin.jpeg";

import genius_kids_desktop from "@images/sponsors_logos/genius_kids_desktop.jpg";
import genius_kids_pin from "@images/sponsors_logos/genius_kids_pin.png";

import kidango_desktop from "@images/sponsors_logos/kidango_desktop.jpg";
import kidango_pin from "@images/sponsors_logos/kidango_pin.png";

import kla_pin from "@images/sponsors_logos/kla_pin.jpg";
import kla_desktop from "@images/sponsors_logos/kla_desktop.jpg";

import aus_goodstart_desktop from "@images/sponsors_logos/goodstart_early_learning_desktop.svg";
import aus_goodstart_pin from "@images/sponsors_logos/goodstart_early_learning_pin.svg";

import aus_g8_pin from "@images/sponsors_logos/g8_pin.png";
import aus_g8_desktop from "@images/sponsors_logos/g8_desktop.png";

import aus_ck_pin from "@images/sponsors_logos/ck_pin.png";
import aus_ck_desktop from "@images/sponsors_logos/ck_desktop.png";

import aus_affinity_pin from "@images/sponsors_logos/affinity_education_pin.svg";
import aus_affinity_desktop from "@images/sponsors_logos/affinity_education_desktop.svg";

import aus_guardian_pin from "@images/sponsors_logos/guardian_pin.svg";
import aus_guardian_desktop from "@images/sponsors_logos/guardian_desktop.svg";

import aus_ku_pin from "@images/sponsors_logos/ku_pin.png";
import aus_ku_desktop from "@images/sponsors_logos/ku_desktop.png";

import aus_think_group_pin from "@images/sponsors_logos/think_group_pin.svg";
import aus_think_group_desktop from "@images/sponsors_logos/think_group_desktop.svg";

import aus_only_about_children_pin from "@images/sponsors_logos/only_about_children_pin.svg";
import aus_only_about_children_desktop from "@images/sponsors_logos/only_about_children_desktop.svg";

import aus_busy_bees_pin from "@images/sponsors_logos/busy_bees_pin.svg";
import aus_busy_bees_desktop from "@images/sponsors_logos/busy_bees_desktop.svg";

import aus_ecms_pin from "@images/sponsors_logos/ecms_pin.png";
import aus_ecms_desktop from "@images/sponsors_logos/ecms_desktop.png";

import aus_sparrow_pin from "@images/sponsors_logos/sparrow_pin.png";
import aus_sparrow_desktop from "@images/sponsors_logos/sparrow_desktop.png";

import aus_lady_gowrie_pin from "@images/sponsors_logos/lady_gowrie_pin.png";
import aus_lady_gowrie_desktop from "@images/sponsors_logos/lady_gowrie_desktop.png";

import aus_one_tree_pin from "@images/sponsors_logos/one_tree_pin.svg";
import aus_one_tree_desktop from "@images/sponsors_logos/one_tree_desktop.svg";

import aus_green_leaves_pin from "@images/sponsors_logos/green_leaves_pin.svg";
import aus_green_leaves_desktop from "@images/sponsors_logos/green_leaves_desktop.svg";

import aus_montessori_accademy_pin from "@images/sponsors_logos/montessori_accademy_pin.png";
import aus_montessori_accademy_desktop from "@images/sponsors_logos/montessori_accademy_desktop.png";

import aus_big_fat_smile_pin from "@images/sponsors_logos/big_fat_smile_pin.png";
import aus_big_fat_smile_desktop from "@images/sponsors_logos/big_fat_smile_desktop.png";

import aus_story_house_pin from "@images/sponsors_logos/story_house_pin.png";
import aus_story_house_desktop from "@images/sponsors_logos/story_house_desktop.png";

import aus_amiga_montessori_pin from "@images/sponsors_logos/amiga_montessori_pin.png";
import aus_amiga_montessori_desktop from "@images/sponsors_logos/amiga_montessori_desktop.png";

import aus_little_zaks_pin from "@images/sponsors_logos/little_zaks_pin.png";
import aus_little_zaks_desktop from "@images/sponsors_logos/little_zaks_desktop.png";

import aus_oxanda_pin from "@images/sponsors_logos/oxanda_pin.png";
import aus_oxanda_desktop from "@images/sponsors_logos/oxanda_desktop.png";

import aus_journey_pin from "@images/sponsors_logos/journey_pin.svg";
import aus_journey_desktop from "@images/sponsors_logos/journey_desktop.svg";

import aus_mindchamps_pin from "@images/sponsors_logos/mindchamps_pin.png";
import aus_mindchamps_desktop from "@images/sponsors_logos/mindchamps_desktop.png";

import aus_edge_early_learning_pin from "@images/sponsors_logos/edge_early_learning_pin.svg";
import aus_edge_early_learning_desktop from "@images/sponsors_logos/edge_early_learning_desktop.svg";

import aus_mayfield_pin from "@images/sponsors_logos/mayfield_pin.png";
import aus_mayfield_desktop from "@images/sponsors_logos/mayfield_desktop.png";

import aus_qccs_pin from "@images/sponsors_logos/QCCS_pin.png";
import aus_qccs_desktop from "@images/sponsors_logos/QCCS_desktop.png";

import aus_kids_club_pin from "@images/sponsors_logos/kids_club_pin.png";
import aus_kids_club_desktop from "@images/sponsors_logos/kids_club_desktop.png";

import aus_sdn_pin from "@images/sponsors_logos/sdn_pin.svg";
import aus_sdn_desktop from "@images/sponsors_logos/sdn_desktop.svg";

import aus_shinebright_pin from "@images/sponsors_logos/shinebright_pin.png";
import aus_shinebright_desktop from "@images/sponsors_logos/shinebright_desktop.png";

import aus_explore_and_develop_pin from "@images/sponsors_logos/explore_and_develop_pin.svg";
import aus_explore_and_develop_desktop from "@images/sponsors_logos/explore_and_develop_desktop.svg";

import aus_sparkways_pin from "@images/sponsors_logos/sparkways_pin.png";
import aus_sparkways_desktop from "@images/sponsors_logos/sparkways_desktop.png";

import aus_queensland_pin from "@images/sponsors_logos/queensland_pin.png";
import aus_queensland_desktop from "@images/sponsors_logos/queensland_desktop.png";

import aus_ckp_pin from "@images/sponsors_logos/ckp_pin.png";
import aus_ckp_desktop from "@images/sponsors_logos/ckp_desktop.png";

import aus_genius_pin from "@images/sponsors_logos/genius_pin.png";
import aus_genius_desktop from "@images/sponsors_logos/genius_desktop.png";

import aus_cache_pin from "@images/sponsors_logos/cache_pin.svg";
import aus_cache_desktop from "@images/sponsors_logos/cache_desktop.svg";

import { Models } from "@services/api";

const kinderCareLogos = {
  desktop: KinderCareDesktop,
  mobile: KinderCareMobile,
  pin: KinderCarePin,
};

const steppingStonesLogos = {
  desktop: SteppingStonesDesktop,
  mobile: SteppingStonesMobile,
  pin: SteppingStonesPin,
};

const o2bLogos = {
  desktop: O2bDesktop,
  mobile: O2bMobile,
  pin: O2bPin,
};

const cremeDeLaCremeLogos = {
  desktop: creme_de_la_creme_desktop,
  mobile: creme_de_la_creme_mobile,
  pin: creme_de_la_creme_pin,
};

const kiddieAcademyLogos = {
  desktop: kiddie_academy_logo_desktop,
  mobile: kiddie_academy_logo_mobile,
  pin: kiddie_academy_logo_pin,
};

const brightHorizontsLogos = {
  desktop: brighthorizonts_logo_desktop,
  mobile: brighthorizonts_logo_mobile,
  pin: brighthorizonts_logo_pin,
};

const cadenceEducationLogos = {
  desktop: cadence_education_desktop,
  mobile: cadence_education_mobile,
  pin: cadence_education_pin,
};

const childcareNetworkLogos = {
  desktop: childcare_network_logo_desktop,
  mobile: childcare_network_logo_mobile,
  pin: childcare_network_logo_pin,
};

const childrensLighthouseLogos = {
  desktop: childrens_lighthouse_logo_desktop,
  mobile: childrens_lighthouse_logo_mobile,
  pin: childrens_lighthouse_logo_pin,
};

const coaLogos = {
  desktop: coa_logo_desktop,
  mobile: coa_logo_mobile,
  pin: coa_logo_pin,
};

const cwLogos = {
  desktop: cw_logo_desktop,
  mobile: cw_logo_mobile,
  pin: cw_logo_pin,
};

const discoveryPointLogos = {
  desktop: discovery_point_desktop,
  mobile: discovery_point_mobile,
  pin: discovery_point_pin,
};

const goddardLogos = {
  desktop: goddard_logo_desktop,
  mobile: goddard_logo_mobile,
  pin: goddard_logo_pin,
};

const kidsRkidsLogos = {
  desktop: kids_r_kids_logo_desktop,
  mobile: kids_r_kids_logo_mobile,
  pin: kids_r_kids_logo_pin,
};

const springEducationLogos = {
  desktop: spring_education_group_logo_desktop,
  mobile: spring_education_group_logo_mobile,
  pin: spring_education_group_logo_pin,
};

const sunshineHouseLogos = {
  desktop: sunshine_house_logo_desktop,
  mobile: sunshine_house_logo_mobile,
  pin: sunshine_house_logo_pin,
};

const tleLogos = {
  desktop: tle_logo_desktop,
  mobile: tle_logo_mobile,
  pin: tle_logo_pin,
};

const primroseLogos = {
  desktop: primrose_logo_desktop,
  mobile: primrose_logo_mobile,
  pin: primrose_logo_pin,
};

const publicSchoolLogos = {
  desktop: public_school_logo_desktop,
  mobile: public_school_logo_mobile,
  pin: public_school_logo_pin,
};

const gardnerLogos = {
  desktop: gardner_school_desktop,
  mobile: gardner_school_mobile,
  pin: gardner_school_pin,
};

const lightbridgeLogos = {
  desktop: lightbridge_academy_desktop,
  mobile: lightbridge_academy_mobile,
  pin: lightbridge_academy_pin,
};

const newhorizonsacademyLogos = {
  desktop: new_horizon_academy_desktop,
  mobile: new_horizon_academy_mobile,
  pin: new_horizon_academy_pin,
};

const lcgLogos = {
  desktop: learning_care_group_desktop,
  mobile: learning_care_group_mobile,
  pin: learning_care_group_pin,
};

const ymcaLogos = {
  desktop: the_ymca_desktop,
  mobile: the_ymca_mobile,
  pin: the_ymca_pin,
};

const nceaLogos = {
  desktop: ncea_desktop,
  mobile: ncea_mobile,
  pin: ncea_pin,
};

const naisLogos = {
  desktop: nais_desktop,
  mobile: nais_mobile,
  pin: nais_pin,
};

const naeycLogos = {
  desktop: naeyc_desktop,
  mobile: naeyc_mobile,
  pin: naeyc_pin,
};

const acsiLogos = {
  desktop: acsi_desktop,
  mobile: acsi_mobile,
  pin: acsi_pin,
};

const amiLogos = {
  desktop: AMI_desktop,
  mobile: AMI_mobile,
  pin: AMI_pin,
};

const amsLogos = {
  desktop: AMS_desktop,
  mobile: AMS_mobile,
  pin: AMS_pin,
};

const amsPathwayLogos = {
  desktop: AMS_pathway_desktop,
  mobile: AMS_pathway_mobile,
  pin: AMS_pathway_pin,
};

const bbmaLogos = {
  desktop: big_blue_marble_desktop,
  mobile: big_blue_marble_mobile,
  pin: big_blue_marble_pin,
};

const montessoriLogos = {
  desktop: montessori_schools_desktop,
  mobile: montessori_schools_mobile,
  pin: montessori_schools_pin,
};

const publicCharterSchoolsLogos = {
  desktop: public_charter_schools_desktop,
  mobile: public_charter_schools_mobile,
  pin: public_charter_schools_pin,
};

const childcareCenterLogos = {
  desktop: childcare_center_desktop,
  mobile: childcare_center_mobile,
  pin: childcare_center_pin,
};

const privateSchoolLogos = {
  desktop: private_school_desktop,
  mobile: private_school_mobile,
  pin: private_school_pin,
};

const spanishSchoolhouseLogos = {
  desktop: spanish_schoolhouse_desktop,
  mobile: spanish_schoolhouse_mobile,
  pin: spanish_schoolhouse_pin,
};

const littleSunshineLogos = {
  desktop: little_sunshine_desktop,
  mobile: little_sunshine_mobile,
  pin: little_sunshine_pin,
};

const lleLogos = {
  desktop: lle_desktop,
  mobile: lle_desktop,
  pin: lle_pin,
};

const klaLogos = {
  desktop: kla_desktop,
  mobile: kla_desktop,
  pin: kla_pin,
};

const geniusKidsLogos = {
  desktop: genius_kids_desktop,
  mobile: genius_kids_desktop,
  pin: genius_kids_pin,
};

const kidangoLogos = {
  desktop: kidango_desktop,
  mobile: kidango_desktop,
  pin: kidango_pin,
};

const safariKidsLogos = {
  desktop: safari_kids_desktop,
  mobile: safari_kids_desktop,
  pin: safari_kids_pin,
};

const ausGoodstartLogos = {
  desktop: aus_goodstart_desktop,
  mobile: aus_goodstart_desktop,
  pin: aus_goodstart_pin,
};

const ausG8Logos = {
  desktop: aus_g8_desktop,
  mobile: aus_g8_desktop,
  pin: aus_g8_pin,
};

const ausCkLogos = {
  desktop: aus_ck_desktop,
  mobile: aus_ck_desktop,
  pin: aus_ck_pin,
};

const ausAffinityLogos = {
  desktop: aus_affinity_desktop,
  mobile: aus_affinity_desktop,
  pin: aus_affinity_pin,
};

const ausGuardianLogos = {
  desktop: aus_guardian_desktop,
  mobile: aus_guardian_desktop,
  pin: aus_guardian_pin,
};

const ausKuLogos = {
  desktop: aus_ku_desktop,
  mobile: aus_ku_desktop,
  pin: aus_ku_pin,
};

const ausThinkGroupLogos = {
  desktop: aus_think_group_desktop,
  mobile: aus_think_group_desktop,
  pin: aus_think_group_pin,
};

const ausOnlyAboutChildrenLogos = {
  desktop: aus_only_about_children_desktop,
  mobile: aus_only_about_children_desktop,
  pin: aus_only_about_children_pin,
};

const ausBusyBeesLogos = {
  desktop: aus_busy_bees_desktop,
  mobile: aus_busy_bees_desktop,
  pin: aus_busy_bees_pin,
};

const ausEcmsLogos = {
  desktop: aus_ecms_desktop,
  mobile: aus_ecms_desktop,
  pin: aus_ecms_pin,
};

const ausSparrowLogos = {
  desktop: aus_sparrow_desktop,
  mobile: aus_sparrow_desktop,
  pin: aus_sparrow_pin,
};

const ausLadyGowrieLogos = {
  desktop: aus_lady_gowrie_desktop,
  mobile: aus_lady_gowrie_desktop,
  pin: aus_lady_gowrie_pin,
};

const ausOneTreeLogos = {
  desktop: aus_one_tree_desktop,
  mobile: aus_one_tree_desktop,
  pin: aus_one_tree_pin,
};

const ausGreenLeavesLogos = {
  desktop: aus_green_leaves_desktop,
  mobile: aus_green_leaves_desktop,
  pin: aus_green_leaves_pin,
};

const ausMontessoriAcademyLogos = {
  desktop: aus_montessori_accademy_desktop,
  mobile: aus_montessori_accademy_desktop,
  pin: aus_montessori_accademy_pin,
};

const ausBigFatSmileLogos = {
  desktop: aus_big_fat_smile_desktop,
  mobile: aus_big_fat_smile_desktop,
  pin: aus_big_fat_smile_pin,
};

const ausStoryHouseLogos = {
  desktop: aus_story_house_desktop,
  mobile: aus_story_house_desktop,
  pin: aus_story_house_pin,
};

const ausAmigaMontessoriLogos = {
  desktop: aus_amiga_montessori_desktop,
  mobile: aus_amiga_montessori_desktop,
  pin: aus_amiga_montessori_pin,
};

const ausLittleZaksLogos = {
  desktop: aus_little_zaks_desktop,
  mobile: aus_little_zaks_desktop,
  pin: aus_little_zaks_pin,
};

const ausOxandaLogos = {
  desktop: aus_oxanda_desktop,
  mobile: aus_oxanda_desktop,
  pin: aus_oxanda_pin,
};

const ausJourneyLogos = {
  desktop: aus_journey_desktop,
  mobile: aus_journey_desktop,
  pin: aus_journey_pin,
};

const ausMindchampsLogos = {
  desktop: aus_mindchamps_desktop,
  mobile: aus_mindchamps_desktop,
  pin: aus_mindchamps_pin,
};

const ausEdgeEarlyLearningLogos = {
  desktop: aus_edge_early_learning_desktop,
  mobile: aus_edge_early_learning_desktop,
  pin: aus_edge_early_learning_pin,
};

const ausMayfieldLogos = {
  desktop: aus_mayfield_desktop,
  mobile: aus_mayfield_desktop,
  pin: aus_mayfield_pin,
};

const ausQccsLogos = {
  desktop: aus_qccs_desktop,
  mobile: aus_qccs_desktop,
  pin: aus_qccs_pin,
};

const ausKidsClubLogos = {
  desktop: aus_kids_club_desktop,
  mobile: aus_kids_club_desktop,
  pin: aus_kids_club_pin,
};

const ausSdnLogos = {
  desktop: aus_sdn_desktop,
  mobile: aus_sdn_desktop,
  pin: aus_sdn_pin,
};

const ausShinebrightLogos = {
  desktop: aus_shinebright_desktop,
  mobile: aus_shinebright_desktop,
  pin: aus_shinebright_pin,
};

const ausExploreAndDevelopLogos = {
  desktop: aus_explore_and_develop_desktop,
  mobile: aus_explore_and_develop_desktop,
  pin: aus_explore_and_develop_pin,
};

const ausSparkwaysLogos = {
  desktop: aus_sparkways_desktop,
  mobile: aus_sparkways_desktop,
  pin: aus_sparkways_pin,
};

const ausQueenslandLogos = {
  desktop: aus_queensland_desktop,
  mobile: aus_queensland_desktop,
  pin: aus_queensland_pin,
};

const ausCkpLogos = {
  desktop: aus_ckp_desktop,
  mobile: aus_ckp_desktop,
  pin: aus_ckp_pin,
};

const ausCacheLogos = {
  desktop: aus_cache_desktop,
  mobile: aus_cache_desktop,
  pin: aus_cache_pin,
};

const ausGeniusLogos = {
  desktop: aus_genius_desktop,
  mobile: aus_genius_desktop,
  pin: aus_genius_pin,
};

export const getSponsorsLogo = (
  position: "desktop" | "mobile" | "pin",
  corporation: Models.Logo,
): string | null => {
  switch (corporation) {
    case Models.Logo.Acsi:
      return acsiLogos[position];
    case Models.Logo.Naeys:
      return naeycLogos[position];
    case Models.Logo.Nais:
      return naisLogos[position];
    case Models.Logo.Ncea:
      return nceaLogos[position];
    case Models.Logo.Ymca:
      return ymcaLogos[position];
    case Models.Logo.Ami:
      return amiLogos[position];
    case Models.Logo.AmsPathway:
      return amsPathwayLogos[position];
    case Models.Logo.Ams:
      return amsLogos[position];
    case Models.Logo.Montessori:
      return montessoriLogos[position];
    case Models.Logo.CharterSchool:
      return publicCharterSchoolsLogos[position];
    default:
      return null;
  }
};
