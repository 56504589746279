import React from "react";
import { useForm, FieldError, FormProvider } from "react-hook-form";

import Typography from "@ui-kit/Typography";
import Button from "@ui-kit/Button";
import { ControlledPhoneInput } from "@ui-kit/PhoneInput";
import { ControlledInputField } from "@ui-kit/InputFields";
import { createCss } from "./styles";
import { useReservationStore, useDeepDivePanelStore } from "@hooks";
import { useUserStore } from "@store/UserStore";
import { email, minLength } from "@validators";
import { Models } from "@services/api";
import { Spacer } from "@ui-kit/Spacer";
import { ControlledBooleanField } from "@ui-kit/BooleanField";
import { expectServerError } from "@helpers/serverError";
import { useNotificationStore } from "@store/NotificationStore";

const AdditionalInfoStep: React.FC = () => {
  const [{ facility }] = useDeepDivePanelStore();
  const [, { setUnknownErrorNotification }] = useNotificationStore();
  const [, { nextStep, prevStep }] = useReservationStore();
  const [{ user }, { login, register }] = useUserStore();
  const methods = useForm();
  const { handleSubmit, control, errors, watch, formState, setError } = methods;
  const css = createCss();

  const submitForm = handleSubmit(
    async ({
      email,
      password,
      authorizationIsRegistered,
      parentFirstName,
      parentLastName,
      parentPhone,
      addressZip,
    }) => {
      try {
        if (authorizationIsRegistered === "yes") {
          await login({ email, password });
        }
        if (authorizationIsRegistered === "no") {
          await register({
            email,
            password,
            addressZip,
            phone: parentPhone,
            firstName: parentFirstName,
            lastName: parentLastName,
            joinedFrom: Models.UserJoinedFrom.PdoWaitlist,
          });
        }

        nextStep({
          type: "PARENT_INFO",
        });
      } catch (e) {
        const errors = expectServerError(e, setUnknownErrorNotification);

        errors.forEach((e) => {
          const path = e.source.pointer?.split("/").filter(Boolean);
          if (path) {
            setError(path[0], { message: e.title, type: "manual" });
          }
        });
      }
    },
  );

  return (
    <div css={css.flowContainer}>
      <div>
        <FormProvider {...methods}>
          <Typography bolded variant="h5" align="center">
            Have you registered with us before?
          </Typography>

          <Spacer size="sm" />

          <ControlledBooleanField name="authorizationIsRegistered" />

          {watch("authorizationIsRegistered") === "no" && (
            <>
              <div css={css.nameRow}>
                <ControlledInputField
                  label="Parent first name"
                  name="parentFirstName"
                  required
                />

                <ControlledInputField
                  label="Parent last name"
                  name="parentLastName"
                  required
                />
              </div>

              <ControlledPhoneInput
                error={(errors.parentPhone as FieldError)?.message}
                label="Parent mobile number"
                required
                name="parentPhone"
                control={control}
              />

              <ControlledInputField
                name="addressZip"
                variant="number"
                label="Zip code"
                maxLength={5}
                disabled={formState.isSubmitting}
                required
              />
            </>
          )}

          {!!watch("authorizationIsRegistered") && (
            <>
              <ControlledInputField
                label="Email"
                type="email"
                name="email"
                required
                rules={email()}
              />

              {watch("authorizationIsRegistered") === "no" && (
                <ControlledInputField
                  name="confirmEmail"
                  label="Confirm email"
                  type="email"
                  onPaste={(event) => {
                    setError("confirmEmail", {
                      message: "Paste is not supported. Please type",
                      type: "manual",
                    });
                    event.preventDefault();
                    return;
                  }}
                  rules={{
                    ...email(),
                    validate: (value: string) => {
                      return (
                        value.toLowerCase() === watch("email").toLowerCase() ||
                        "Email doesn't match"
                      );
                    },
                  }}
                />
              )}

              <ControlledInputField
                label="Password"
                inputType="password"
                name="password"
                required
                helperText="Password must be at least 6 characters"
                rules={minLength(6)}
              />
            </>
          )}
        </FormProvider>
      </div>

      <div css={css.actions}>
        <Button
          variant="outlined"
          color={
            facility.subscriptionTier === Models.SubscriptionTier.Ivy
              ? "secondary"
              : "black"
          }
          fullWidth
          size="large"
          onClick={prevStep}
          name="prev"
        >
          Back
        </Button>
        <Button
          variant="contained"
          color={
            facility.subscriptionTier === Models.SubscriptionTier.Ivy
              ? "secondary"
              : "black"
          }
          fullWidth
          size="large"
          onClick={submitForm}
          name="next"
          loading={formState.isSubmitting}
          disabled={!user && !watch("authorizationIsRegistered")}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default AdditionalInfoStep;
