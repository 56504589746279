import keyBy from "lodash/keyBy";

import { Models } from "@services/api";

type Language = {
  id: Models.Languages;
  title: string;
  filterOrder: number;
};

export const LANGUAGES: Language[] = [
  {
    filterOrder: 0,
    id: Models.Languages.English,
    title: "English",
  },
  {
    filterOrder: 2,
    id: Models.Languages.French,
    title: "French",
  },
  {
    filterOrder: 1,
    id: Models.Languages.Chinese,
    title: "Chinese",
  },
  {
    filterOrder: 4,
    id: Models.Languages.Spanish,
    title: "Spanish",
  },
  {
    filterOrder: 3,
    id: Models.Languages.Hebrew,
    title: "Hebrew",
  },
  {
    filterOrder: 5,
    id: Models.Languages.Russian,
    title: "Russian",
  },
];

export const languagesMap = keyBy(LANGUAGES, "id");

export default {
  LANGUAGES,
  languagesMap,
};
