import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  root: (theme) => ({
    [theme.mediaRequests.notMobile]: {
      width: 350,
    },
  }),

  button: {
    width: "calc(50% - 8px)",
  },

  actions: {
    display: "flex",
    justifyContent: "space-between",

    "&.center": {
      justifyContent: "center",
    },
  },
});
