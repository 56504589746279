import cloneDeep from "lodash/cloneDeep";

import { Models } from "@services/api";

export const CHILDREN: Models.Child[] = [
  {
    birthday: "2019-05-05T00:00:00Z",
    createdAt: "2019-11-15T11:28:21.78467Z",
    firstName: "Noelle",
    gender: Models.Gender.Female,
    id: 1,
    lastName: "Vainshel",
    medicalInformation: "Some medical info",
    updatedAt: "2019-11-15T11:28:21.872152Z",
  },
  {
    birthday: "2019-02-12T00:00:00Z",
    createdAt: "2019-11-22T10:20:15.733249015Z",
    firstName: "Bruce",
    gender: Models.Gender.Male,
    id: 2,
    lastName: "Wayne",
    medicalInformation: "",
    updatedAt: "2019-11-22T10:20:15.970853852Z",
  },
];

export const NEW_CHILD: Models.Child = {
  birthday: "",
  firstName: "",
  gender: Models.Gender.Male,
  lastName: "",
  medicalInformation: null,
};

export const getChildFulfilledMock = (
  initial: Partial<Models.Child> = {},
): Models.Child => {
  return {
    ...cloneDeep(CHILDREN[0]),
    ...initial,
  };
};
