import { createUseStyles } from "react-jss";

const styles = {
  action: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 45,

    "& button": {
      minWidth: 100,
    },

    "&.claimed": {
      justifyContent: "center",
    },
  },
};

export default createUseStyles(styles, { name: "EditFacilitySuccessStep" });
