import { Interpolation } from "@emotion/react";
import { fade } from "@material-ui/core/styles";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const useCss = (): Record<Classes, Interpolation<ITheme>> => ({
  ratingWrapper: {
    width: "100%",
  },

  reviewsActions: {
    display: "flex",
    justifyContent: "space-between",
  },

  reviewAction: {
    width: "100%",

    "&.short": {
      width: "calc(50% - 8px)",
    },
  },

  overall: {
    backgroundColor: "#d9d9dd",
    borderRadius: 10,
    cursor: "pointer",
    fontWeight: 700,
    padding: "6px 16px",

    "&:hover": {
      backgroundColor: fade("#d9d9dd", 0.8),
    },
  },

  ratingItemsContainer: {
    borderRadius: 7,
    padding: "0 16px 0 0",

    "&:not(.isOwner)": {
      cursor: "pointer",
    },
  },

  rating: (theme) => ({
    color: theme.palette.text.primary,
    display: "flex",
    fontSize: 16,
    justifyContent: "space-between",
    letterSpacing: "0.02857em",
    lineHeight: 1.75,

    "& + $rating": {
      marginTop: 16,
    },

    "& .name": {
      alignItems: "center",
      display: "inline-flex",

      "& img": {
        marginRight: 7,
        width: 15,
      },

      "& svg": {
        marginRight: 14,
      },
    },

    "& .reviews-count": {
      color: theme.palette.text.primary,
      fontSize: 12,
      fontWeight: 400,
      paddingRight: 10,
    },

    "& .reviews-grade": {
      display: "inline-block",
      textAlign: "right",
      width: 40,
    },
  }),
});
