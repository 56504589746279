import { createUseStyles } from "react-jss";

import { ITheme } from "@theme";

const styles = (theme: ITheme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },

  list: {
    position: "relative",

    "&:not(.collapsed)": {
      padding: "15px 0",
    },
  },

  divider: {
    height: 8,
  },

  listItem: {
    paddingBottom: 2,
    paddingTop: 2,

    "&.photos": {
      [theme.mediaRequests.notMobile]: {
        width: "50%",
      },

      [theme.mediaRequests.mobile]: {
        width: "calc(50% + 20px)",
      },
    },

    "&.weekend-care": {
      whiteSpace: "initial",
    },
  },

  listItemText: {
    fontSize: "0.9rem",
    whiteSpace: "initial",
  },

  warning: {
    color: theme.palette.error.main,
    fontSize: 14,
  },

  alignCenter: {
    textAlign: "center",
  },

  starIcon: {
    fontSize: 15,

    "&.full": {
      color: "#FFD700",
    },

    "&.empty": {
      color: "grey",
    },
  },

  facilityName: {
    alignItems: "center",
    display: "flex",

    "& span": {
      fontSize: 22,
      fontWeight: 600,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },

  actionsContainer: {
    width: "100%",
  },

  galleryBlock: {
    height: 100,
    position: "absolute",
    right: 16,
    top: 8,

    "& .edit-icon": {
      fontSize: 20,
    },

    [theme.mediaRequests.mobile]: {
      width: "calc(50% - 30px)",
    },

    [theme.mediaRequests.notMobile]: {
      width: "calc(50% - 10px)",
    },
  },

  verifiedSign: {
    flexShrink: 0,
    height: 20,
    marginRight: 10,
    width: 20,
  },

  actions: {
    display: "flex",
    justifyContent: "space-evenly",
    marginBottom: 15,
    marginTop: 10,
  },

  politicalIcon: {
    marginLeft: 5,
    verticalAlign: "bottom",
    width: 25,

    "&.camera": {
      transform: "scaleX(-1)",
    },
  },

  section: {
    position: "relative",
    width: "100%",
  },

  sectionAction: {
    alignItems: "center",
    display: "flex",
    height: 25,
    justifyContent: "center",
    position: "absolute",
    right: 0,
    top: 0,
    width: 25,
  },

  directionsControl: {
    bottom: "auto !important",
    float: "none",
    left: 10,
    margin: 0,
    position: "absolute",
    right: "auto !important",
    top: -180,
    zIndex: 1,

    "& div": {
      background: "transparent",
      boxShadow: "none !important",
      margin: "0 !important",

      "& button + button": {
        marginTop: 10,
      },

      "& button": {
        backgroundColor: "white",
        borderColor: "rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186)",
        borderRadius: "100%",
        borderStyle: "solid",
        borderWidth: 2,
        cursor: "pointer",
        display: "block",
        height: 50,
        outline: "none",
        padding: 0,
        width: 50,

        "&:hover": {
          backgroundColor: "#f2f2f2",
        },

        "& svg.direction-icon": {
          height: "1.4em",
          width: "1.4em",
        },
      },
    },
  },

  tooltip: {
    cursor: "pointer",
    height: 20,
    marginLeft: 5,
    verticalAlign: "middle",
    width: 20,
  },

  item: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-start",
    padding: "4px 16px",
    position: "relative",
    width: "100%",
  },

  spacer: {
    padding: "8px 0",
  },

  icon: {
    color: theme.palette.text.primary,
    display: "inline-flex",
    flexShrink: 0,
    lineHeight: 0,
    minWidth: 36,
  },

  block: {
    padding: "10px 0",
    position: "relative",
  },

  text: {
    flex: "1 1 auto",
    fontSize: "0.9rem",
    whiteSpace: "initial",

    "&.space-between": {
      display: "flex",
      justifyContent: "space-between",
    },
  },

  link: {
    color: "inherit",
  },
});

export default createUseStyles(styles, { name: "Highlights" });
