import keyBy from "lodash/keyBy";

import { Models } from "@services/api";

export type ProgramType = {
  id: Models.SchoolOngoingGradeID;
  title: string;
  shortTitle: string;
  order: number;
};

export const programTypes: ProgramType[] = [
  {
    id: Models.SchoolOngoingGradeID.Infant,
    order: 1,
    shortTitle: "Infant",
    title: "Infant care",
  },
  {
    id: Models.SchoolOngoingGradeID.Toddler,
    order: 2,
    shortTitle: "Toddler",
    title: "Toddler care",
  },
  {
    id: Models.SchoolOngoingGradeID.PreSchool,
    order: 3,
    shortTitle: "Preschool",
    title: "Preschool",
  },
  {
    id: Models.SchoolOngoingGradeID.PreK2,
    order: 4,
    shortTitle: "Pre K2",
    title: "Pre K2",
  },
  {
    id: Models.SchoolOngoingGradeID.PreK3,
    order: 5,
    shortTitle: "Pre K3",
    title: "Pre K3",
  },
  {
    id: Models.SchoolOngoingGradeID.PreK4,
    order: 6,
    shortTitle: "Pre K4",
    title: "Pre K4",
  },
  {
    id: Models.SchoolOngoingGradeID.PreK,
    order: 7,
    shortTitle: "Pre K",
    title: "Pre K",
  },
  {
    id: Models.SchoolOngoingGradeID.TK,
    order: 8,
    shortTitle: "TK",
    title: "Transitional K",
  },
  {
    id: Models.SchoolOngoingGradeID.K,
    order: 9,
    shortTitle: "K",
    title: "Kindergarten",
  },
];

export const programsTypesMap = keyBy(programTypes, "id");
