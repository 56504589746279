import isFinite from "lodash/isFinite";
import isNumber from "lodash/isNumber";

import { format_number } from "@helpers/format_number";
import { Models } from "@services/api";

import { theme } from "@theme";

export const countFacilitiesByType = (
  list: Models.FacilityCard[],
): Record<Models.FacilityTypeID, number> => {
  const counters = {} as Record<Models.FacilityTypeID, number>;

  list?.forEach((f) => {
    if (counters[f.facilityType]) {
      counters[f.facilityType] += 1;
    } else {
      counters[f.facilityType] = 1;
    }
  });

  return counters;
};

export const getFeesString = (
  facility: Models.Facility,
  fee: "feesRegistration" | "feesApplication",
): string | null => {
  switch (fee) {
    case "feesRegistration":
      if (!isNumber(facility.tuitionInfo.feesRegistration)) {
        return null;
      }
      return format_number(facility.tuitionInfo.feesRegistration / 100, {
        format: "$0,0",
      });
    case "feesApplication":
      if (!isNumber(facility.tuitionInfo.feesApplication)) {
        return null;
      }
      return format_number(facility.tuitionInfo.feesApplication / 100, {
        format: "$0,0",
      });
    default:
      return null;
  }
};

export const getEnrollmentCapacityString = (
  facility: Models.Facility,
): string | null => {
  if (!isFinite(facility.operatingInfo.enrollmentCapacity)) {
    return null;
  }

  return `${facility.operatingInfo.enrollmentCapacity} students`;
};

export const getFacilityPinColor = (
  type: Models.FacilityTypeID,
): string | undefined => {
  const fill =
    type === Models.FacilityTypeID.DaycareCenter
      ? theme.palette.pink.main
      : type === Models.FacilityTypeID.PreSchool
      ? theme.palette.success.dark
      : type === Models.FacilityTypeID.PrivateSchool
      ? theme.palette.primary.main
      : undefined;

  return fill;
};

export const isAustralia = (facility: Models.Facility) => {
  return facility.address.country === "AUS";
};

export const isUSA = (facility: Models.Facility) => {
  return facility.address.country === "US";
};

export const getPlaySpace = (facility: Models.Facility) => {
  if (!facility.jurisdiction) {
    return "";
  }

  const unit = isUSA(facility) ? "ft" : "meters";

  return `at least ${facility.jurisdiction.indoorSpace} sq ${unit} per child`;
};

export const getSchoolSize = (facility: Models.Facility): string => {
  if (!facility.size) {
    return "";
  }

  const unit = isUSA(facility) ? "ft" : "meters";

  if (facility.size < 1000) {
    return `less than 1,000 sq ${unit}`;
  } else if (facility.size < 2500) {
    return `1,000 - 2,500 sq ${unit}`;
  } else if (facility.size < 5000) {
    return `2,500 - 5,000 sq ${unit}`;
  } else if (facility.size < 7500) {
    return `5,000 - 7,500 sq ${unit}`;
  } else if (facility.size < 10000) {
    return `7,500 - 10,000 sq ${unit}`;
  } else {
    return `more than 10,000 sq ${unit}`;
  }
};

export const getHolidaysList = (
  facility: Models.Facility,
): Models.HolidayScheduleID[] => {
  if (isUSA(facility)) {
    return [
      Models.HolidayScheduleID.IndependenceDay,
      Models.HolidayScheduleID.LaborDay,
      Models.HolidayScheduleID.ColumbusDay,
      Models.HolidayScheduleID.VeteransDay,
      Models.HolidayScheduleID.ThanksgivingDay,
      Models.HolidayScheduleID.DayAfterThanksgiving,
      Models.HolidayScheduleID.ThanksgivingRecess,
      Models.HolidayScheduleID.WinterrRecess,
      Models.HolidayScheduleID.ChristmasEve,
      Models.HolidayScheduleID.ChristmasDay,
      Models.HolidayScheduleID.NewYear,
      Models.HolidayScheduleID.Mlk,
      Models.HolidayScheduleID.PresidentsDay,
      Models.HolidayScheduleID.SpringRecess,
      Models.HolidayScheduleID.EasterRecess,
      Models.HolidayScheduleID.MemorialDay,
      Models.HolidayScheduleID.RoshHashanah,
      Models.HolidayScheduleID.YomKippur,
      Models.HolidayScheduleID.Sukkos,
      Models.HolidayScheduleID.Purim,
      Models.HolidayScheduleID.Pesach,
      Models.HolidayScheduleID.Shavuos,
    ];
  }

  if (isAustralia(facility)) {
    const commonAusHolidays = [
      Models.HolidayScheduleID.Term1Holidays,
      Models.HolidayScheduleID.Term2Holidays,
      Models.HolidayScheduleID.Term3Holidays,
      Models.HolidayScheduleID.Term4Holidays,
      Models.HolidayScheduleID.NewYear,
      Models.HolidayScheduleID.AustraliaDay,
      Models.HolidayScheduleID.GoodFriday,
      Models.HolidayScheduleID.EasterSaturday,
      Models.HolidayScheduleID.EasterSunday,
      Models.HolidayScheduleID.EasterMonday,
      Models.HolidayScheduleID.AnzacDay,
      Models.HolidayScheduleID.ChristmasDay,
      Models.HolidayScheduleID.BoxingDay,
      Models.HolidayScheduleID.ChristmasDayAdditional,
      Models.HolidayScheduleID.BoxingDayAdditional,
    ];
    if (facility.address.state === "NSW") {
      return commonAusHolidays.concat([
        Models.HolidayScheduleID.QueensBirthday,
        Models.HolidayScheduleID.LabourDay,
      ]);
    }
    if (facility.address.state === "VIC") {
      return commonAusHolidays.concat([
        Models.HolidayScheduleID.LabourDay,
        Models.HolidayScheduleID.QueensBirthday,
        Models.HolidayScheduleID.MelbourneCupDay,
      ]);
    }
    if (facility.address.state === "QLD") {
      return commonAusHolidays.concat([
        Models.HolidayScheduleID.LabourDay,
        Models.HolidayScheduleID.RoyalQueenslandShow,
        Models.HolidayScheduleID.QueensBirthday,
      ]);
    }
    if (facility.address.state === "WA") {
      return commonAusHolidays.concat([
        Models.HolidayScheduleID.LabourDay,
        Models.HolidayScheduleID.WesternAustraliaDay,
        Models.HolidayScheduleID.QueensBirthday,
      ]);
    }
    if (facility.address.state === "SA") {
      return commonAusHolidays.concat([
        Models.HolidayScheduleID.AdelaideCupDay,
        Models.HolidayScheduleID.QueensBirthday,
        Models.HolidayScheduleID.LabourDay,
        Models.HolidayScheduleID.ChristmasEve,
      ]);
    }
    if (facility.address.state === "TAS") {
      return commonAusHolidays.concat([
        Models.HolidayScheduleID.RoyalHobartRegatta,
        Models.HolidayScheduleID.EightHoursDay,
        Models.HolidayScheduleID.EasterTuesday,
        Models.HolidayScheduleID.Agfest,
        Models.HolidayScheduleID.RoyalHobartShow,
        Models.HolidayScheduleID.RecreationDay,
      ]);
    }
    if (facility.address.state === "ACT") {
      return commonAusHolidays.concat([
        Models.HolidayScheduleID.CanberraDay,
        Models.HolidayScheduleID.ReconciliationDay,
        Models.HolidayScheduleID.QueensBirthday,
        Models.HolidayScheduleID.LabourDay,
      ]);
    }
    if (facility.address.state === "NT") {
      return commonAusHolidays.concat([
        Models.HolidayScheduleID.MayDay,
        Models.HolidayScheduleID.QueensBirthday,
        Models.HolidayScheduleID.BorroloolaShowDay,
        Models.HolidayScheduleID.AliceSpringsShowDay,
        Models.HolidayScheduleID.TennantCreekShowDay,
        Models.HolidayScheduleID.KatherineShowDay,
        Models.HolidayScheduleID.DarwinShowDay,
        Models.HolidayScheduleID.PicnicDay,
      ]);
    }
  }

  return [];
};
