import React, { Fragment } from "react";

import { useCss } from "./styles";
import { CheckedListProps } from "./types";
import Typography from "@ui-kit/Typography";
import { NewDesignIcons } from "@components/Icons";

export const CheckedList: React.FC<CheckedListProps> = ({
  items,
  paragraph = false,
  gap = false,
  variant = "body1",
  classes,
  ...props
}) => {
  const css = useCss();

  return (
    <Fragment>
      {items.map((text, index) => (
        <Typography
          {...props}
          variant={variant}
          key={`item-${index}`}
          css={css.item}
          className={classes?.item}
          gutterBottom={index + 1 !== items.length}
          paragraph={paragraph && index + 1 === items.length}
          gap={gap && index + 1 === items.length}
        >
          <NewDesignIcons.CheckRounded />

          {text}
        </Typography>
      ))}
    </Fragment>
  );
};
