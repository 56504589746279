import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const useCss = (): Record<Classes, Interpolation<ITheme>> => ({
  notification: {
    alignItems: "center",
    borderRadius: 17,
    cursor: "pointer",
    display: "flex",
    fontWeight: 600,
    justifyContent: "center",
    marginTop: 5,
    padding: "8px 22px",

    "& svg": {
      marginRight: 5,
    },
  },

  closedNotification: (theme) => ({
    backgroundColor: theme.palette.error.main,
    color: "white",
  }),

  openingNotification: (theme) => ({
    backgroundColor: theme.palette.success.main,
    color: theme.palette.text.primary,
  }),
});
