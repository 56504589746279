import React, { useMemo } from "react";

import { SectionTitleProps } from "./types";
import { useCss } from "./styles";
import { useDeepDivePanelStore } from "@hooks/useDeepDivePanelStore";

export const SectionTitle: React.FC<SectionTitleProps> = ({
  children,
  styles,
}) => {
  const [{ facility }] = useDeepDivePanelStore();
  const css = useMemo(() => {
    return useCss({
      tier: facility.subscriptionTier,
    });
  }, [facility.subscriptionTier]);

  return <div css={[css.root, styles?.root]}>{children}</div>;
};
