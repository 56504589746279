import React from "react";
import clsx from "clsx";
import MaterialFormControlLabel, {
  FormControlLabelProps,
} from "@material-ui/core/FormControlLabel";

import useStyles from "./styles";

const FormControlLabel: React.FunctionComponent<FormControlLabelProps> = (
  props,
) => {
  const classes = useStyles();

  return (
    <MaterialFormControlLabel
      {...props}
      classes={{
        ...props.classes,
        label: clsx(classes.labelDefault, props.classes?.label),
      }}
    />
  );
};

export default FormControlLabel;
