import React, { Fragment } from "react";
import numeral from "numeral";
import clsx from "clsx";
import isNil from "lodash/isNil";

import useStyles from "../Highlights/styles";
import { comunityTypesMap } from "@constants/comunity-type";
import { useDeepDivePanelStore } from "@hooks";
import { useMapStore } from "@store/MapStore";
import Dialog from "@ui-kit/Dialog";
import Typography from "@ui-kit/Typography";
import { CheckedList } from "@components/CheckedList";

import WorkerIcon from "@images/worker.svg";
import DemocraticLogoIcon from "@images/democratic-logo.png";
import RepublicanLogoIcon from "@images/republican-logo.png";
import HomeIcon from "@images/home.svg";
import GraduationIcon from "@images/graduation.svg";
import StopwatchIcon from "@images/stopwatch.svg";
import LoveIcon from "@images/love.svg";
import WindmillIcon from "@images/windmill.svg";
import CCTVIcon from "@images/cctv.svg";
import BudgetIcon from "@images/budget.svg";
import { isPrivateSchool, isPublicSchool } from "@selectors";

import { NearbySchools } from "./NearbySchools";
import { CoffeeShops } from "./CoffeeShops";
import { AvgDailyTemp } from "./AvgDailyTemperature";
import { HeatIndex } from "./HeatIndex";

import { Spacer } from "@ui-kit/Spacer";
import { useCss } from "./styles";
import { must } from "@utils/must";
import { useViewport } from "@hooks/useViewport";

type IProps = {
  onClose: () => void;
};

export const CountySpotlightDialog: React.FC<IProps> = ({ onClose }) => {
  const [{ facility }] = useDeepDivePanelStore();
  const [
    { neighbourhoodsShown, districtShown },
    { setNeighbourhoodsShown, setDistrictShown },
  ] = useMapStore();
  const classes = useStyles();
  const css = useCss();
  const { isMobile } = useViewport();

  return (
    <Dialog
      open
      onClose={onClose}
      data-test="county-spotlight-dialog"
      tier={facility.subscriptionTier}
      title="Neighborhood Highlights"
    >
      <div css={css.root}>
        <CheckedList
          items={[
            "Considering a move?",
            "Get to know what the area is really like",
          ]}
        />

        <Spacer size="medium" />
        <Typography variant="h5" bolded align="center" css={css.sectionTitle}>
          Neighborhood
        </Typography>
        <Spacer size="medium" />

        {!isNil(facility.address.neighborhood) && (
          <Fragment>
            <Typography>
              <Typography variant="inherit" bolded>
                Neighborhood name:
              </Typography>{" "}
              {facility.address.neighborhood}
            </Typography>
            <Spacer size="small" />
          </Fragment>
        )}

        <NearbySchools />
        <CoffeeShops />

        {!isNil(facility.cityInfo?.redLightsCctvStatus) && (
          <Fragment>
            <Spacer size="small" />
            <Typography>
              <Typography variant="inherit" bolded>
                Drivability:
              </Typography>{" "}
              {facility.cityInfo?.redLightsCctvStatus}{" "}
              <img
                className={clsx(classes.politicalIcon, "camera")}
                src={CCTVIcon}
                alt="camera"
              />
            </Typography>
          </Fragment>
        )}

        {!isNil(facility.congressionalDistrictInfo) &&
          !isNil(facility.congressionalDistrictInfo.commutingToWork) && (
            <Fragment>
              <Spacer size="small" />
              <Typography data-test="commuting-to-work-display">
                <Typography variant="inherit" bolded>
                  Work commute:
                </Typography>{" "}
                typically{" "}
                {facility.congressionalDistrictInfo.commutingToWork < 30
                  ? "less"
                  : "more"}{" "}
                than 30 min each way{" "}
                <img
                  className={clsx(classes.politicalIcon)}
                  src={StopwatchIcon}
                  alt="stopwatch"
                />
              </Typography>
            </Fragment>
          )}

        <Spacer size="small" />
        <Typography>
          <Typography variant="inherit" bolded>
            Air quality:
          </Typography>{" "}
          {facility.cityInfo?.airQuality}{" "}
          <img
            className={clsx(classes.politicalIcon)}
            src={WindmillIcon}
            alt="windmill"
          />
        </Typography>

        <AvgDailyTemp />
        <HeatIndex />

        {(isPrivateSchool(facility) || isPublicSchool(facility)) &&
          (!isNil(facility.zipCodeInfo?.publicSchoolsCount) ||
            !isNil(facility.zipCodeInfo?.studentsCount) ||
            (!isNil(facility.zipCodeInfo?.averageStudentsTeacherRatio) &&
              must(facility.zipCodeInfo?.averageStudentsTeacherRatio) >=
                5)) && (
            <Fragment>
              <Spacer size="small" />

              <Typography data-test="public-schools-count-display">
                <Typography variant="inherit" bolded>
                  Public schools in district:
                </Typography>{" "}
                {!isNil(facility.zipCodeInfo?.publicSchoolsCount) ? (
                  <Fragment>
                    {facility.zipCodeInfo?.publicSchoolsCount}{" "}
                    {must(facility.zipCodeInfo?.publicSchoolsCount) > 1
                      ? "schools"
                      : "school"}
                  </Fragment>
                ) : (
                  "---"
                )}
              </Typography>

              <Typography data-test="district-total-students-display">
                <Typography variant="inherit" bolded>
                  Total students in district:
                </Typography>{" "}
                {!isNil(facility.zipCodeInfo?.studentsCount)
                  ? `${numeral(facility.zipCodeInfo?.studentsCount).format(
                      "0,0",
                    )} students`
                  : "---"}
              </Typography>

              <Typography gutterBottom data-test="district-ratio-display">
                <Typography variant="inherit" bolded>
                  District student:teacher ratio:
                </Typography>{" "}
                {!isNil(facility.zipCodeInfo?.averageStudentsTeacherRatio) &&
                must(facility.zipCodeInfo?.averageStudentsTeacherRatio) >= 5 ? (
                  <Fragment>
                    {must(facility.zipCodeInfo?.averageStudentsTeacherRatio) >
                    30
                      ? "more than 30:1"
                      : `${Math.round(
                          must(
                            facility.zipCodeInfo?.averageStudentsTeacherRatio,
                          ),
                        )}:1`}
                  </Fragment>
                ) : (
                  "---"
                )}
              </Typography>
            </Fragment>
          )}

        {!isNil(facility.communityType) &&
          (isPrivateSchool(facility) || isPublicSchool(facility)) && (
            <Fragment>
              <Spacer size="small" />
              <Typography data-test="community-type-display">
                <Typography variant="inherit" bolded>
                  Community type:
                </Typography>{" "}
                {comunityTypesMap[facility.communityType].name}
              </Typography>
            </Fragment>
          )}

        {!isNil(facility.cityInfo?.averageHouseholdIncome) && (
          <Fragment>
            <Spacer size="small" />
            <Typography data-test="household-income-display">
              <Typography variant="inherit" bolded>
                Household income:
              </Typography>{" "}
              {numeral(facility.cityInfo?.averageHouseholdIncome).format(
                "$0,0",
              )}{" "}
              (area avg){" "}
              <img
                className={classes.politicalIcon}
                src={BudgetIcon}
                alt="budget"
              />
            </Typography>
          </Fragment>
        )}

        {(!isNil(facility.congressionalDistrictInfo) ||
          !isNil(facility.address.county)) && (
          <Fragment>
            <Spacer size="medium" />
            <Typography
              variant="h5"
              bolded
              align="center"
              css={css.sectionTitle}
            >
              County
            </Typography>
          </Fragment>
        )}

        <Spacer size="small" />

        {!!facility.address.county && (
          <Fragment>
            <Spacer size="small" />
            <Typography>
              <Typography variant="inherit" bolded>
                County name:
              </Typography>{" "}
              {facility.address.county}
            </Typography>
          </Fragment>
        )}

        {!isNil(facility.countyPersonalIncomeRank) && (
          <Fragment>
            <Spacer size="small" />
            <Typography data-test="county-personal-income-display">
              <Typography variant="inherit" bolded>
                Economic ranking:
              </Typography>{" "}
              <span>
                {facility.countyPersonalIncomeRank} of{" "}
                {facility.stateCountiesCount} (1: wealthiest county)
              </span>
            </Typography>
          </Fragment>
        )}

        {!isNil(facility.congressionalDistrictInfo) && (
          <Fragment>
            <Spacer size="medium" />
            <Typography
              variant="h5"
              bolded
              align="center"
              css={css.sectionTitle}
            >
              Congressional district
            </Typography>
            <Spacer size="medium" />

            <Typography>
              <Typography variant="inherit" bolded>
                Congressional district:
              </Typography>{" "}
              {
                facility.congressionalDistrictInfo.id
                  ?.split(/(\d+)/)
                  .filter(Boolean)[1]
              }
            </Typography>

            {!isMobile && (
              <Fragment>
                <Spacer size="small" />
                <Typography>
                  <Typography variant="inherit" bolded>
                    District boundaries:
                  </Typography>{" "}
                  <span
                    data-test="activate-districts-link"
                    className="g-link"
                    onClick={() => {
                      if (neighbourhoodsShown && !districtShown) {
                        setNeighbourhoodsShown(false);
                      }

                      setDistrictShown(!districtShown);
                    }}
                  >
                    {districtShown ? "hide" : "show"}
                  </span>
                </Typography>
              </Fragment>
            )}

            {!isNil(facility.congressionalDistrictInfo.votingLean) && (
              <Fragment>
                <Spacer size="small" />
                <Typography data-test="political-frift-display">
                  <Typography variant="inherit" bolded>
                    Political drift:
                  </Typography>{" "}
                  {facility.congressionalDistrictInfo.votingLean === 0 ? (
                    "congressional district votes down the middle"
                  ) : facility.congressionalDistrictInfo.votingLean < 0 ? (
                    <Fragment>
                      congressional district leans{" "}
                      <img
                        className={classes.politicalIcon}
                        src={DemocraticLogoIcon}
                        alt="democratic-logo"
                      />
                    </Fragment>
                  ) : (
                    <Fragment>
                      congressional district leans{" "}
                      <img
                        className={classes.politicalIcon}
                        src={RepublicanLogoIcon}
                        alt="republican-logo"
                      />
                    </Fragment>
                  )}
                </Typography>
              </Fragment>
            )}

            {!isNil(facility.congressionalDistrictInfo.householdIncome) && (
              <Fragment>
                <Spacer size="small" />
                <Typography data-test="district-household-income-display">
                  <Typography variant="inherit" bolded>
                    Household income:
                  </Typography>{" "}
                  {numeral(
                    Math.round(
                      facility.congressionalDistrictInfo.householdIncome / 500,
                    ) * 500,
                  ).format("$0,0")}{" "}
                  (district avg){" "}
                  <img
                    className={classes.politicalIcon}
                    src={BudgetIcon}
                    alt="budget"
                  />
                </Typography>
              </Fragment>
            )}

            {!isNil(facility.congressionalDistrictInfo?.mortgagePayment) && (
              <Fragment>
                <Spacer size="small" />
                <Typography data-test="political-frift-display">
                  <Typography variant="inherit" bolded>
                    Mortgage expense:
                  </Typography>{" "}
                  {numeral(
                    Math.round(
                      facility.congressionalDistrictInfo.mortgagePayment / 5,
                    ) * 5,
                  ).format("$0,0")}{" "}
                  (district median){" "}
                  <img
                    className={classes.politicalIcon}
                    src={HomeIcon}
                    alt="home"
                  />
                </Typography>
              </Fragment>
            )}

            {facility.congressionalDistrictInfo.buyRentIndex !== null && (
              <Fragment>
                <Spacer size="small" />
                <Typography data-test="political-frift-display">
                  <Typography variant="inherit" bolded>
                    Buy vs rent index:
                  </Typography>{" "}
                  district has{" "}
                  {numeral(
                    facility.congressionalDistrictInfo.buyRentIndex,
                  ).format("0,0.0")}{" "}
                  home owners for each renter
                </Typography>
              </Fragment>
            )}

            {!isNil(facility.congressionalDistrictInfo.populationDensity) && (
              <Fragment>
                <Spacer size="small" />
                <Typography data-test="population-density-display">
                  <Typography variant="inherit" bolded>
                    Population density:
                  </Typography>{" "}
                  {numeral(
                    Math.round(
                      facility.congressionalDistrictInfo.populationDensity / 5,
                    ) * 5,
                  ).format("0,0")}{" "}
                  per sq mi{" "}
                  <img
                    className={classes.politicalIcon}
                    src={LoveIcon}
                    alt="family"
                  />
                </Typography>
              </Fragment>
            )}
            {facility.congressionalDistrictInfo.populationDensityRank !==
              null && (
              <Fragment>
                <Spacer size="small" />
                <Typography data-test="density-ranking-display">
                  <Typography variant="inherit" bolded>
                    Density ranking:
                  </Typography>{" "}
                  {facility.congressionalDistrictInfo.populationDensityRank} of{" "}
                  {facility.congressionalDistrictInfo.stateTotalDistricts} (1:
                  densest district in state)
                </Typography>
              </Fragment>
            )}

            {facility.congressionalDistrictInfo.gradPercentage !== null && (
              <Fragment>
                <Spacer size="small" />
                <Typography data-test="higher-education-display">
                  <Typography variant="inherit" bolded>
                    Higher education:
                  </Typography>{" "}
                  {numeral(
                    facility.congressionalDistrictInfo.gradPercentage,
                  ).format("0%")}{" "}
                  of district residents have a graduate degree{" "}
                  <img
                    className={clsx(classes.politicalIcon)}
                    src={GraduationIcon}
                    alt="graduation"
                  />
                </Typography>
              </Fragment>
            )}
            {!isNil(
              facility.congressionalDistrictInfo.whiteBlueCollarIndex,
            ) && (
              <Fragment>
                <Spacer size="small" />
                <Typography data-test="white-blue-index-display">
                  <Typography variant="inherit" bolded>
                    White vs blue collar index:
                  </Typography>{" "}
                  district has{" "}
                  {numeral(
                    facility.congressionalDistrictInfo.whiteBlueCollarIndex,
                  ).format("0,0.00")}{" "}
                  white collar workers for each blue collar worker{" "}
                  <img
                    className={clsx(classes.politicalIcon)}
                    src={WorkerIcon}
                    alt="worker"
                  />
                </Typography>
              </Fragment>
            )}

            {facility.congressionalDistrictInfo.povertyLevel && (
              <Fragment>
                <Spacer size="small" />
                <Typography data-test="poverty-display">
                  <Typography variant="inherit" bolded>
                    Poverty:
                  </Typography>{" "}
                  {numeral(
                    Math.round(
                      facility.congressionalDistrictInfo.povertyLevel * 100,
                    ) / 100,
                  ).format("0%")}{" "}
                  of district residents live below the poverty line
                </Typography>
              </Fragment>
            )}
            {!isNil(facility.congressionalDistrictInfo.povertyRank) && (
              <Fragment>
                <Spacer size="small" />
                <Typography data-test="poverty-ranking-display">
                  <Typography variant="inherit" bolded>
                    Poverty ranking:
                  </Typography>{" "}
                  {facility.congressionalDistrictInfo.povertyRank} of{" "}
                  {facility.congressionalDistrictInfo.stateTotalDistricts} (1:
                  highest % in state)
                </Typography>
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    </Dialog>
  );
};
