import React, { useMemo } from "react";

import { NewDesignIcons } from "@components/Icons";
import { createCss } from "./styles";
import { TierProps } from "./types";
import { tiersMap } from "@constants/facility-tier";

export const Tier: React.FC<TierProps> = ({
  tier,
  active = true,
  styles,
  classes,
  onClick,
}) => {
  const css = useMemo(() => {
    return createCss({
      active,
    });
  }, [active]);

  return (
    <div
      css={[css.root, styles?.root]}
      className={classes?.root}
      onClick={onClick}
    >
      <NewDesignIcons.TierBadge />
      {tiersMap[tier].toUpperCase()} TIER
    </div>
  );
};
