export const isInstagramUrl = (url?: string): boolean => {
  if (!url) {
    return false;
  }
  return /instagram/.exec(url) !== null;
};

export const isFacebookUrl = (url?: string): boolean => {
  if (!url) {
    return false;
  }

  return Boolean(/facebook/.exec(url) || /fb\.watch/.exec(url));
};

export const isDropboxUrl = (url?: string): boolean => {
  if (!url) {
    return false;
  }
  return /dropbox/.exec(url) !== null;
};

export const isGoogleDriveUrl = (url?: string): boolean => {
  if (!url) {
    return false;
  }
  return /drive\.google/.exec(url) !== null;
};

export const isOneDriveUrl = (url?: string): boolean => {
  if (!url) {
    return false;
  }
  return /onedrive/.exec(url) !== null;
};
