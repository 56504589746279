import { Interpolation } from "@emotion/react";

import { Models } from "@services/api";
import { ITheme } from "@theme";
import { Classes } from "./types";

export const useCss = ({
  tier,
}: {
  tier: Models.SubscriptionTier;
}): Record<Classes, Interpolation<ITheme>> => ({
  sectionRoot: (theme) =>
    tier === Models.SubscriptionTier.Community
      ? {
          backgroundColor: theme.palette.text.primary,
          color: "white",
        }
      : {},

  sectionContent: (theme) => {
    if (
      tier === Models.SubscriptionTier.Pro ||
      tier === Models.SubscriptionTier.Ivy
    ) {
      return {
        backgroundColor: theme.palette.text.primary,
        borderColor: theme.palette.text.primary,
        color: "white",
      };
    }

    return {};
  },

  sectionTitle: (theme) => {
    if (tier === Models.SubscriptionTier.Ivy) {
      return {
        color: theme.palette.yellow.main,
      };
    }

    return {};
  },
});
