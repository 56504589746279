import keyBy from "lodash/keyBy";

import { Models } from "@services/api";

type Designation = {
  id: Models.AdditionalDesignations;
  name: string;
};

export const DESIGNATIONS: Designation[] = [
  {
    id: Models.AdditionalDesignations.CorporateCenter,
    name: "Corporate center",
  },
  {
    id: Models.AdditionalDesignations.FranchiseCenter,
    name: "Franchise center",
  },
  {
    id: Models.AdditionalDesignations.Nursery,
    name: "Nursery school",
  },
  {
    id: Models.AdditionalDesignations.CooperativeSchools,
    name: "Cooperative school",
  },
  {
    id: Models.AdditionalDesignations.GovernmentAffiliated,
    name: "Government affiliated",
  },
  {
    id: Models.AdditionalDesignations.EduAffiliated,
    name: "Edu affiliated",
  },
  {
    id: Models.AdditionalDesignations.MilitaryAffiliated,
    name: "Military affiliated",
  },
  {
    id: Models.AdditionalDesignations.EmployerSponsoredCenter,
    name: "Employer-sponsored center",
  },
  {
    id: Models.AdditionalDesignations.BackUpCareCenter,
    name: "Back-up care center",
  },
  {
    id: Models.AdditionalDesignations.AfterSchoolProgram,
    name: "After-school program",
  },
  {
    id: Models.AdditionalDesignations.BeforeSchoolProgram,
    name: "Before-school program",
  },
  {
    id: Models.AdditionalDesignations.BeforeAfterSchoolCare,
    name: "Before & after school care",
  },
  {
    id: Models.AdditionalDesignations.HeadStart,
    name: "Head Start",
  },
  {
    id: Models.AdditionalDesignations.EarlyHeadStart,
    name: "Early Head Start",
  },
];

export const designationsMap = keyBy(DESIGNATIONS, "id");
