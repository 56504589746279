import keyBy from "lodash/keyBy";

export enum RATINGS_IDS {
  Management = "gradeManagement",
  Staff = "gradeStaff",
  Communication = "gradeCommunication",
  Care = "gradeCare",
  Curriculum = "gradeCurriculum",
  Cleanliness = "gradeCleanliness",
  GradePickUpDropOff = "gradePickUpDropOff",
  Meals = "gradeMeals",
  Booking = "gradeBooking",
}

export type Rating = {
  id: string;
  name: string;
};

export const RATINGS: Rating[] = [
  {
    id: RATINGS_IDS.Management,
    name: "Management",
  },
  {
    id: RATINGS_IDS.Staff,
    name: "Staff",
  },
  {
    id: RATINGS_IDS.Communication,
    name: "Responsiveness",
  },
  {
    id: RATINGS_IDS.Care,
    name: "Care quality",
  },
  {
    id: RATINGS_IDS.Curriculum,
    name: "Curriculum quality",
  },
  {
    id: RATINGS_IDS.Cleanliness,
    name: "Cleanliness",
  },
  {
    id: RATINGS_IDS.GradePickUpDropOff,
    name: "Drop-off & pick-up",
  },
  {
    id: RATINGS_IDS.Meals,
    name: "Meals",
  },
  {
    id: RATINGS_IDS.Booking,
    name: "Parent Pass service",
  },
];

export const ratingsMap = keyBy(RATINGS, "id");

export default {
  RATINGS,
  ratingsMap,
};
