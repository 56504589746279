import React, { Fragment, useMemo } from "react";
import get from "lodash/get";
import { Controller, useFormContext, UseFormMethods } from "react-hook-form";
import orderBy from "lodash/orderBy";
import sortBy from "lodash/sortBy";

import { ControlledMultilineInputField } from "@ui-kit/InputFields";
import { createCss } from "./styles";
import Typography from "@ui-kit/Typography";
import { Models } from "@services/api";
import MultipleSelect from "@ui-kit/MultipleSelect";
import {
  REQUIRED_DOCUMENT,
  RequiredDocumentsMap,
} from "@constants/required-documents";
import { Spacer } from "@ui-kit/Spacer";
import { HolidaysNamesMap } from "@constants/hollyday_schedule";
import { LinkOrFile } from "@components/LinkOrFile";
import {
  SuppliesFromHomeMap,
  SUPPLIES_FROM_HOME,
} from "@constants/supplies-from-home";
import {
  FirstDayChecklistMap,
  FIRST_DAY_CHECKLIST,
} from "@constants/first-day-checklist";
import { ServerError } from "@models/common";
import { getHolidaysList } from "@selectors/facility";
import { FacilityDocumentsInfo } from "@models/FacilityDocumentsInfo";
import { ControlledBooleanField } from "@ui-kit/BooleanField";

interface IProps {
  facilityView: Models.Facility;
  setFacilityView: React.Dispatch<React.SetStateAction<Models.Facility>>;
  isClaim?: boolean;
  isShortClaim?: boolean;
}

const isFieldOfFacilityDocumentsInfo = (field: string) => {
  return (
    field === "documentsInfo.enrollmentPacketFile" ||
    field === "documentsInfo.parentHandbookFile" ||
    field === "documentsInfo.schoolCalendarFile"
  );
};

export const processServerErrors = (
  errors: ServerError[],
  setError: UseFormMethods["setError"],
): boolean => {
  let errorHandled = false;

  errors.forEach((e) => {
    const path = e.source.pointer?.split("/").filter(Boolean).join(".");

    if (!path) {
      return false;
    }

    if (isFieldOfFacilityDocumentsInfo(path)) {
      setError(path, {
        message: e.title,
        type: "manual",
      });
      errorHandled = true;
      return true;
    }
  });

  return errorHandled;
};

export const EditDocumentsView: React.FC<IProps> = ({
  facilityView,
  setFacilityView,
  isClaim,
  isShortClaim,
}) => {
  const documentsInfo = useMemo(
    () => FacilityDocumentsInfo.fromDto(facilityView.documentsInfo),
    [],
  );

  const { control, errors } = useFormContext();
  const css = createCss();
  const holidaysList = useMemo(() => {
    return getHolidaysList(facilityView).map((h) => ({
      id: h,
      name: HolidaysNamesMap[h],
    }));
  }, []);

  const updateView = () => {
    setFacilityView((prev) => ({
      ...prev,
      documentsInfo: documentsInfo.toDto(),
    }));
  };

  return (
    <div data-test="facility-documents-editor">
      {!isShortClaim && (
        <Fragment>
          <Typography variant="h5" align="center" css={css.sectionTitle}>
            Enrollment packet
          </Typography>

          <MultipleSelect
            onChange={(event) => {
              const { value } = event.target;

              if (!value.length) {
                return;
              }

              documentsInfo.requiredEnrollmentDocuments = value.map(
                (a) => a.id,
              );
              updateView();
            }}
            items={REQUIRED_DOCUMENT}
            itemLabelProp="name"
            required={isClaim}
            label="Required enrollment documents"
            value={
              documentsInfo.requiredEnrollmentDocuments?.map(
                (a) => RequiredDocumentsMap[a],
              ) || []
            }
            renderValue={(selected) => {
              const ordered = orderBy(selected, "order");
              const first = ordered.slice(0, 1);
              const rest = ordered.slice(1);

              const firstStr = first.map((s) => s.name).join(", ");

              if (!rest.length) {
                return firstStr;
              } else {
                return `${firstStr} (${rest.length} more)`;
              }
            }}
            name="documentsInfo.requiredEnrollmentDocuments"
          />

          <Spacer size="small" />

          <LinkOrFile
            label="Enrollment application"
            file={documentsInfo.enrollmentPacketFile}
            onChange={(file) => {
              documentsInfo.enrollmentPacketFile = file;
              updateView();
            }}
            formData={{
              control,
              error: get(errors, "documentsInfo.enrollmentPacketFile")?.message,
              name: "documentsInfo.enrollmentPacketFile",
            }}
            data-test="enrollmentPacketFile-uploader"
          />

          <Spacer size="small" />

          <LinkOrFile
            label="Parent handbook"
            file={documentsInfo.parentHandbookFile}
            onChange={(file) => {
              documentsInfo.parentHandbookFile = file;
              updateView();
            }}
            formData={{
              control,
              error: get(errors, "documentsInfo.parentHandbookFile")?.message,
              name: "documentsInfo.parentHandbookFile",
            }}
            data-test="parentHandbookFile-uploader"
          />

          <ControlledMultilineInputField
            defaultValue={documentsInfo.requiredEnrollmentDocumentsDescription}
            onChange={(event) => {
              documentsInfo.requiredEnrollmentDocumentsDescription =
                event.target.value;
              updateView();
            }}
            placeholder="About enrollment documents"
            name="documentsInfo.requiredEnrollmentDocumentsDescription"
          />

          <Spacer size="medium" />

          <Typography variant="h5" align="center" css={css.sectionTitle}>
            First day checklist
          </Typography>

          <Spacer size="small" />

          <MultipleSelect
            onChange={(event) => {
              const { value } = event.target;

              if (!value.length) {
                return;
              }

              documentsInfo.firstDayChecklist = value.map((a) => a.id);
              updateView();
            }}
            required={isClaim}
            items={FIRST_DAY_CHECKLIST}
            itemLabelProp="name"
            label="New students first day checklist"
            value={
              documentsInfo.firstDayChecklist?.map(
                (a) => FirstDayChecklistMap[a],
              ) || []
            }
            renderValue={(selected) => {
              const ordered = orderBy(selected, "order");
              const first = ordered.slice(0, 1);
              const rest = ordered.slice(1);

              const firstStr = first.map((s) => s.name).join(", ");

              if (!rest.length) {
                return firstStr;
              } else {
                return `${firstStr} (${rest.length} more)`;
              }
            }}
            name="documentsInfo.firstDayChecklist"
          />

          <ControlledMultilineInputField
            defaultValue={documentsInfo.firstDayChecklistDescription}
            onChange={(event) => {
              documentsInfo.firstDayChecklistDescription = event.target.value;
              updateView();
            }}
            placeholder="About first day checklist"
            name="documentsInfo.firstDayChecklistDescription"
          />

          <Spacer size="medium" />

          <Typography variant="h5" align="center" css={css.sectionTitle}>
            Supplies from home
          </Typography>

          <Spacer size="small" />

          <MultipleSelect
            onChange={(event) => {
              const { value } = event.target;

              documentsInfo.suppliesFromHome = value.map((a) => a.id);
              updateView();
            }}
            items={SUPPLIES_FROM_HOME}
            itemLabelProp="name"
            label="Permitted items from home"
            value={
              documentsInfo.suppliesFromHome?.map(
                (a) => SuppliesFromHomeMap[a],
              ) || []
            }
            renderValue={(selected) => {
              const ordered = orderBy(selected, "order");
              const first = ordered.slice(0, 1);
              const rest = ordered.slice(1);

              const firstStr = first.map((s) => s.name).join(", ");

              if (!rest.length) {
                return firstStr;
              } else {
                return `${firstStr} (${rest.length} more)`;
              }
            }}
            name="documentsInfo.suppliesFromHome"
          />

          <ControlledMultilineInputField
            defaultValue={documentsInfo.suppliesFromHomeDescription}
            onChange={(event) => {
              documentsInfo.suppliesFromHomeDescription = event.target.value;
              updateView();
            }}
            placeholder="About supplies from home"
            name="documentsInfo.suppliesFromHomeDescription"
          />

          <Spacer size="medium" />

          <Typography variant="h5" align="center" css={css.sectionTitle}>
            Observed holidays
          </Typography>

          <Spacer size="small" />
        </Fragment>
      )}

      <ControlledBooleanField
        name="documentsInfo.schoolCalendar"
        label="Public school calendar"
        required={isClaim}
        defaultValue={documentsInfo.schoolCalendar}
        onChange={(event) => {
          documentsInfo.schoolCalendar = event.target.value === "yes";
          updateView();
        }}
      />

      <Controller
        render={({ onChange, ...controllerProps }) => (
          <MultipleSelect
            {...controllerProps}
            items={holidaysList}
            itemLabelProp="name"
            label="Holiday schedule"
            renderValue={(selected) => {
              const ordered = sortBy(selected, (h) =>
                holidaysList.findIndex((_) => _.id === h.id),
              );
              const first = ordered.slice(0, 1);
              const rest = ordered.slice(1);

              const firstStr = first.map((s) => s.name).join(", ");

              if (!rest.length) {
                return firstStr;
              } else {
                return `${firstStr} (${rest.length} more)`;
              }
            }}
            disabled={!!documentsInfo.schoolCalendar}
            onChange={(event) => {
              const { value } = event.target;

              if (!value.length) {
                return;
              }

              documentsInfo.holidays = value.map((a) => a.id);
              updateView();
              onChange(event);
            }}
          />
        )}
        defaultValue={
          documentsInfo.holidays
            ?.map((h) => {
              return holidaysList.find((_) => _.id === h);
            })
            .filter(Boolean) || []
        }
        name="documentsInfo.holidays"
        control={control}
      />

      <Spacer size="small" />

      {!isShortClaim && (
        <Fragment>
          <LinkOrFile
            label="School calendar"
            file={documentsInfo.schoolCalendarFile}
            onChange={(file) => {
              documentsInfo.schoolCalendarFile = file;
              updateView();
            }}
            formData={{
              control,
              error: get(errors, "documentsInfo.schoolCalendarFile")?.message,
              name: "documentsInfo.schoolCalendarFile",
            }}
            data-test="schoolCalendarFile-uploader"
          />

          <Spacer size="small" />

          <ControlledMultilineInputField
            defaultValue={documentsInfo?.holidaysDescription}
            onChange={(event) => {
              documentsInfo.holidaysDescription = event.target.value;
              updateView();
            }}
            placeholder="About holidays"
            name="documentsInfo.holidaysDescription"
          />
        </Fragment>
      )}
    </div>
  );
};
