import React from "react";
import qs from "query-string";
import { useHistory, useLocation } from "react-router-dom";

import Button from "@ui-kit/Button";
import { createCss } from "./styles";
import Typography from "@ui-kit/Typography";
import InfoIcon from "@material-ui/icons/Info";
import { useReservationStore } from "@hooks/useReservationStore";
import { useDeepDivePanelStore } from "@hooks/useDeepDivePanelStore";
import { getConfigAgeRange } from "@selectors/weekend-care-config";
import { Models } from "@services/api";
import { BookingConfig } from "@models/BookingConfig";

const EligibilityStep: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const [, { nextStep }] = useReservationStore();
  const [{ facility, bookingConfig }] = useDeepDivePanelStore();
  const css = createCss();

  const eligibilityName = BookingConfig.getEligibilityName(
    bookingConfig?.eligibility,
  );
  const eligibilityDescription = BookingConfig.getEligibilityDescription(
    bookingConfig?.eligibility,
    bookingConfig?.serviceEligibilityDescription,
  );

  return (
    <div css={css.flowContainer}>
      <div>
        <div css={css.warning}>
          <InfoIcon color="primary" className="icon" />
          <div>
            <Typography paragraph bolded color="error">
              {getConfigAgeRange(bookingConfig)}
            </Typography>
            <Typography>Ages served</Typography>
          </div>
        </div>
        <div css={css.warning}>
          <InfoIcon color="primary" className="icon" />
          <div>
            <Typography bolded color="error" paragraph>
              {eligibilityName}
            </Typography>
            {!!eligibilityDescription && (
              <Typography paragraph italic>
                {eligibilityDescription}
              </Typography>
            )}
          </div>
        </div>
      </div>

      <div css={css.actions}>
        <Button
          variant="outlined"
          color={
            facility.subscriptionTier === Models.SubscriptionTier.Ivy
              ? "secondary"
              : "black"
          }
          fullWidth
          size="large"
          onClick={() => {
            history.push({
              pathname: `/map/${facility?.id}/PDO`,
              search: qs.stringify({
                ...qs.parse(search),
                step: undefined,
              }),
            });
          }}
          name="prev"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color={
            facility.subscriptionTier === Models.SubscriptionTier.Ivy
              ? "secondary"
              : "black"
          }
          fullWidth
          size="large"
          onClick={() => nextStep({ type: "ELIGIBILITY" })}
          name="next"
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default EligibilityStep;
