import React, { Fragment } from "react";

import Typography from "@ui-kit/Typography";
import { useDeepDivePanelStore } from "@hooks";
import { getFormattedSchedule, isPublicSchool } from "@selectors";

export const OperatingHours: React.FC = () => {
  const [{ facility }] = useDeepDivePanelStore();

  const schedule = getFormattedSchedule(facility.schedule);

  if (schedule === null) {
    return null;
  }

  return (
    <Fragment>
      <Typography gutterBottom>
        <Typography variant="inherit" bolded>
          Operating hrs:
        </Typography>{" "}
        {schedule}
      </Typography>
      {!isPublicSchool(facility) && Boolean(facility.operatingHoursInfo) && (
        <Typography italic data-test="operating-hours-info-display">
          {facility.operatingHoursInfo}
        </Typography>
      )}
    </Fragment>
  );
};
