import { createUseStyles } from "react-jss";

import { ITheme } from "@theme";

const styles = (theme: ITheme) => ({
  successWrapper: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    marginBottom: 15,

    "& .icon": {
      alignItems: "center",
      backgroundColor: theme.palette.success.main,
      borderRadius: "50%",
      color: "white",
      display: "flex",
      height: 30,
      justifyContent: "center",
      marginRight: 10,
      width: 30,

      "& svg": {
        fontSize: 20,
      },
    },
  },

  button: {
    display: "flex",
    margin: "30px auto 0",
    minWidth: 180,
  },
});

export default createUseStyles<ITheme, keyof ReturnType<typeof styles>>(
  styles,
  { name: "SuccessStep" },
);
