import React, { Fragment } from "react";
import clsx from "clsx";
import { useHistory, useLocation } from "react-router-dom";
import numeral from "numeral";

import { RATINGS, RATINGS_IDS } from "@constants/ratings";
import Button from "@ui-kit/Button";
import Typography from "@ui-kit/Typography";
import { ReactGAEventWait } from "@helpers/ga";
import { useDeepDivePanelStore } from "@hooks";
import { StarRateOutlined } from "@components/Icons";
import { Section } from "@components/DeepDivePanel/Section";
import { Spacer } from "@ui-kit/Spacer";
import { useUserStore } from "@store/UserStore";
import { Models } from "@services/api";
import { useCss } from "./styles";
import { isFacilityOwner } from "@selectors";
import { must } from "@utils/must";

export const RatingBlock: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [{ user }] = useUserStore();
  const [{ facility }] = useDeepDivePanelStore();
  const { search } = location;

  const css = useCss();

  const isOwner = isFacilityOwner(facility, user);

  const OverallBlock = (
    <div
      css={css.overall}
      onClick={() => {
        if (isOwner) {
          return null;
        }

        ReactGAEventWait({
          action: "RatingCategoryClick",
          category: "DDP",
        });

        history.push({
          pathname: `/map/${facility.id}/rating`,
          search,
        });
      }}
      data-test="ratings-summary"
    >
      <div css={css.rating}>
        <span className="name">Overall rating</span>
        <Typography
          variant="inherit"
          bolded
          color={
            !facility.reviewsSummary.count
              ? "textPrimary"
              : must(facility.reviewsSummary.gradeOverall) >= 6.95
              ? "textPrimary"
              : "error"
          }
        >
          {!!facility.reviewsSummary.count && (
            <Fragment>
              {" "}
              <span className="reviews-count">
                {facility.reviewsSummary.count > 99
                  ? "99+ ratings"
                  : facility.reviewsSummary.count === 1
                  ? "1 rating"
                  : `${facility.reviewsSummary.count} ratings`}
              </span>
            </Fragment>
          )}
          <span className="reviews-grade">
            {!facility.reviewsSummary.count ? (
              "---"
            ) : (
              <Fragment>
                <span data-test="rating-count">
                  {facility.reviewsSummary.gradeOverall === 10
                    ? "10"
                    : numeral(facility.reviewsSummary.gradeOverall).format(
                        "0.0",
                      )}
                </span>
                <span style={{ fontSize: 11 }}>/10</span>
              </Fragment>
            )}
          </span>
        </Typography>
      </div>
    </div>
  );

  return (
    <Section borderTop id="ddp-rating-section">
      <Section.Title>Facility rating</Section.Title>

      <Spacer size="small" />

      <div css={css.ratingWrapper}>
        <Typography paragraph align="justify">
          Parents rate school performance across distinct service categories,
          which are then combined to arrive at an overall facility rating
        </Typography>

        {(!isOwner || !!facility.reviewsSummary.gradeCommentCount) && (
          <Fragment>
            <div css={css.reviewsActions}>
              {!!facility.reviewsSummary.gradeCommentCount && (
                <Button
                  variant="contained"
                  size="large"
                  color={
                    facility.subscriptionTier === Models.SubscriptionTier.Ivy
                      ? "yellow"
                      : "black"
                  }
                  data-test="reviews-button"
                  onClick={() => {
                    history.push({
                      pathname: `/map/${facility.id}/reviews`,
                      search,
                    });
                  }}
                  css={css.reviewAction}
                  className={clsx({
                    short: !isFacilityOwner(facility, user),
                  })}
                >
                  Reviews (
                  {facility.reviewsSummary.gradeCommentCount < 100
                    ? facility.reviewsSummary.gradeCommentCount
                    : "99+"}
                  )
                </Button>
              )}

              {!isOwner && (
                <Button
                  variant="outlined"
                  size="large"
                  color={
                    facility.subscriptionTier === Models.SubscriptionTier.Ivy
                      ? "secondary"
                      : "black"
                  }
                  onClick={() => {
                    ReactGAEventWait({
                      action: "SubmitRatingClick",
                      category: "DDP",
                    });

                    history.push({
                      pathname: `/map/${facility.id}/rating`,
                      search,
                    });
                  }}
                  data-test="submit-rating-trigger"
                  css={css.reviewAction}
                  className={clsx({
                    short: !!facility.reviewsSummary.gradeCommentCount,
                  })}
                >
                  Submit rating
                </Button>
              )}
            </div>
            <Spacer size="medium" />
          </Fragment>
        )}

        <div
          css={css.ratingItemsContainer}
          data-test="ratings-container"
          onClick={() => {
            if (isOwner) {
              return null;
            }

            ReactGAEventWait({
              action: "RatingCategoryClick",
              category: "DDP",
            });

            history.push({
              pathname: `/map/${facility.id}/rating`,
              search,
            });
          }}
          className={clsx({
            isOwner,
          })}
        >
          {RATINGS.map((rate) => {
            if (
              rate.id === RATINGS_IDS.Meals &&
              !facility.nutritionInfo?.mealsServed
            ) {
              return null;
            }
            if (rate.id === RATINGS_IDS.Booking && !facility.bookingEnabled) {
              return null;
            }

            const reviewsCount =
              facility.reviewsSummary[
                (rate.id + "Count") as keyof Models.FacilityReviewsSummary
              ];

            if (!reviewsCount && facility.reviewsSummary.count > 0) {
              return null;
            }

            const reviewValue: number =
              facility.reviewsSummary[
                rate.id as keyof Models.FacilityReviewsSummary
              ] || 0;

            return (
              <div
                css={css.rating}
                className={clsx({
                  inactive: reviewsCount === 0,
                })}
                key={`review-${rate.id}`}
                data-test={`rating-${rate.id}`}
              >
                <span className="name">
                  <StarRateOutlined />
                  {rate.name}
                </span>
                <Typography
                  variant="inherit"
                  bolded
                  color={
                    reviewsCount === 0
                      ? "textPrimary"
                      : reviewValue >= 6.95
                      ? "textPrimary"
                      : "error"
                  }
                >
                  <span className="reviews-grade">
                    {reviewsCount === 0 ? (
                      "---"
                    ) : (
                      <Fragment>
                        <span data-test="rating-count">
                          {reviewValue === 10
                            ? "10"
                            : numeral(reviewValue).format("0.0")}
                        </span>
                        <span style={{ fontSize: 11 }}>/10</span>
                      </Fragment>
                    )}
                  </span>
                </Typography>
              </div>
            );
          }).filter(Boolean)}
        </div>

        <Spacer size="small" />
        {OverallBlock}
      </div>
    </Section>
  );
};
