import isNumber from "lodash/isNumber";

import { Models } from "@services/api";
import formatAgeRange from "@helpers/format-age-range";

export const getShiftsByDay = (
  config: Models.BookingConfig,
  day: Models.WeekDay,
  type: Models.SessionType,
): Models.Session[] => {
  return config.sessions.filter(
    (s) => s.weekday === day && type === s.shiftType,
  );
};

export const ageRangeIsValid = (range: Models.AgeRange): boolean => {
  if (!range.ageFrom || !range.ageTo) {
    return false;
  }

  if (
    !isNumber(range.ageFrom.days) ||
    !range.ageFrom.preferredUnit ||
    !isNumber(range.ageTo.days) ||
    !range.ageTo.preferredUnit
  ) {
    return false;
  }

  return true;
};

export const getConfigAgeRange = (
  config?: Models.BookingConfig,
): string | null => {
  if (!config) {
    return null;
  }
  if (ageRangeIsValid(config.ageRange)) {
    return formatAgeRange(
      config.ageRange.ageFrom.days,
      config.ageRange.ageFrom.preferredUnit,
      config.ageRange.ageTo.days,
      config.ageRange.ageTo.preferredUnit,
    );
  }

  return null;
};
