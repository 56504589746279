import React, { useMemo } from "react";

import Typography from "@ui-kit/Typography";

import { useDeepDivePanelStore } from "@hooks";

export const CoffeeShops: React.FC = () => {
  const [{ facility }] = useDeepDivePanelStore();

  return useMemo(() => {
    return <Component />;
  }, [facility.zipCodeInfo?.starbucksCount]);
};

const Component: React.FC = () => {
  const [{ facility }] = useDeepDivePanelStore();

  if (!facility.zipCodeInfo?.starbucksCount) {
    return null;
  }

  return (
    <Typography data-test="coffee-shops-display" gutterBottom>
      <Typography variant="inherit" bolded>
        Coffee shops:
      </Typography>{" "}
      {facility.zipCodeInfo?.starbucksCount} Starbucks found nearby
    </Typography>
  );
};
