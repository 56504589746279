import { createUseStyles } from "react-jss";

import { ITheme } from "@theme";

const styles = (theme: ITheme) => ({
  root: {
    [theme.mediaRequests.notMobile]: {
      width: 450,
    },
  },

  saveButton: {
    marginTop: 20,
  },

  agesWrapper: {
    display: "flex",
    justifyContent: "space-between",

    "& > div": {
      width: "calc(100% / 2 - 10px)",
    },
  },

  group: {
    flexDirection: "row",
    marginLeft: 10,

    "& label:last-child": {
      marginRight: 0,
    },
  },

  radioLabel: {
    width: 190,
  },

  radioContainer: {
    alignItems: "center",
    flexDirection: "row",
    marginBottom: 10,
  },

  radio: {
    paddingBottom: 4,
    paddingTop: 4,
  },

  spacer: {
    height: 1,
    margin: "10px 0",
  },

  commonFeesRow: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },

  commonFeesInput: {
    marginTop: 0,
    width: 100,
  },

  commonFeesLabel: {
    width: 200,
  },

  tuitionSelect: {
    marginTop: 0,
  },

  tuitionInput: {
    "&:first-of-type": {
      marginRight: 15,
    },
  },

  foundedLabel: {
    marginTop: 26,
  },

  dialogActions: {
    display: "flex",
    justifyContent: "center",
    marginTop: 45,

    "& button + button": {
      marginLeft: 10,
    },

    "& svg": {
      marginRight: 8,
    },
  },

  discountRow: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",

    "& .control": {
      margin: 0,
      width: 110,
    },

    "& .select": {
      margin: 0,
    },
  },

  checkRow: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    marginLeft: -9,
  },

  sectionTitle: {
    backgroundColor: "rgba(0, 0, 0, .06)",
    padding: 7,
  },
});

export default createUseStyles<ITheme, keyof ReturnType<typeof styles>>(
  styles,
  { name: "EditFacilityHighlightsForm" },
);
