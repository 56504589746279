import React from "react";

import Typography from "@ui-kit/Typography";
import { useDeepDivePanelStore } from "@hooks";
import { format_percent } from "@helpers/format_number";

export const WhiteStudents: React.FC = () => {
  const [{ facility }] = useDeepDivePanelStore();

  if (!facility.whiteRacePercent) {
    return null;
  }

  return (
    <Typography data-test="white-students-display" gutterBottom>
      <Typography variant="inherit" bolded>
        White students:
      </Typography>{" "}
      {facility.whiteRacePercent < 0.01
        ? "less than 1%"
        : format_percent(facility.whiteRacePercent, { round: true })}
    </Typography>
  );
};
