import React from "react";
import { Controller, useFormContext, UseFormMethods } from "react-hook-form";

import { Input } from "@ui-kit/InputFields";

import { EditVirtualTourProps } from "./types";
import { required } from "@validators";
import { ServerError } from "@models/common";
import { trimEventValue } from "@helpers/trimEventValue";

export type FormFields = {
  mediaFacebookPhotosUrl: string;
  mediaGooglePhotosUrl: string;
  mediaWebsitePhotosUrl: string;
};

const isFieldOfMediaUrl = (field: string) => {
  return (
    "mediaFacebookPhotosUrl" === field ||
    "mediaGooglePhotosUrl" === field ||
    "mediaWebsitePhotosUrl" === field
  );
};

export const processServerErrors = (
  errors: ServerError[],
  setError: UseFormMethods["setError"],
): boolean => {
  let errorHandled = false;

  errors.forEach((e) => {
    const paths = e.source.pointer?.split("/");

    if (!paths) {
      return false;
    }

    if (isFieldOfMediaUrl(paths[1])) {
      setError(paths[1], {
        message: e.title,
        type: "manual",
      });
      errorHandled = true;
      return true;
    }
  });

  return errorHandled;
};

export const EditMediaUrlsView: React.FC<EditVirtualTourProps> = ({
  facilityView,
  setFacilityView,
  isClaim,
  isShortClaim,
}) => {
  const { control, errors } = useFormContext<FormFields>();

  return (
    <div>
      {!isShortClaim && (
        <Controller
          render={({ onChange, ...controllerProps }) => (
            <Input
              {...controllerProps}
              error={errors.mediaFacebookPhotosUrl?.message}
              label="Facebook page: paste link"
              onChange={(event) => {
                setFacilityView((prev) => ({
                  ...prev,
                  mediaFacebookPhotosUrl: event.target.value.trim(),
                }));
                onChange(trimEventValue(event));
              }}
              required={isClaim}
            />
          )}
          rules={{
            ...(isClaim ? required() : {}),
          }}
          defaultValue={facilityView.mediaFacebookPhotosUrl || ""}
          name="mediaFacebookPhotosUrl"
          control={control}
        />
      )}

      <Controller
        render={({ onChange, ...controllerProps }) => (
          <Input
            {...controllerProps}
            error={errors.mediaWebsitePhotosUrl?.message}
            label="School website: paste link"
            onChange={(event) => {
              setFacilityView((prev) => ({
                ...prev,
                mediaWebsitePhotosUrl: event.target.value.trim(),
              }));
              onChange(trimEventValue(event));
            }}
            required={isClaim}
          />
        )}
        rules={{
          ...(isClaim ? required() : {}),
        }}
        defaultValue={facilityView.mediaWebsitePhotosUrl || ""}
        name="mediaWebsitePhotosUrl"
        control={control}
      />

      {!isShortClaim && (
        <Controller
          render={({ onChange, ...controllerProps }) => (
            <Input
              {...controllerProps}
              error={errors.mediaGooglePhotosUrl?.message}
              label="Google photos: paste link"
              onChange={(event) => {
                setFacilityView((prev) => ({
                  ...prev,
                  mediaGooglePhotosUrl: event.target.value.trim(),
                }));
                onChange(trimEventValue(event));
              }}
            />
          )}
          defaultValue={facilityView.mediaGooglePhotosUrl || ""}
          name="mediaGooglePhotosUrl"
          control={control}
        />
      )}
    </div>
  );
};
