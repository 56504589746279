import React, { useState } from "react";
import { DateTime } from "luxon";
import numeral from "numeral";
import orderBy from "lodash/orderBy";
import isNil from "lodash/isNil";

import Checkbox from "@material-ui/core/Checkbox";
import Button from "@ui-kit/Button";
import { createCss } from "./styles";
import { useReservationStore } from "@hooks";
import { getAge } from "@helpers/get-age-from-date";
import Typography from "@ui-kit/Typography";
import { getChildFullName } from "@selectors";
import { useDeepDivePanelStore } from "@hooks/useDeepDivePanelStore";
import { Models } from "@services/api";
import { Spacer } from "@ui-kit/Spacer";

export const ReviewStep: React.FC = () => {
  const [{ facility, bookingConfig }] = useDeepDivePanelStore();
  const [{ child, fetching, selectedShifts }, { nextStep, prevStep }] =
    useReservationStore();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const css = createCss();

  function submitForm() {
    nextStep({
      type: "BOOK",
    });
  }

  function renderShifts() {
    return (
      <Typography gutterBottom>
        <Typography variant="inherit" bolded>
          Service time:
        </Typography>{" "}
        {orderBy(selectedShifts.slice(), "start").map((shift, index) => (
          <React.Fragment key={`shift-${index}`}>
            {index !== 0 && " | "}
            {`${DateTime.fromISO(shift.shift.dateTimeFrom, {
              zone: facility?.address.timezone,
            })
              .toFormat("h:mma")
              .toLowerCase()} ` +
              `- ${DateTime.fromISO(shift.shift.dateTimeTo, {
                zone: facility?.address.timezone,
              })
                .toFormat("h:mma")
                .toLowerCase()}`}
          </React.Fragment>
        ))}
      </Typography>
    );
  }

  function renderCost() {
    return (
      <>
        {selectedShifts.some((c) => c.discount) && (
          <Typography gutterBottom>
            <Typography variant="inherit" bolded>
              Promotion:
            </Typography>{" "}
            {bookingConfig?.discountPercent}% off
          </Typography>
        )}
        <Typography gutterBottom>
          <Typography variant="inherit" bolded>
            Co-payment:
          </Typography>{" "}
          {numeral(selectedShifts[0].serviceFee / 100).format("$0,0.00")}
        </Typography>
        <Typography gutterBottom>
          <Typography variant="inherit" bolded>
            Service fee:
          </Typography>{" "}
          {numeral(selectedShifts[0].processingFee / 100).format("$0,0.00")}
          {!!selectedShifts[0].processingFee && ` (non-refundable)`}
        </Typography>
      </>
    );
  }

  function renderDate() {
    return (
      <Typography gutterBottom>
        <Typography variant="inherit" bolded>
          Service date:
        </Typography>{" "}
        {DateTime.fromISO(selectedShifts[0].shift.dateTimeFrom, {
          zone: facility?.address.timezone,
        }).toFormat("cccc, M/d/yyyy")}
      </Typography>
    );
  }

  const LastDropOff = () => {
    if (!selectedShifts[0].shift.session.cutoffMinutes) {
      return null;
    }

    return (
      <Typography>
        <Typography variant="inherit" bolded>
          Last drop-off:
        </Typography>{" "}
        {selectedShifts[0].shift.session.cutoffMinutes} minutes prior to closing
      </Typography>
    );
  };

  return (
    <div css={css.flowContainer}>
      <div>
        <div css={css.confirmationPanel}>
          {renderDate()}
          {renderShifts()}
          <LastDropOff />

          <Spacer size="md" />

          <Typography gutterBottom>
            <Typography variant="inherit" bolded>
              Child name:
            </Typography>{" "}
            {getChildFullName(child)}
          </Typography>
          <Typography>
            <Typography variant="inherit" bolded>
              Child age:
            </Typography>{" "}
            {!isNil(child) && getAge(child.birthday)?.str}
          </Typography>

          <Spacer size="md" />

          {renderCost()}

          <Spacer size="sm" />

          <div
            css={css.checkRow}
            onClick={() => setTermsAccepted((prev) => !prev)}
            data-test="accept-terms-check"
          >
            <Checkbox color="primary" checked={termsAccepted} />
            <Typography>
              <a href="/terms" target="_blank" rel="noreferrer">
                I accept terms of service
              </a>
            </Typography>
          </div>
        </div>
      </div>

      <div css={css.actions}>
        <Button
          variant="outlined"
          color={
            facility.subscriptionTier === Models.SubscriptionTier.Ivy
              ? "secondary"
              : "black"
          }
          fullWidth
          size="large"
          onClick={prevStep}
          name="prev"
        >
          Back
        </Button>

        <Button
          variant="contained"
          color={
            facility.subscriptionTier === Models.SubscriptionTier.Ivy
              ? "secondary"
              : "black"
          }
          fullWidth
          size="large"
          name="next"
          onClick={submitForm}
          loading={fetching}
          disabled={!termsAccepted}
        >
          Book
        </Button>
      </div>
    </div>
  );
};
