import React from "react";

import Typography from "@ui-kit/Typography";
import { useDeepDivePanelStore } from "@hooks";

export const FullTimeTeachers: React.FC = () => {
  const [{ facility }] = useDeepDivePanelStore();

  if (!facility.fullTimeTeachersCount || facility.fullTimeTeachersCount < 5) {
    return null;
  }

  return (
    <Typography data-test="full-time-teachers-display" gutterBottom>
      <Typography variant="inherit" bolded>
        Full time teachers:
      </Typography>{" "}
      {facility.fullTimeTeachersCount < 50
        ? facility.fullTimeTeachersCount
        : "more than 50"}
    </Typography>
  );
};
