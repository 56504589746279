import React from "react";

import Typography from "@ui-kit/Typography";
import { getTimeStringFromTimestamp } from "@helpers/getTimeString";
import { createCss } from "./styles";
import { ServiceSessionProps } from "./types";
import { format_number } from "@helpers/format_number";

export const ServiceSession: React.FC<ServiceSessionProps> = ({ session }) => {
  const css = createCss();

  return (
    <div css={css.root}>
      <Typography gutterBottom>
        <Typography bolded variant="inherit">
          Service day:
        </Typography>{" "}
        {session.weekday.capitalize()}
      </Typography>
      <Typography gutterBottom>
        <Typography bolded variant="inherit">
          Service time:
        </Typography>{" "}
        {getTimeStringFromTimestamp(
          session.config.timestampFrom,
          session.config.timestampTo,
        )}
      </Typography>

      {!!session.config.cutoffMinutes && (
        <Typography gutterBottom={session.config.ratePerHour > 0}>
          <Typography bolded variant="inherit">
            Last drop-off:
          </Typography>{" "}
          {session.config.cutoffMinutes} minutes before closing
        </Typography>
      )}

      {session.config.ratePerHour > 0 && (
        <Typography>
          <Typography bolded variant="inherit">
            Co-payment:
          </Typography>{" "}
          {format_number(session.config.ratePerHour / 100, { format: "$0,0" })}{" "}
          per reservation
        </Typography>
      )}
    </div>
  );
};
