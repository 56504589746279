import { DateTime } from "luxon";

export const getTimeString = (
  from: number | null,
  to: number | null,
): string | null => {
  if (!from || !to) {
    return null;
  }

  return (
    `${DateTime.local()
      .startOf("day")
      .plus({ seconds: from })
      .toFormat("h:mma")
      .toLowerCase()}\xa0-\xa0` +
    `${DateTime.local()
      .startOf("day")
      .plus({ seconds: to })
      .toFormat("h:mma")
      .toLowerCase()}`
  );
};

export const getTimeStringFromTimestamp = (
  from: string | null,
  to: string | null,
  skipZero = false,
): string | null => {
  if (!from || !to) {
    return null;
  }

  const utcFrom = DateTime.fromISO(from, { zone: "UTC" });
  const utcTo = DateTime.fromISO(to, { zone: "UTC" });

  if (skipZero) {
    if (utcFrom.get("hour") === 0 || utcTo.get("hour") === 0) {
      return null;
    }
  }

  return (
    `${utcFrom.toFormat("h:mma").toLowerCase()}\xa0-\xa0` +
    `${utcTo.toFormat("h:mma").toLowerCase()}`
  );
};
