import React from "react";
import Humanize from "humanize-plus";

import { createCss } from "../styles";
import Typography from "@ui-kit/Typography";
import { Spacer } from "@ui-kit/Spacer";
import { BookingConfig } from "@models/BookingConfig";
import { Models } from "@services/api";
import Button from "@ui-kit/Button";
import { useDeepDivePanelStore } from "@hooks/useDeepDivePanelStore";

interface BookingRulesSectionProps {
  bookingConfig: Models.BookingConfig;
}

export const BookingRulesSection: React.FC<BookingRulesSectionProps> = ({
  bookingConfig,
}) => {
  const [{ facility }] = useDeepDivePanelStore();
  const css = createCss();

  return (
    <>
      <Spacer size="md" />

      <Typography variant="h5" bolded align="center" css={css.sectionTitle}>
        Booking rules
      </Typography>

      <Spacer size="sm" />

      <Typography bolded>Reservations:</Typography>
      <Typography italic>
        parents can make reservations up to{" "}
        {bookingConfig.serviceBookingInAdvanceWeeks}{" "}
        {Humanize.pluralize(
          bookingConfig.serviceBookingInAdvanceWeeks!,
          "week",
        )}{" "}
        in advance. Reservations must be submitted online as the facility cannot
        accept walk-ins.
      </Typography>

      {bookingConfig.discountPercent > 0 && (
        <>
          <Spacer size="sm" />

          <Typography bolded>Promotions:</Typography>
          <Typography italic>
            parents save {bookingConfig.discountPercent}% when booking at least
            3 days in advance
          </Typography>
        </>
      )}

      <Spacer size="sm" />

      <Typography bolded>Cancellations:</Typography>
      <Typography italic>
        {BookingConfig.getCancellationDescription(
          bookingConfig.cancellationPeriodHours,
        )}
      </Typography>

      <Spacer size="sm" />

      <Typography bolded>Refunds:</Typography>
      <Typography italic>
        Upon cancellation, if a co-payment was due as part of your reservation,
        it will be refunded back to the method of payment originally used.
        Previously paid service fees (if any) are non-refundable.
      </Typography>

      <Spacer size="sm" />

      <Typography bolded>Illness:</Typography>
      <Typography italic>
        Because of risk posed to other children, this facility cannot
        accommodate children exhibiting symptoms of illness.
      </Typography>

      <Spacer size="sm" />

      <Typography bolded>Medical forms:</Typography>
      <Typography italic>
        Per regulatory requirements, child immunization & medical forms may need
        to be filed with the facility as part of your visit. If you would like
        this information to be submitted automatically, simply upload it when
        booking.
      </Typography>

      <Spacer size="sm" />

      <Button
        variant="outlined"
        color="secondary"
        href={`/registration-forms/${facility.address.country}/${facility.address.state}`}
        target="_blank"
        fullWidth
        size="large"
      >
        View forms
      </Button>
    </>
  );
};
