import React, { Fragment } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import { ageUnitsMap } from "@constants/age-units";
import { denormalizeAge } from "@helpers/ages-helper";
import Typography from "@ui-kit/Typography";
import { isPrivateSchool, isPublicSchool } from "@selectors";
import { useDeepDivePanelStore } from "@hooks";
import { Spacer } from "@ui-kit/Spacer";

export const TeacherChildRatiosTable: React.FC = () => {
  const [{ facility }] = useDeepDivePanelStore();

  if (isPrivateSchool(facility) || isPublicSchool(facility)) {
    return null;
  }

  if (!facility.jurisdiction?.teacherStudentRatios) {
    return null;
  }

  return (
    <Fragment>
      <Typography bolded gutterBottom>
        Teacher:child ratios
      </Typography>

      <Table size="small">
        <TableBody>
          {facility.jurisdiction.teacherStudentRatios?.map((ratio) => (
            <TableRow key={`ratio-${ratio.students}`}>
              <TableCell>
                {denormalizeAge(
                  ratio.ageFrom.days,
                  ratio.ageFrom.preferredUnit,
                )}
                {ageUnitsMap[ratio.ageFrom.preferredUnit].title}
              </TableCell>
              <TableCell>
                {ratio.ageTo.days &&
                  ratio.ageTo.days < 3000000 &&
                  `${denormalizeAge(
                    ratio.ageTo.days,
                    ratio.ageTo.preferredUnit,
                  )}` + `${ageUnitsMap[ratio.ageTo.preferredUnit].title}`}
              </TableCell>
              <TableCell>1:{ratio.students}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Spacer size="small" />
    </Fragment>
  );
};
