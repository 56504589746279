import React from "react";

import useStyles from "../styles";
import Button from "@ui-kit/Button";
import { useDeepDivePanelStore } from "@hooks";
import Typography from "@ui-kit/Typography";
import BudgeIcon from "@images/vbadge_flower_blue.svg";
import CheckIcon from "@material-ui/icons/Check";
import { Spacer } from "@ui-kit/Spacer";

interface ClaimSuccessStepProps {
  showSchoolResponseSuccess: boolean;
}

export const ClaimSuccessStep: React.FC<ClaimSuccessStepProps> = ({
  showSchoolResponseSuccess,
}) => {
  const classes = useStyles();
  const [{ facility }] = useDeepDivePanelStore();

  return (
    <>
      <div className={classes.claimed}>
        <img src={BudgeIcon} alt="claimed-badge" />
        School claimed
      </div>

      {showSchoolResponseSuccess && (
        <div className={classes.successWrapper}>
          <div className="icon">
            <CheckIcon />
          </div>
          <Typography bolded>Response sent</Typography>
        </div>
      )}

      <Spacer size="small" />

      <div className={classes.boostTextContainer}>
        <Button
          variant="outlined"
          color="secondary"
          size="large"
          fullWidth
          className={classes.boostButton}
          link={{
            pathname: "/support",
            search: `?facilityId=${facility.id}`,
          }}
        >
          Questions? Contact support
        </Button>
      </div>
    </>
  );
};
