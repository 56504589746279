import keyBy from "lodash/keyBy";

import { Models } from "@services/api";

export type Affiliation = {
  filterOrder: number;
  id: Models.AffiliationID;
  name: string;
  ddpName?: string;
  chipName: string;
};

export const AFFILIATIONS: Affiliation[] = [
  {
    chipName: "Christian",
    ddpName: "Christian",
    id: Models.AffiliationID.ChristianAffiliation,
    name: "Christian affiliation",
  },
  {
    chipName: "Christian",
    ddpName: "Christian",
    id: Models.AffiliationID.ChristianNceaAffiliation,
    name: "NCEA affiliated",
  },
  {
    chipName: "Christian",
    ddpName: "Christian",
    id: Models.AffiliationID.ChristianAcsiAffiliation,
    name: "ACSI affiliated",
  },
  {
    chipName: "Jewish",
    ddpName: "Jewish",
    id: Models.AffiliationID.JewishAffiliation,
    name: "Jewish affiliation",
  },
  {
    chipName: "Muslim",
    ddpName: "Muslim",
    id: Models.AffiliationID.MuslimAffiliation,
    name: "Muslim affiliation",
  },
].map((a, i) => ({ ...a, filterOrder: i }));

export const AffiliationsMap = keyBy(AFFILIATIONS, "id");
