import { Layer } from "mapbox-gl";
import { GeoJSON } from "geojson";

export const facilityPointLayer: Layer = {
  id: "unclustered-point",
  layout: {
    "icon-allow-overlap": true,
    "icon-anchor": "bottom",
    "icon-ignore-placement": true,
    "icon-image": ["get", "color"],
    "icon-size": ["get", "size"],
    "symbol-sort-key": ["get", "order"],
  },
  source: "facilities",
  type: "symbol",
};

export const circleRadiusLayer: Layer = {
  id: "circle_radius_l_l",
  layout: {},
  paint: {
    "line-color": "#0000ff",
    "line-width": 2,
  },
  source: "circle_radius_s",
  type: "line",
};

export const getCircleRadiusSourceData = (
  coords: [number, number][],
): GeoJSON.FeatureCollection<GeoJSON.Geometry> => {
  return {
    features: [
      {
        geometry: {
          coordinates: [coords],
          type: "Polygon",
        },
        properties: {},
        type: "Feature",
      },
    ],
    type: "FeatureCollection",
  };
};
