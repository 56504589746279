import React, { Fragment } from "react";
import isNil from "lodash/isNil";

import Typography from "@ui-kit/Typography";
import { useDeepDivePanelStore } from "@hooks";
import { isPrivateSchool, isPublicSchool } from "@selectors";
import { Spacer } from "@ui-kit/Spacer";
import { getPlaySpace, getSchoolSize } from "@selectors/facility";

export const Size: React.FC = () => {
  const [{ facility }] = useDeepDivePanelStore();

  if (!facility.jurisdiction) {
    return null;
  }

  if (isPrivateSchool(facility) || isPublicSchool(facility)) {
    return null;
  }

  return (
    <Fragment>
      {!isNil(facility.size) && (
        <Typography gutterBottom data-test="facility-size-display">
          <Typography variant="inherit" bolded>
            Facility size:
          </Typography>{" "}
          {getSchoolSize(facility)}
        </Typography>
      )}
      <Typography>
        <Typography variant="inherit" bolded>
          Play space:
        </Typography>{" "}
        {getPlaySpace(facility)}
      </Typography>
      <Spacer size="small" />
    </Fragment>
  );
};
