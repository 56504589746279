import React, { useState } from "react";

import { CheckedList } from "@components/CheckedList";
import Button from "@ui-kit/Button";
import { CountySpotlightDialog } from "../CountySpotlightDialog";
import { AvgDailyTemp } from "../CountySpotlightDialog/AvgDailyTemperature";
import { CoffeeShops } from "../CountySpotlightDialog/CoffeeShops";
import { HeatIndex } from "../CountySpotlightDialog/HeatIndex";
import { NearbySchools } from "../CountySpotlightDialog/NearbySchools";
import { Section } from "@components/DeepDivePanel/Section";
import { Spacer } from "@ui-kit/Spacer";
import { Models } from "@services/api";
import { useDeepDivePanelStore } from "@hooks/useDeepDivePanelStore";

export const CountySpotlight: React.FC = () => {
  const [{ facility }] = useDeepDivePanelStore();
  const [opened, setOpened] = useState(false);

  return (
    <Section borderTop>
      <Section.Title>Neighborhood Highlights</Section.Title>

      <Spacer size="small" />
      <CheckedList
        items={[
          "Considering a move?",
          "Get to know what the area is really like",
        ]}
        paragraph
      />

      <Button
        variant="outlined"
        color={
          facility.subscriptionTier === Models.SubscriptionTier.Ivy
            ? "secondary"
            : "black"
        }
        onClick={() => setOpened(true)}
        data-test="learn-more-button"
        fab
        fullWidth
        size="large"
      >
        Learn more
      </Button>

      <Spacer />

      <NearbySchools />
      <CoffeeShops />
      <AvgDailyTemp />
      <HeatIndex />

      {opened && <CountySpotlightDialog onClose={() => setOpened(false)} />}
    </Section>
  );
};
