import React, { useState, useEffect } from "react";

import FsLightbox, { FsLightboxProps } from "fslightbox-react";

export const Lightbox: React.FC<Omit<FsLightboxProps, "toggler">> = (props) => {
  const [toggler, setToggler] = useState(false);

  useEffect(() => {
    setToggler((prev) => !prev);
  }, []);

  return <FsLightbox {...props} toggler={toggler} />;
};
