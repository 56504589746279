import React, { Fragment } from "react";
import { useTracking } from "react-tracking";

import Typography from "@ui-kit/Typography";
import { useDeepDivePanelStore } from "@hooks";
import { isAustralia } from "@selectors/facility";

export const ImmunizationForm: React.FC = () => {
  const { trackEvent } = useTracking();
  const [{ facility }] = useDeepDivePanelStore();

  if (isAustralia(facility)) {
    return null;
  }

  if (!facility.jurisdiction) {
    return null;
  }

  return (
    <Fragment>
      <Typography gutterBottom>
        <Typography variant="inherit" bolded>
          Student immunizations forms:
        </Typography>{" "}
        {facility.jurisdiction.documents?.find(
          (d) => d.type === "immunisationInfo",
        ) && (
          <a
            href={
              facility.jurisdiction.documents.find(
                (d) => d.type === "immunisationInfo",
              )?.templateUrl
            }
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              trackEvent({
                action: "DocumentClick",
                label: "ImmunizationForms",
              });
            }}
          >
            click to view
          </a>
        )}
      </Typography>
      <Typography italic align="justify">
        Per regulatory requirements, this facility must keep student health
        records on file, including age-appropriate immunizations
      </Typography>
    </Fragment>
  );
};
