import React, { useState, useEffect, Fragment } from "react";
import cloneDeep from "lodash/cloneDeep";
import clsx from "clsx";
import qs from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import isNumber from "lodash/isNumber";
import isFunction from "lodash/isFunction";
import MaskedInput from "react-text-mask";
import track, { useTracking } from "react-tracking";

import Dialog from "@ui-kit/Dialog";
import useStyles from "./styles";
import Typography from "@ui-kit/Typography";
import { ROUTES } from "@constants";
import { EditFacilityContactInfoState } from "@components/EditFacilityInfoForm/types";
import { useDeepDivePanelStore, usePrevious } from "@hooks";
import { useUserStore } from "@store/UserStore";
import { ReactGAEventWait } from "@helpers/ga";
import { Models, FACILITY_API } from "@services/api";
import { ContactInfoStep } from "./ContactInfoStep";
import { OperationalDetailsStep } from "./OperationalDetailsStep";
import { isPublicSchool } from "@selectors";
import { ClaimSuccessStep } from "./SuccessStep";
import { InitialStep } from "./InitialStep";
import { CloseWarning } from "./CloseWarning";
import { OverviewStep } from "./OverviewStep";
import { useEnrollmentResponseStore } from "@store/EnrollmentResponseStore";
import { useNotificationStore } from "@store/NotificationStore";
import { Amplitude } from "@services/amplitude";

enum ClaimStep {
  ContactInfo = "contactInfo",
  OperatinalInfo = "operationalInfo",
  OverviewInfo = "overview",
  Initial = "initial",
  Confirm = "confirm",
  Success = "success",
}

const ClaimForm: React.FC = () => {
  const { trackEvent } = useTracking();
  const history = useHistory();
  const { search } = useLocation();
  const query = qs.parse(search);
  const [{ user }, { confirmPin, getUserData, updateUser }] = useUserStore();
  const prevUser = usePrevious(user);
  const [, { setNotification }] = useNotificationStore();
  const [{ afterClaimCallback }, { resetAfterClaimCallback }] =
    useEnrollmentResponseStore();
  const [{ facility }, { saveFacility, setFacility }] = useDeepDivePanelStore();
  const [editContactsState, setEditContactsState] =
    useState<EditFacilityContactInfoState>(() => ({
      editDesignations: false,
      editDistrict: false,
      editEmail: false,
      editLanguages: false,
      editName: false,
      editOperatingHours: !isNumber(facility.schedule[0]?.start),
      editPhone: !facility.phone,
      editStatus: false,
    }));

  const [fetching, setFetching] = useState(false);
  const [showCloseWarning, setShowCloseWarning] = useState(false);
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [facilityView, setFacilityView] = useState(cloneDeep(facility));
  const [showSchoolResponseSuccess, setShowSchoolResponseSuccess] =
    useState(false);
  const [role, setRole] = useState(user?.role || Models.UserRole.Owner);
  const [pin, setPin] = useState("");
  const [timer, setTimer] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const classes = useStyles();

  useEffect(() => {
    if (!prevUser && user) {
      setShouldSubmit(true);
    }
  }, [user]);

  useEffect(() => {
    if (facility.accountId || isPublicSchool(facility)) {
      history.replace(`/map/${facility.id}`);
    }

    trackEvent({
      action: "Page Loaded",
    });

    history.replace({
      search: qs.stringify({
        ...query,
        step: ClaimStep.Initial,
      }),
    });
  }, []);

  useEffect(() => {
    setFacilityView(cloneDeep(facility));
  }, [facility]);

  useEffect(() => {
    if (shouldSubmit && user) {
      void updateUser({
        ...user,
        role,
      }).then(async () => {
        if (user?.isConfirmed) {
          try {
            ReactGAEventWait({
              action: "Submit",
              category: "FacilityClaim",
            });

            setFetching(true);

            await saveFacility(facilityView);
            await FACILITY_API.facilitiesByIdClaim({
              id: facility.id,
              facilitiesClaim: {},
            });
            setFacility(
              (await FACILITY_API.facilitiesByIdGet({ id: facility.id })).data,
            );
            await getUserData();

            if (isFunction(afterClaimCallback)) {
              await afterClaimCallback();
              setShowSchoolResponseSuccess(true);
            }

            ReactGAEventWait({
              action: "SubmitSuccess",
              category: "FacilityClaim",
            });
            trackEvent({
              action: "Submitted Successfully",
              facilityId: facility.id,
            });
            setStep(ClaimStep.Success);
          } catch (error) {
            ReactGAEventWait({
              action: "SubmitError",
              category: "FacilityClaim",
            });

            const errors = error?.response?.data?.errors;

            if (errors) {
              setNotification({
                message: errors[0].title,
                type: "error",
              });
            } else {
              throw error;
            }
          } finally {
            setFetching(false);
          }
        } else {
          sendConfirm();
          setStep(ClaimStep.Confirm);
          setShouldSubmit(false);
          void saveFacility(facilityView);
        }
      });
    }
  }, [shouldSubmit]);

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        setTimer((prevState) => prevState - 1);
      }, 1000);
    }
  }, [timer]);

  useEffect(() => {
    const content = document.getElementsByClassName("dialog-content")[0];

    if (content && content.scrollTo) {
      content.scrollTo(0, 0);
    }
  }, [query.step]);

  function sendConfirm() {
    trackEvent({
      action: "Send Confirmation",
      facilityId: facility.id,
    });
    void FACILITY_API.facilitiesByIdUserConfirmFromClaim({
      id: facility.id,
    });
    setTimer(20);
  }

  const setStep = (s: ClaimStep) => {
    trackEvent({
      action: "Step Change",
      step: s,
    });
    history.push({
      search: qs.stringify({
        ...query,
        step: s,
      }),
    });
  };

  function checkAuthorization() {
    if (!user) {
      history.replace({
        pathname: ROUTES.SIGNUP_FORM,
        search: qs.stringify({
          addressZip: facilityView.address.zip,
          addressCountry: facilityView.address.country,
          email: facilityView.email,
          joinedFrom: Models.UserJoinedFrom.ClaimFacility,
          phone: facilityView.phone,
          signUpVariant: "claim",
        }),
      });
    } else {
      setShouldSubmit(true);
    }
  }

  function closeModal() {
    if (query.step === ClaimStep.Success) {
      resetAfterClaimCallback();
      history.push({
        pathname: `/map/${facility.id}`,
        search,
      });
    } else {
      setShowCloseWarning(true);
    }
  }

  function renderConfirmStep() {
    return (
      <Fragment>
        <Typography gutterBottom bolded align="center">
          Check inbox, promotions or spam folder
        </Typography>
        <Typography gutterBottom align="center">
          Use pin to claim your school profile
        </Typography>
        <Typography paragraph align="center">
          Pin will expire in 10 minutes
        </Typography>

        <MaskedInput
          mask={[/\d/, /\d/, /\d/, /\d/]}
          placeholderChar={"_"}
          showMask
          className={clsx(classes.pinInput, {
            error: Boolean(errorMessage),
          })}
          onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value.trim().slice(0, 4);
            setPin(value);
            setErrorMessage("");

            if (value.replace(/\D+/g, "").length === 4) {
              try {
                await confirmPin(value);
                setShouldSubmit(true);
              } catch (error) {
                const errors = error?.response?.data?.errors;

                if (errors) {
                  setErrorMessage(errors[0].title);
                }
              }
            }
          }}
          onFocus={(
            event: React.FocusEvent<HTMLDivElement & HTMLInputElement>,
          ) => {
            const { target } = event;
            const index = target.value.indexOf("_");

            if (index !== -1) {
              setTimeout(() => {
                target.setSelectionRange(index, index);
              }, 100);
            }
          }}
          value={pin}
          type="tel"
          data-test="pin-field"
        />

        {Boolean(errorMessage) && (
          <div className={classes.pinError}>{errorMessage}</div>
        )}

        <div
          className={clsx(classes.resend, {
            active: timer === 0,
          })}
          onClick={timer === 0 ? sendConfirm : undefined}
        >
          Resend pin {timer > 0 && `(${timer})`}
        </div>
      </Fragment>
    );
  }

  const getStepView = () => {
    switch (query.step) {
      case ClaimStep.Initial:
        return (
          <InitialStep
            facilityView={facilityView}
            setFacilityView={setFacilityView}
            onNext={() => setStep(ClaimStep.ContactInfo)}
            defaultRole={role}
            setRole={setRole}
          />
        );
      case ClaimStep.ContactInfo:
        return (
          <ContactInfoStep
            facilityView={facilityView}
            setFacilityView={setFacilityView}
            onNext={() => setStep(ClaimStep.OperatinalInfo)}
            onPrev={() => setStep(ClaimStep.Initial)}
            editState={editContactsState}
            onEditState={setEditContactsState}
          />
        );
      case ClaimStep.OperatinalInfo:
        return (
          <OperationalDetailsStep
            facilityView={facilityView}
            setFacilityView={setFacilityView}
            onNext={() => setStep(ClaimStep.OverviewInfo)}
            onPrev={() => history.goBack()}
          />
        );
      case ClaimStep.OverviewInfo:
        return (
          <OverviewStep
            facilityView={facilityView}
            setFacilityView={setFacilityView}
            onNext={checkAuthorization}
            onPrev={() => history.goBack()}
            loading={fetching}
          />
        );
      case ClaimStep.Confirm:
        return renderConfirmStep();
      case ClaimStep.Success:
        return (
          <ClaimSuccessStep
            showSchoolResponseSuccess={showSchoolResponseSuccess}
          />
        );
    }
  };

  return (
    <Dialog
      open
      onClose={closeModal}
      data-test="claim-school-modal"
      tier={facility.subscriptionTier}
      title="Claim facility"
    >
      {showCloseWarning && (
        <CloseWarning
          onClose={() => setShowCloseWarning(false)}
          facilityView={facilityView}
        />
      )}
      <div className={classes.container}>{getStepView()}</div>
    </Dialog>
  );
};

export default track({
  page: "Facility Claim Short Flow",
})(ClaimForm);
