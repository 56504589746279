import { createUseStyles } from "react-jss";

import { ITheme } from "@theme";

const styles = (theme: ITheme) => ({
  root: {
    [theme.mediaRequests.notMobile]: {
      width: 400,
    },
  },

  timeActionsRow: {
    alignItems: "flex-start",
    display: "flex",

    [theme.mediaRequests.mobile]: {
      "& > div:not(:last-child)": {
        paddingRight: 15,
      },
    },

    [theme.mediaRequests.notMobile]: {
      "& > div": {
        width: "calc((100% - 15px) / 3)",

        "&:not(:last-child)": {
          marginRight: 15,
        },
      },
    },
  },

  saveButton: {
    marginTop: 20,
  },

  propertyLabel: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 10,
  },

  propertyLabelCheck: {
    padding: 0,
  },

  propertyValue: {
    color: theme.palette.primary.main,
    cursor: "pointer",

    "&.edit": {
      textDecoration: "line-through",
    },

    "&.disabled": {
      color: theme.palette.text.primary,
      cursor: "default",
    },
  },

  radio: {
    padding: "4px 12px",
  },

  dialogActions: {
    display: "flex",
    justifyContent: "center",
    marginTop: 45,

    "& svg": {
      marginRight: 8,
    },

    "& button + button": {
      marginLeft: 10,
    },
  },
});

export default createUseStyles<ITheme, keyof ReturnType<typeof styles>>(
  styles,
  { name: "EditFacilityInfoForm" },
);
