import isNil from "lodash/isNil";
import isFinite from "lodash/isFinite";

const parseValue = (v: number | string | null | undefined) => {
  if (isNil(v)) {
    return null;
  } else if (typeof v === "string") {
    const parsed = parseInt(v, 10);
    return isFinite(parsed) ? parsed : null;
  } else {
    return v;
  }
};

export class Money {
  private state: number | null;

  constructor(v: number | string | null | undefined) {
    this.state = parseValue(v);
  }

  set value(v: number | string | null | undefined) {
    this.state = parseValue(v);
  }

  get value(): number | null {
    return this.state;
  }

  static fromDto(dto: number | null | undefined): Money {
    const v = !isNil(dto) ? dto / 100 : dto;
    return new Money(v);
  }

  public toDto(): number | null {
    if (!isNil(this.state)) {
      return this.state * 100;
    }
    return null;
  }
}
