import { Models } from "@services/api";
import { daysToWeeks, daysToMonths, daysToYears } from "./ages-helper";

export const getAgeLimitMessage = (
  max: number,
  unit: Models.AgeUnit,
): string | undefined => {
  switch (unit) {
    case Models.AgeUnit.Weeks:
      return `0 to ${daysToWeeks(max)} are acceptable values for wks`;
    case Models.AgeUnit.Months:
      return `0 to ${daysToMonths(max)} are acceptable values for mo`;
    case Models.AgeUnit.Years:
      return `0 to ${daysToYears(max)} are acceptable values for yrs`;
  }
};
