/* global JSX */
import React, { Fragment } from "react";

import { Models } from "@services/api";
import { getFormattedSchedule } from "@selectors";

interface IProps {
  facility: Models.Facility | Models.FacilityCard;
  emptyView?: JSX.Element;
}

const OperatingHours: React.FC<IProps> = ({
  facility,
  emptyView = <span>Operating hours not yet specified</span>,
}) => {
  const schedule = getFormattedSchedule(facility.schedule);

  return <Fragment>{schedule || emptyView}</Fragment>;
};

export default OperatingHours;
