import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const useCss = (): Record<Classes, Interpolation<ITheme>> => ({
  container: (theme) => ({
    position: "relative",
    width: "100%",

    [theme.mediaRequests.notMobile]: {
      width: 400,
    },

    "& .uploaderRoot": {
      bottom: 0,
      height: "initial",
      left: 0,
      position: "absolute",
      right: 0,
      top: 0,
    },
  }),

  footer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 30,

    "& button + button": {
      marginLeft: 10,
    },
  },

  photoWrapper: {
    marginTop: 30,
    paddingTop: "100%",
    position: "relative",
  },

  photoRequirements: {
    marginBottom: 15,
  },

  uploadPhotosTitle: {
    marginBottom: 15,
    marginTop: 15,
  },

  newPhotoWrapper: {
    paddingTop: "100%",
    position: "relative",
  },

  divider: {
    height: 2,
    margin: "20px 0",
  },

  doneAction: {
    display: "flex",
    justifyContent: "center",
    marginTop: 45,

    "& button": {
      minWidth: 100,
    },
  },

  categoryLabel: {
    marginTop: 20,
  },

  sectionTitle: {
    backgroundColor: "rgba(0, 0, 0, .06)",
    padding: 7,
  },

  videoThumbnail: {
    height: 200,
    width: "100%",
  },

  videoWrapper: {
    marginTop: 16,
  },
});
