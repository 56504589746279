import React, { Fragment } from "react";
import isFunction from "lodash/isFunction";
import clsx from "clsx";
import { useHistory, useLocation } from "react-router-dom";

import Button from "@ui-kit/Button";
import SuccessStep from "@components/SuccessStep";
import { useDeepDivePanelStore } from "@hooks";
import useStyles from "./styles.jss";
import { Models } from "@services/api";
import { Spacer } from "@ui-kit/Spacer";
import { ShadowDivider } from "@components/ShadowDivider";

export const EditFacilitySuccessStep: React.FC<{
  onDone?: () => void;
}> = ({ onDone }) => {
  const history = useHistory();
  const { search } = useLocation();
  const [{ facility }] = useDeepDivePanelStore();
  const classes = useStyles();

  return (
    <Fragment>
      <Spacer />
      <SuccessStep
        text="Submitted"
        content={
          <Fragment>
            <ShadowDivider />
            <div
              className={clsx(classes.action, {
                claimed: !!facility.accountId,
              })}
            >
              {!facility.accountId && (
                <Button
                  variant="outlined"
                  color={
                    facility.subscriptionTier === Models.SubscriptionTier.Ivy
                      ? "secondary"
                      : "black"
                  }
                  size="large"
                  data-test="claim-button"
                  onClick={() => {
                    history.push({
                      pathname: `/map/${facility.id}/claim`,
                      search,
                    });
                  }}
                >
                  Is this your school?
                </Button>
              )}
              <Button
                variant="contained"
                color={
                  facility.subscriptionTier === Models.SubscriptionTier.Ivy
                    ? "secondary"
                    : "black"
                }
                data-test="done-button"
                size="large"
                onClick={() => {
                  if (isFunction(onDone)) {
                    onDone();
                  } else {
                    history.replace({
                      pathname: `/map/${facility.id}`,
                      search,
                    });
                  }
                }}
              >
                Done
              </Button>
            </div>
          </Fragment>
        }
      />
    </Fragment>
  );
};
