import React, { useState, useEffect, useCallback } from "react";
import isFunction from "lodash/isFunction";
import isNil from "lodash/isNil";

import Typography from "@ui-kit/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@ui-kit/Dialog";
import Button from "@ui-kit/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import VideoThumbnailImage from "@images/play-arrow.svg";
import { createCss } from "./styles";
import { VideoThumbnailProps } from "./types";
import { getThumbnailURL } from "./getThumbnailURL";
import { getServiceSpecificData } from "./getServiceSpecificData";

export const VideoThumbnail: React.FC<VideoThumbnailProps> = ({
  file,
  onDelete,
  onClick,
}) => {
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [thumbnail, setThumbnail] = useState<string | null>(null);
  const css = createCss();

  const { text: servicePlayText, styles: serviceThumbStyles } =
    getServiceSpecificData(file?.original);

  const loadThumbnail = useCallback(
    async (url?: string) => {
      if (!url) {
        return;
      }
      try {
        const thumb = await getThumbnailURL(url);
        setThumbnail(thumb);
      } catch {
        setThumbnail(null);
      }
    },
    [setThumbnail],
  );

  useEffect(() => {
    void loadThumbnail(file?.original);
  }, [file?.original, loadThumbnail]);

  const renderDeleteDialog = () => {
    return (
      <Dialog open onClose={() => setDeleteDialog(false)}>
        <Typography bolded paragraph>
          Remove file?
        </Typography>

        <DialogActions>
          <Button onClick={() => setDeleteDialog(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              setDeleteDialog(false);

              if (isFunction(onDelete) && !isNil(file)) {
                onDelete(file);
              }
            }}
            variant="contained"
            color="error"
            data-test="confirm-remove-file"
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div css={css.root}>
      {deleteDialog && renderDeleteDialog()}

      {onDelete && (
        <div
          css={css.deleteButton}
          onClick={(event) => {
            event.stopPropagation();
            setDeleteDialog(true);
          }}
          data-test="delete-button"
        >
          <DeleteIcon />
        </div>
      )}
      <div
        css={[
          css.container,
          thumbnail && {
            backgroundImage: `url(${thumbnail})`,
          },
          serviceThumbStyles,
        ]}
        onClick={() => {
          if (isFunction(onClick)) {
            onClick(file);
          }
        }}
      >
        <img src={VideoThumbnailImage} />

        {servicePlayText && (
          <Typography bolded css={css.playText}>
            {servicePlayText}
          </Typography>
        )}
      </div>
    </div>
  );
};
