import { DateTime, Duration } from "luxon";

export const generateTimeList = (
  start: number,
  iter: number,
): {
  id: number;
  name: string;
  time: number;
}[] => {
  const times = [];
  let time = DateTime.local().startOf("day").plus({ hours: start });

  for (let i = 0; i < iter; i++) {
    const formatted = time.toFormat("h:mma").toLowerCase();
    const obj = time.toObject();
    const seconds = Duration.fromObject({
      hours: obj.hour,
      minutes: obj.minute,
    }).as("seconds");

    times.push({
      id: seconds,
      name: formatted,
      time: seconds,
    });

    time = time.plus({ minutes: 30 });
  }

  return times;
};
