import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  actions: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 30,

    "& button": {
      width: 140,
    },
  },

  flowContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "100%",
    position: "relative",
    width: "100%",
  },

  warning: {
    alignItems: "flex-start",
    display: "flex",
    marginBottom: 26,
    paddingRight: 10,

    "& .icon": {
      marginRight: 10,
    },
  },
});
