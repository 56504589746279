import React, { Fragment } from "react";
import isNil from "lodash/isNil";

import { monthsMap } from "@constants/months";
import Typography from "@ui-kit/Typography";
import { useDeepDivePanelStore } from "@hooks";

interface SchoolYearProps {
  gutterBottom?: boolean;
}

export const SchoolYear: React.FC<SchoolYearProps> = ({
  gutterBottom = true,
}) => {
  const [{ facility }] = useDeepDivePanelStore();

  return (
    <Typography gutterBottom={gutterBottom} data-test="school-year-display">
      <Typography variant="inherit" bolded>
        School year:
      </Typography>{" "}
      {isNil(facility.operatingInfo.yearLengthIsWhole) ? (
        "---"
      ) : facility.operatingInfo.yearLengthIsWhole ? (
        "year-round"
      ) : (
        <Fragment>
          {!!facility.operatingInfo.yearLengthStartMonth && (
            <Fragment>
              {monthsMap[facility.operatingInfo.yearLengthStartMonth].title}
              {!!facility.operatingInfo.yearLengthEndMonth &&
                ` - ${
                  monthsMap[facility.operatingInfo.yearLengthEndMonth].title
                }`}
            </Fragment>
          )}
        </Fragment>
      )}
    </Typography>
  );
};
