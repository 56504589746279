import { createUseStyles } from "react-jss";

export default createUseStyles(
  {
    root: {
      margin: 0,
    },
  },
  { name: "CustomListItemText" },
);
