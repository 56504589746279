import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  Fragment,
} from "react";
import { useForm, Controller, FieldError } from "react-hook-form";
import qs from "query-string";
import { useHistory, useLocation } from "react-router-dom";

import { Input } from "@ui-kit/InputFields";
import Dialog from "@ui-kit/Dialog";
import { createCss } from "./styles";
import Button from "@ui-kit/Button";
import { required } from "@validators";
import { ControlledPhoneInput } from "@ui-kit/PhoneInput";
import { ReactGAEventWait } from "@helpers/ga";
import { FACILITY_CARD_API, Models, GEO_API } from "@services/api";
import { useNotificationStore } from "@store/NotificationStore";
import { FacilitySearchResults } from "./Results";
import { goToFormError } from "@helpers/goToFormError";
import { View, SearchPayload } from "./types";
import Select from "@ui-kit/Select";
import { AxiosError } from "axios";
import { ServerError } from "@models/common";

type FormFields = {
  name: string;
  addressZip: string;
  addressStreet: string;
  addressCountry: string;
  phone: Models.Phone;
  id: string;
  addressState: string;
};

const SEARCH_TYPES = [
  {
    id: "name",
    name: "Facility name",
  },
  {
    id: "address",
    name: "Facility address",
  },
  {
    id: "phone",
    name: "Facility phone number",
  },
  {
    id: "id",
    name: "Facility ID",
  },
];

const countries = [
  {
    id: "US",
    name: "United States",
  },
  {
    id: "AUS",
    name: "Australia",
  },
];

const FindToClaimDialog: React.FC<{
  onClose: () => void;
  defaultView?: View;
}> = ({ onClose, defaultView = "address" }) => {
  const history = useHistory();
  const { search } = useLocation();
  const {
    handleSubmit,
    control,
    errors,
    formState,
    setError,
    trigger,
    setValue,
    watch,
  } = useForm<FormFields>({
    shouldUnregister: false,
  });
  const [view, setViewState] = useState<View>(defaultView);
  const viewRef = useRef<View>(defaultView);
  const [, { setUnknownErrorNotification, setNotification }] =
    useNotificationStore();
  const [findResponse, setFindResponse] = useState<{
    schools: Models.FacilityCard[];
    total: number;
  } | null>(null);
  const [states, setStates] = useState<Models.GeoStatesInfoItem[]>([]);
  const [searchPayload, setSearchPayload] = useState<SearchPayload>({
    addressState: "",
    addressStreet: "",
    addressZip: "",
    addressCountry: "",
    name: "",
  });
  const css = createCss();

  useEffect(() => {
    const query = qs.parse(search);

    if (
      query.findFacilityView === "phone" &&
      !!query.findFacilityPhone &&
      !!query.findFacilityPhoneCountry
    ) {
      setView("phone");
      setValue("phone", {
        number: query.findFacilityPhone,
        country: query.findFacilityPhoneCountry,
      });

      setTimeout(() => void submitPhone(), 100);
    }

    if (query.findFacilityView === "name" && !!query.findFacilityName) {
      setView("name");
      setValue("name", query.findFacilityName);
    }

    history.replace({
      search: qs.stringify({
        ...query,
        findFacilityName: undefined,
        findFacilityPhone: undefined,
        findFacilityPhoneCountry: undefined,
        findFacilityView: undefined,
      }),
    });

    void (async () => {
      setStates((await GEO_API.geoStatesIndex()).data.data);
    })();
  }, []);

  useEffect(() => {
    document
      .querySelector('[data-test="create-facility-form"')
      ?.querySelector(".dialog-content")
      ?.scrollTo?.(0, 0);
  }, [findResponse]);

  const setView = useCallback(
    (value: View) => {
      setViewState(value);
      viewRef.current = value;
    },
    [setViewState],
  );

  const submitId = handleSubmit(async ({ id }) => {
    try {
      const value = id.toUpperCase();
      const regex = /[A-Z0-9]*/g;
      const matches = value.match(regex)?.filter(Boolean);

      const { data } = await FACILITY_CARD_API.facilityCardsIndex({
        ids: matches?.slice(0, 200),
      });

      setFindResponse({
        schools: data.data,
        total: data.meta.total || 0,
      });
    } catch (error) {
      setError("id", {
        message: "Invalid ID",
        type: "manual",
      });
    }
  });

  const submitAddress = handleSubmit(async ({ addressZip, addressStreet }) => {
    try {
      const facilityList = await FACILITY_CARD_API.facilityCardsIndex({
        addressStreet,
        addressZip: addressZip ? [addressZip] : undefined,
      });

      ReactGAEventWait({
        action: "FindFacilityByAddressSuccess",
        category: "FindFacilityDialog",
      });

      setFindResponse({
        schools: facilityList.data.data,
        total: facilityList.data.meta.total || 0,
      });
      setSearchPayload({
        addressState: "",
        addressStreet,
        addressZip,
        addressCountry: "",
        name: "",
      });
    } catch (e) {
      ReactGAEventWait({
        action: "FindFacilityByAddressError",
        category: "FindFacilityDialog",
      });

      const error: AxiosError<{ errors: ServerError[] }> = e;
      const errors = error?.response?.data?.errors;

      if (errors) {
        setNotification({
          message: errors[0].title,
          type: "error",
        });
      } else {
        setUnknownErrorNotification();
        throw error;
      }
    }
  });

  const submitName = handleSubmit(
    async ({ addressZip, addressState, name, addressCountry }) => {
      try {
        const facilityList = await FACILITY_CARD_API.facilityCardsIndex({
          addressState: addressState ? [addressState] : undefined,
          addressCountry: addressCountry ? [addressCountry] : undefined,
          addressZip: addressZip ? [addressZip] : undefined,
          end: 50,
          name,
          start: 0,
        });

        ReactGAEventWait({
          action: "FindFacilityByNameSuccess",
          category: "FindFacilityDialog",
        });

        setFindResponse({
          schools: facilityList.data.data,
          total: facilityList.data.meta.total || 0,
        });
        setSearchPayload({
          addressState,
          addressStreet: "",
          addressZip,
          addressCountry,
          name,
        });
      } catch (e) {
        ReactGAEventWait({
          action: "FindFacilityByNameError",
          category: "FindFacilityDialog",
        });

        const error: AxiosError<{ errors: ServerError[] }> = e;
        const errors = error?.response?.data?.errors;

        if (errors) {
          setNotification({
            message: errors[0].title,
            type: "error",
          });
        } else {
          setUnknownErrorNotification();
          throw error;
        }
      }
    },
  );

  const submitPhone = handleSubmit(async ({ phone }) => {
    try {
      const facilityList = await FACILITY_CARD_API.facilityCardsIndex({
        phone: phone.number ? [phone.number] : undefined,
      });

      ReactGAEventWait({
        action: "FindFacilityByPhoneSuccess",
        category: "FindFacilityDialog",
      });

      setFindResponse({
        schools: facilityList.data.data,
        total: facilityList.data.meta.total || 0,
      });
      setSearchPayload({
        addressState: "",
        addressStreet: "",
        addressZip: "",
        addressCountry: "",
        name: "",
        phone,
      });
    } catch (e) {
      ReactGAEventWait({
        action: "FindFacilityByPhoneError",
        category: "FindFacilityDialog",
      });

      const error: AxiosError<{ errors: ServerError[] }> = e;
      const errors = error?.response?.data?.errors;

      if (errors) {
        setNotification({
          message: errors[0].title,
          type: "error",
        });
      } else {
        setUnknownErrorNotification();
        throw error;
      }
    }
  });

  return (
    <Dialog
      open
      onClose={onClose}
      title="Facility search"
      data-test="create-facility-form"
    >
      {findResponse ? (
        <div css={css.container}>
          <FacilitySearchResults
            onCancel={() => setFindResponse(null)}
            facilities={findResponse.schools}
            total={findResponse.total}
            searchPayload={searchPayload}
            view={view}
          />
        </div>
      ) : (
        <form
          css={css.container}
          onSubmit={(event) => {
            event.preventDefault();

            void trigger().then((valid) => {
              if (valid) {
                if (view === "id") {
                  void submitId();
                } else if (view === "address") {
                  void submitAddress();
                } else if (view === "name") {
                  void submitName();
                } else {
                  void submitPhone();
                }
              } else {
                goToFormError();
              }
            });
          }}
        >
          <div>
            <Select
              items={SEARCH_TYPES}
              labelProp="name"
              required
              onChange={(event) => {
                setView(event.value.id as View);
              }}
              renderValue={(value) => {
                return `Search by: ${
                  SEARCH_TYPES.find((t) => t.id === value)?.name
                }`;
              }}
              variant="outlined"
              data-test="search-type-select"
              name="search-type"
              value={view}
            />

            {view === "id" && (
              <Controller
                as={
                  <Input
                    data-test="id-field"
                    label="Facility ID"
                    error={errors.id?.message}
                  />
                }
                rules={{
                  validate: (value: string) => {
                    if (viewRef.current === "id" && !value) {
                      return required().required;
                    }

                    return true;
                  },
                }}
                name="id"
                control={control}
                defaultValue=""
              />
            )}

            {view === "phone" && (
              <ControlledPhoneInput
                error={(errors.phone as FieldError)?.message}
                label="Facility phone number"
                disabled={formState.isSubmitting}
                name="phone"
                control={control}
                defaultValue={searchPayload.phone}
                required={viewRef.current === "phone"}
              />
            )}

            {view === "address" && (
              <Fragment>
                <Controller
                  as={
                    <Input
                      data-test="street-field"
                      label="Street address"
                      error={errors.addressStreet?.message}
                    />
                  }
                  rules={{
                    validate: (value: string) => {
                      if (viewRef.current === "address") {
                        if (!value) {
                          return required().required;
                        }
                      }

                      return true;
                    },
                  }}
                  name="addressStreet"
                  control={control}
                  defaultValue={searchPayload.addressStreet}
                />

                <Controller
                  as={
                    <Input
                      data-test="zip-field"
                      label="Zip code"
                      variant="number"
                      maxLength={5}
                      error={errors.addressZip?.message}
                    />
                  }
                  rules={{
                    validate: (value: string) => {
                      if (viewRef.current === "address") {
                        if (!value) {
                          return required().required;
                        }
                      }

                      return true;
                    },
                  }}
                  name="addressZip"
                  control={control}
                  defaultValue={searchPayload.addressZip}
                />
              </Fragment>
            )}

            {view === "name" && (
              <Fragment>
                <Controller
                  render={(controllerProps) => (
                    <Select
                      {...controllerProps}
                      items={countries}
                      error={errors.addressCountry?.message}
                      labelProp="name"
                      label="Country"
                    />
                  )}
                  rules={{
                    validate: (value: string) => {
                      if (viewRef.current === "name") {
                        if (!value) {
                          return required().required;
                        }
                      }

                      return true;
                    },
                  }}
                  defaultValue="US"
                  name="addressCountry"
                  control={control}
                />

                <Controller
                  as={
                    <Input
                      data-test="name-field"
                      label="Facility name"
                      error={errors.name?.message}
                    />
                  }
                  rules={{
                    validate: (value: string) => {
                      if (viewRef.current === "name") {
                        if (!value) {
                          return required().required;
                        }
                      }

                      return true;
                    },
                  }}
                  name="name"
                  control={control}
                  defaultValue={searchPayload.name}
                />

                <Controller
                  render={(controllerProps) => (
                    <Select
                      {...controllerProps}
                      items={states.filter(
                        (s) => s.country === watch("addressCountry", "US"),
                      )}
                      error={errors.addressState?.message}
                      labelProp="name"
                      label="State"
                    />
                  )}
                  rules={{
                    validate: (value: string) => {
                      if (viewRef.current === "name") {
                        if (!value) {
                          return required().required;
                        }
                      }

                      return true;
                    },
                  }}
                  defaultValue=""
                  name="addressState"
                  control={control}
                />

                <Controller
                  as={
                    <Input
                      data-test="zip-field"
                      label="Zip code"
                      variant="number"
                      maxLength={5}
                      error={errors.addressZip?.message}
                    />
                  }
                  rules={{
                    validate: (value: string) => {
                      if (viewRef.current === "address") {
                        if (!value) {
                          return required().required;
                        }
                      }

                      return true;
                    },
                  }}
                  name="addressZip"
                  control={control}
                  defaultValue={searchPayload.addressZip}
                />
              </Fragment>
            )}

            <Button
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              data-test="submit-btn"
              type="submit"
              css={css.submitButton}
              loading={formState.isSubmitting}
              disabled={!view}
            >
              Search
            </Button>
          </div>
        </form>
      )}
    </Dialog>
  );
};

export default FindToClaimDialog;
