import { HelmetProps } from "react-helmet";
import { globalConfig } from "@constants";
import { Models } from "@services/api";

import appInfographicImage from "@images/mobile_services.png";

const PAGE_DESCRIPTION =
  "Research Infant to K schools. Find best fit for your family.";

export default (facility: Models.Facility): HelmetProps => {
  return {
    link: [
      {
        href: `${globalConfig.UI_URL}/map/${facility.id}`,
        rel: "canonical",
      },
    ],
    meta: [
      {
        content: PAGE_DESCRIPTION,
        name: "description",
      },
      {
        content: `Parent Day Out | ${facility.name}`,
        property: "og:site_name",
      },
      {
        content: `Parent Day Out | ${facility.name}`,
        property: "og:title",
      },
      {
        content: PAGE_DESCRIPTION,
        property: "og:description",
      },
      {
        content: Boolean(facility.media.find((m) => m.category === "profile"))
          ? facility.media.find((m) => m.category === "profile")?.file.w600px
          : appInfographicImage,
        property: "og:image",
      },
      {
        content: `/map/${facility.id}`,
        property: "og:url",
      },
      {
        content: "summary_large_image",
        name: "twitter:card",
      },
      {
        content: `Parent Day Out | ${facility.name}`,
        name: "twitter:title",
      },
      {
        content: PAGE_DESCRIPTION,
        name: "twitter:description",
      },
      {
        content: Boolean(facility.media.find((m) => m.category === "profile"))
          ? facility.media.find((m) => m.category === "profile")?.file.w600px
          : appInfographicImage,
        name: "twitter:image",
      },
    ],
    title: `${facility.name} | Parent day out`,
  };
};
