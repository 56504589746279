import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  root: {
    position: "relative",
  },

  container: (theme) => ({
    alignItems: "center",
    backgroundColor: theme.palette.yellow.main,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    color: "white",
    cursor: "pointer",
    display: "flex",
    height: 200,
    justifyContent: "center",
    position: "relative",
    width: "100%",

    "& img": {
      height: 100,
      width: 100,
    },
  }),

  deleteButton: (theme) => ({
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: 5,
    cursor: "pointer",
    display: "flex",
    fontSize: 12,
    height: 25,
    justifyContent: "center",
    left: 5,
    position: "absolute",
    top: 5,
    width: 25,
    zIndex: 2,

    "& svg": {
      color: theme.palette.error.main,
      fontSize: 20,
    },
  }),

  playText: {
    position: "absolute",
    transform: "translateY(70px)",
  },
});
