import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  datesContainer: {
    marginBottom: 20,
    marginTop: 10,
  },

  shiftRow: (theme) => ({
    alignItems: "center",
    display: "flex",

    "& > span": {
      display: "inline-flex",
      flexWrap: "wrap",
      justifyContent: "flex-end",

      "& div": {
        width: "100%",
      },

      "&  .discount": {
        color: theme.palette.error.main,
        textAlign: "right",
        textDecoration: "line-through",
      },

      "& .age-error": {
        color: theme.palette.error.main,
        fontSize: 12,
        fontWeight: 700,
      },

      "&:first-of-type": {
        width: "60%",
      },

      "&:not(:first-of-type)": {
        width: "40%",
      },
    },
  }),

  actions: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 30,

    "& button": {
      width: 140,
    },
  },

  materialCheckboxRoot: {
    padding: 3,
  },

  stepTitle: {
    textTransform: "uppercase",

    "&.with-gap": {
      marginTop: 16,
    },
  },

  flowContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "100%",
    position: "relative",
    width: "100%",
  },

  dayTitle: {
    backgroundColor: "rgba(0, 0, 0, 0.06)",
    marginTop: 24,
    padding: "3px 5px",
    display: "flex",
    justifyContent: "space-between",
  },

  description: {
    fontSize: 16,
  },

  warning: {
    alignItems: "center",
    display: "flex",

    "& .icon": {
      height: 24,
      marginRight: 8,
    },
  },
});
