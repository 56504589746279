import { createUseStyles } from "react-jss";

export default createUseStyles(
  {
    labelDefault: {
      fontSize: "1rem",
    },
  },
  { name: "CustomFormControlLabel" },
);
