import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  item: {
    alignItems: "center",
    display: "flex",
    marginBottom: 12,
    position: "relative",
  },

  icon: (theme) => ({
    color: theme.palette.text.primary,
    height: 22,
    marginRight: 14,
    width: 22,
  }),

  contactsActions: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",

    "& > button": {
      width: "calc(50% - 8px)",
    },
  },
});
