import isNil from "lodash/isNil";

import { Models } from "@services/api";
import { Money } from "./Money";

export class FacilityNutritionInfo {
  private _sampleMenuFile: Models.UploadedFile = { original: "" };
  private _mealsServedDescription: string | null = null;
  private _mealsCatered: boolean | null = null;
  private _costIsIncludedInTuition: boolean | null = null;
  private _costTo: Money | null = null;
  private _costFrom: Money | null = null;
  private _outsideFoodAllowed: boolean | null = null;
  private _peanutFreeFacility: boolean | null = null;
  private _glutenFreeFacility: boolean | null = null;
  private _mealsServed: boolean | null = null;
  private _mealsServedBreakfast: boolean | null = null;
  private _mealsServedMorningSnack: boolean | null = null;
  private _mealsServedLunch: boolean | null = null;
  private _mealsServedAfternoonSnack: boolean | null = null;

  get sampleMenuFile(): Models.UploadedFile {
    return this._sampleMenuFile;
  }
  set sampleMenuFile(v: Models.UploadedFile) {
    this._sampleMenuFile = v;
  }

  get mealsServedDescription(): string | null {
    return this._mealsServedDescription;
  }
  set mealsServedDescription(v: string | null | undefined) {
    this._mealsServedDescription = !isNil(v) ? v : null;
  }

  get mealsCatered(): boolean | null {
    return this._mealsCatered;
  }
  set mealsCatered(v: boolean | null | undefined) {
    this._mealsCatered = !isNil(v) ? v : null;
  }

  get costIsIncludedInTuition(): boolean | null {
    return this._costIsIncludedInTuition;
  }
  set costIsIncludedInTuition(v: boolean | null | undefined) {
    this._costIsIncludedInTuition = !isNil(v) ? v : null;
  }

  // @ts-ignore
  get costFrom(): Money | null {
    return this._costFrom;
  }
  set costFrom(v: Money | number | string | null | undefined) {
    if (v) {
      if (v instanceof Money) {
        this._costFrom = v;
      } else if (this._costFrom) {
        this._costFrom.value = v;
      } else {
        this._costFrom = new Money(v);
      }
    } else {
      this._costFrom = null;
    }
  }

  // @ts-ignore
  get costTo(): Money | null {
    return this._costTo;
  }

  set costTo(v: Money | number | string | null | undefined) {
    if (v) {
      if (v instanceof Money) {
        this._costTo = v;
      } else if (this._costTo) {
        this._costTo.value = v;
      } else {
        this._costTo = new Money(v);
      }
    } else {
      this._costTo = null;
    }
  }

  get outsideFoodAllowed(): boolean | null {
    return this._outsideFoodAllowed;
  }
  set outsideFoodAllowed(v: boolean | null | undefined) {
    this._outsideFoodAllowed = !isNil(v) ? v : null;
  }

  get peanutFreeFacility(): boolean | null {
    return this._peanutFreeFacility;
  }
  set peanutFreeFacility(v: boolean | null | undefined) {
    this._peanutFreeFacility = !isNil(v) ? v : null;
  }

  get glutenFreeFacility(): boolean | null {
    return this._glutenFreeFacility;
  }
  set glutenFreeFacility(v: boolean | null | undefined) {
    this._glutenFreeFacility = !isNil(v) ? v : null;
  }

  get mealsServed(): boolean | null {
    return this._mealsServed;
  }
  set mealsServed(v: boolean | null | undefined) {
    this._mealsServed = !isNil(v) ? v : null;
  }

  get mealsServedBreakfast(): boolean | null {
    return this._mealsServedBreakfast;
  }
  set mealsServedBreakfast(v: boolean | null | undefined) {
    this._mealsServedBreakfast = !isNil(v) ? v : null;
  }

  get mealsServedMorningSnack(): boolean | null {
    return this._mealsServedMorningSnack;
  }
  set mealsServedMorningSnack(v: boolean | null | undefined) {
    this._mealsServedMorningSnack = !isNil(v) ? v : null;
  }

  get mealsServedLunch(): boolean | null {
    return this._mealsServedLunch;
  }
  set mealsServedLunch(v: boolean | null | undefined) {
    this._mealsServedLunch = !isNil(v) ? v : null;
  }

  get mealsServedAfternoonSnack(): boolean | null {
    return this._mealsServedAfternoonSnack;
  }
  set mealsServedAfternoonSnack(v: boolean | null | undefined) {
    this._mealsServedAfternoonSnack = !isNil(v) ? v : null;
  }

  public toDto(): Models.FacilityNutritionInfo {
    return {
      sampleMenuFile: this.sampleMenuFile,
      mealsServedDescription: this.mealsServedDescription,
      mealsCatered: this.mealsCatered,
      costIsIncludedInTuition: this.costIsIncludedInTuition,
      costFrom: this.costFrom?.toDto(),
      costTo: this.costTo?.toDto(),
      outsideFoodAllowed: this.outsideFoodAllowed,
      peanutFreeFacility: this.peanutFreeFacility,
      glutenFreeFacility: this.glutenFreeFacility,
      mealsServed: this.mealsServed,
      mealsServedBreakfast: this.mealsServedBreakfast,
      mealsServedMorningSnack: this.mealsServedMorningSnack,
      mealsServedLunch: this.mealsServedLunch,
      mealsServedAfternoonSnack: this.mealsServedAfternoonSnack,
    };
  }

  static fromDto(dto: Models.FacilityNutritionInfo): FacilityNutritionInfo {
    const nutritionInfo = new FacilityNutritionInfo();

    nutritionInfo.sampleMenuFile = dto.sampleMenuFile;
    nutritionInfo.mealsServedDescription = dto.mealsServedDescription;
    nutritionInfo.mealsCatered = dto.mealsCatered;
    nutritionInfo.costIsIncludedInTuition = dto.costIsIncludedInTuition;
    nutritionInfo.costFrom = Money.fromDto(dto.costFrom);
    nutritionInfo.costTo = Money.fromDto(dto.costTo);
    nutritionInfo.outsideFoodAllowed = dto.outsideFoodAllowed;
    nutritionInfo.peanutFreeFacility = dto.peanutFreeFacility;
    nutritionInfo.glutenFreeFacility = dto.glutenFreeFacility;
    nutritionInfo.mealsServed = dto.mealsServed;
    nutritionInfo.mealsServedBreakfast = dto.mealsServedBreakfast;
    nutritionInfo.mealsServedMorningSnack = dto.mealsServedMorningSnack;
    nutritionInfo.mealsServedLunch = dto.mealsServedLunch;
    nutritionInfo.mealsServedAfternoonSnack = dto.mealsServedAfternoonSnack;

    return nutritionInfo;
  }
}
