import React from "react";
import clsx from "clsx";
import MaterialListItemText, {
  ListItemTextProps,
} from "@material-ui/core/ListItemText";

import useStyles from "./styles.jss";

const ListItemText: React.FunctionComponent<ListItemTextProps> = (props) => {
  const classes = useStyles();

  return (
    <MaterialListItemText
      {...props}
      classes={{
        ...props.classes,
        root: clsx(classes.root, props.classes?.root),
      }}
    />
  );
};

ListItemText.defaultProps = {
  classes: {},
};

export default ListItemText;
