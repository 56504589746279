import React, { useMemo, Fragment, useCallback } from "react";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { useQuery } from "react-query";
import { Link, useHistory, useLocation } from "react-router-dom";
import numeral from "numeral";
import qs from "query-string";
import orderBy from "lodash/orderBy";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import StarIcon from "@material-ui/icons/Star";
import LanguageIcon from "@material-ui/icons/Language";
import { useSwipeable } from "react-swipeable";

import { NewDesignIcons, SensorDoor } from "@components/Icons";

import { getFullGradeString } from "@helpers/grades-helpers";
import { useCss } from "./styles";
import OperatingHours from "@components/DeepDivePanel/OperatingHours";
import { schoolTypesMap } from "@constants/school-types";
import { languagesMap } from "@constants/languages";
import Typography from "@ui-kit/Typography";
import { useDeepDivePanelStore } from "@hooks";
import { ReactGAEventWait } from "@helpers/ga";
import { FacilityContacts } from "../FacilityContacts";
import { PDOAction } from "../PDOAction";
import { useAppStore } from "@store/AppStore";
import { Section } from "@components/DeepDivePanel/Section";
import { OperationalStatus } from "@components/DeepDivePanel/OperationalStatus";
import PhotoCameraOutlined from "@material-ui/icons/PhotoCameraOutlined";
import { useUserStore } from "@store/UserStore";

import BudgeIcon from "@images/vbadge_flower_blue.svg";
import { Models, API } from "@services/api";
import {
  isFacilityOwner,
  isOshcSchool,
  isPreSchool,
  isPrivateSchool,
  isPublicSchool,
} from "@selectors";
import { getSponsorsLogo } from "@helpers/getSponsorsLogo";
import { Spacer } from "@ui-kit/Spacer";
import { Tier } from "@pages/DeepDivePanel/Tier";
import { BRANDS } from "@constants/brands";
import { must } from "@utils/must";
import { useViewport } from "@hooks/useViewport";

interface IProps {
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

const facilityTypesIcons = {
  [Models.FacilityTypeID.PreSchool]: NewDesignIcons.MapPinGreen,
  [Models.FacilityTypeID.HeadstartSchool]: NewDesignIcons.MapPinGreen,
  [Models.FacilityTypeID.DaycareCenter]: NewDesignIcons.MapPinPink,
  [Models.FacilityTypeID.PrivateSchool]: NewDesignIcons.MapPinBlue,
  [Models.FacilityTypeID.PublicSchool]: NewDesignIcons.MapPinPink,
  [Models.FacilityTypeID.CharterSchool]: NewDesignIcons.MapPinPink,
  [Models.FacilityTypeID.Oshc]: NewDesignIcons.MapPinBlack,
};

const Highlights: React.FC<IProps> = ({ onClose, open, onOpen }) => {
  const history = useHistory();
  const location = useLocation();
  const [, { setLoader }] = useAppStore();
  const { isMobile } = useViewport();
  const [{ user }] = useUserStore();
  const [{ facility }] = useDeepDivePanelStore();
  const { search } = location;
  const { data: corporations } = useQuery(
    "corporations",
    API.corporations.getList,
  );
  const swipeHandlers = useSwipeable({
    onSwipedDown: open ? onClose : undefined,
    onSwipedUp:
      !open && onOpen
        ? () => {
            onOpen();
            setLoader(true);
            setTimeout(() => {
              setLoader(false);
            }, 1500);
          }
        : undefined,
  });

  const css = useMemo(
    () =>
      useCss({
        tier: facility.subscriptionTier,
      }),
    [facility.subscriptionTier],
  );

  const sponsoredLogo = useMemo(() => {
    if (facility.mediaProfileLogoApproved) {
      const profilePhoto = facility.media.find(
        (m) => m.category === Models.MediaCategory.Profile,
      );

      if (profilePhoto) {
        return profilePhoto.file.w200px;
      }
    }

    if (facility.logo === Models.Logo.Corporation) {
      const corp = corporations?.find((c) => c.id === facility.corporationId);

      if (corp?.logo?.square?.original) {
        return corp.logo.square.original;
      }
    }

    return getSponsorsLogo("pin", facility.logo);
  }, [facility.logo]);

  const getFacilityTypeIcon = useCallback(() => {
    const Icon = facilityTypesIcons[facility.facilityType];

    return <Icon css={css.icon} />;
  }, [facility.facilityType]);

  function renderTitleBlock() {
    return (
      <div>
        <div
          css={css.facilityName}
          onClick={isMobile ? (open ? onClose : onOpen) : undefined}
        >
          {!!facility.accountId && (
            <img
              css={css.verifiedSign}
              src={BudgeIcon}
              alt="claimed-badge"
              title="This school profile has been claimed"
            />
          )}
          <span data-test="facility-name-display" title={facility.name}>
            {facility.name}
          </span>
        </div>

        <Spacer size="small" />

        {((isMobile && open) || !isMobile) && <OperationalStatus />}

        {isMobile && (
          <ListItemSecondaryAction css={css.mobileToggler}>
            <IconButton onClick={isMobile && open ? onClose : onOpen}>
              {open ? (
                <ExpandMoreIcon css={css.expandHighlightsButton} />
              ) : (
                <ExpandLessIcon css={css.expandHighlightsButton} />
              )}
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </div>
    );
  }

  const renderRatingPreview = () => {
    if (!facility.reviewsSummary.count && isFacilityOwner(facility, user)) {
      return <Typography>&nbsp;</Typography>;
    }

    return (
      <div css={css.listItem}>
        <StarIcon css={css.icon} />
        <Typography>
          {Boolean(facility.reviewsSummary.count) ? (
            <Typography
              variant="inherit"
              onClick={() => {
                document
                  .getElementById("ddp-rating-section")
                  ?.scrollIntoView?.();
              }}
              css={{ cursor: "pointer" }}
            >
              <Typography
                variant="inherit"
                color={
                  must(facility.reviewsSummary.gradeOverall) >= 6.95
                    ? "textPrimary"
                    : "error"
                }
                bolded
              >
                {numeral(facility.reviewsSummary.gradeOverall).format("0.[0]")}
                <Typography variant="inherit" css={{ fontSize: 11 }}>
                  /10
                </Typography>
              </Typography>
              <Typography variant="inherit" css={{ fontSize: 11 }}>
                {` (${facility.reviewsSummary.count} ${
                  facility.reviewsSummary.count === 1 ? "rating" : "ratings"
                })`}
              </Typography>
            </Typography>
          ) : (
            <Fragment>
              {isMobile && !open ? (
                <span className="g-link">submit rating</span>
              ) : (
                <Link
                  to={{
                    pathname: `/map/${facility.id}/rating`,
                    search,
                  }}
                  onClick={() => {
                    ReactGAEventWait({
                      action: "AddRatingClick",
                      category: "DDP",
                    });
                  }}
                >
                  submit rating
                </Link>
              )}
            </Fragment>
          )}
        </Typography>
      </div>
    );
  };

  const renderHoursPreview = () => {
    return (
      <div css={css.listItem}>
        <AccessTimeIcon css={css.icon} />
        <Typography data-test="operating-hours-display">
          <OperatingHours
            facility={facility}
            emptyView={
              <Fragment>
                {isMobile && !open ? (
                  <span className="g-link">add hours</span>
                ) : (
                  <Link
                    to={{
                      pathname: `/map/${facility.id}/edit/contact-info`,
                      search: qs.stringify({
                        ...qs.parse(search),
                        editOperatingHours: true,
                      }),
                    }}
                    onClick={() => {
                      ReactGAEventWait({
                        action: "AddHoursClick",
                        category: "DDP",
                      });
                    }}
                  >
                    add hours
                  </Link>
                )}
              </Fragment>
            }
          />
        </Typography>
      </div>
    );
  };

  const renderGrade = () => {
    return (
      <div css={css.listItem}>
        <SensorDoor css={css.icon} />
        <Typography data-test="ongoing-grade-display">
          {getFullGradeString(facility, true, true) || "---"}
        </Typography>
      </div>
    );
  };

  const getPhotosLabel = () => {
    const brand = BRANDS.find((b) => b.id === facility.logo);

    if (!brand) {
      return (
        <Fragment>
          School
          <br />
          photos
        </Fragment>
      );
    }

    return brand.name;
  };

  function renderMainInfoBlock() {
    const goToTiers = () => {
      history.push({
        pathname: `/map/${facility.id}/about-tier`,
        search,
      });
    };

    return (
      <Fragment>
        {(!isMobile || open) && <PDOAction />}

        <div css={css.list} onClick={isMobile && !open ? onOpen : undefined}>
          <div css={css.rightSide}>
            <div
              css={css.galleryBlock}
              data-test="gallery-block"
              onClick={() => {
                if (isMobile && !open) {
                  return;
                }

                history.push({
                  pathname: `/map/${facility.id}/photos`,
                  search,
                });
              }}
            >
              {!!sponsoredLogo ? (
                <div
                  css={[
                    css.galleryImg,
                    {
                      backgroundImage: `url(${sponsoredLogo})`,
                    },
                  ]}
                />
              ) : (
                <div
                  css={css.galleryPlaceholder}
                  className={
                    isPreSchool(facility)
                      ? "green"
                      : isPrivateSchool(facility)
                      ? "blue"
                      : isOshcSchool(facility)
                      ? "black"
                      : "pink"
                  }
                >
                  {getPhotosLabel()}
                </div>
              )}
            </div>
            <div css={css.galleryCounter}>
              {facility.media.length +
                (facility.mediaFacebookPhotosUrl ? 1 : 0) +
                (facility.mediaGooglePhotosUrl ? 1 : 0) +
                (facility.mediaWebsitePhotosUrl ? 1 : 0) +
                1}
            </div>
            <div css={css.cameraIcon}>
              <PhotoCameraOutlined />
            </div>
          </div>
          <div css={css.listItem}>
            {getFacilityTypeIcon()}
            <Typography data-test="facility-type-display" bolded>
              {schoolTypesMap[facility.facilityType].title}
            </Typography>
          </div>

          <div css={css.listItem}>
            <LanguageIcon css={css.icon} />
            <Typography data-test="language-display">
              {[
                Models.Languages.English,
                ...orderBy(
                  facility.languages
                    .filter((l) => l !== Models.Languages.English)
                    .map((l) => languagesMap[l]),
                  "filterOrder",
                ).map((l) => l.id),
              ]
                .filter(
                  (l) =>
                    facility.languages.length < 3 ||
                    l !== Models.Languages.English,
                )
                .map((l) => languagesMap[l].title)
                .join(", ")}
            </Typography>
          </div>

          {isPublicSchool(facility) || isPrivateSchool(facility) ? (
            <>
              {renderGrade()}
              {renderRatingPreview()}
            </>
          ) : (
            <>
              {renderHoursPreview()}
              {renderRatingPreview()}
            </>
          )}

          <div css={css.tiersContainer}>
            <Tier
              tier={Models.SubscriptionTier.Community}
              active={
                facility.subscriptionTier === Models.SubscriptionTier.Community
              }
              onClick={goToTiers}
              styles={{
                root: css.tier,
              }}
            />
            <Tier
              tier={Models.SubscriptionTier.Pro}
              active={facility.subscriptionTier === Models.SubscriptionTier.Pro}
              onClick={goToTiers}
              styles={{
                root: css.tier,
              }}
            />
            <Tier
              tier={Models.SubscriptionTier.Ivy}
              active={facility.subscriptionTier === Models.SubscriptionTier.Ivy}
              onClick={goToTiers}
              styles={{
                root: css.tier,
              }}
            />
          </div>
        </div>
      </Fragment>
    );
  }

  return (
    <Section>
      {isMobile ? (
        <>
          <div {...swipeHandlers}>
            {renderTitleBlock()}
            {renderMainInfoBlock()}
          </div>

          <FacilityContacts />
        </>
      ) : (
        <>
          {renderTitleBlock()}
          {renderMainInfoBlock()}
          <FacilityContacts />
        </>
      )}
    </Section>
  );
};

export default Highlights;
