import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  container: (theme) => ({
    display: "flex",
    fontSize: 14,
    minHeight: "100%",

    [theme.mediaRequests.notMobile]: {
      width: 500,
    },
  }),
});
