import React, { useMemo, useState, Fragment } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import clsx from "clsx";

import { NewDesignIcons } from "@components/Icons";
import Favorite from "@components/Favorite";
import { useDeepDivePanelStore } from "@hooks";
import { useCss } from "./styles";
import { TryToClaimDialog } from "@pages/DeepDivePanel/TryToClaimDialog";
import { Spacer } from "@ui-kit/Spacer";
import { getGoogleMapsSatelliteViewLink } from "@helpers/getGoogleMapsSateliteViewLink";
import { isAdmin } from "@selectors";
import { useUserStore } from "@store/UserStore";

export const NavigationActions: React.FC = () => {
  const [showEdit, setShowEdit] = useState(false);
  const [{ facility }] = useDeepDivePanelStore();
  const [{ user }] = useUserStore();
  const { search } = useLocation();
  const history = useHistory();
  const css = useMemo(
    () =>
      useCss({
        tier: facility.subscriptionTier,
      }),
    [facility.subscriptionTier],
  );

  return (
    <Fragment>
      <div css={css.actionsContainer}>
        <Link
          to={{
            pathname: `/map/${facility.id}/tuition`,
            search,
          }}
          css={css.action}
          data-test="tuition-info-link"
        >
          <NewDesignIcons.Tuition />
          Tuition
        </Link>
        <Link
          to={{
            pathname: `/map/${facility.id}/meals-and-nutrition`,
            search,
          }}
          css={css.action}
          data-test="meals-info-link"
        >
          <NewDesignIcons.Meal />
          Meals
        </Link>
        <Link
          to={{
            pathname: `/map/${facility.id}/features-and-amenities`,
            search,
          }}
          css={css.action}
          data-test="amenities-info-link"
        >
          <NewDesignIcons.Key />
          Amenities
        </Link>
        <Link
          to={{
            pathname: `/map/${facility.id}/programs-and-services`,
            search,
          }}
          css={css.action}
          data-test="programs-info-link"
        >
          <NewDesignIcons.Ring />
          Programs
        </Link>
        <Link
          to={{
            pathname: `/map/${facility.id}/checklists-and-documents`,
            search,
          }}
          css={css.action}
          data-test="documents-info-link"
        >
          <NewDesignIcons.Checklist />
          Checklists
        </Link>
      </div>

      <Spacer size="medium" />

      <div css={css.actionsContainer}>
        {showEdit && (
          <TryToClaimDialog
            onClose={() => setShowEdit(false)}
            title="Suggest an edit"
            description="Are you the school administrator?"
            primaryAction={{
              label: "Yes, this is my school",
              onClick: () => {
                setShowEdit(false);
                history.push({
                  pathname: `/map/${facility.id}/claim`,
                  search,
                });
              },
            }}
            secondaryAction={{
              label: "No, I am not",
              onClick: () => {
                setShowEdit(false);
                history.push({
                  pathname: `/map/${facility.id}/edit`,
                  search,
                });
              },
            }}
          />
        )}

        <Link
          to={{
            pathname: `/map/${facility.id}/share`,
            search,
          }}
          css={css.action}
          data-test="share-school-button"
        >
          <NewDesignIcons.Share />
          Share
        </Link>
        <a
          css={css.action}
          href={getGoogleMapsSatelliteViewLink(
            facility.address.location.lon,
            facility.address.location.lat,
          )}
          target="_blank"
          rel="noopener noreferrer"
        >
          <NewDesignIcons.StreetView />
          St. view
        </a>
        <div css={css.action}>
          <Favorite />
          Fave
        </div>
        <a
          css={css.action}
          href={`https://www.google.com/maps/dir/?api=1&destination=${facility.address.location.lat},${facility.address.location.lon}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <NewDesignIcons.Direction />
          Directions
        </a>
        <span
          onClick={() => {
            if (!facility.isEditable && !isAdmin(user)) {
              return;
            }

            if (!facility.accountId) {
              setShowEdit(true);
            } else {
              history.push({
                pathname: `/map/${facility.id}/edit`,
                search,
              });
            }
          }}
          css={css.action}
          className={clsx({ disabled: !facility.isEditable && !isAdmin(user) })}
          data-test="suggest-edit-btn"
        >
          <NewDesignIcons.Edit />
          Edit
        </span>
      </div>
    </Fragment>
  );
};
