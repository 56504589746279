import React, { Fragment } from "react";
import { Controller } from "react-hook-form";

import {
  EditMediaUrlsView,
  processServerErrors as processMediaErrors,
} from "@pages/DeepDivePanel/EditMediaUrls";
import { Models } from "@services/api";
import { ClaimStep } from "@pages/FacilityClaim/ClaimStep";
import Typography from "@ui-kit/Typography";
import { Spacer } from "@ui-kit/Spacer";
import Select from "@ui-kit/Select";
import { required } from "@validators";
import { ROLES } from "@constants/user-roles";

interface IProps {
  facilityView: Models.Facility;
  setFacilityView: React.Dispatch<React.SetStateAction<Models.Facility>>;
  onNext: () => void;
  setRole: (role: Models.UserRole) => void;
  defaultRole: Models.UserRole;
}

export const InitialStep: React.FC<IProps> = ({
  facilityView,
  setFacilityView,
  onNext,
  defaultRole,
  setRole,
}) => {
  return (
    <ClaimStep
      onNext={onNext}
      barText="Step 1 of 4"
      facilityView={facilityView}
      setFacilityView={setFacilityView}
      processErrors={[processMediaErrors]}
    >
      {(view, setView, methods) => (
        <Fragment>
          <Typography bolded required variant="h5">
            Your role
          </Typography>

          <Controller
            render={({ onChange, ...controllerProps }) => (
              <Select
                {...controllerProps}
                onChange={(event) => {
                  setRole(event.value.id);
                  onChange(event);
                }}
                items={ROLES}
                labelProp="name"
              />
            )}
            rules={required()}
            defaultValue={defaultRole}
            name="role"
            control={methods.control}
          />

          <Spacer size="medium" />

          <Typography bolded variant="h5">
            School links
          </Typography>
          <EditMediaUrlsView
            isClaim
            isShortClaim
            facilityView={view}
            setFacilityView={setView}
          />

          <Spacer />

          <Typography>
            <span css={{ color: "red" }}>*</span> Claiming your school profile
            is free of charge. By proceeding, you certify that you are an
            authorized representative, employee, or administrator of this
            school.
          </Typography>
        </Fragment>
      )}
    </ClaimStep>
  );
};
