import React from "react";

import Typography from "@ui-kit/Typography";
import { isAustralia } from "@selectors/facility";
import { useDeepDivePanelStore } from "@hooks";

export const StaffBackgroundChecks: React.FC = () => {
  const [{ facility }] = useDeepDivePanelStore();

  if (isAustralia(facility)) {
    return null;
  }

  return (
    <>
      <Typography gutterBottom>
        <Typography variant="inherit" bolded>
          Staff background checks:
        </Typography>{" "}
        performed
      </Typography>
      <Typography italic align="justify">
        Background checks performed prior to employment typically include
        criminal history, fingerprints, child abuse and neglect registry, sex
        offender registry
      </Typography>
    </>
  );
};
