import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";

import Dialog from "@ui-kit/Dialog";
import ChildSelectStep from "./ChildSelectStep";
import ShiftSelectStep from "./ShiftSelectStep";
import PaymentSelectStep from "./PaymentSelectStep";
import MedicalFormsStep from "./MedicalFormsStep";
import AdditionalInfoStep from "./AdditionalInfoStep";
import ErrorStep from "./Error";
import { createCss } from "./styles";
import { ReviewStep } from "./ReviewStep";
import EligibilityStep from "./EligibilityStep";
import ConfirmationStep from "./ConfirmationStep";
import { useDeepDivePanelStore } from "@hooks";
import {
  ReservationSteps,
  ReservationStoreProvider,
  useReservationStore,
} from "@hooks/useReservationStore";

const STEPS_TITLES = {
  "additional-info": "Confirm contact information",
  "child-select": "Add / select child",
  confirmation: "Booking request",
  eligibility: "Service eligibility",
  "medical-forms": "Medical forms",
  review: "Booking details",
  "select-payment": "Payment method",
  "shift-select": "Service date / time",
};

const ReservationFlow: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const searchQuery = qs.parse(location.search);
  const [{ facility }] = useDeepDivePanelStore();
  const [{ fetching, step }, { setStep }] = useReservationStore();

  const css = createCss();

  useEffect(() => {
    if (step === null) {
      searchQuery.step = "eligibility";

      setStep("eligibility");

      history.replace({
        search: qs.stringify(searchQuery),
      });
    }
  }, []);

  useEffect(() => {
    if (!!step && searchQuery.step !== step) {
      setStep(searchQuery.step as ReservationSteps);
    }
  }, [searchQuery.step]);

  function closeFlow(path = `/map/${facility.id}`) {
    history.push({
      pathname: path,
      search: qs.stringify({
        ...searchQuery,
        step: undefined,
      }),
    });
  }

  return (
    <Dialog
      open
      onClose={() => {
        if (step === "confirmation") {
          closeFlow();
        } else {
          closeFlow(`/map/${facility.id}/PDO`);
        }
      }}
      title={STEPS_TITLES[searchQuery.step as keyof typeof STEPS_TITLES]}
      data-test="reservation-flow"
      loading={fetching}
      tier={facility.subscriptionTier}
      protectQuit={step !== "confirmation"}
    >
      <div css={css.container}>
        {step === "eligibility" && <EligibilityStep />}
        {step === "error" && <ErrorStep />}
        {step === "child-select" && <ChildSelectStep />}
        {step === "shift-select" && <ShiftSelectStep />}
        {step === "select-payment" && <PaymentSelectStep />}
        {step === "review" && <ReviewStep />}
        {step === "medical-forms" && <MedicalFormsStep />}
        {step === "additional-info" && <AdditionalInfoStep />}
        {step === "confirmation" && <ConfirmationStep onClose={closeFlow} />}
      </div>
    </Dialog>
  );
};

const Wrapper: React.FC = () => {
  const [{ facility }] = useDeepDivePanelStore();

  return (
    <ReservationStoreProvider initialState={{ facility }}>
      <ReservationFlow />
    </ReservationStoreProvider>
  );
};

export default Wrapper;
