import { createUseStyles } from "react-jss";

const styles = {
  agesWrapper: {
    display: "flex",
    justifyContent: "space-between",

    "& > div": {
      width: "calc(100% / 2 - 10px)",
    },
  },

  commonFeesRow: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },

  commonFeesInput: {
    marginTop: 0,
    width: 100,
  },

  commonFeesLabel: {
    width: 200,
  },

  tuitionSelect: {
    marginTop: 0,
  },

  tuitionInput: {
    "&:first-of-type": {
      marginRight: 15,
    },
  },

  discountRow: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",

    "& .control": {
      margin: 0,
      width: 110,
    },

    "& .select": {
      margin: 0,
    },
  },

  sectionTitle: {
    backgroundColor: "rgba(0, 0, 0, .06)",
    padding: 7,
  },
};

export default createUseStyles(styles, { name: "EditFacilityHighlightsForm" });
