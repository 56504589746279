import React, { useMemo } from "react";
import { DateTime } from "luxon";
import numeral from "numeral";
import orderBy from "lodash/orderBy";
import isNil from "lodash/isNil";

import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Radio from "@material-ui/core/Radio";
import Button from "@ui-kit/Button";
import Typography from "@ui-kit/Typography";
import { createCss } from "./styles";

import List from "@material-ui/core/List";
import ListItem from "@ui-kit/ListItem";
import ListItemIcon from "@ui-kit/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { getConfigAgeRange } from "@selectors/weekend-care-config";
import { useReservationStore, useDeepDivePanelStore } from "@hooks";
import { Models } from "@services/api";

const shiftUnavailabilityMap: {
  [key in Models.BookingConfigSuggestedShiftUnavailabilityReason]: string;
} = {
  [Models.BookingConfigSuggestedShiftUnavailabilityReason.AlreadyBooked]:
    "Already booked",
  [Models.BookingConfigSuggestedShiftUnavailabilityReason.AgeGroupFull]:
    "Age-group is full",
  [Models.BookingConfigSuggestedShiftUnavailabilityReason.AgeNotServed]:
    "Age not served",
  [Models.BookingConfigSuggestedShiftUnavailabilityReason.TooLate]: "Too late",
  [Models.BookingConfigSuggestedShiftUnavailabilityReason.TooEarly]:
    "Too early",
};

const ShiftSelectStep: React.FC = () => {
  const [{ facility, bookingConfig }] = useDeepDivePanelStore();
  const [
    { suggestedShifts, selectedShifts },
    { prevStep, nextStep, setSelectedShifts },
  ] = useReservationStore();

  const css = createCss();
  const tz = facility.address.timezone;

  const sortedShifts = useMemo(() => {
    const result: {
      data: {
        date: DateTime;
        shifts: Models.BookingConfigSuggestedShift[];
      }[];
    } = {
      data: [],
    };

    const shifts = orderBy(suggestedShifts.slice(), [
      "shift.dateTimeFrom",
      "shift.dateTimeTo",
    ]);

    if (!shifts.length) {
      return result;
    }

    const initializeDay = (
      day: DateTime,
    ): {
      date: DateTime;
      shifts: Models.BookingConfigSuggestedShift[];
    } => ({
      date: day,
      shifts: [],
    });

    let currentDay = DateTime.fromISO(shifts[0].shift.dateTimeFrom, {
      zone: tz,
    });
    let day = initializeDay(currentDay);

    shifts.forEach((shift) => {
      const shiftStart = DateTime.fromISO(shift.shift.dateTimeFrom, {
        zone: tz,
      });

      if (!currentDay.hasSame(shiftStart, "day")) {
        currentDay = shiftStart;
        day = initializeDay(currentDay);
      }

      if (!day.shifts.length) {
        result.data.push(day);
      }

      day.shifts.push(shift);
    });

    return result;
  }, [suggestedShifts]);

  function selectShift(shift: Models.BookingConfigSuggestedShift) {
    setSelectedShifts([shift]);
  }

  function renderDates() {
    return (
      <div css={css.datesContainer}>
        {sortedShifts.data.map((day, dayIndex) => (
          <React.Fragment key={`shift-day-${dayIndex}`}>
            <Typography paragraph bolded css={css.dayTitle}>
              <span>{day.date.toFormat("MM/dd/yyyy, cccc")}</span>
              <span>Co-pay</span>
            </Typography>

            {day.shifts.some(
              (s) =>
                s.shift.status ===
                Models.BookingConfigSuggestedShiftStatus.Upcoming,
            ) && (
              <Typography paragraph css={css.warning}>
                <Typography display="inline" color="error" className="icon">
                  <ErrorOutlineIcon />
                </Typography>
                reservations can be made 4 wk in advance
              </Typography>
            )}
            {day.shifts.some(
              (s) =>
                s.unavailabilityReason ===
                Models.BookingConfigSuggestedShiftUnavailabilityReason
                  .AgeNotServed,
            ) && (
              <Typography paragraph css={css.warning}>
                <Typography display="inline" color="error" className="icon">
                  <ErrorOutlineIcon />
                </Typography>
                Eligibility error: ages served{" "}
                {getConfigAgeRange(bookingConfig)}
              </Typography>
            )}
            {day.shifts.some((s) => Boolean(s.discount)) && (
              <Typography paragraph css={css.warning}>
                <Typography display="inline" color="success" className="icon">
                  <ErrorOutlineIcon />
                </Typography>
                save {bookingConfig?.discountPercent}% when booking at least 3
                days in advance
              </Typography>
            )}

            <List>
              {day.shifts
                .filter(
                  (s) =>
                    s.shift.status !==
                    Models.BookingConfigSuggestedShiftStatus.Completed,
                )
                .map((shift) => (
                  <React.Fragment key={`shift-${shift.shift.id}`}>
                    <ListItem
                      key={`shift-${shift.shift.id}`}
                      data-test={`shift-${shift.shift.id}`}
                      role={undefined}
                      button
                      disableGutters
                      onClick={() => selectShift(shift)}
                      disabled={Boolean(shift.unavailabilityReason)}
                    >
                      <ListItemIcon>
                        <Radio
                          color="primary"
                          checked={Boolean(
                            selectedShifts.find(
                              (s) => s.shift.id === shift.shift.id,
                            ),
                          )}
                          tabIndex={-1}
                          disableRipple
                          css={css.materialCheckboxRoot}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <div css={css.shiftRow}>
                            <span>
                              <div>
                                {`${DateTime.fromISO(shift.shift.dateTimeFrom, {
                                  zone: tz,
                                })
                                  .toFormat("h:mma")
                                  .toLowerCase()} ` +
                                  `- ${DateTime.fromISO(
                                    shift.shift.dateTimeTo,
                                    {
                                      zone: tz,
                                    },
                                  )
                                    .toFormat("h:mma")
                                    .toLowerCase()}`}
                              </div>
                            </span>
                            {!isNil(shift.unavailabilityReason) ? (
                              <span>
                                {shift.unavailabilityReason !==
                                  Models
                                    .BookingConfigSuggestedShiftUnavailabilityReason
                                    .AgeNotServed &&
                                  shiftUnavailabilityMap[
                                    shift.unavailabilityReason
                                  ]}
                              </span>
                            ) : (
                              <span>
                                {Boolean(shift.discount) && (
                                  <div className="discount">
                                    {numeral(
                                      shift.serviceFee /
                                        (1 - shift.discount) /
                                        100,
                                    ).format("$0,0.00")}
                                  </div>
                                )}
                                <span>
                                  {numeral(shift.serviceFee / 100).format(
                                    "$0,0.00",
                                  )}
                                </span>
                              </span>
                            )}
                          </div>
                        }
                      />
                    </ListItem>
                  </React.Fragment>
                ))}
            </List>
          </React.Fragment>
        ))}
      </div>
    );
  }

  return (
    <div css={css.flowContainer} data-test="shift-select-step">
      <div>{renderDates()}</div>
      <div css={css.actions}>
        <Button
          variant="outlined"
          color={
            facility.subscriptionTier === Models.SubscriptionTier.Ivy
              ? "secondary"
              : "black"
          }
          fullWidth
          size="large"
          onClick={prevStep}
          name="prev"
        >
          Back
        </Button>
        <Button
          variant="contained"
          color={
            facility.subscriptionTier === Models.SubscriptionTier.Ivy
              ? "secondary"
              : "black"
          }
          fullWidth
          size="large"
          onClick={() => nextStep({ type: "SHIFTS_SELECT" })}
          disabled={!selectedShifts.length}
          name="next"
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default ShiftSelectStep;
