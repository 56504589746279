import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const useCss = (): Record<Classes, Interpolation<ITheme>> => ({
  item: (theme) => ({
    alignItems: "flex-start",
    display: "flex",
    position: "relative",

    "& svg": {
      color: theme.palette.success.main,
      height: 16,
      marginRight: 13,
      marginTop: 4,
      width: 16,
    },
  }),
});
