import React, { Fragment, useState, useMemo } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import isNil from "lodash/isNil";
import qs from "query-string";

import PhoneIcon from "@material-ui/icons/Phone";
import PinIcon from "@material-ui/icons/PersonPinCircle";
import { Tour as TourIcon } from "@components/Icons";
import BookmarkIcon from "@material-ui/icons/Bookmark";

import { Spacer } from "@ui-kit/Spacer";
import { ROUTES } from "@constants";
import { useDeepDivePanelStore } from "@hooks";
import { formatPhone, getPhoneCountryPrefix } from "@helpers/format-phone";
import { designationsMap } from "@constants/additional_designations";
import {
  isChildcareCenter,
  getFacilityFullAddress,
  isPublicSchool,
  isFacilityOwner,
} from "@selectors";
import Button from "@ui-kit/Button";
import { NavigationActions } from "@components/DeepDivePanel/NavigationActions";
import { createCss } from "./styles";
import { Typography } from "@material-ui/core";
import { Models } from "@services/api";
import { useUserStore } from "@store/UserStore";
import { useMapStore } from "@store/MapStore";
import { getGoogleMapsSatelliteViewLink } from "@helpers/getGoogleMapsSateliteViewLink";
import { useViewport } from "@hooks/useViewport";

export const FacilityContacts: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const [, { setShowFindToClaimDialog }] = useMapStore();
  const { isMobile } = useViewport();
  const [{ user }] = useUserStore();
  const [{ facility }] = useDeepDivePanelStore();
  const css = createCss();

  const buttonsColor = useMemo(() => {
    return facility.subscriptionTier === Models.SubscriptionTier.Ivy
      ? "secondary"
      : "black";
  }, [facility.subscriptionTier]);

  return (
    <Fragment>
      <Spacer size="md" />

      <div>
        {isChildcareCenter(facility) && (
          <div css={css.item}>
            <TourIcon css={css.icon} />
            <Link to={ROUTES.QUESTIONNAIRE}>
              <Typography color="primary">School tour questionnaire</Typography>
            </Link>
          </div>
        )}

        {!isNil(facility.additionalDesignations) && (
          <div css={css.item}>
            <BookmarkIcon css={css.icon} />
            <Typography data-test="additionalDesignations-display">
              {designationsMap[facility.additionalDesignations].name}
            </Typography>
          </div>
        )}

        {!isNil(facility.phone) && (
          <div css={css.item}>
            <PhoneIcon css={css.icon} />
            <a
              href={`tel:${getPhoneCountryPrefix(facility.phone.country)}${
                facility.phone.number
              }`}
            >
              <Typography data-test="phone-display" color="textPrimary">
                {formatPhone(facility.phone)}
              </Typography>
            </a>

            <span
              className="g-link"
              data-test="findSisterSchools-button"
              css={{
                marginLeft: 25,
              }}
              onClick={() => {
                if (facility.corporationId) {
                  if (isMobile) {
                    history.push({
                      pathname: "/map",
                      search: qs.stringify({
                        corporation: facility.corporationId,
                      }),
                    });
                  } else {
                    history.push({
                      search: qs.stringify({
                        corporation: facility.corporationId,
                      }),
                    });
                  }
                } else {
                  history.replace({
                    search: qs.stringify({
                      ...qs.parse(search),
                      findFacilityPhoneCountry: facility.phone?.country,
                      findFacilityPhone: facility.phone?.number,
                      findFacilityView: "phone",
                    }),
                  });
                  setShowFindToClaimDialog(true);
                }
              }}
            >
              Find sister schools
            </span>
          </div>
        )}

        <div css={css.item}>
          <PinIcon css={css.icon} />
          <a
            href={getGoogleMapsSatelliteViewLink(
              facility.address.location.lon,
              facility.address.location.lat,
            )}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography data-test="address-display" color="textPrimary">
              {getFacilityFullAddress(facility)}
            </Typography>
          </a>
        </div>
      </div>

      {!isPublicSchool(facility) && !isFacilityOwner(facility, user) && (
        <Fragment>
          <Spacer size="small" />

          {facility.conversationsEnabled && (
            <>
              <div css={css.contactsActions}>
                <Button
                  color={buttonsColor}
                  variant="contained"
                  disabled={!facility.email}
                  size="large"
                  link={`/map/${facility.id}/service-inquiry`}
                  fullWidth
                  data-test="enrollment-inquiry-trigger"
                >
                  Contact school
                </Button>
                <Button
                  color={buttonsColor}
                  variant="contained"
                  link={`/map/${facility.id}/tour-inquiry`}
                  fullWidth
                  size="large"
                  disabled={!facility.email}
                >
                  Request a tour
                </Button>
              </div>

              <Spacer size="sm" />
            </>
          )}

          <div css={css.contactsActions}>
            <Button
              color={buttonsColor}
              variant="outlined"
              link={`/map/${facility.id}/share`}
              fullWidth
              size="large"
            >
              Share profile
            </Button>
            <Button
              color={buttonsColor}
              variant="outlined"
              link={`/map/${facility.id}/photos`}
              fullWidth
              size="large"
            >
              School photos
            </Button>
          </div>
        </Fragment>
      )}

      <Spacer size="medium" />
      <NavigationActions />
    </Fragment>
  );
};
