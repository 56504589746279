export const getThumbnailURL = async (link: string): Promise<string | null> => {
  const params = {
    patterns: [
      // {regex: /facebook/, type: "facebook", url: "//img.youtube.com/vi/%1" },
      {
        regex: /youtu\.be\/([\w\-.]+)/,
        type: "youtube",
        url: "//img.youtube.com/vi/%1",
      },
      {
        regex: /youtube\.com(.+)v=([^&]+)/,
        type: "youtube",
        url: "//img.youtube.com/vi/%2",
      },
      {
        regex: /vimeo\.com\/([0-9]+)/,
        type: "vimeo",
        url: "//vimeo.com/api/v2/video/%1.json",
      },
      {
        regex: /vimeo\.com\/(.*)\/([0-9]+)/,
        type: "vimeo",
        url: "//vimeo.com/api/v2/video/%2.json",
      },
    ],
    vmimg: "large",
    ytimg: "0",
  };

  let url = "";
  let match: RegExpExecArray | null;
  let type: string | undefined;

  params.patterns.forEach((pattern) => {
    match = pattern.regex.exec(link);

    if (match) {
      const position = /%([0-9]+)/.exec(pattern.url)?.[1];

      if (position) {
        type = pattern.type;
        url = pattern.url.replace(/%([0-9]+)/, match[parseInt(position, 10)]);

        return false;
      }
    }
  });

  if (!type) {
    return "Not found pattern.";
  }

  switch (type) {
    case "youtube":
      return `${url}/${params.ytimg}.jpg`;
    case "vimeo":
      const response = await fetch(url);
      const data = await response.json();

      return data[0]["thumbnail_" + params.vmimg] as string;
    default:
      return null;
  }
};
