import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import orderBy from "lodash/orderBy";
import isNil from "lodash/isNil";
import qs from "query-string";
import Humanize from "humanize-plus";
import PinIcon from "@material-ui/icons/PersonPinCircle";
import PhoneIcon from "@material-ui/icons/Phone";

import { Models } from "@services/api";
import Typography from "@ui-kit/Typography";
import Button from "@ui-kit/Button";
import { createCss } from "./styles";
import { getFacilityFullAddress } from "@selectors";
import { useMapStore } from "@store/MapStore";
import { View, SearchPayload } from "../types";
import BudgeIcon from "@images/vbadge_flower_blue.svg";
import { NewDesignIcons } from "@components/Icons";
import { schoolTypesMap } from "@constants/school-types";
import { formatPhone } from "@helpers/format-phone";

type FacilitySearchResultsProps = {
  onCancel: () => void;
  facilities: Models.FacilityCard[];
  view: View;
  searchPayload: SearchPayload;
  total: number;
};

const facilityTypesIcons = {
  [Models.FacilityTypeID.PreSchool]: NewDesignIcons.MapPinGreen,
  [Models.FacilityTypeID.HeadstartSchool]: NewDesignIcons.MapPinGreen,
  [Models.FacilityTypeID.DaycareCenter]: NewDesignIcons.MapPinPink,
  [Models.FacilityTypeID.PrivateSchool]: NewDesignIcons.MapPinBlue,
  [Models.FacilityTypeID.PublicSchool]: NewDesignIcons.MapPinPink,
  [Models.FacilityTypeID.CharterSchool]: NewDesignIcons.MapPinPink,
  [Models.FacilityTypeID.Oshc]: NewDesignIcons.MapPinBlack,
};

export const FacilitySearchResults: React.FC<FacilitySearchResultsProps> = ({
  onCancel,
  facilities,
  view,
  searchPayload,
  total,
}) => {
  const history = useHistory();
  const mapStore = useMapStore(false);
  const css = createCss();

  return (
    <div>
      <Typography gap>
        {total > 50 ? (
          <Fragment>
            Top{" "}
            <Typography variant="inherit" bolded>
              50
            </Typography>{" "}
            matches displayed.
          </Fragment>
        ) : (
          <Fragment>
            <Typography
              variant="inherit"
              bolded
              color={!facilities.length ? "error" : "inherit"}
            >
              {facilities.length}
            </Typography>{" "}
            {Humanize.pluralize(facilities.length, "match", "matches")} found.
            {!facilities.length && " Try a different query"}
          </Fragment>
        )}
      </Typography>

      {facilities.length > 1 && (
        <Button
          variant="outlined"
          fab
          color="primary"
          size="large"
          fullWidth
          css={css.viewAll}
          onClick={() => {
            mapStore?.[1].setShowFindToClaimDialog(false);
            if (view === "id") {
              history.push(`/map?ids=${facilities.map((f) => f.id).join("|")}`);
            }

            if (view === "phone") {
              const p = searchPayload.phone?.number;

              history.push(`/map?phone=${p}`);
            }

            if (view === "address") {
              let a = searchPayload.addressStreet;

              if (searchPayload.addressZip) {
                a += `|${searchPayload.addressZip}`;
              }

              history.push(`/map?address=${a}`);
            }

            if (view === "name") {
              let n = searchPayload.name;

              if (searchPayload.addressCountry) {
                n += `|${searchPayload.addressCountry}`;
              }
              if (searchPayload.addressState) {
                n += `|${searchPayload.addressState}`;
              }
              if (searchPayload.addressZip) {
                n += `|${searchPayload.addressZip}`;
              }

              history.push(`/map?facilityName=${n}`);
            }
          }}
        >
          View all
        </Button>
      )}

      {!facilities.length && (
        <div css={css.item}>
          <div>
            <Typography gutterBottom bolded className="name">
              Facility name
            </Typography>
            <Typography className="address">
              <PinIcon />
              City, State, Zip code
            </Typography>
          </div>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            css={css.itemButton}
            disabled
          >
            View
          </Button>
        </div>
      )}

      {orderBy(facilities, ["address.state", "address.zip", "name"]).map(
        (f) => (
          <div css={css.item} key={f.id}>
            <div>
              <div css={css.facilityNameRow}>
                {!!f.accountId && (
                  <img
                    className="icon"
                    src={BudgeIcon}
                    alt="claimed-badge"
                    title="This school profile has been claimed"
                  />
                )}
                <Typography bolded className="name">
                  {f.name}
                </Typography>
              </div>
              <Typography className="field" gutterBottom>
                {facilityTypesIcons[f.facilityType]({})}
                {schoolTypesMap[f.facilityType].title}
              </Typography>
              <Typography className="field" gutterBottom>
                <NewDesignIcons.TierBadge />
                School tier:{" "}
                <Typography
                  variant="inherit"
                  className="tier"
                  bolded={
                    f.subscriptionTier === Models.SubscriptionTier.Community
                  }
                  color={
                    f.subscriptionTier === Models.SubscriptionTier.Community
                      ? "textPrimary"
                      : "textSecondary"
                  }
                >
                  base
                </Typography>{" "}
                <Typography
                  variant="inherit"
                  className="tier"
                  bolded={f.subscriptionTier === Models.SubscriptionTier.Pro}
                  color={
                    f.subscriptionTier === Models.SubscriptionTier.Pro
                      ? "textPrimary"
                      : "textSecondary"
                  }
                >
                  pro
                </Typography>{" "}
                <Typography
                  variant="inherit"
                  className="tier"
                  bolded={f.subscriptionTier === Models.SubscriptionTier.Ivy}
                  color={
                    f.subscriptionTier === Models.SubscriptionTier.Ivy
                      ? "textPrimary"
                      : "textSecondary"
                  }
                >
                  ivy
                </Typography>
              </Typography>
              {!isNil(f.phone) && (
                <Typography className="field" gutterBottom>
                  <PhoneIcon />
                  {formatPhone(f.phone)}
                </Typography>
              )}
              <Typography className="field">
                <PinIcon />
                {getFacilityFullAddress(f)}
              </Typography>
            </div>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              css={css.itemButton}
              onClick={() => {
                history.push({
                  pathname: `/map/${f.id}`,
                  search: qs.stringify({
                    lat: f.address.location.lat,
                    lng: f.address.location.lon,
                  }),
                });
                mapStore?.[1].setShowFindToClaimDialog(false);
              }}
              data-test={`view-facility-button-${f.id}`}
            >
              View
            </Button>
          </div>
        ),
      )}

      <div css={css.actions}>
        <Button
          color="primary"
          variant="outlined"
          size="large"
          name="back"
          onClick={onCancel}
        >
          Back
        </Button>
      </div>
    </div>
  );
};
