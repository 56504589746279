import React from "react";
import { useHistory } from "react-router-dom";

import Typography from "@ui-kit/Typography";
import { createCss } from "./styles";
import SuccessStep from "@components/SuccessStep";
import { ROUTES } from "@constants";
import { useDeepDivePanelStore } from "@hooks/useDeepDivePanelStore";

interface IProps {
  onClose: () => void;
}

const ConfirmationStep: React.FC<IProps> = ({ onClose }) => {
  const history = useHistory();
  const [{ facility }] = useDeepDivePanelStore();
  const css = createCss();

  return (
    <div
      css={css.flowContainer}
      style={{
        justifyContent: "center",
      }}
    >
      <SuccessStep
        text="Submitted"
        content={
          <Typography align="center">Confirmation email on its way</Typography>
        }
        buttonFullWidth={false}
        buttonText="View reservation history"
        onDone={() => {
          const unlisten = history.listen((location: any) => {
            if (location.pathname === `/map/${facility?.id}`) {
              unlisten();

              setTimeout(() => {
                history.push(ROUTES.ACTIVITIES);
              }, 500);
            }
          });

          onClose();
        }}
      />
    </div>
  );
};

export default ConfirmationStep;
