import React, { Fragment } from "react";

import Typography from "@ui-kit/Typography";
import { Size } from "./Size";
import { StaffBackgroundChecks } from "./StaffBackgroundChecks";
import { SickChildExclusion } from "./SickChildExclusion";
import { TeacherChildRatiosTable } from "./TeacherChildRatiosTable";
import { ImmunizationForm } from "./ImmunizationForm";
import { Spacer } from "@ui-kit/Spacer";
import { useCss } from "./styles";

export const FacultyAndSecurity: React.FC = () => {
  const css = useCss();

  return (
    <Fragment>
      <Typography bolded align="center" css={css.title}>
        Faculty & security
      </Typography>

      <Spacer size="small" />

      <Size />
      <TeacherChildRatiosTable />
      <StaffBackgroundChecks />
      <Spacer size="small" />
      <SickChildExclusion />
      <ImmunizationForm />
    </Fragment>
  );
};
