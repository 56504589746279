import React from "react";
import clsx from "clsx";
import qs from "query-string";
import { useHistory } from "react-router-dom";

import CloseIcon from "@material-ui/icons/Close";

import { useMapStore } from "@store/MapStore";
import { createCss } from "./styles";
import { SearchAreaActionProps } from "./types";

export const SearchAreaButton: React.FC<SearchAreaActionProps> = ({ map }) => {
  const [
    {
      filterIsActive,
      filters,
      searchAreaButtonVisible,
      globalFiltersAreActive,
    },
    { setSearchString, setSearchAreaButtonVisible },
  ] = useMapStore();
  const history = useHistory();
  const css = createCss();

  const handleClose = () => setSearchAreaButtonVisible(false);

  const handleSearch = () => {
    if (!map) {
      return;
    }

    const { lat, lng } = map.getCenter();

    setSearchString("");
    history.replace({
      pathname: "/map",
      search: qs.stringify({
        lat,
        lng,
        ...filters.secondary,
      }),
    });
  };

  if (!searchAreaButtonVisible || globalFiltersAreActive.current) {
    return null;
  }

  return (
    <div
      css={css.root}
      className={clsx({
        filtersActive: filterIsActive.current,
      })}
    >
      <div css={css.label} onClick={handleSearch}>
        Search area
      </div>
      <div css={css.cross} onClick={handleClose}>
        <CloseIcon />
      </div>
    </div>
  );
};
