import React from "react";

import EditOverviewView from "@components/FacilityOverviewForm";
import { EditFacilityDialog } from "@pages/DeepDivePanel/EditFacilityDialog";

const EditOverview: React.FC = () => {
  return (
    <EditFacilityDialog name="EditFacilityOverview" title="About your school">
      {(view, setView) => (
        <EditOverviewView facilityView={view} setFacilityView={setView} />
      )}
    </EditFacilityDialog>
  );
};

export default EditOverview;
