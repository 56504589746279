import React, { useMemo } from "react";
import get from "lodash/get";
import { useFormContext, Controller, UseFormMethods } from "react-hook-form";
import isNumber from "lodash/isNumber";
import orderBy from "lodash/orderBy";

import { ControlledInputField } from "@ui-kit/InputFields";
import useStyles from "./styles";
import { ControlledSelectField } from "@ui-kit/Select";
import { ageUnits } from "@constants/age-units";
import { denormalizeAge } from "@helpers/ages-helper";
import { MONTHS } from "@constants/months";
import Typography from "@ui-kit/Typography";
import MultipleSelect from "@ui-kit/MultipleSelect";
import Checkbox from "@material-ui/core/Checkbox";
import { AFFILIATIONS } from "@constants/accreditations";
import { Models } from "@services/api";
import { programsTypesMap } from "@constants/programs";
import { Spacer } from "@ui-kit/Spacer";
import { required } from "@validators";
import { WEEKDAYS, Weekday, weekdaysMap } from "@constants/weekdays";
import { getAgeLimitMessage } from "@helpers/getAgeLimitMessage";
import { ServerError } from "@models/common";
import { FacilityOperatingInfo } from "@models/FacilityOperatingInfo";

function formatAge(
  value: number | null | undefined,
  unit: Models.AgeUnit | null | undefined,
) {
  const age = denormalizeAge(value, unit);

  if (isNumber(age)) {
    return age.toString();
  }

  return "";
}

const isFieldOfFacilityOperatingInfo = (field: string) => {
  return (
    field === "operatingInfo.foundedAt" ||
    field === "operatingInfo.yearLengthEndMonth" ||
    field === "operatingInfo.yearLengthStartMonth" ||
    field === "operatingInfo.schoolDays" ||
    field === "operatingInfo.ageFrom" ||
    field === "operatingInfo.ageFromUnit" ||
    field === "operatingInfo.ageTo" ||
    field === "operatingInfo.ageToUnit" ||
    field === "operatingInfo.ongoingGradesTo" ||
    field === "operatingInfo.ongoingGradesFrom" ||
    field === "operatingInfo.enrollmentCapacity" ||
    field === "operatingInfo.currentEnrollment" ||
    field === "operatingInfo.numberOfClassrooms" ||
    field === "operatingInfo.religiousAffiliation" ||
    field === "operatingInfo.naeycAccredited" ||
    field === "operatingInfo.ymcaAffiliated" ||
    field === "operatingInfo.naisAffiliated"
  );
};

export const processServerErrors = (
  errors: ServerError[],
  setError: UseFormMethods["setError"],
  watch: UseFormMethods["watch"],
): boolean => {
  let errorHandled = false;

  errors.forEach((e) => {
    const path = e.source.pointer?.split("/").filter(Boolean).join(".");

    if (!path) {
      return false;
    }

    if (path === "operatingInfo.ageFrom" || path === "operatingInfo.ageTo") {
      switch (e.code) {
        case "age_unit_conflict":
          setError(path, {
            message: getAgeLimitMessage(
              e.meta?.threshold,
              watch(`${path}Unit`),
            ),
            type: "manual",
          });
          errorHandled = true;
          return true;
      }
    }

    if (isFieldOfFacilityOperatingInfo(path)) {
      setError(path, {
        message: e.title,
        type: "manual",
      });
      errorHandled = true;
      return true;
    }
  });

  return errorHandled;
};

const GRADES = [
  {
    id: Models.SchoolOngoingGradeID.Infant,
    name: programsTypesMap[Models.SchoolOngoingGradeID.Infant].title,
  },
  {
    id: Models.SchoolOngoingGradeID.Toddler,
    name: programsTypesMap[Models.SchoolOngoingGradeID.Toddler].title,
  },
  {
    id: Models.SchoolOngoingGradeID.PreSchool,
    name: programsTypesMap[Models.SchoolOngoingGradeID.PreSchool].title,
  },
  {
    id: Models.SchoolOngoingGradeID.PreK2,
    name: programsTypesMap[Models.SchoolOngoingGradeID.PreK2].title,
  },
  {
    id: Models.SchoolOngoingGradeID.PreK3,
    name: programsTypesMap[Models.SchoolOngoingGradeID.PreK3].title,
  },
  {
    id: Models.SchoolOngoingGradeID.PreK4,
    name: programsTypesMap[Models.SchoolOngoingGradeID.PreK4].title,
  },
  {
    id: Models.SchoolOngoingGradeID.PreK,
    name: programsTypesMap[Models.SchoolOngoingGradeID.PreK].title,
  },
  {
    id: Models.SchoolOngoingGradeID.TK,
    name: programsTypesMap[Models.SchoolOngoingGradeID.TK].title,
  },
  {
    id: Models.SchoolOngoingGradeID.K,
    name: programsTypesMap[Models.SchoolOngoingGradeID.K].title,
  },
  { id: Models.SchoolOngoingGradeID._1, name: "1st grade" },
  { id: Models.SchoolOngoingGradeID._2, name: "2nd grade" },
  { id: Models.SchoolOngoingGradeID._3, name: "3rd grade" },
  { id: Models.SchoolOngoingGradeID._4, name: "4th grade" },
  { id: Models.SchoolOngoingGradeID._5, name: "5th grade" },
  { id: Models.SchoolOngoingGradeID._6, name: "6th grade" },
  { id: Models.SchoolOngoingGradeID._7, name: "7th grade" },
  { id: Models.SchoolOngoingGradeID._8, name: "8th grade" },
  { id: Models.SchoolOngoingGradeID._9, name: "9th grade" },
  { id: Models.SchoolOngoingGradeID._10, name: "10th grade" },
  { id: Models.SchoolOngoingGradeID._11, name: "11th grade" },
  { id: Models.SchoolOngoingGradeID._12, name: "12th grade" },
];

const filterGrades = (
  grades: {
    id: Models.SchoolOngoingGradeID;
    name: string;
  }[],
  currentGrade?: Models.SchoolOngoingGradeID | null,
) => {
  return grades.filter((g) => {
    if (g.id === Models.SchoolOngoingGradeID.PreSchool) {
      if (currentGrade === Models.SchoolOngoingGradeID.PreSchool) {
        return true;
      }
      return false;
    }
    if (g.id === Models.SchoolOngoingGradeID.PreK) {
      if (currentGrade === Models.SchoolOngoingGradeID.PreK) {
        return true;
      }
      return false;
    }
    return true;
  });
};

const getGradeIndex = (
  grade: string | null | undefined,
  position: "start" | "end",
) => {
  const i = GRADES.findIndex((g) => g.id === grade);

  if (i === -1) {
    if (position === "start") {
      return 0;
    } else {
      return GRADES.length - 1;
    }
  }

  if (position === "start") {
    return i + 1;
  } else {
    return i;
  }
};

interface IProps {
  facilityView: Models.Facility;
  setFacilityView: React.Dispatch<React.SetStateAction<Models.Facility>>;
  isClaim?: boolean;
  isShortClaim?: boolean;
}

const EditFacilityForm: React.FC<IProps> = ({
  facilityView,
  setFacilityView,
  isClaim,
  isShortClaim,
}) => {
  const operatingInfo = useMemo(
    () => FacilityOperatingInfo.fromDto(facilityView.operatingInfo),
    [],
  );
  const classes = useStyles();
  const { control, errors, watch, setValue, clearErrors } = useFormContext();

  const updateView = () => {
    setFacilityView((prev) => ({
      ...prev,
      operatingInfo: operatingInfo.toDto(),
    }));
  };

  return (
    <div data-test="facility-operating-details-editor">
      <div className={classes.agesWrapper}>
        <div className={classes.foundedLabel}>
          <Typography variant="inherit" required={isClaim}>
            Year established:
          </Typography>
        </div>

        <ControlledInputField
          defaultValue={operatingInfo.foundedAt}
          name="operatingInfo.foundedAt"
          variant="integer"
          maxLength={4}
          required={isClaim}
          onChange={(event) => {
            operatingInfo.foundedAt = event.target.value;
            updateView();
          }}
        />
      </div>

      <div className={classes.agesWrapper}>
        <ControlledSelectField
          items={[
            { id: "year-round", title: "Year-round" },
            ...MONTHS.filter((m) => m.id !== operatingInfo.yearLengthEndMonth),
          ]}
          labelProp="title"
          label="School year start"
          defaultValue={
            operatingInfo.yearLengthIsWhole
              ? "year-round"
              : operatingInfo.yearLengthStartMonth
          }
          name="operatingInfo.yearLengthStartMonth"
          required={isClaim}
          onChange={(event) => {
            const value = event.value.id;
            operatingInfo.yearLengthStartMonth = value as Models.MonthID;
            updateView();

            if (value === "year-round") {
              setValue("operatingInfo.yearLengthEndMonth", "year-round");
              clearErrors("operatingInfo.yearLengthEndMonth");
            } else {
              if (watch("operatingInfo.yearLengthEndMonth") === "year-round") {
                setValue("operatingInfo.yearLengthEndMonth", "");
              }
            }
          }}
        />

        <ControlledSelectField
          items={[
            ...(operatingInfo.yearLengthIsWhole
              ? [{ id: "year-round", title: "Year-round" }]
              : []),
            ...MONTHS.filter(
              (m) => m.id !== operatingInfo.yearLengthStartMonth,
            ),
          ]}
          name="operatingInfo.yearLengthEndMonth"
          defaultValue={
            operatingInfo.yearLengthIsWhole
              ? "year-round"
              : operatingInfo.yearLengthEndMonth
          }
          labelProp="title"
          label="School year end"
          disabled={!!operatingInfo.yearLengthIsWhole}
          required={isClaim && !operatingInfo.yearLengthIsWhole}
          onChange={(event) => {
            operatingInfo.yearLengthEndMonth = event.value.id as Models.MonthID;
            updateView();
          }}
        />
      </div>

      {!isShortClaim && (
        <Controller
          render={({ onChange, ...controllerProps }) => (
            <MultipleSelect
              {...controllerProps}
              required={isClaim}
              items={WEEKDAYS}
              itemLabelProp="name"
              label="School days"
              renderValue={(selected) =>
                orderBy(selected, "order")
                  .map((s) => s.nameShort)
                  .join(", ")
              }
              error={get(errors, "operatingInfo.schoolDays")?.message}
              onChange={(event) => {
                operatingInfo.schoolDays = event.target.value.map((a) => a.id);
                updateView();
                onChange(event);
              }}
            />
          )}
          rules={{
            validate: (value: Weekday[]) => {
              if (isClaim && !value.length) {
                return required().required;
              }
              return true;
            },
          }}
          defaultValue={
            operatingInfo.schoolDays?.map((a) => weekdaysMap[a]) || []
          }
          name="operatingInfo.schoolDays"
          control={control}
        />
      )}

      <Spacer size="medium" />

      <div className={classes.agesWrapper}>
        <ControlledInputField
          label="Min age served"
          variant="integer"
          maxLength={3}
          defaultValue={formatAge(
            operatingInfo.ageFrom,
            operatingInfo.ageFromUnit,
          )}
          name="operatingInfo.ageFrom"
          required={isClaim}
          onChange={(event) => {
            operatingInfo.ageFrom = event.target.value;
            updateView();
          }}
        />

        <ControlledSelectField
          label="Unit"
          items={ageUnits.filter((a) => a.id !== Models.AgeUnit.YearsMonths)}
          defaultValue={operatingInfo.ageFromUnit}
          name="operatingInfo.ageFromUnit"
          labelProp="title"
          required={isClaim}
          onChange={(event) => {
            operatingInfo.ageFromUnit = event.value.id;
            operatingInfo.ageFrom = watch("operatingInfo.ageFrom");
            updateView();
          }}
        />
      </div>

      <div className={classes.agesWrapper}>
        <ControlledInputField
          label="Max age served"
          variant="integer"
          maxLength={3}
          required={isClaim}
          noZero
          defaultValue={formatAge(operatingInfo.ageTo, operatingInfo.ageToUnit)}
          name="operatingInfo.ageTo"
          onChange={(event) => {
            operatingInfo.ageTo = event.target.value;
            updateView();
          }}
        />

        <ControlledSelectField
          label="Unit"
          items={ageUnits.filter((a) => a.id !== Models.AgeUnit.YearsMonths)}
          defaultValue={operatingInfo.ageToUnit}
          name="operatingInfo.ageToUnit"
          labelProp="title"
          required={isClaim}
          onChange={(event) => {
            operatingInfo.ageToUnit = event.value.id;
            operatingInfo.ageTo = watch("operatingInfo.ageTo");
            updateView();
          }}
        />
      </div>

      <Spacer size="medium" />

      <ControlledSelectField
        label="Starting grade"
        items={filterGrades(
          GRADES.slice(0, getGradeIndex(operatingInfo.ongoingGradesTo, "end")),
          operatingInfo.ongoingGradesFrom,
        )}
        labelProp="name"
        defaultValue={operatingInfo.ongoingGradesFrom}
        name="operatingInfo.ongoingGradesFrom"
        required={isClaim}
        onChange={(event) => {
          operatingInfo.ongoingGradesFrom = event.value.id;
          updateView();
        }}
      />

      <ControlledSelectField
        label="Ending grade"
        items={filterGrades(
          GRADES.slice(
            getGradeIndex(operatingInfo.ongoingGradesFrom, "start"),
            GRADES.length,
          ),
          operatingInfo.ongoingGradesTo,
        )}
        defaultValue={operatingInfo.ongoingGradesTo}
        name="operatingInfo.ongoingGradesTo"
        labelProp="name"
        required={isClaim}
        onChange={(event) => {
          operatingInfo.ongoingGradesTo = event.value.id;
          updateView();
        }}
      />

      <Spacer size="medium" />

      <ControlledInputField
        label="Enrollment capacity"
        variant="integer"
        maxLength={4}
        type="phone"
        required={isClaim}
        noZero
        defaultValue={operatingInfo.enrollmentCapacity}
        name="operatingInfo.enrollmentCapacity"
        onChange={(event) => {
          operatingInfo.enrollmentCapacity = event.target.value;
          updateView();
        }}
      />

      {!isShortClaim && (
        <ControlledInputField
          label="Current enrollment"
          defaultValue={operatingInfo.currentEnrollment}
          name="operatingInfo.currentEnrollment"
          variant="integer"
          maxLength={4}
          type="phone"
          noZero
          onChange={(event) => {
            operatingInfo.currentEnrollment = event.target.value;
            updateView();
          }}
        />
      )}

      <ControlledInputField
        label="№ of classrooms"
        variant="integer"
        maxLength={2}
        defaultValue={operatingInfo.numberOfClassrooms}
        name="operatingInfo.numberOfClassrooms"
        type="phone"
        required={isClaim}
        noZero
        onChange={(event) => {
          operatingInfo.numberOfClassrooms = event.target.value;
          updateView();
        }}
      />

      {!isShortClaim && (
        <>
          <Spacer size="medium" />

          <ControlledSelectField
            label="Affiliations"
            items={orderBy(AFFILIATIONS, "filterOrder")}
            labelProp="name"
            name="operatingInfo.religiousAffiliation"
            defaultValue={operatingInfo.religiousAffiliation}
            allowEmpty
            onChange={(event) => {
              operatingInfo.religiousAffiliation = event.value.id;
              updateView();
            }}
          />

          <Spacer />

          <div
            className={classes.checkRow}
            onClick={() => {
              operatingInfo.naeycAccredited = !operatingInfo.naeycAccredited;
              updateView();
            }}
            data-test="naeyc-accredited-check"
          >
            <Checkbox
              color="primary"
              checked={!!operatingInfo.naeycAccredited}
            />
            <Typography>NAEYC accredited</Typography>
          </div>

          <div
            className={classes.checkRow}
            onClick={() => {
              operatingInfo.naisAffiliated = !operatingInfo.naisAffiliated;
              updateView();
            }}
            data-test="nais-affiliated-check"
          >
            <Checkbox
              color="primary"
              checked={!!operatingInfo.naisAffiliated}
            />
            <Typography>NAIS affiliated</Typography>
          </div>

          <div
            className={classes.checkRow}
            onClick={() => {
              operatingInfo.ymcaAffiliated = !operatingInfo.ymcaAffiliated;
              updateView();
            }}
            data-test="ymca-check"
          >
            <Checkbox
              color="primary"
              checked={!!operatingInfo.ymcaAffiliated}
            />
            <Typography>YMCA</Typography>
          </div>
        </>
      )}
    </div>
  );
};

export default EditFacilityForm;
