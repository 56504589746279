import React from "react";

import Typography from "@ui-kit/Typography";
import { isPublicSchool, formatYrsInBusiness } from "@selectors";
import { useDeepDivePanelStore } from "@hooks";

interface YearsInBusinessProps {
  gutterBottom?: boolean;
}

export const YearsInBusiness: React.FC<YearsInBusinessProps> = ({
  gutterBottom = true,
}) => {
  const [{ facility }] = useDeepDivePanelStore();

  if (isPublicSchool(facility)) {
    return null;
  }

  return (
    <Typography gutterBottom={gutterBottom} data-test="yrs-in-bisiness-display">
      <Typography variant="inherit" bolded>
        Years in business:
      </Typography>{" "}
      {formatYrsInBusiness(facility) || "---"}
    </Typography>
  );
};
