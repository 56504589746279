import React from "react";
import isNil from "lodash/isNil";

import Button from "@ui-kit/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { OutlinedInput } from "@ui-kit/InputFields";
import { createCss } from "./styles";
import Typography from "@ui-kit/Typography";
import { useReservationStore } from "@hooks";
import { useDeepDivePanelStore } from "@hooks/useDeepDivePanelStore";
import { Models } from "@services/api";
import { LinkOrFile } from "@components/LinkOrFile";
import { must } from "@utils/must";

const MedicalFormsStep: React.FC = () => {
  const [
    { isChildEnrolled, childMedicalInfo, child },
    { setIsChildEnrolled, setChildMedicalInfo, prevStep, nextStep, setChild },
  ] = useReservationStore();
  const [{ facility }] = useDeepDivePanelStore();

  const css = createCss();

  return (
    <div css={css.flowContainer}>
      <div>
        <Typography>
          Does the facility have your child's medical records on file?
        </Typography>

        <FormControl margin="normal">
          <RadioGroup
            name="has-forms"
            value={isNil(isChildEnrolled) ? "" : isChildEnrolled ? "yes" : "no"}
            onChange={(event) =>
              setIsChildEnrolled(event.target.value === "yes")
            }
            css={css.materialRadioGroupRoot}
          >
            <FormControlLabel
              value="yes"
              data-test="medical-forms-yes"
              control={<Radio color="primary" />}
              label="Yes"
            />
            <FormControlLabel
              value="no"
              data-test="medical-forms-no"
              control={<Radio color="primary" />}
              label="No"
            />
          </RadioGroup>
        </FormControl>

        {!isNil(isChildEnrolled) && (
          <>
            {!isChildEnrolled && (
              <Typography paragraph>
                <Typography variant="inherit" bolded>
                  Medical forms:
                </Typography>{" "}
                per regulatory requirements, child immunization & medical forms
                may need to be filed with the facility as part of your visit. If
                you would like this information submitted automatically, simply
                upload it when booking.
              </Typography>
            )}

            <div css={css.childMedicalInfoTitle}>
              Tell us more about your child
            </div>
            <OutlinedInput
              value={childMedicalInfo}
              onChange={(event) => {
                setChildMedicalInfo(event.target.value);
              }}
              multiline
              placeholder="Allergies, food intolerance, medical history"
              data-test="medical-forms-field"
              rowsMax="6"
              maxChars={750}
            />

            {!isChildEnrolled && (
              <>
                <LinkOrFile
                  label="Child immunization record"
                  file={child?.documents?.immunizationRecord}
                  onChange={(file) =>
                    setChild({
                      ...must(child),
                      documents: child?.documents
                        ? { ...child.documents, immunizationRecord: file }
                        : {
                            medicalHistory: { original: "" },
                            immunizationRecord: file,
                          },
                    })
                  }
                  data-test="immunizationRecord-linkOrLink"
                  hideControls
                />

                <LinkOrFile
                  label="Child medical history"
                  file={child?.documents?.medicalHistory}
                  onChange={(file) =>
                    setChild({
                      ...must(child),
                      documents: child?.documents
                        ? { ...child.documents, medicalHistory: file }
                        : {
                            immunizationRecord: { original: "" },
                            medicalHistory: file,
                          },
                    })
                  }
                  data-test="medicalHistory-linkOrLink"
                  hideControls
                />
              </>
            )}
          </>
        )}
      </div>

      <div css={css.actions}>
        <Button
          variant="outlined"
          color={
            facility.subscriptionTier === Models.SubscriptionTier.Ivy
              ? "secondary"
              : "black"
          }
          fullWidth
          size="large"
          onClick={prevStep}
          data-test="prev-btn"
        >
          Back
        </Button>
        <Button
          variant="contained"
          color={
            facility.subscriptionTier === Models.SubscriptionTier.Ivy
              ? "secondary"
              : "black"
          }
          fullWidth
          size="large"
          onClick={() => nextStep({ type: "MEDICAL_INFO" })}
          data-test="next-btn"
          disabled={isNil(isChildEnrolled)}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default MedicalFormsStep;
