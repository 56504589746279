import keyBy from "lodash/keyBy";

import { Models } from "@services/api";

export type TuitionWaitlist = {
  id: Models.WaitlistProvided;
  name: string;
};

export const TUITION_WAITLIST: TuitionWaitlist[] = [
  {
    id: Models.WaitlistProvided.Yes,
    name: "yes",
  },
  {
    id: Models.WaitlistProvided.No,
    name: "no",
  },
  {
    id: Models.WaitlistProvided.AllGrades,
    name: "all grades",
  },
  {
    id: Models.WaitlistProvided.SomeGrades,
    name: "some grades",
  },
];

export const tuitionWaitlistMap = keyBy(TUITION_WAITLIST, "id");
