import { createUseStyles } from "react-jss";

import { ITheme } from "@theme";

const styles = (theme: ITheme) => ({
  root: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
  },

  confirmation: {
    [theme.mediaRequests.notMobile]: {
      width: 400,
    },
  },

  icon: {
    "&.active": {
      color: theme.palette.error.main,
    },
  },
});

export default createUseStyles<ITheme, keyof ReturnType<typeof styles>>(
  styles,
  { name: "Favorite" },
);
