{
  /* eslint-disable */
}
import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export const StreetView: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      d="M23.077,21.154C23.077,23.796,17.594,25,12.5,25S1.923,23.796,1.923,21.154
      c0-2.033,3.025-2.992,5.562-3.439c0.525-0.091,1.022,0.258,1.114,0.781s-0.257,1.022-0.78,1.114
      c-3.06,0.537-3.924,1.379-3.974,1.559c0.147,0.504,3.032,1.908,8.655,1.908s8.508-1.404,8.656-1.938
      c-0.051-0.15-0.92-0.997-4.002-1.533c-0.523-0.091-0.873-0.59-0.782-1.113c0.091-0.522,0.581-0.871,1.113-0.782
      C20.036,18.156,23.077,19.113,23.077,21.154z M8.654,15.385h0.961v5.769c0,0.53,0.432,0.961,0.962,0.961h3.846
      c0.53,0,0.962-0.432,0.962-0.961v-5.769h0.961c0.53,0,0.962-0.432,0.962-0.962V8.654c0-0.492-0.772-1.572-2.112-1.728
      c-0.55-0.105-1.611-0.18-2.681-0.18c-1.08-0.001-2.156,0.075-2.712,0.18C8.464,7.083,7.692,8.163,7.692,8.654v5.769
      C7.692,14.953,8.124,15.385,8.654,15.385z M12.5,5.769c1.593,0,2.885-1.292,2.885-2.885C15.385,1.292,14.093,0,12.5,0
      S9.615,1.292,9.615,2.885S10.907,5.769,12.5,5.769z"
    />
  </SvgIcon>
);

export const InstagramLogo: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      d="M12,24C5.4,24,0,18.6,0,12S5.4,0,12,0s12,5.4,12,12S18.6,24,12,24z M12,1C5.9,1,1,5.9,1,12s4.9,11,11,11
      s11-4.9,11-11C23,5.9,18.1,1,12,1z"
    />
    <path
      d="M12,7.3c1.5,0,1.7,0,2.3,0c0.4,0,0.7,0.1,1.1,0.2c0.5,0.2,0.9,0.6,1.1,1.1c0.1,0.3,0.2,0.7,0.2,1.1
      c0,0.6,0,0.8,0,2.3s0,1.7,0,2.3c0,0.4-0.1,0.7-0.2,1c-0.2,0.5-0.6,0.9-1.1,1.1c-0.3,0.1-0.7,0.2-1.1,0.2c-0.6,0-0.8,0-2.3,0
      s-1.7,0-2.3,0c-0.4,0-0.7-0.1-1-0.2c-0.5-0.2-0.9-0.6-1.1-1.1c-0.1-0.3-0.2-0.7-0.2-1.1c0-0.6,0-0.8,0-2.3s0-1.7,0-2.3
      c0-0.4,0.1-0.7,0.2-1c0.2-0.5,0.6-0.9,1.1-1.1C9,7.4,9.3,7.3,9.7,7.3C10.3,7.3,10.5,7.3,12,7.3 M12,6.3c-1.6,0-1.8,0-2.4,0
      c-0.5,0-0.9,0.1-1.4,0.3C7.5,6.8,6.8,7.5,6.6,8.2C6.4,8.7,6.3,9.2,6.3,9.6c0,0.6,0,0.8,0,2.4s0,1.8,0,2.4c0,0.5,0.1,0.9,0.3,1.4
      c0.3,0.8,0.9,1.4,1.7,1.7c0.4,0.2,0.9,0.3,1.4,0.3c0.6,0,0.8,0,2.4,0s1.8,0,2.4,0c0.5,0,0.9-0.1,1.4-0.3c0.8-0.3,1.4-0.9,1.7-1.7
      c0.2-0.4,0.3-0.9,0.3-1.4c0-0.6,0-0.8,0-2.4s0-1.8,0-2.4c0-0.5-0.1-0.9-0.3-1.4c-0.3-0.8-0.9-1.4-1.7-1.7c-0.4-0.2-0.9-0.3-1.4-0.3
      C13.7,6.3,13.5,6.3,12,6.3L12,6.3z M12,9.1c-1.6,0-3,1.3-3,3c0,1.6,1.3,3,3,3c1.6,0,3-1.3,3-3c0,0,0,0,0,0C15,10.4,13.6,9.1,12,9.1
      C12,9.1,12,9.1,12,9.1L12,9.1z M12,13.9c-1.1,0-1.9-0.9-1.9-1.9s0.9-1.9,1.9-1.9c1.1,0,1.9,0.8,1.9,1.9C13.9,13.1,13.1,13.9,12,13.9
      C12,13.9,12,13.9,12,13.9L12,13.9z M15.8,8.9c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7c0,0,0,0,0,0
      C15.4,8.2,15.7,8.5,15.8,8.9C15.7,8.9,15.7,8.9,15.8,8.9z"
    />
  </SvgIcon>
);

export const LinkedInLogo: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      d="M12,24C5.4,24,0,18.6,0,12S5.4,0,12,0s12,5.4,12,12S18.6,24,12,24z M12,1C5.9,1,1,5.9,1,12s4.9,11,11,11
	    s11-4.9,11-11C23,5.9,18.1,1,12,1z"
    />
    <path
      d="M6.9,10.1H9v7.3H6.9V10.1z M7.9,9.2L7.9,9.2C7.2,9.2,6.7,8.7,6.6,8c0,0,0-0.1,0-0.1c0-0.7,0.5-1.2,1.2-1.3
      c0,0,0.1,0,0.1,0c0.7,0,1.2,0.5,1.3,1.2c0,0,0,0.1,0,0.1c0,0.7-0.5,1.2-1.2,1.3C8,9.2,7.9,9.2,7.9,9.2L7.9,9.2z M17.4,17.4H15v-3.8
      c0-1-0.4-1.7-1.2-1.7c-0.5,0-1,0.4-1.1,0.9c-0.1,0.2-0.1,0.4-0.1,0.6v3.9h-2.4c0,0,0-6.7,0-7.3h2.4v1.1c0.1-0.5,0.9-1.2,2.1-1.2
      c1.5,0,2.7,1.1,2.7,3.3V17.4z"
    />
  </SvgIcon>
);

export const FacebookLogo: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      d="M12,24C5.4,24,0,18.6,0,12S5.4,0,12,0s12,5.4,12,12S18.6,24,12,24z M12,1C5.9,1,1,5.9,1,12s4.9,11,11,11
      s11-4.9,11-11C23,5.9,18.1,1,12,1z"
    />
    <path
      d="M12.7,18.8h-2.6v-6H8.9v-2.1h1.3V9.5c0-1.7,0.7-2.7,2.8-2.7h1.7v2.1h-1.1c-0.8,0-0.8,0.3-0.8,0.8v1h1.9
      l-0.2,2.1h-1.7V18.8L12.7,18.8z"
    />
  </SvgIcon>
);

export const TwitterLogo: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      d="M12,24C5.4,24,0,18.6,0,12S5.4,0,12,0s12,5.4,12,12S18.6,24,12,24z M12,1C5.9,1,1,5.9,1,12s4.9,11,11,11
	    s11-4.9,11-11C23,5.9,18.1,1,12,1z"
    />
    <path
      d="M16.4,8.6c0.5-0.3,0.9-0.8,1-1.4c-0.5,0.3-1,0.5-1.5,0.6c-0.4-0.5-1.1-0.8-1.7-0.8c-1.3,0-2.4,1.2-2.4,2.5
      c0,0.2,0,0.4,0.1,0.6C10,10,8.2,9,7,7.5C6.8,7.9,6.7,8.3,6.7,8.8c0,0.8,0.4,1.6,1.1,2.1c-0.4,0-0.7-0.1-1.1-0.3v0
      c0,1.2,0.8,2.2,1.9,2.4c-0.2,0.1-0.4,0.1-0.6,0.1c-0.1,0-0.3,0-0.4,0c0.3,1,1.2,1.7,2.2,1.7c-0.8,0.7-1.9,1.1-2.9,1.1
      c-0.2,0-0.4,0-0.6,0c1.1,0.7,2.3,1.1,3.6,1.1c4.4,0,6.8-3.8,6.8-7.1c0-0.1,0-0.2,0-0.3c0.5-0.4,0.9-0.8,1.2-1.3
      C17.4,8.4,16.9,8.6,16.4,8.6z"
    />
  </SvgIcon>
);

export const SensorDoor: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <rect fill="none" height="24" width="24" />
    <path d="M18,2H6C4.9,2,4,2.9,4,4v18h16V4C20,2.9,19.1,2,18,2z M15.5,13.5c-0.83,0-1.5-0.67-1.5-1.5s0.67-1.5,1.5-1.5 S17,11.17,17,12S16.33,13.5,15.5,13.5z" />
  </SvgIcon>
);

export const EscalatorWarning: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <rect fill="none" height="24" width="24" />
    <path d="M6.5,2c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2S5.4,2,6.5,2z M15.5,9.5c0,0.83,0.67,1.5,1.5,1.5s1.5-0.67,1.5-1.5 S17.83,8,17,8S15.5,8.67,15.5,9.5z M18.5,12h-2.84c-0.58,0.01-1.14,0.32-1.45,0.86l-0.92,1.32L9.72,8C9.35,7.37,8.69,7.01,8.01,7H5 C3.9,7,3,7.9,3,9v6h1.5v7h5V11.61L12.03,16h2.2L15,14.9V22h4v-5h1v-3.5C20,12.68,19.33,12,18.5,12z" />
  </SvgIcon>
);

export const FiltersStar: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      d="M12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28z"
      fill="none"
    ></path>
    <path d="M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z"></path>
  </SvgIcon>
);

export const PlayIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      d="M12,24C5.4,24,0,18.6,0,12S5.4,0,12,0s12,5.4,12,12S18.6,24,12,24z M12,1.1C6,1.1,1.1,6,1.1,12S6,22.9,12,22.9
      c6,0,10.9-4.9,10.9-10.9C22.9,6,18,1.1,12,1.1z"
    />
    <path d="M8.6,6.8l8.9,5.2l-8.9,4.9V6.8z" />
  </SvgIcon>
);

export const QuestionQuote: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      d="M6.28,23.45a1.41,1.41,0,0,1-.57-.11A1.49,1.49,0,0,1,4.78,22V20.36a1,1,0,0,0-1-1A3.75,3.75,0,0,1,0,15.58V4.33A3.75,3.75,0,0,1,3.75.58h16.5A3.75,3.75,0,0,1,24,4.33v6.56a.94.94,0,0,1-1.88,0V4.33a1.88,1.88,0,0,0-1.87-1.88H3.75A1.88,1.88,0,0,0,1.87,4.33V15.58a1.88,1.88,0,0,0,1.88,1.87,2.92,2.92,0,0,1,2.91,2.91v.69l2-2a5.23,5.23,0,0,1,3.75-1.56h7.8a1.87,1.87,0,0,0,1.87-1.87.94.94,0,0,1,1.88,0,3.76,3.76,0,0,1-3.75,3.75h-7.8a3.4,3.4,0,0,0-2.43,1L7.34,23a1.47,1.47,0,0,1-1.06.44Zm6.66-8.76a.94.94,0,1,0-.94.93.94.94,0,0,0,.94-.93Zm0-3.28v-.66a.14.14,0,0,1,.07-.12A3.19,3.19,0,1,0,9.79,5.31a3.17,3.17,0,0,0-1,2.3.94.94,0,0,0,1.88,0,1.31,1.31,0,0,1,.4-1,1.27,1.27,0,0,1,1-.36,1.31,1.31,0,0,1,.37,2.55,2,2,0,0,0-1.36,1.9v.66a.94.94,0,0,0,1.88,0Zm0,0"
      transform="translate(0 -0.58)"
    />
  </SvgIcon>
);

export const Calculate: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <g>
      <rect fill="none" height="24" width="24" />
    </g>
    <g>
      <g>
        <path d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z" />
        <rect height="1.5" width="5" x="6.25" y="7.72" />
        <rect height="1.5" width="5" x="13" y="15.75" />
        <rect height="1.5" width="5" x="13" y="13.25" />
        <polygon points="8,18 9.5,18 9.5,16 11.5,16 11.5,14.5 9.5,14.5 9.5,12.5 8,12.5 8,14.5 6,14.5 6,16 8,16" />
        <polygon points="14.09,10.95 15.5,9.54 16.91,10.95 17.97,9.89 16.56,8.47 17.97,7.06 16.91,6 15.5,7.41 14.09,6 13.03,7.06 14.44,8.47 13.03,9.89" />
      </g>
    </g>
  </SvgIcon>
);

export const Tour: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M21 4H7V2H5v20h2v-8h14l-2-5 2-5zm-6 5c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2z" />
  </SvgIcon>
);

export const SupportAgent: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <g>
      <rect fill="none" height="24" width="24" />
    </g>
    <g>
      <g>
        <path d="M21,12.22C21,6.73,16.74,3,12,3c-4.69,0-9,3.65-9,9.28C2.4,12.62,2,13.26,2,14v2c0,1.1,0.9,2,2,2h1v-6.1 c0-3.87,3.13-7,7-7s7,3.13,7,7V19h-8v2h8c1.1,0,2-0.9,2-2v-1.22c0.59-0.31,1-0.92,1-1.64v-2.3C22,13.14,21.59,12.53,21,12.22z" />
        <circle cx="9" cy="13" r="1" />
        <circle cx="15" cy="13" r="1" />
        <path d="M18,11.03C17.52,8.18,15.04,6,12.05,6c-3.03,0-6.29,2.51-6.03,6.45c2.47-1.01,4.33-3.21,4.86-5.89 C12.19,9.19,14.88,11,18,11.03z" />
      </g>
    </g>
  </SvgIcon>
);

export const StarRateOutlined: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <g>
      <rect fill="none" height="24" width="24" x="0" />
      <path d="M12,8.89L12.94,12h2.82l-2.27,1.62l0.93,3.01L12,14.79l-2.42,1.84l0.93-3.01L8.24,12h2.82L12,8.89 M12,2l-2.42,8H2 l6.17,4.41L5.83,22L12,17.31L18.18,22l-2.35-7.59L22,10h-7.58L12,2L12,2z" />
    </g>
  </SvgIcon>
);

type NewDesignIcon =
  | "Camera"
  | "Panorama"
  | "PersonFolder"
  | "Megaphone"
  | "CheckRounded"
  | "CloseRounded"
  | "Tuition"
  | "Meal"
  | "Checklist"
  | "Ring"
  | "Key"
  | "Vr"
  | "VrPremium"
  | "Edit"
  | "Share"
  | "StreetView"
  | "Heart"
  | "Direction"
  | "MapPin"
  | "MapPinPink"
  | "MapPinGreen"
  | "MapPinBlue"
  | "MapPinBlack"
  | "TierBadge"
  | "Floorplan"
  | "Brochure";

export const NewDesignIcons: {
  [key in NewDesignIcon]: React.FC<SvgIconProps>;
} = {
  Camera: (props) => (
    <SvgIcon {...props}>
      <path
        d="M21.7,6.1l-5.1,2.6V6.5c0-1.6-1.3-2.8-2.8-2.8c0,0,0,0,0,0h-11C1.2,3.7,0,5,0,6.5v11
			c0,1.6,1.3,2.8,2.8,2.8h11c1.6,0,2.8-1.3,2.8-2.8v-2.1l5.1,2.6c0.8,0.4,1.7,0,2.1-0.7c0.1-0.2,0.1-0.4,0.2-0.7V7.5
			c0-0.9-0.7-1.6-1.6-1.6C22.2,5.9,22,6,21.7,6.1z M14.7,17.5c0,0.5-0.4,0.9-0.9,0.9h-11c-0.5,0-0.9-0.4-0.9-0.9v-11
			c0-0.5,0.4-0.9,0.9-0.9c0,0,0,0,0,0h11c0.5,0,0.9,0.4,0.9,0.9c0,0,0,0,0,0V17.5z M22.1,16.1l-5.6-2.8v-2.5L22.1,8L22.1,16.1z"
      />
    </SvgIcon>
  ),
  Panorama: (props) => (
    <SvgIcon {...props}>
      <path
        d="M20.1,10.1l-0.4,1.3c2.1,0.7,2.9,1.4,2.9,1.9s-0.8,1.2-2.9,1.9c-1.3,0.4-2.7,0.7-4.1,0.8l0.1,1.4
				c1.5-0.1,2.9-0.4,4.4-0.9c2.5-0.8,3.9-1.9,3.9-3.2S22.7,10.9,20.1,10.1z"
      />
      <path
        d="M10.1,15.3l1,1c-2.3,0-4.6-0.4-6.8-1c-2.2-0.7-2.9-1.5-2.9-1.9s0.8-1.2,2.9-1.9l-0.4-1.3
				C1.3,10.9,0,12,0,13.4s1.3,2.4,3.9,3.2c2.3,0.7,4.8,1,7.2,1l-1,1l1,1l2.6-2.7l-2.7-2.6L10.1,15.3z"
      />
      <path
        d="M7.3,10.4c-0.4,0-0.7-0.2-0.7-0.6c0,0,0-0.1,0-0.1H5.5c0,0.9,0.7,1.7,1.7,1.7c0.1,0,0.2,0,0.3,0
				c0.8,0.1,1.6-0.5,1.7-1.3c0-0.1,0-0.1,0-0.2c0-0.6-0.3-1.1-0.9-1.3l0,0C8.7,8.4,9,7.9,9,7.4c0-0.8-0.6-1.4-1.7-1.4
				C6.4,5.8,5.6,6.5,5.5,7.4c0,0.1,0,0.1,0,0.2h1.3C6.7,7.2,6.9,7,7.2,7c0,0,0,0,0.1,0c0.3,0,0.5,0.2,0.5,0.5c0,0,0,0.1,0,0.1
				C7.8,8,7.4,8.1,7,8.1H6.8v1H7c0.7,0,0.9,0.2,0.9,0.7c0,0.3-0.2,0.6-0.5,0.6C7.4,10.4,7.4,10.4,7.3,10.4z"
      />
      <path
        d="M12.2,7.9c-0.4,0-0.9,0.2-1.1,0.5c0-1,0.2-1.5,0.8-1.5c0.3,0,0.6,0.2,0.6,0.5c0,0,0,0,0,0h1.2
				c0-0.9-0.8-1.6-1.7-1.5c0,0,0,0,0,0c-1.4,0-1.9,0.9-1.9,2.7c0,1.6,0.4,2.7,2,2.7c0.9,0.1,1.7-0.6,1.8-1.5c0-0.1,0-0.2,0-0.2
				c0.1-0.8-0.5-1.6-1.3-1.7C12.4,7.9,12.3,7.9,12.2,7.9z M11.9,10.4c-0.4,0-0.7-0.2-0.7-0.6c0,0,0-0.1,0-0.1c0-0.4,0.3-0.7,0.6-0.7
				c0,0,0.1,0,0.1,0c0.4,0,0.7,0.2,0.7,0.6c0,0,0,0.1,0,0.1c0,0.4-0.2,0.7-0.6,0.7C12,10.4,12,10.4,11.9,10.4z"
      />
      <path
        d="M18.5,8.6c0-1.6-0.5-2.7-2-2.7s-2,1.1-2,2.7s0.5,2.7,2,2.7S18.5,10.2,18.5,8.6z M15.7,8.6
				c0-0.8,0.1-1.5,0.8-1.5s0.8,0.7,0.8,1.5s-0.1,1.5-0.8,1.5S15.7,9.5,15.7,8.6z"
      />
      <path
        d="M20.8,7.2c0.8,0,1.4-0.6,1.4-1.4s-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4v0C19.4,6.5,20,7.2,20.8,7.2z
				 M20.8,5.3c0.3,0,0.5,0.2,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5c0,0,0,0,0,0C20.3,5.5,20.5,5.3,20.8,5.3z"
      />
    </SvgIcon>
  ),
  PersonFolder: (props) => (
    <SvgIcon {...props}>
      <path
        d="M19.8,5.1h-6.4c-0.7,0-1.3-0.3-1.7-0.7l-1-1c-0.8-0.8-1.9-1.3-3-1.3H4.2C1.9,2.1,0,4,0,6.4v11.3
				c0,2.3,1.9,4.2,4.2,4.2c0,0,0,0,0,0h15.5c2.3,0,4.2-1.9,4.2-4.2l0,0V9.3C24,7,22.1,5.1,19.8,5.1z M22.2,17.7
				c0,1.4-1.1,2.5-2.5,2.4H4.2c-1.4,0-2.5-1.1-2.5-2.5l0,0V6.4c0-1.4,1.1-2.5,2.5-2.5h3.4c0.7,0,1.3,0.3,1.7,0.7l1,1
				c0.8,0.8,1.9,1.2,3,1.2h6.4c1.4,0,2.5,1.1,2.5,2.5L22.2,17.7z"
      />
      <path
        d="M17.4,14.3c1.2-1.1,1.3-3,0.2-4.2c-1.1-1.2-3-1.3-4.2-0.2c-1.2,1.1-1.3,3-0.2,4.2c0.1,0.1,0.2,0.2,0.2,0.2
				c-1.5,0.8-2.5,2.3-2.4,4h1.8c0-1.5,1.2-2.7,2.7-2.7c1.5,0,2.7,1.2,2.7,2.7h1.8C19.8,16.6,18.9,15.1,17.4,14.3z M15.4,10.8
				c0.7,0,1.2,0.6,1.2,1.2s-0.6,1.2-1.2,1.2c-0.7,0-1.2-0.5-1.2-1.2C14.1,11.4,14.6,10.8,15.4,10.8C15.3,10.8,15.3,10.8,15.4,10.8z"
      />
    </SvgIcon>
  ),
  Megaphone: (props) => (
    <SvgIcon {...props}>
      <path
        d="M23.1,9.9h-2c-0.5-0.1-0.9,0.3-0.9,0.8c-0.1,0.5,0.3,0.9,0.8,0.9c0.1,0,0.1,0,0.2,0h2
				c0.5,0.1,0.9-0.3,0.9-0.8c0.1-0.5-0.3-0.9-0.8-0.9C23.3,9.9,23.2,9.9,23.1,9.9z"
      />
      <path
        d="M22.5,16l-1.6-1.2c-0.4-0.3-0.9-0.2-1.2,0.1c0,0,0,0,0,0c-0.3,0.4-0.2,0.9,0.1,1.2l1.6,1.2
				c0.1,0.1,0.3,0.2,0.5,0.2c0.3,0,0.5-0.1,0.7-0.3C22.9,16.8,22.8,16.3,22.5,16z"
      />
      <path
        d="M20.4,7c0.2,0,0.4-0.1,0.5-0.2l1.6-1.2c0.4-0.3,0.4-0.8,0.1-1.2C22.3,4,21.8,4,21.4,4.3l-1.6,1.2
				c-0.4,0.3-0.4,0.8-0.1,1.2C19.9,6.9,20.1,7,20.4,7z"
      />
      <path
        d="M3.8,7.3C1.7,7.3,0,9,0,11v1.2l0,0c0.1,2,1.8,3.5,3.8,3.5h2.1v4.9h1.7v-4.9h2.6l6.7,3.9V3.4l-6.7,3.9H3.8z
				 M15.2,6.3v10.5l-4.6-2.7H3.8c-1.2,0-2.1-1-2.1-2.1V11c0-1.2,1-2.1,2.1-2.1c0,0,0,0,0,0h6.8L15.2,6.3z"
      />
    </SvgIcon>
  ),
  CloseRounded: (props) => (
    <SvgIcon {...props}>
      <path d="M20.49,3.51a12,12,0,1,0,0,17A12,12,0,0,0,20.49,3.51ZM18.29,16,16,18.29l-4-4-4,4L5.71,16l4-4-4-4L8,5.71l4,4,4-4L18.29,8l-4,4Z" />
    </SvgIcon>
  ),
  CheckRounded: (props) => (
    <SvgIcon {...props}>
      <path d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0ZM10.21,18.48l-6-6,2.28-2.28,3.68,3.68,7.26-7.26,2.28,2.28Z" />
    </SvgIcon>
  ),
  Tuition: (props) => (
    <SvgIcon {...props}>
      <path
        d="M18.4,8.1V5l2.7-1.3c0.3-0.1,0.5-0.4,0.5-0.7c0-0.3-0.3-0.6-0.6-0.7L12.5,0c-0.1,0-0.3,0-0.4,0L4,2.3
				C3.6,2.3,3.3,2.6,3.3,3v2.7C2.9,5.7,2.5,6,2.5,6.4c0,0.4,0.3,0.8,0.8,0.8v1.1c0,0.4,0.3,0.8,0.7,0.8s0.8-0.3,0.8-0.7
				c0-0.1,0-0.1,0-0.2V7.2c0.4,0,0.8-0.3,0.8-0.8c0-0.4-0.3-0.8-0.8-0.8V4.2L6.3,5v3.1c-1.9,1.7-3,4.2-3,6.8c0,5,4.1,9.1,9.1,9.1
				s9.1-4.1,9.1-9.1C21.5,12.3,20.4,9.8,18.4,8.1L18.4,8.1z M7.8,5c2.9-1.1,6.2-1.1,9.1,0v2.2c-1.5-0.5-3-0.8-4.5-0.8
				c-1.5,0-3.1,0.2-4.5,0.8L7.8,5z M17.7,3.7c-1.7-0.7-3.5-1.1-5.3-1c-1.8,0-3.6,0.3-5.3,1L6.2,3.2l6.2-1.7l6.2,1.7L17.7,3.7z
				 M7.8,8.8c2.9-1.2,6.2-1.2,9.1,0c3.4,2.5,4,7.3,1.5,10.6s-7.3,4-10.6,1.5s-4-7.3-1.5-10.6C6.7,9.8,7.2,9.3,7.8,8.8z"
      />
      <path
        d="M12.8,14.6h-0.9c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1c0,0,0,0,0,0h1.4c0.3,0,0.6,0.2,0.6,0.6l0,0
				c0,0.4,0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7c0,0,0,0,0,0c0-1-0.8-1.9-1.9-1.9H13v-0.6c0-0.4-0.2-0.7-0.6-0.7c-0.4,0-0.7,0.2-0.7,0.6
				c0,0.1,0,0.1,0,0.2v0.6c-1.3,0.1-2.3,1.3-2.2,2.6c0.1,1.2,1.1,2.1,2.3,2.2h0.9c0.6-0.1,1.1,0.4,1.2,1c0.1,0.6-0.4,1.1-1,1.2
				c-0.1,0-0.1,0-0.2,0h-1.4c-0.3,0-0.6-0.2-0.6-0.6c0,0,0,0,0,0c0-0.4-0.3-0.7-0.7-0.7s-0.7,0.3-0.7,0.7c0,1.1,0.9,1.9,1.9,1.9h0.3
				v0.3c0,0.4,0.2,0.7,0.6,0.7c0.4,0,0.7-0.2,0.7-0.6c0-0.1,0-0.1,0-0.2v-0.3c1.3-0.1,2.3-1.2,2.3-2.5
				C15.2,15.5,14.1,14.5,12.8,14.6z"
      />
    </SvgIcon>
  ),
  Checklist: (props) => (
    <SvgIcon {...props}>
      <path
        d="M21.4,5.5c-0.1-1.3-1.1-2.4-2.5-2.4h-2.1V2.2c0-0.4-0.4-0.5-0.7-0.5h-1.6C14.2,0.6,13.1,0,12,0c-1.1,0-2.2,0.6-2.6,1.7
				H7.8c-0.3,0-0.7,0.2-0.7,0.5v0.9H5c-1.3,0-2.4,1.1-2.5,2.4v16.2C2.6,23.1,3.7,24.1,5,24h14c1.3,0.1,2.4-0.9,2.5-2.2L21.4,5.5z
				 M8.4,5.1V3h1.4c0.3,0,0.6-0.2,0.7-0.6c0.2-0.7,0.7-1.2,1.4-1.2c0.7,0,1.3,0.5,1.4,1.2C13.5,2.8,13.7,3,14.1,3h1.5v2.1H8.4z
				 M20.1,21.8c-0.1,0.6-0.6,1-1.2,0.9H5c-0.6,0.1-1.1-0.4-1.2-0.9V5.5C3.9,4.9,4.4,4.5,5,4.4h2.1v1.4c0,0.4,0.3,0.6,0.7,0.6h8.4
				c0.4,0,0.7-0.3,0.8-0.6V4.4H19c0.6,0,1.1,0.5,1.2,1.1V21.8z"
      />
      <path
        d="M9.9,12.7c-0.3-0.2-0.7-0.2-0.9,0l-1.8,1.6l-0.7-0.7c-0.3-0.2-0.7-0.2-0.9,0c-0.2,0.3-0.2,0.7,0,0.9l1.2,1.2
				c0.1,0.1,0.3,0.2,0.4,0.2l0,0c0.2,0,0.3-0.1,0.4-0.2l2.2-2.1C10.2,13.4,10.2,13,9.9,12.7C9.9,12.8,9.9,12.8,9.9,12.7L9.9,12.7z"
      />
      <path d="M18,13.9h-6.5c-0.4,0-0.7,0.3-0.7,0.6c0,0.4,0.3,0.7,0.7,0.7H18c0.4,0,0.7-0.3,0.7-0.7C18.6,14.2,18.3,13.9,18,13.9z" />
      <path
        d="M9.9,8.2C9.6,7.9,9.2,7.9,9,8.2L7.2,9.8L6.6,9.1c-0.3-0.3-0.7-0.3-0.9,0c0,0,0,0,0,0C5.4,9.3,5.3,9.7,5.6,10l1.2,1.2
				c0.1,0.1,0.3,0.2,0.4,0.2l0,0c0.2,0,0.3-0.1,0.4-0.2l2.2-2.1C10.1,8.9,10.1,8.5,9.9,8.2C9.8,8.2,9.8,8.2,9.9,8.2L9.9,8.2z"
      />
      <path
        d="M18,9.4h-6.5c-0.4,0-0.7,0.2-0.7,0.6c0,0.4,0.2,0.7,0.6,0.7c0.1,0,0.1,0,0.2,0H18c0.4,0,0.7-0.2,0.7-0.6
				c0-0.4-0.2-0.7-0.6-0.7C18.1,9.4,18,9.4,18,9.4z"
      />
      <path
        d="M9.9,17.2C9.6,17,9.2,17,9,17.2l-1.8,1.6l-0.7-0.7c-0.3-0.2-0.7-0.2-0.9,0c-0.2,0.3-0.2,0.7,0,1l1.2,1.2
				c0.1,0.1,0.3,0.2,0.4,0.2l0,0c0.2,0,0.3-0.1,0.4-0.2l2.2-2.1C10.1,18,10.2,17.6,9.9,17.2C9.9,17.3,9.9,17.3,9.9,17.2L9.9,17.2z"
      />
      <path d="M18,18.4h-6.5c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7H18c0.4,0,0.7-0.3,0.7-0.7C18.6,18.7,18.3,18.4,18,18.4z" />
    </SvgIcon>
  ),
  Ring: (props) => (
    <SvgIcon {...props}>
      <path
        d="M21.1,16.3h1v-1c0-5.2-3.9-9.5-9.1-10V4.6c0-0.6-0.4-1-1-1l0,0c-0.6,0-1,0.4-1,1v0.6
				c-5.1,0.5-9.1,4.8-9.1,10v1H21.1z M12,7.2c4.1,0,7.5,3.1,8,7.2H3.9C4.4,10.2,7.9,7.2,12,7.2z"
      />
      <polygon points="0.3,18.4 0,20.4 24,20.4 23.6,18.4 			" />
    </SvgIcon>
  ),
  Key: (props) => (
    <SvgIcon {...props}>
      <path
        d="M12,24c-3.8,0-6.9-3.1-6.9-7c0-2.7,1.6-5.1,4-6.3V0h5.9v1.9h3.1v5.8h-3.1v3c3.5,1.7,4.9,5.8,3.2,9.3
				C17,22.4,14.6,24,12,24z M10.8,1.8V12l-0.6,0.2c-2.7,1-4.1,3.9-3.1,6.6s3.9,4.1,6.6,3.1c2.7-1,4.1-3.9,3.1-6.6
				c-0.5-1.5-1.7-2.6-3.1-3.1L13.2,12V6h3.1V3.7h-3.1V1.8H10.8z"
      />
      <path
        d="M12,20.1c-1.7,0-3-1.4-3-3.1s1.4-3,3.1-3c1.7,0,3,1.4,3,3C15.1,18.7,13.7,20.1,12,20.1z M12,15.8
				c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3C13.3,16.3,12.7,15.8,12,15.8L12,15.8z"
      />
    </SvgIcon>
  ),
  Meal: (props) => (
    <SvgIcon {...props}>
      <path
        d="M16.4,0c-0.4-0.1-0.7,0.2-0.8,0.6c0,0,0,0.1,0,0.1v21.2c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1v-7.6
				l0,0l0,0c0-1.2,0.2-2.3,0.7-3.3c0.9-1.9,0.9-4.1,0.2-6C20.2,3.3,18.9,0.4,16.4,0L16.4,0z M19.4,5.4c0.6,1.6,0.6,3.4-0.2,5
				c-0.4,1-0.7,2.1-0.8,3.2H17V1.7C18.3,2.5,19.1,4.4,19.4,5.4L19.4,5.4z M18.5,21.9c0,0.4-0.3,0.7-0.7,0.7s-0.7-0.3-0.7-0.7
				c0,0,0,0,0,0V15h1.4V21.9z"
      />
      <path
        d="M9.1,0C8.7,0,8.4,0.3,8.4,0.7c0,0,0,0,0,0v4.1H7V0.7C7,0.3,6.6,0,6.2,0.1c-0.3,0-0.6,0.3-0.6,0.6v4.1H4.2
				V0.7C4.2,0.3,3.9,0,3.5,0c0,0,0,0,0,0C3.1,0,2.8,0.3,2.8,0.7c0,0,0,0,0,0v5.7c0,1.7,1.2,3.1,2.8,3.4v3.8H4.9
				c-0.4,0-0.7,0.3-0.7,0.7c0,0,0,0,0,0v7.6c-0.1,1.2,0.8,2.2,2,2.3s2.2-0.8,2.3-2c0-0.1,0-0.2,0-0.3v-7.6c0-0.4-0.3-0.7-0.7-0.7
				c0,0,0,0,0,0H7V9.8C8.6,9.5,9.8,8,9.8,6.4V0.7C9.8,0.3,9.5,0,9.1,0L9.1,0z M7,21.9c0,0.4-0.4,0.7-0.8,0.6c-0.3,0-0.6-0.3-0.6-0.6
				V15H7V21.9z M8.4,6.3c0,1.2-0.9,2.2-2.1,2.2c-1.2,0-2.1-1-2.1-2.2L8.4,6.3z"
      />
    </SvgIcon>
  ),
  Vr: (props) => (
    <SvgIcon {...props}>
      <path
        d="M10.8,16.2v1h-0.2H9.7c-1.8-0.1-3.6-0.3-5.4-0.8c-0.6-0.2-1.2-0.4-1.8-0.6c-0.6-0.2-1.1-0.6-1.5-1
				c-0.2-0.2-0.3-0.4-0.4-0.7c0-0.3,0-0.6,0.2-0.8c0.2-0.3,0.4-0.5,0.6-0.7c0.2-0.2,0.5-0.4,0.8-0.5c-0.7,0.2-1.3,0.6-1.8,1.1
				C0.2,13.4,0,13.8,0,14.2c0,0.4,0.1,0.8,0.4,1.1c0.4,0.6,1,1.1,1.6,1.4c0.6,0.3,1.2,0.6,1.9,0.9c1.3,0.4,2.6,0.8,4,0.9
				c0.6,0.1,1.2,0.1,1.7,0.2c0.3,0,0.6,0.1,0.9,0.1h0.2v1.1l2-1.2l1.3-0.7l-1.3-0.7L10.8,16.2z"
      />
      <path
        d="M18.2,5.2c-0.6-0.9-1.7-1.3-2.7-1L13,4.7c-0.7,0.1-1.3,0.1-2,0L8.5,4.2c-1-0.3-2.1,0.1-2.7,1L5.6,5.7h12.9
				L18.2,5.2z"
      />
      <path
        d="M20.6,11.7V8.9c0-1.3-1.1-2.3-2.4-2.3H5.7c-1.3,0-2.3,1-2.3,2.3v2.8C3.3,13,4.4,14,5.7,14h3.4
				c0.8,0,1.5-0.5,1.9-1.2c0.1-0.3,0.4-0.5,0.7-0.5h0.6c0.3,0,0.6,0.2,0.7,0.5c0.3,0.7,1,1.2,1.8,1.2h3.4C19.6,14,20.6,13,20.6,11.7
				L20.6,11.7z M12.7,8.1h-1.3c-0.2,0-0.3-0.2-0.3-0.4c0-0.2,0.1-0.3,0.3-0.3h1.3c0.2,0,0.3,0.2,0.3,0.4C12.9,7.9,12.8,8.1,12.7,8.1
				z"
      />
      <path
        d="M23.6,13.1c-0.5-0.5-1.1-0.8-1.7-1.1c0.3,0.2,0.5,0.4,0.8,0.6c0.2,0.2,0.4,0.5,0.6,0.7
				c0.1,0.2,0.2,0.5,0.2,0.8c0,0.3-0.2,0.5-0.4,0.7c-0.4,0.4-0.9,0.8-1.5,1c-0.6,0.2-1.2,0.4-1.8,0.6c-1.4,0.3-2.8,0.6-4.2,0.7v1.6
				c1.6-0.2,3.1-0.5,4.6-1c0.7-0.3,1.3-0.6,1.9-1c0.7-0.3,1.2-0.8,1.7-1.4c0.2-0.3,0.4-0.7,0.3-1.1C24,13.8,23.8,13.4,23.6,13.1z"
      />
      <text
        transform="matrix(1 0 0 1 9.9249 10.8204)"
        fontWeight="600"
        fontSize="2px"
        fill="#ffffff"
      >
        Enter
      </text>
    </SvgIcon>
  ),
  VrPremium: (props) => (
    <SvgIcon {...props}>
      <path
        fill="#F6CE0E"
        d="M10.8,16.2v1h-0.2H9.7c-1.8-0.1-3.6-0.3-5.4-0.8c-0.6-0.2-1.2-0.4-1.8-0.6c-0.6-0.2-1.1-0.6-1.5-1
				c-0.2-0.2-0.3-0.4-0.4-0.7c0-0.3,0-0.6,0.2-0.8c0.2-0.3,0.4-0.5,0.6-0.7c0.2-0.2,0.5-0.4,0.8-0.5c-0.7,0.2-1.3,0.6-1.8,1.1
				C0.2,13.4,0,13.8,0,14.2c0,0.4,0.1,0.8,0.4,1.1c0.4,0.6,1,1.1,1.6,1.4c0.6,0.3,1.2,0.6,1.9,0.9c1.3,0.4,2.6,0.8,4,0.9
				c0.6,0.1,1.2,0.1,1.7,0.2c0.3,0,0.6,0.1,0.9,0.1h0.2v1.1l2-1.2l1.3-0.7l-1.3-0.7L10.8,16.2z"
      />
      <path
        fill="#1B1B1E"
        d="M18.2,5.2c-0.6-0.9-1.7-1.3-2.7-1L13,4.7c-0.7,0.1-1.3,0.1-2,0L8.5,4.2c-1-0.3-2.1,0.1-2.7,1L5.6,5.7h12.9
				L18.2,5.2z"
      />
      <path
        fill="#1B1B1E"
        d="M20.6,11.7V8.9c0-1.3-1.1-2.3-2.4-2.3H5.7c-1.3,0-2.3,1-2.3,2.3v2.8C3.3,13,4.4,14,5.7,14h3.4
				c0.8,0,1.5-0.5,1.9-1.2c0.1-0.3,0.4-0.5,0.7-0.5h0.6c0.3,0,0.6,0.2,0.7,0.5c0.3,0.7,1,1.2,1.8,1.2h3.4C19.6,14,20.6,13,20.6,11.7
				L20.6,11.7z M12.7,8.1h-1.3c-0.2,0-0.3-0.2-0.3-0.4c0-0.2,0.1-0.3,0.3-0.3h1.3c0.2,0,0.3,0.2,0.3,0.4C12.9,7.9,12.8,8.1,12.7,8.1
				z"
      />
      <path
        fill="#F6CE0E"
        d="M23.6,13.1c-0.5-0.5-1.1-0.8-1.7-1.1c0.3,0.2,0.5,0.4,0.8,0.6c0.2,0.2,0.4,0.5,0.6,0.7
				c0.1,0.2,0.2,0.5,0.2,0.8c0,0.3-0.2,0.5-0.4,0.7c-0.4,0.4-0.9,0.8-1.5,1c-0.6,0.2-1.2,0.4-1.8,0.6c-1.4,0.3-2.8,0.6-4.2,0.7v1.6
				c1.6-0.2,3.1-0.5,4.6-1c0.7-0.3,1.3-0.6,1.9-1c0.7-0.3,1.2-0.8,1.7-1.4c0.2-0.3,0.4-0.7,0.3-1.1C24,13.8,23.8,13.4,23.6,13.1z"
      />
      <text
        transform="matrix(1 0 0 1 9.9249 10.8204)"
        fontWeight="600"
        fontSize="2px"
        fill="#ffffff"
      >
        Enter
      </text>
    </SvgIcon>
  ),
  Edit: (props) => (
    <SvgIcon {...props}>
      <path d="M19.26,0,16.64,2.62l4.74,4.74L24,4.74A6.72,6.72,0,0,0,19.26,0Z" />
      <polygon points="0 19.26 0 24 4.74 24 19.27 9.47 14.53 4.73 0 19.26" />
    </SvgIcon>
  ),
  Share: (props) => (
    <SvgIcon {...props}>
      <path
        d="M18.8,15.6c-0.9,0-1.9,0.3-2.6,0.9l-6.8-3.9c0.1-0.4,0.1-0.8,0-1.2l6.8-3.9
			C18,8.9,20.6,8.6,22,6.8c1.4-1.8,1.1-4.4-0.7-5.9c-1.8-1.4-4.4-1.1-5.9,0.7c-0.6,0.7-0.9,1.7-0.9,2.6c0,0.2,0,0.4,0.1,0.6L7.8,8.7
			C6,7.3,3.4,7.6,2,9.4s-1.1,4.4,0.7,5.9c1.5,1.2,3.7,1.2,5.2,0l6.8,3.9c0,0.2,0,0.4-0.1,0.6c0,2.3,1.9,4.2,4.2,4.2
			c2.3,0,4.2-1.9,4.2-4.2S21.1,15.6,18.8,15.6C18.8,15.6,18.8,15.6,18.8,15.6z"
      />
    </SvgIcon>
  ),
  Direction: (props) => (
    <SvgIcon {...props}>
      <path d="M23.64,11.15,12.84.35a1.19,1.19,0,0,0-1.69,0L.35,11.15a1.2,1.2,0,0,0,0,1.7l10.8,10.79h0a1.2,1.2,0,0,0,1.7,0l10.8-10.8a1.19,1.19,0,0,0,0-1.7ZM14.4,15V12H9.6v3.6H7.2V10.8A1.19,1.19,0,0,1,8.4,9.6h6v-3l4.2,4.2Z" />
    </SvgIcon>
  ),
  Heart: (props) => (
    <SvgIcon {...props}>
      <path
        d="M17.2,2.1L17.2,2.1c-2,0-3.9,0.9-5.2,2.4c-1.3-1.5-3.1-2.4-5.1-2.4H6.8C3,2.1,0,5.1,0,8.9c0,0,0,0,0,0
				c0,6.4,11.2,12.7,11.7,13c0.2,0.1,0.4,0.1,0.6,0c0.5-0.2,11.7-6.4,11.7-13C24,5.1,21,2.1,17.2,2.1z M12,19.3
				c-3.5-2.1-9.7-6.8-9.7-10.5c0-2.5,2-4.5,4.5-4.5c0,0,0,0,0,0l0,0C8.1,4.4,9.4,5,10.2,6L12,8.1l1.8-2.1c0.8-1,2.1-1.6,3.4-1.6l0,0
				c2.5,0,4.5,2,4.5,4.5C21.7,12.6,15.6,17.2,12,19.3z"
      />
    </SvgIcon>
  ),
  StreetView: (props) => (
    <SvgIcon {...props}>
      <path
        d="M11.9,5.6c1.6,0,2.8-1.3,2.8-2.8c0-1.6-1.3-2.8-2.8-2.8C10.3,0,9,1.3,9,2.8C9,4.4,10.3,5.6,11.9,5.6
				C11.9,5.6,11.9,5.6,11.9,5.6z"
      />
      <path
        d="M18.8,19c-0.5-0.1-1.1-0.2-1.7-0.3c-0.4,0-0.7,0.3-0.7,0.7c0,0.3,0.2,0.6,0.5,0.7c0.4,0.1,0.8,0.1,1.2,0.2
				c0.9,0.1,1.8,0.4,2.6,0.9c-0.8,0.5-1.7,0.8-2.6,0.9c-2,0.4-4.1,0.5-6.1,0.5c-2.1,0-4.1-0.1-6.1-0.5C5,22,4.1,21.7,3.3,21.2
				c0.8-0.5,1.7-0.8,2.6-0.9l1-0.2c0.4-0.1,0.6-0.4,0.6-0.8c-0.1-0.4-0.4-0.7-0.8-0.6c0,0,0,0,0,0c-0.5,0.1-1,0.2-1.4,0.3
				c-2.3,0.5-3.4,1.2-3.4,2.2s1.1,1.7,3.3,2.2C7.4,23.8,9.7,24,12,24c2.3,0,4.6-0.2,6.8-0.6c2.2-0.5,3.3-1.2,3.3-2.2
				S21,19.5,18.8,19z"
      />
      <path
        d="M8.8,14.3v4.9c0,1.2,0.9,2.1,2.1,2.1h2c1.2,0,2.1-0.9,2.1-2.1v-4.9c0.7-0.4,1.2-1.1,1.2-1.9V9.1
				C16.2,8,15.2,7,14.1,7c0,0,0,0-0.1,0H9.7C8.6,7,7.6,8,7.6,9.1c0,0,0,0,0,0v3.3C7.6,13.2,8.1,14,8.8,14.3z"
      />
    </SvgIcon>
  ),
  MapPin: (props) => (
    <SvgIcon {...props} viewBox="0 0 45.91 65.85">
      <path
        fill={props.fill}
        d="M23,0A23,23,0,0,0,2.77,33.89l0,.06,0,0L20.42,61.85l.85,1.33L23,65.85l1.69-2.67.84-1.33L43.07,34l0,0,0-.06A23,23,0,0,0,23,0"
      />
      <path
        fill="#ffffff"
        d="M8.61,22.46A14.35,14.35,0,1,1,23,36.81,14.35,14.35,0,0,1,8.61,22.46"
      />
      <path
        fill={props.fill}
        d="M42.91,23A20,20,0,1,0,6.48,34.21h0L23,60.24l16.48-26h0A19.89,19.89,0,0,0,42.91,23M23,34.88A11.92,11.92,0,1,1,34.88,23,11.92,11.92,0,0,1,23,34.88"
      />
      <path
        fill={props.fill}
        d="M13.82,19.89a.56.56,0,0,0,0,1.05l9,3.18a.58.58,0,0,0,.19,0,.57.57,0,0,0,.18,0l9-3.18a.56.56,0,0,0,0-1.05l-9-3.16a.59.59,0,0,0-.37,0Z"
      />
      <path
        fill={props.fill}
        d="M14.79,25.43a1.11,1.11,0,0,0-.57,1,1.12,1.12,0,0,0,.57,1v1.29a.56.56,0,1,0,1.11,0V27.37a1.11,1.11,0,0,0,.56-1,1.1,1.1,0,0,0-.56-1V22.86l-1.11-.4Z"
      />
      <path
        fill={props.fill}
        d="M23,25.27a1.78,1.78,0,0,1-.56-.1l-5-1.79v2.45c0,.6.62,1.11,1.85,1.51a13.19,13.19,0,0,0,7.47,0c1.23-.4,1.86-.91,1.86-1.51V23.38l-5,1.79a1.78,1.78,0,0,1-.56.1"
      />
    </SvgIcon>
  ),
  MapPinBlue: (props) => (
    <SvgIcon {...props} viewBox="0 0 45.91 65.85">
      <path
        fill="#0f1f69"
        d="M23,0A23,23,0,0,0,2.77,33.89l0,.06,0,0L20.42,61.85l.85,1.33L23,65.85l1.69-2.67.84-1.33L43.07,34l0,0,0-.06A23,23,0,0,0,23,0"
      />
      <path
        fill="#ffffff"
        d="M8.61,22.46A14.35,14.35,0,1,1,23,36.81,14.35,14.35,0,0,1,8.61,22.46"
      />
      <path
        fill="#0000ff"
        d="M42.91,23A20,20,0,1,0,6.48,34.21h0L23,60.24l16.48-26h0A19.89,19.89,0,0,0,42.91,23M23,34.88A11.92,11.92,0,1,1,34.88,23,11.92,11.92,0,0,1,23,34.88"
      />
      <path
        fill="#0000ff"
        d="M13.82,19.89a.56.56,0,0,0,0,1.05l9,3.18a.58.58,0,0,0,.19,0,.57.57,0,0,0,.18,0l9-3.18a.56.56,0,0,0,0-1.05l-9-3.16a.59.59,0,0,0-.37,0Z"
      />
      <path
        fill="#0000ff"
        d="M14.79,25.43a1.11,1.11,0,0,0-.57,1,1.12,1.12,0,0,0,.57,1v1.29a.56.56,0,1,0,1.11,0V27.37a1.11,1.11,0,0,0,.56-1,1.1,1.1,0,0,0-.56-1V22.86l-1.11-.4Z"
      />
      <path
        fill="#0000ff"
        d="M23,25.27a1.78,1.78,0,0,1-.56-.1l-5-1.79v2.45c0,.6.62,1.11,1.85,1.51a13.19,13.19,0,0,0,7.47,0c1.23-.4,1.86-.91,1.86-1.51V23.38l-5,1.79a1.78,1.78,0,0,1-.56.1"
      />
    </SvgIcon>
  ),
  MapPinPink: (props) => (
    <SvgIcon {...props} viewBox="0 0 45.91 65.85">
      <path
        fill="#a10856"
        d="M23,0A23,23,0,0,0,2.77,33.89l0,.06,0,0L20.42,61.85l.85,1.33L23,65.85l1.69-2.67.84-1.33L43.07,34l0,0,0-.06A23,23,0,0,0,23,0"
      />
      <path
        fill="#ffffff"
        d="M8.61,22.46A14.35,14.35,0,1,1,23,36.81,14.35,14.35,0,0,1,8.61,22.46"
      />
      <path
        fill="#ff0083"
        d="M42.91,23A20,20,0,1,0,6.48,34.21h0L23,60.24l16.48-26h0A19.89,19.89,0,0,0,42.91,23M23,34.88A11.92,11.92,0,1,1,34.88,23,11.92,11.92,0,0,1,23,34.88"
      />
      <path
        fill="#ff0083"
        d="M13.82,19.89a.56.56,0,0,0,0,1.05l9,3.18a.58.58,0,0,0,.19,0,.57.57,0,0,0,.18,0l9-3.18a.56.56,0,0,0,0-1.05l-9-3.16a.59.59,0,0,0-.37,0Z"
      />
      <path
        fill="#ff0083"
        d="M14.79,25.43a1.11,1.11,0,0,0-.57,1,1.12,1.12,0,0,0,.57,1v1.29a.56.56,0,1,0,1.11,0V27.37a1.11,1.11,0,0,0,.56-1,1.1,1.1,0,0,0-.56-1V22.86l-1.11-.4Z"
      />
      <path
        fill="#ff0083"
        d="M23,25.27a1.78,1.78,0,0,1-.56-.1l-5-1.79v2.45c0,.6.62,1.11,1.85,1.51a13.19,13.19,0,0,0,7.47,0c1.23-.4,1.86-.91,1.86-1.51V23.38l-5,1.79a1.78,1.78,0,0,1-.56.1"
      />
    </SvgIcon>
  ),
  MapPinGreen: (props) => (
    <SvgIcon {...props} viewBox="0 0 45.91 65.85">
      <path
        fill="#116D02"
        d="M23,0A23,23,0,0,0,2.77,33.89l0,.06,0,0L20.42,61.85l.85,1.33L23,65.85l1.69-2.67.84-1.33L43.07,34l0,0,0-.06A23,23,0,0,0,23,0"
      />
      <path
        fill="#ffffff"
        d="M8.61,22.46A14.35,14.35,0,1,1,23,36.81,14.35,14.35,0,0,1,8.61,22.46"
      />
      <path
        fill="#1FDA53"
        d="M42.91,23A20,20,0,1,0,6.48,34.21h0L23,60.24l16.48-26h0A19.89,19.89,0,0,0,42.91,23M23,34.88A11.92,11.92,0,1,1,34.88,23,11.92,11.92,0,0,1,23,34.88"
      />
      <path
        fill="#1FDA53"
        d="M13.82,19.89a.56.56,0,0,0,0,1.05l9,3.18a.58.58,0,0,0,.19,0,.57.57,0,0,0,.18,0l9-3.18a.56.56,0,0,0,0-1.05l-9-3.16a.59.59,0,0,0-.37,0Z"
      />
      <path
        fill="#1FDA53"
        d="M14.79,25.43a1.11,1.11,0,0,0-.57,1,1.12,1.12,0,0,0,.57,1v1.29a.56.56,0,1,0,1.11,0V27.37a1.11,1.11,0,0,0,.56-1,1.1,1.1,0,0,0-.56-1V22.86l-1.11-.4Z"
      />
      <path
        fill="#1FDA53"
        d="M23,25.27a1.78,1.78,0,0,1-.56-.1l-5-1.79v2.45c0,.6.62,1.11,1.85,1.51a13.19,13.19,0,0,0,7.47,0c1.23-.4,1.86-.91,1.86-1.51V23.38l-5,1.79a1.78,1.78,0,0,1-.56.1"
      />
    </SvgIcon>
  ),
  TierBadge: (props) => (
    <SvgIcon {...props}>
      <path
        d="M3.8,15.4L1.6,20c-0.1,0.2-0.1,0.5,0,0.7C1.7,20.9,2,21,2.2,21h3.4l2.1,2.7C7.8,23.9,8,24,8.2,24
				c0.3,0,0.5-0.2,0.6-0.4l2.1-4.3C8.2,19.1,5.6,17.7,3.8,15.4z"
      />
      <path
        d="M22.4,20l-2.3-4.5c-1.8,2.2-4.3,3.6-7.2,3.9l2.1,4.3c0.1,0.2,0.4,0.4,0.6,0.4c0.2,0,0.4-0.1,0.6-0.3l2-2.8
				h3.4c0.2,0,0.5-0.1,0.6-0.3C22.5,20.4,22.5,20.2,22.4,20L22.4,20z"
      />
      <path
        d="M12,0C7,0,3,4,3,9s4,9,9,9s9-4,9-9S17,0,12,0z M17.2,7.5l-0.8,4.8c0,0.3-0.3,0.5-0.6,0.5H8.1
				c-0.3,0-0.5-0.2-0.6-0.5L6.8,7.5C6.7,7.3,6.8,7.1,7,7c0.2-0.1,0.4-0.2,0.6-0.1L9.7,8l1.8-3.2c0.2-0.3,0.5-0.4,0.8-0.2
				c0.1,0,0.1,0.1,0.2,0.2L14.3,8l2.2-1.1C16.6,6.8,16.8,6.9,17,7C17.2,7.1,17.3,7.3,17.2,7.5L17.2,7.5z"
      />
    </SvgIcon>
  ),
  MapPinBlack: (props) => (
    <SvgIcon {...props} viewBox="0 0 45.91 65.85">
      <path
        fill="#000000"
        d="M23,0A23,23,0,0,0,2.77,33.89l0,.06,0,0L20.42,61.85l.85,1.33L23,65.85l1.69-2.67.84-1.33L43.07,34l0,0,0-.06A23,23,0,0,0,23,0"
      />
      <path
        fill="#ffffff"
        d="M8.61,22.46A14.35,14.35,0,1,1,23,36.81,14.35,14.35,0,0,1,8.61,22.46"
      />
      <path
        fill="#2e2c2c"
        d="M42.91,23A20,20,0,1,0,6.48,34.21h0L23,60.24l16.48-26h0A19.89,19.89,0,0,0,42.91,23M23,34.88A11.92,11.92,0,1,1,34.88,23,11.92,11.92,0,0,1,23,34.88"
      />
      <path
        fill="#2e2c2c"
        d="M13.82,19.89a.56.56,0,0,0,0,1.05l9,3.18a.58.58,0,0,0,.19,0,.57.57,0,0,0,.18,0l9-3.18a.56.56,0,0,0,0-1.05l-9-3.16a.59.59,0,0,0-.37,0Z"
      />
      <path
        fill="#2e2c2c"
        d="M14.79,25.43a1.11,1.11,0,0,0-.57,1,1.12,1.12,0,0,0,.57,1v1.29a.56.56,0,1,0,1.11,0V27.37a1.11,1.11,0,0,0,.56-1,1.1,1.1,0,0,0-.56-1V22.86l-1.11-.4Z"
      />
      <path
        fill="#2e2c2c"
        d="M23,25.27a1.78,1.78,0,0,1-.56-.1l-5-1.79v2.45c0,.6.62,1.11,1.85,1.51a13.19,13.19,0,0,0,7.47,0c1.23-.4,1.86-.91,1.86-1.51V23.38l-5,1.79a1.78,1.78,0,0,1-.56.1"
      />
    </SvgIcon>
  ),
  Floorplan: (props) => (
    <SvgIcon {...props}>
      <path
        d="M21.6,2.2h-2.4v2.1H4.8V2.2H2.4C1.1,2.2,0,3.3,0,4.6c0,0,0,0,0,0v14.8c0,1.3,1.1,2.4,2.4,2.4h19.2
				c1.3,0,2.4-1.1,2.4-2.4V4.6C24,3.3,22.9,2.2,21.6,2.2z M3.2,17H2.4c-0.3,0-0.6,0.1-0.8,0.1V4.6c0-0.5,0.4-0.8,0.8-0.8h0.8V17z
				 M21.6,18.6c0.5,0,0.8,0.4,0.8,0.8s-0.4,0.8-0.8,0.8c0,0,0,0,0,0H2.4c-0.5,0-0.8-0.4-0.8-0.8c0-0.5,0.4-0.8,0.8-0.8c0,0,0,0,0,0
				h2.4V5.8h14.4v12.7H21.6z M20.8,17V3.8h0.8c0.5,0,0.8,0.4,0.8,0.8v12.5C22.2,17,21.9,17,21.6,17H20.8z"
      />
      <path
        d="M12,8.4L6.4,13L6.4,13l1,1.2l0.9-0.7v3.8h7.6v-3.8l0.8,0.7l1-1.2L12,8.4L12,8.4z M11.2,13.9v1.8H9.8v-3.5
				l2.2-1.8l2.2,1.8v3.5h-1.4v-1.8H11.2z"
      />
    </SvgIcon>
  ),
  Brochure: (props) => (
    <SvgIcon {...props}>
      <path
        d="M4.3,23.3C4.3,23.7,4.6,24,5,24c0,0,0,0,0,0H19c0.4,0,0.7-0.3,0.7-0.7l0,0V6.4c0-0.4-0.3-0.7-0.7-0.7h-2.1
				V3.6c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.1-0.4-0.2-0.6-0.1l-1.9,0.5V0.7c0-0.4-0.3-0.7-0.7-0.7c-0.1,0-0.3,0-0.4,0.1L4.6,5.8
				C4.4,5.9,4.3,6.1,4.3,6.4l0,0V23.3z M18.3,22.6H5.7V7.1h12.7V22.6z M15.5,4.5v1.2h-4.8L15.5,4.5z M12.7,2v1.7l-4,1L12.7,2z"
      />
      <path
        d="M7.9,19.8c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7c0,0,0,0,0,0h4.9c0.4,0,0.7-0.3,0.7-0.7
				c0-0.4-0.3-0.7-0.7-0.7H7.9z"
      />
      <path
        d="M11.1,14.9c-0.4,0-0.7,0.4-0.6,0.8c0,0.3,0.3,0.6,0.6,0.6h4.9c0.4,0,0.7-0.4,0.6-0.8c0-0.3-0.3-0.6-0.6-0.6
				H11.1z"
      />
      <path
        d="M7.9,17.3c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h8.1c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0
				c0-0.4-0.3-0.7-0.7-0.7c0,0,0,0,0,0L7.9,17.3z"
      />
      <path
        d="M7.3,12.6c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1V9.4c0-0.6-0.4-1-1-1H8.3c-0.6,0-1,0.4-1,1V12.6z M8.7,12.2
				V9.8h3.5v2.4H8.7z"
      />
    </SvgIcon>
  ),
};
