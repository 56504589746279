import { CSSObject } from "@emotion/react";
import {
  isInstagramUrl,
  isDropboxUrl,
  isFacebookUrl,
  isGoogleDriveUrl,
  isOneDriveUrl,
} from "@helpers/socialMedia";

export const getServiceSpecificData = (
  url?: string,
): {
  styles: CSSObject | null;
  text: string | null;
} => {
  let styles: CSSObject | null = null;
  let text: string | null = null;

  if (isInstagramUrl(url)) {
    styles = {
      background:
        "linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d)",
    };
    text = "Play instagram video";
  }
  if (isFacebookUrl(url)) {
    styles = {
      backgroundColor: "#4267B2",
    };
    text = "Play facebook video";
  }
  if (isGoogleDriveUrl(url)) {
    styles = {
      background:
        "linear-gradient(45deg, rgba(31,164,99,1) 25%, rgba(255,208,75,1) 25%, " +
        "rgba(255,208,75,1) 50%, rgba(70,136,244,1) 50%, rgba(61,110,201,1) 75%, rgba(70,136,244,1) 75%)",
    };
    text = "Play GoogleDrive video";
  }
  if (isDropboxUrl(url)) {
    styles = {
      backgroundColor: "#0D2481",
    };
    text = "Play Dropbox video";
  }
  if (isOneDriveUrl(url)) {
    styles = {
      backgroundColor: "#00A4EF",
    };
    text = "Play OneDrive video";
  }

  return { styles, text };
};
