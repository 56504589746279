import React from "react";
import MaterialListItemIcon, {
  ListItemIconProps,
} from "@material-ui/core/ListItemIcon";

import useStyles from "./styles.jss";

const ListItemIcon: React.FC<ListItemIconProps> = (props) => {
  const classes = useStyles();

  return <MaterialListItemIcon {...props} classes={classes} />;
};

export default ListItemIcon;
