/* global ProtoExtends */
import React from "react";
import MaterialDivider, {
  DividerProps as MaterialDividerProps,
} from "@material-ui/core/Divider";
import clsx from "clsx";

import useStyles from "./styles.jss";

type DividerProps = ProtoExtends<
  MaterialDividerProps,
  {
    withGap?: boolean;
  }
>;

export const Divider: React.FC<DividerProps> = ({ withGap, ...props }) => {
  const classes = useStyles();

  return (
    <MaterialDivider
      classes={{
        root: clsx(classes.root, {
          withGap,
        }),
      }}
      {...props}
    />
  );
};
