import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  barContainer: {
    height: 30,
    position: "relative",
  },

  barContent: (theme) => ({
    alignItems: "center",
    backgroundColor: theme.palette.text.primary,
    color: "white",
    display: "flex",
    height: 30,
    left: -24,
    paddingLeft: 24,
    position: "absolute",
    top: -16,
    width: "calc(100% + 48px)",
  }),

  footer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 30,

    "& .small-button": {
      width: 140,
    },
  },
});
