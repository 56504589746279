import keyBy from "lodash/keyBy";

import { Models } from "@services/api";

export type StudentBody = {
  id: Models.StudentBody;
  name: string;
};

export const STUDENT_BODIES: StudentBody[] = [
  {
    id: Models.StudentBody.Coed,
    name: "Coed",
  },
  {
    id: Models.StudentBody.Male,
    name: "All-male",
  },
  {
    id: Models.StudentBody.Female,
    name: "All-female",
  },
];

export const studentBodyMap = keyBy(STUDENT_BODIES, "id");
