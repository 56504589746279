import React from "react";

import ListItem from "@material-ui/core/ListItem";
import DoneOutlined from "@material-ui/icons/DoneOutlined";

import Typography from "@ui-kit/Typography";
import { FilterItemProps } from "./types";
import { useCss } from "../styles";

export const FilterItem: React.FC<FilterItemProps> = ({
  id,
  onClick,
  Icon,
  label,
  isActive,
}) => {
  const css = useCss();

  return (
    <ListItem
      data-test={`facility-filter-${id}`}
      onClick={onClick}
      disableGutters
    >
      <Icon css={css.icon} />
      <Typography>{label}</Typography>
      {isActive && <DoneOutlined css={css.filterSelected} />}
    </ListItem>
  );
};
