import { Models } from "@services/api";

export const getSchoolsBounds = (
  schools: Models.FacilityCard[],
): {
  bottom: number;
  left: number;
  right: number;
  top: number;
} | null => {
  if (!schools.length) {
    return null;
  }

  const first = schools[0];

  let minLat = first.address.location.lat;
  let maxLat = first.address.location.lat;
  let minLng = first.address.location.lon;
  let maxLng = first.address.location.lon;

  schools.forEach((s) => {
    if (s.address.location.lat > maxLat) {
      maxLat = s.address.location.lat;
    }

    if (s.address.location.lat < minLat) {
      minLat = s.address.location.lat;
    }

    if (s.address.location.lon > maxLng) {
      maxLng = s.address.location.lon;
    }

    if (s.address.location.lon < minLng) {
      minLng = s.address.location.lon;
    }
  });

  return {
    bottom: minLat,
    left: minLng,
    right: maxLng,
    top: maxLat,
  };
};
