import times from "lodash/times";

type Result = [number, number][];

export const getRadiusCoordinates = (
  center: { lat: number; lng: number },
  radius: number,
): Result => {
  const points = 64;
  const km = 1.60934 * radius;
  const result: Result = [];
  const distanceX = km / (111.32 * Math.cos((center.lat * Math.PI) / 180));
  const distanceY = km / 110.574;

  let theta;
  let x;
  let y;

  times(points, (i) => {
    theta = (i / points) * (2 * Math.PI);
    x = distanceX * Math.cos(theta);
    y = distanceY * Math.sin(theta);

    result.push([center.lng + x, center.lat + y]);
  });

  result.push(result[0]);

  return result;
};
