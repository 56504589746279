import keyBy from "lodash/keyBy";

import { Models } from "@services/api";

export type RequiredDocument = {
  id: Models.RequiredDocumentsID;
  name: string;
  order: number;
};

export const REQUIRED_DOCUMENT: RequiredDocument[] = [
  {
    id: Models.RequiredDocumentsID.EnrollmentApplication,
    name: "Enrollment application",
  },
  {
    id: Models.RequiredDocumentsID.EnrollmentAgreement,
    name: "Enrollment agreement",
  },
  {
    id: Models.RequiredDocumentsID.TuitionAgreement,
    name: "Tuition agreement",
  },
  {
    id: Models.RequiredDocumentsID.ChildPhotograph,
    name: "Child photograph",
  },
  {
    id: Models.RequiredDocumentsID.FamilyPhotograph,
    name: "Parent / family photograph",
  },
  {
    id: Models.RequiredDocumentsID.AuthorizedReleaseForm,
    name: "Authorized release form",
  },
  {
    id: Models.RequiredDocumentsID.ChildHealthRecord,
    name: "Child health record",
  },
  {
    id: Models.RequiredDocumentsID.CertificateImmunization,
    name: "Certificate of immunization",
  },
  {
    id: Models.RequiredDocumentsID.VisionHearingScreening,
    name: "Vision & hearing screening",
  },
  {
    id: Models.RequiredDocumentsID.AllergyAlertForm,
    name: "Allergy alert form",
  },
  {
    id: Models.RequiredDocumentsID.DietaryRestrictionLetter,
    name: "Dietary restriction letter",
  },
  {
    id: Models.RequiredDocumentsID.MedicationAdministrationForm,
    name: "Medication administration form",
  },
  {
    id: Models.RequiredDocumentsID.TransportationAgreement,
    name: "Transportation agreement",
  },
  {
    id: Models.RequiredDocumentsID.PhotoReleaseForm,
    name: "Photo release form",
  },
  {
    id: Models.RequiredDocumentsID.VideoMonitoringForm,
    name: "Video monitoring form",
  },
  {
    id: Models.RequiredDocumentsID.TechnologyPolicy,
    name: "Technology policy",
  },
  {
    id: Models.RequiredDocumentsID.MobileDevicePolicy,
    name: "Mobile device policy",
  },
  {
    id: Models.RequiredDocumentsID.ChildCustodAgreement,
    name: "Child custody agreement",
  },
].map((s, i) => ({ ...s, order: i }));

export const RequiredDocumentsMap = keyBy(REQUIRED_DOCUMENT, "id");
