import React, { useState, useEffect, useMemo, useRef } from "react";
import { useHistory, Switch, Route } from "react-router-dom";
import loadable from "@loadable/component";
import Helmet from "react-helmet";
import clsx from "clsx";
import { useTracking } from "react-tracking";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import HighlightsTab from "@components/DeepDivePanel/Highlights";
import PDO from "@components/DeepDivePanel/ResevationProgram";
import { createCss } from "./styles";
import META from "./meta";
import Reviews from "@pages/Reviews";
import RatingModal from "@pages/RatingModal";
import FacilityClaimShort from "@pages/FacilityClaimShort";
import EditFacility from "@components/Edit";
import MediaGallery from "@components/MediaGallery";
import ReservationFlow from "@components/WeekendCareFlow";
import EditFacilityOverviewDialog from "@pages/EditFacilityOverviewDialog";
import { useDeepDivePanelStore } from "@hooks";
import { ReactGAEventWait } from "@helpers/ga";
import { DeepDivePanelProps } from "./types";
import { retry } from "@utils";

const EditFacilityInfoForm = loadable(() =>
  retry(() => import("../../components/EditFacilityInfoForm")),
);
const EditDocumentsPage = loadable(() =>
  retry(() => import("../../pages/DeepDivePanel/EditDocuments")),
);
const EditMealsAndNutrition = loadable(() =>
  retry(() => import("../../pages/DeepDivePanel/EditMealsAndNutrition")),
);
const ShareModal = loadable(() => retry(() => import("../ShareModal")));
const ServiceInquiryPage = loadable(() =>
  retry(() => import("../../pages/ServiceInquiry")),
);
const ServiceInquiryResponsePage = loadable(() =>
  retry(() => import("../../pages/ServiceInquiryResponse")),
);
const BulletinBoardPage = loadable(() =>
  retry(() => import("../../pages/EditFacilityBulletinBoard")),
);
const BulletinBoardView = loadable(() =>
  retry(() => import("../../pages/DeepDivePanel/BulletinBoardView")),
);
const VirtualSchoolTour = loadable(() =>
  retry(() => import("../../pages/DeepDivePanel/VirtualSchoolTour")),
);
const EditVirtualTour = loadable(() =>
  retry(() => import("../../pages/DeepDivePanel/EditVirtualTour")),
);
const MealsAndNutrition = loadable(() =>
  retry(() => import("../../pages/DeepDivePanel/MealsAndNutrition")),
);
const ProgramsAndServices = loadable(() =>
  retry(() => import("../../pages/DeepDivePanel/ProgramsAndServices")),
);
const Tuition = loadable(() =>
  retry(() => import("../../pages/DeepDivePanel/Tuition")),
);
const FeaturesAndAmenities = loadable(() =>
  retry(() => import("../../pages/DeepDivePanel/FeaturesAndAmenities")),
);
const ChecklistsAndDocuments = loadable(() =>
  retry(() => import("../../pages/DeepDivePanel/ChecklistsAndDocuments")),
);
const EditFeaturesAndAmenities = loadable(() =>
  retry(() => import("../../pages/DeepDivePanel/EditFeaturesAndAmenities")),
);
const EditProgramsAndServices = loadable(() =>
  retry(() => import("../../pages/DeepDivePanel/EditProgramsAndServices")),
);
const PhotoGallery = loadable(() =>
  retry(() => import("../../pages/DeepDivePanel/PhotoGallery")),
);
const EditOperationalDetails = loadable(() =>
  retry(() => import("../../pages/DeepDivePanel/EditOperationalDetails")),
);
const EditTuitionAndFees = loadable(() =>
  retry(() => import("../../pages/DeepDivePanel/EditTuitionAndFees")),
);
const AboutTierDialog = loadable(() =>
  retry(() => import("../../pages/DeepDivePanel/AboutTierDialog")),
);
const TourInquiryPage = loadable(() =>
  retry(() => import("../../pages/DeepDivePanel/TourInquiry")),
);
const ConnectParentDialog = loadable(() =>
  retry(() => import("../../pages/DeepDivePanel/ConnectParent")),
);
const ProfileRequired = loadable(() =>
  retry(() => import("../../pages/FacilityClaim")),
);

export const DeepDivePanelInner: React.FC<DeepDivePanelProps> = ({
  open,
  facility,
  ...props
}) => {
  const { trackEvent } = useTracking();
  const history = useHistory();
  const [panelCollapsed, setPanelCollapsed] = useState(false);
  const [, { setFacility }] = useDeepDivePanelStore();
  const ddpBodyRef = useRef<HTMLDivElement>(null);
  const css = useMemo(
    () =>
      createCss({
        tier: facility.subscriptionTier,
      }),
    [facility.subscriptionTier],
  );

  useEffect(() => {
    ReactGAEventWait({
      action: "Opened",
      category: "DDP",
      label: `${facility.id}`,
    });
  }, []);

  useEffect(() => {
    setFacility(facility);
  }, [facility]);

  useEffect(() => {
    trackEvent({
      action: "DDP Opened",
      facilityId: facility.id,
    });
    setPanelCollapsed(false);
    ddpBodyRef.current?.scrollTo?.(0, 0);
  }, [facility.id]);

  function renderRoutes() {
    return (
      <Switch>
        <Route
          path="/map/:facilityId/edit/operating-details"
          component={EditOperationalDetails}
        />
        <Route
          path="/map/:facilityId/edit/tuition-and-fees"
          component={EditTuitionAndFees}
        />
        <Route
          path="/map/:facilityId/edit/features-and-amenities"
          component={EditFeaturesAndAmenities}
        />
        <Route
          path="/map/:facilityId/edit/documents"
          component={EditDocumentsPage}
        />
        <Route
          path="/map/:facilityId/edit/virtual-tour"
          component={EditVirtualTour}
        />
        <Route
          path="/map/:facilityId/virtual-tour"
          component={VirtualSchoolTour}
        />
        <Route exact path="/map/:facilityId/edit" component={EditFacility} />
        <Route
          path="/map/:facilityId/edit/contact-info"
          component={EditFacilityInfoForm}
        />
        <Route path="/map/:facilityId/edit/photos" component={MediaGallery} />
        <Route path="/map/:facilityId/reviews" component={Reviews} />
        <Route path="/map/:facilityId/rating" component={RatingModal} />
        <Route path="/map/:facilityId/claim" component={FacilityClaimShort} />
        <Route
          path="/map/:facilityId/missing-data"
          component={ProfileRequired}
        />
        <Route exact path="/map/:facilityId/PDO" component={PDO} />
        <Route
          path="/map/:facilityId/meals-and-nutrition"
          component={MealsAndNutrition}
        />
        <Route
          path="/map/:facilityId/edit/overview"
          component={EditFacilityOverviewDialog}
        />
        <Route
          path="/map/:facilityId/edit/bulletin-board"
          component={BulletinBoardPage}
        />
        <Route
          path="/map/:facilityId/edit/programs-and-services"
          component={EditProgramsAndServices}
        />
        <Route
          path="/map/:facilityId/edit/meals-and-nutrition"
          component={EditMealsAndNutrition}
        />
        <Route path="/map/:facilityId/share" component={ShareModal} />
        <Route
          exact
          path="/map/:facilityId/service-inquiry"
          component={ServiceInquiryPage}
        />
        <Route
          path="/map/:facilityId/programs-and-services"
          component={ProgramsAndServices}
        />
        <Route
          path="/map/:facilityId/features-and-amenities"
          component={FeaturesAndAmenities}
        />
        <Route path="/map/:facilityId/photos" component={PhotoGallery} />
        <Route
          path="/map/:facilityId/checklists-and-documents"
          component={ChecklistsAndDocuments}
        />
        <Route path="/map/:facilityId/tuition" component={Tuition} />
        <Route
          path="/map/:facilityId/service-inquiry/:inquiryID/response"
          component={ServiceInquiryResponsePage}
        />
        <Route
          path="/map/:facilityId/PDO/reservation"
          component={ReservationFlow}
        />
        <Route path="/map/:facilityId/about-tier" component={AboutTierDialog} />
        <Route
          path="/map/:facilityId/connect"
          component={ConnectParentDialog}
        />
        <Route
          path="/map/:facilityId/tour-inquiry"
          component={TourInquiryPage}
        />
        <Route
          path="/map/:facilityId/bulletin-board"
          component={BulletinBoardView}
        />
        {/* <Redirect to={`/map/${facility.id}`} /> */}
      </Switch>
    );
  }

  return (
    <div
      css={css.root}
      data-test="deep-dive-panel"
      className={clsx({
        collapsed: panelCollapsed,
      })}
    >
      <Helmet {...META(facility)} />
      {renderRoutes()}

      <div
        css={css.ddpToggler}
        title={`${panelCollapsed ? "Expand" : "Collapse"} panel`}
        onClick={() => {
          setPanelCollapsed(!panelCollapsed);
        }}
      >
        <ArrowDropDownIcon
          className={clsx("arrow", {
            collapsed: panelCollapsed,
          })}
        />
      </div>

      <div
        data-test="ddp-body"
        css={css.body}
        className={clsx({
          open,
        })}
        ref={ddpBodyRef}
      >
        <HighlightsTab open={open} {...props} />
      </div>
    </div>
  );
};

export const MobilePanelInner: React.FC<DeepDivePanelProps> = ({
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [{ facility }] = useDeepDivePanelStore();
  const css = useMemo(
    () =>
      createCss({
        tier: facility.subscriptionTier,
      }),
    [facility.subscriptionTier],
  );

  return (
    <div css={[css.drawer, open && css.drawerOpen, !open && css.drawerClose]}>
      <DeepDivePanelInner
        {...props}
        onOpen={() => {
          setOpen(true);
          // TODO cli tsc doesn't recognize ????
          (document.body.style as any).overscrollBehavior = "contain";
        }}
        onClose={() => {
          setOpen(false);
          (document.body.style as any).overscrollBehavior = "";
        }}
        open={open}
      />
    </div>
  );
};
