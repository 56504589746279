import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes, TryToClaimDialogProps } from "./types";

export const createCss = (
  props: Partial<TryToClaimDialogProps>,
): Record<Classes, Interpolation<ITheme>> => ({
  root: (theme) => ({
    [theme.mediaRequests.notMobile]: {
      width: 400,
    },
  }),

  actions: {
    display: "flex",
    justifyContent: props.secondaryAction ? "space-between" : "center",
  },
});
