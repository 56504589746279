import isNil from "lodash/isNil";

import { Money } from "./Money";
import { Models } from "@services/api";

export class FacilityTuitionInfo {
  private _scheduleFile: Models.UploadedFile = { original: "" };
  private _costPeriod: Models.TuitionTypeID | null = null;
  private _costTo: Money | null = null;
  private _costFrom: Money | null = null;
  private _costDescription: string | null = null;
  private _discountsSiblings: boolean | null = null;
  private _discountsMilitary: boolean | null = null;
  private _discountsCorporate: boolean | null = null;
  private _discountsSiblingsValue: Models.DiscountID[] = [];
  private _discountsMilitaryValue: Models.DiscountID[] = [];
  private _discountsCorporateValue: Models.DiscountID[] = [];
  private _discountsDescription: string | null = null;
  private _feesApplication: Money | null = null;
  private _feesRegistration: Money | null = null;
  private _feesEnrollmentDeposit: Money | null = null;
  private _feesSupplies: Money | null = null;
  private _feesActivity: Money | null = null;
  private _feesEarlyDropOff: Money | null = null;
  private _feesLatePickUp: Money | null = null;
  private _feesDescription: string | null = null;
  private _waitlistProvided: Models.WaitlistProvided | null = null;
  private _waitlistFee: Money | null = null;
  private _waitlistDescription: string | null = null;
  private _withdrawalPolicyNoticePeriod: Models.WithdrawalPolicyNoticePeriod | null =
    null;
  private _withdrawalPolicyDescription: string | null = null;

  get feesDescription(): string | null {
    return this._feesDescription;
  }
  set feesDescription(v: string | null | undefined) {
    this._feesDescription = !isNil(v) ? v : null;
  }

  // @ts-ignore
  get feesApplication(): Money | null {
    return this._feesApplication;
  }

  set feesApplication(v: Money | number | string | null | undefined) {
    if (v) {
      if (v instanceof Money) {
        this._feesApplication = v;
      } else if (this._feesApplication) {
        this._feesApplication.value = v;
      } else {
        this._feesApplication = new Money(v);
      }
    } else {
      this._feesApplication = null;
    }
  }

  // @ts-ignore
  get feesRegistration(): Money | null {
    return this._feesRegistration;
  }

  set feesRegistration(v: Money | number | string | null | undefined) {
    if (v) {
      if (v instanceof Money) {
        this._feesRegistration = v;
      } else if (this._feesRegistration) {
        this._feesRegistration.value = v;
      } else {
        this._feesRegistration = new Money(v);
      }
    } else {
      this._feesRegistration = null;
    }
  }

  // @ts-ignore
  get feesEnrollmentDeposit(): Money | null {
    return this._feesEnrollmentDeposit;
  }

  set feesEnrollmentDeposit(v: Money | number | string | null | undefined) {
    if (v) {
      if (v instanceof Money) {
        this._feesEnrollmentDeposit = v;
      } else if (this._feesEnrollmentDeposit) {
        this._feesEnrollmentDeposit.value = v;
      } else {
        this._feesEnrollmentDeposit = new Money(v);
      }
    } else {
      this._feesEnrollmentDeposit = null;
    }
  }

  // @ts-ignore
  get feesSupplies(): Money | null {
    return this._feesSupplies;
  }

  set feesSupplies(v: Money | number | string | null | undefined) {
    if (v) {
      if (v instanceof Money) {
        this._feesSupplies = v;
      } else if (this._feesSupplies) {
        this._feesSupplies.value = v;
      } else {
        this._feesSupplies = new Money(v);
      }
    } else {
      this._feesSupplies = null;
    }
  }

  // @ts-ignore
  get feesActivity(): Money | null {
    return this._feesActivity;
  }

  set feesActivity(v: Money | number | string | null | undefined) {
    if (v) {
      if (v instanceof Money) {
        this._feesActivity = v;
      } else if (this._feesActivity) {
        this._feesActivity.value = v;
      } else {
        this._feesActivity = new Money(v);
      }
    } else {
      this._feesActivity = null;
    }
  }

  // @ts-ignore
  get feesEarlyDropOff(): Money | null {
    return this._feesEarlyDropOff;
  }

  set feesEarlyDropOff(v: Money | number | string | null | undefined) {
    if (v) {
      if (v instanceof Money) {
        this._feesEarlyDropOff = v;
      } else if (this._feesEarlyDropOff) {
        this._feesEarlyDropOff.value = v;
      } else {
        this._feesEarlyDropOff = new Money(v);
      }
    } else {
      this._feesEarlyDropOff = null;
    }
  }

  // @ts-ignore
  get feesLatePickUp(): Money | null {
    return this._feesLatePickUp;
  }

  set feesLatePickUp(v: Money | number | string | null | undefined) {
    if (v) {
      if (v instanceof Money) {
        this._feesLatePickUp = v;
      } else if (this._feesLatePickUp) {
        this._feesLatePickUp.value = v;
      } else {
        this._feesLatePickUp = new Money(v);
      }
    } else {
      this._feesLatePickUp = null;
    }
  }

  // @ts-ignore
  get waitlistFee(): Money | null {
    return this._waitlistFee;
  }

  set waitlistFee(v: Money | number | string | null | undefined) {
    if (v) {
      if (v instanceof Money) {
        this._waitlistFee = v;
      } else if (this._waitlistFee) {
        this._waitlistFee.value = v;
      } else {
        this._waitlistFee = new Money(v);
      }
    } else {
      this._waitlistFee = null;
    }
  }

  get waitlistProvided(): Models.WaitlistProvided | null {
    return this._waitlistProvided;
  }
  set waitlistProvided(v: Models.WaitlistProvided | null | undefined) {
    this._waitlistProvided = !isNil(v) ? v : null;
  }

  get waitlistDescription(): string | null {
    return this._waitlistDescription;
  }
  set waitlistDescription(v: string | null | undefined) {
    this._waitlistDescription = !isNil(v) ? v : null;
  }

  get discountsSiblingsValue(): Models.DiscountID[] {
    return this._discountsSiblingsValue;
  }
  set discountsSiblingsValue(v: Models.DiscountID[]) {
    this._discountsSiblingsValue = v;
  }

  get discountsMilitaryValue(): Models.DiscountID[] {
    return this._discountsMilitaryValue;
  }
  set discountsMilitaryValue(v: Models.DiscountID[]) {
    this._discountsMilitaryValue = v;
  }

  get discountsCorporateValue(): Models.DiscountID[] {
    return this._discountsCorporateValue;
  }
  set discountsCorporateValue(v: Models.DiscountID[]) {
    this._discountsCorporateValue = v;
  }

  get discountsSiblings(): boolean | null {
    return this._discountsSiblings;
  }
  set discountsSiblings(v: boolean | null | undefined) {
    this._discountsSiblings = !isNil(v) ? v : null;
  }

  get discountsMilitary(): boolean | null {
    return this._discountsMilitary;
  }
  set discountsMilitary(v: boolean | null | undefined) {
    this._discountsMilitary = !isNil(v) ? v : null;
  }

  get discountsCorporate(): boolean | null {
    return this._discountsCorporate;
  }
  set discountsCorporate(v: boolean | null | undefined) {
    this._discountsCorporate = !isNil(v) ? v : null;
  }

  get discountsDescription(): string | null {
    return this._discountsDescription;
  }
  set discountsDescription(v: string | null | undefined) {
    this._discountsDescription = !isNil(v) ? v : null;
  }

  get withdrawalPolicyNoticePeriod(): Models.WithdrawalPolicyNoticePeriod | null {
    return this._withdrawalPolicyNoticePeriod;
  }
  set withdrawalPolicyNoticePeriod(
    v: Models.WithdrawalPolicyNoticePeriod | null | undefined,
  ) {
    this._withdrawalPolicyNoticePeriod = !isNil(v) ? v : null;
  }

  get withdrawalPolicyDescription(): string | null {
    return this._withdrawalPolicyDescription;
  }
  set withdrawalPolicyDescription(v: string | null | undefined) {
    this._withdrawalPolicyDescription = !isNil(v) ? v : null;
  }

  get costPeriod(): Models.TuitionTypeID | null {
    return this._costPeriod;
  }
  set costPeriod(v: Models.TuitionTypeID | null | undefined) {
    if (v === Models.TuitionTypeID.Free) {
      this.costFrom = null;
      this.costTo = null;
    }

    this._costPeriod = !isNil(v) ? v : null;
  }

  get costDescription(): string | null {
    return this._costDescription;
  }
  set costDescription(v: string | null | undefined) {
    this._costDescription = !isNil(v) ? v : null;
  }

  // @ts-ignore
  get costFrom(): Money | null {
    return this._costFrom;
  }

  set costFrom(v: Money | number | string | null | undefined) {
    if (v) {
      if (v instanceof Money) {
        this._costFrom = v;
      } else if (this._costFrom) {
        this._costFrom.value = v;
      } else {
        this._costFrom = new Money(v);
      }
    } else {
      this._costFrom = null;
    }
  }

  // @ts-ignore
  get costTo(): Money | null {
    return this._costTo;
  }

  set costTo(v: Money | number | string | null | undefined) {
    if (v) {
      if (v instanceof Money) {
        this._costTo = v;
      } else if (this._costTo) {
        this._costTo.value = v;
      } else {
        this._costTo = new Money(v);
      }
    } else {
      this._costTo = null;
    }
  }

  get scheduleFile(): Models.UploadedFile {
    return this._scheduleFile;
  }
  set scheduleFile(v: Models.UploadedFile) {
    this._scheduleFile = v;
  }

  constructor() {}

  public toDto(): Models.FacilityTuitionInfo {
    return {
      feesApplication: this.feesApplication?.toDto(),
      feesRegistration: this.feesRegistration?.toDto(),
      feesEnrollmentDeposit: this.feesEnrollmentDeposit?.toDto(),
      feesSupplies: this.feesSupplies?.toDto(),
      feesActivity: this.feesActivity?.toDto(),
      feesEarlyDropOff: this.feesEarlyDropOff?.toDto(),
      feesLatePickUp: this.feesLatePickUp?.toDto(),
      feesDescription: this.feesDescription,
      discountsCorporate: this.discountsCorporate,
      discountsMilitary: this.discountsMilitary,
      discountsSiblings: this.discountsSiblings,
      discountsCorporateValue: this.discountsCorporateValue.slice(),
      discountsMilitaryValue: this.discountsMilitaryValue.slice(),
      discountsSiblingsValue: this.discountsSiblingsValue.slice(),
      discountsDescription: this.discountsDescription,
      scheduleFile: this.scheduleFile,
      waitlistFee: this.waitlistFee?.toDto(),
      waitlistProvided: this.waitlistProvided,
      waitlistDescription: this.waitlistDescription,
      withdrawalPolicyDescription: this.withdrawalPolicyDescription,
      withdrawalPolicyNoticePeriod: this.withdrawalPolicyNoticePeriod,
      costPeriod: this.costPeriod,
      costDescription: this.costDescription,
      costFrom: this.costFrom?.toDto(),
      costTo: this.costTo?.toDto(),
    };
  }

  static fromDto(dto: Models.FacilityTuitionInfo): FacilityTuitionInfo {
    const tuitionInfo = new FacilityTuitionInfo();

    tuitionInfo.feesApplication = Money.fromDto(dto.feesApplication);
    tuitionInfo.feesRegistration = Money.fromDto(dto.feesRegistration);
    tuitionInfo.feesEnrollmentDeposit = Money.fromDto(
      dto.feesEnrollmentDeposit,
    );
    tuitionInfo.feesSupplies = Money.fromDto(dto.feesSupplies);
    tuitionInfo.feesActivity = Money.fromDto(dto.feesActivity);
    tuitionInfo.feesEarlyDropOff = Money.fromDto(dto.feesEarlyDropOff);
    tuitionInfo.feesLatePickUp = Money.fromDto(dto.feesLatePickUp);
    tuitionInfo.waitlistFee = Money.fromDto(dto.waitlistFee);
    tuitionInfo.waitlistProvided = dto.waitlistProvided;
    tuitionInfo.waitlistDescription = dto.waitlistDescription;
    tuitionInfo.feesDescription = dto.feesDescription;
    tuitionInfo.discountsCorporate = dto.discountsCorporate;
    tuitionInfo.discountsMilitary = dto.discountsMilitary;
    tuitionInfo.discountsSiblings = dto.discountsSiblings;
    tuitionInfo.discountsSiblingsValue = dto.discountsSiblingsValue.slice();
    tuitionInfo.discountsMilitaryValue = dto.discountsMilitaryValue.slice();
    tuitionInfo.discountsCorporateValue = dto.discountsCorporateValue.slice();
    tuitionInfo.discountsDescription = dto.discountsDescription;
    tuitionInfo.withdrawalPolicyDescription = dto.withdrawalPolicyDescription;
    tuitionInfo.withdrawalPolicyNoticePeriod = dto.withdrawalPolicyNoticePeriod;
    tuitionInfo.costPeriod = dto.costPeriod;
    tuitionInfo.costDescription = dto.costDescription;
    tuitionInfo.costFrom = Money.fromDto(dto.costFrom);
    tuitionInfo.costTo = Money.fromDto(dto.costTo);

    return tuitionInfo;
  }
}
