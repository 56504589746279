import isNil from "lodash/isNil";
import isFinite from "lodash/isFinite";
import isNumber from "lodash/isNumber";

import { Models } from "@services/api";
import { normalizeAge } from "@helpers/ages-helper";

export class FacilityOperatingInfo {
  private _foundedAt: number | null = null;
  private _schoolDays: Models.WeekDay[] = [];
  private _enrollmentCapacity: number | null = null;
  private _currentEnrollment: number | null = null;
  private _numberOfClassrooms: number | null = null;
  private _religiousAffiliation: Models.AffiliationID | null = null;
  private _ongoingGradesFrom: Models.SchoolOngoingGradeID | null = null;
  private _ongoingGradesTo: Models.SchoolOngoingGradeID | null = null;
  private _naisAffiliated: boolean | null = null;
  private _ymcaAffiliated: boolean | null = null;
  private _naeycAccredited: boolean | null = null;
  private _yearLengthIsWhole: boolean | null = null;
  private _yearLengthStartMonth: Models.MonthID | null = null;
  private _yearLengthEndMonth: Models.MonthID | null = null;
  private _ageFrom: number | null = null;
  private _ageFromUnit: Models.AgeUnit | null = null;
  private _ageTo: number | null = null;
  private _ageToUnit: Models.AgeUnit | null = null;

  get foundedAt(): number | null {
    return this._foundedAt;
  }
  set foundedAt(v: number | string | null | undefined) {
    if (typeof v === "string") {
      const parsed = parseInt(v, 10);
      this._foundedAt = isFinite(parsed) ? parsed : null;
    } else {
      this._foundedAt = !isNil(v) ? v : null;
    }
  }

  get enrollmentCapacity(): number | null {
    return this._enrollmentCapacity;
  }
  set enrollmentCapacity(v: number | string | null | undefined) {
    if (typeof v === "string") {
      const parsed = parseInt(v, 10);
      this._enrollmentCapacity = isFinite(parsed) ? parsed : null;
    } else {
      this._enrollmentCapacity = !isNil(v) ? v : null;
    }
  }

  get currentEnrollment(): number | null {
    return this._currentEnrollment;
  }
  set currentEnrollment(v: number | string | null | undefined) {
    if (typeof v === "string") {
      const parsed = parseInt(v, 10);
      this._currentEnrollment = isFinite(parsed) ? parsed : null;
    } else {
      this._currentEnrollment = !isNil(v) ? v : null;
    }
  }

  get numberOfClassrooms(): number | null {
    return this._numberOfClassrooms;
  }
  set numberOfClassrooms(v: number | string | null | undefined) {
    if (typeof v === "string") {
      const parsed = parseInt(v, 10);
      this._numberOfClassrooms = isFinite(parsed) ? parsed : null;
    } else {
      this._numberOfClassrooms = !isNil(v) ? v : null;
    }
  }

  get religiousAffiliation(): Models.AffiliationID | null {
    return this._religiousAffiliation;
  }
  set religiousAffiliation(v: Models.AffiliationID | null | undefined) {
    this._religiousAffiliation = !isNil(v) ? v : null;
  }

  get ongoingGradesFrom(): Models.SchoolOngoingGradeID | null {
    return this._ongoingGradesFrom;
  }
  set ongoingGradesFrom(v: Models.SchoolOngoingGradeID | null | undefined) {
    this._ongoingGradesFrom = !isNil(v) ? v : null;
  }

  get ongoingGradesTo(): Models.SchoolOngoingGradeID | null {
    return this._ongoingGradesTo;
  }
  set ongoingGradesTo(v: Models.SchoolOngoingGradeID | null | undefined) {
    this._ongoingGradesTo = !isNil(v) ? v : null;
  }

  get schoolDays(): Models.WeekDay[] {
    return this._schoolDays;
  }
  set schoolDays(v: Models.WeekDay[]) {
    this._schoolDays = v;
  }

  get naisAffiliated(): boolean | null {
    return this._naisAffiliated;
  }
  set naisAffiliated(v: boolean | null | undefined) {
    this._naisAffiliated = !isNil(v) ? v : null;
  }

  get ymcaAffiliated(): boolean | null {
    return this._ymcaAffiliated;
  }
  set ymcaAffiliated(v: boolean | null | undefined) {
    this._ymcaAffiliated = !isNil(v) ? v : null;
  }

  get naeycAccredited(): boolean | null {
    return this._naeycAccredited;
  }
  set naeycAccredited(v: boolean | null | undefined) {
    this._naeycAccredited = !isNil(v) ? v : null;
  }

  get yearLengthIsWhole(): boolean | null {
    return this._yearLengthIsWhole;
  }
  set yearLengthIsWhole(v: boolean | null | undefined) {
    this._yearLengthIsWhole = !isNil(v) ? v : null;
  }

  get yearLengthEndMonth(): Models.MonthID | null {
    return this._yearLengthEndMonth;
  }
  set yearLengthEndMonth(v: Models.MonthID | null | undefined) {
    this._yearLengthEndMonth = !isNil(v) ? v : null;
  }

  get yearLengthStartMonth(): Models.MonthID | null {
    return this._yearLengthStartMonth;
  }
  set yearLengthStartMonth(
    v: Models.MonthID | "year-round" | null | undefined,
  ) {
    if (v === "year-round") {
      this.yearLengthIsWhole = true;
      this._yearLengthStartMonth = null;
      this.yearLengthEndMonth = null;
    } else if (!isNil(v)) {
      this.yearLengthIsWhole = false;
      this._yearLengthStartMonth = v;
    } else {
      this._yearLengthStartMonth = null;
    }
  }

  get ageFrom(): number | null {
    return this._ageFrom;
  }
  set ageFrom(v: number | string | null | undefined) {
    if (isNumber(v)) {
      this._ageFrom = v;
    } else if (!isNil(v) && !isNil(this.ageFromUnit)) {
      this._ageFrom = normalizeAge(parseInt(v, 10), this.ageFromUnit);
    } else {
      this._ageFrom = null;
    }
  }

  get ageFromUnit(): Models.AgeUnit | null {
    return this._ageFromUnit;
  }
  set ageFromUnit(v: Models.AgeUnit | null | undefined) {
    this._ageFromUnit = !isNil(v) ? v : null;
  }

  get ageTo(): number | null {
    return this._ageTo;
  }
  set ageTo(v: number | string | null | undefined) {
    if (isNumber(v)) {
      this._ageTo = v;
    } else if (!isNil(v) && !isNil(this.ageToUnit)) {
      this._ageTo = normalizeAge(parseInt(v, 10), this.ageToUnit);
    } else {
      this._ageTo = null;
    }
  }

  get ageToUnit(): Models.AgeUnit | null {
    return this._ageToUnit;
  }
  set ageToUnit(v: Models.AgeUnit | null | undefined) {
    this._ageToUnit = !isNil(v) ? v : null;
  }

  public toDto(): Models.FacilityOperatingInfo {
    return {
      foundedAt: this.foundedAt,
      schoolDays: this.schoolDays,
      currentEnrollment: this.currentEnrollment,
      enrollmentCapacity: this.enrollmentCapacity,
      numberOfClassrooms: this.numberOfClassrooms,
      religiousAffiliation: this.religiousAffiliation,
      ongoingGradesFrom: this.ongoingGradesFrom,
      ongoingGradesTo: this.ongoingGradesTo,
      naisAffiliated: this.naisAffiliated,
      naeycAccredited: this.naeycAccredited,
      ymcaAffiliated: this.ymcaAffiliated,
      yearLengthIsWhole: this.yearLengthIsWhole,
      yearLengthStartMonth: this.yearLengthStartMonth,
      yearLengthEndMonth: this.yearLengthEndMonth,
      ageFrom: this.ageFrom,
      ageFromUnit: this.ageFromUnit || undefined,
      ageTo: this.ageTo,
      ageToUnit: this.ageToUnit || undefined,
    };
  }

  static fromDto(dto: Models.FacilityOperatingInfo): FacilityOperatingInfo {
    const operatingInfo = new FacilityOperatingInfo();

    operatingInfo.foundedAt = dto.foundedAt;
    operatingInfo.schoolDays = dto.schoolDays;
    operatingInfo.currentEnrollment = dto.currentEnrollment;
    operatingInfo.enrollmentCapacity = dto.enrollmentCapacity;
    operatingInfo.numberOfClassrooms = dto.numberOfClassrooms;
    operatingInfo.religiousAffiliation = dto.religiousAffiliation;
    operatingInfo.ongoingGradesFrom = dto.ongoingGradesFrom;
    operatingInfo.ongoingGradesTo = dto.ongoingGradesTo;
    operatingInfo.naisAffiliated = dto.naisAffiliated;
    operatingInfo.ymcaAffiliated = dto.ymcaAffiliated;
    operatingInfo.naeycAccredited = dto.naeycAccredited;
    operatingInfo.yearLengthIsWhole = dto.yearLengthIsWhole;
    operatingInfo.yearLengthStartMonth = dto.yearLengthStartMonth;
    operatingInfo.yearLengthEndMonth = dto.yearLengthEndMonth;
    operatingInfo.ageFrom = dto.ageFrom;
    operatingInfo.ageFromUnit = dto.ageFromUnit;
    operatingInfo.ageTo = dto.ageTo;
    operatingInfo.ageToUnit = dto.ageToUnit;

    return operatingInfo;
  }
}
