import { Map, MapboxGeoJSONFeature } from "mapbox-gl";
import pointToLineDistance from "@turf/point-to-line-distance";
import polygonToLineString from "@turf/polygon-to-line";
import { Feature, LineString, point, polygon } from "@turf/helpers";

const getUniqueFeatures = (
  array: MapboxGeoJSONFeature[],
  comparatorProperty: string,
) => {
  const existingFeatureKeys: { [key: string]: boolean } = {};
  const uniqueFeatures = array.filter((el) => {
    const key = el.properties?.[comparatorProperty];

    if (existingFeatureKeys[key]) {
      return false;
    } else {
      existingFeatureKeys[key] = true;
      return true;
    }
  });

  return uniqueFeatures;
};

export const toggleNeighborhoodLayer = (
  map: Map,
  show: boolean,
  anchorCenter: { lng: number; lat: number },
  radius: number,
): void => {
  if (show) {
    const features = map.querySourceFeatures("composite", {
      sourceLayer: "neighbourhoods",
    });

    const uniqueFeatures = getUniqueFeatures(features, "RegionID");

    const filtered: any[] = uniqueFeatures.filter((f) => {
      try {
        const distanceToCenter =
          pointToLineDistance(
            point([anchorCenter.lng, anchorCenter.lat]),
            polygonToLineString(
              polygon((f.geometry as any).coordinates),
            ) as Feature<LineString>,
          ) * 1000;

        return distanceToCenter <= radius * 1000 * 1.60934;
      } catch (error) {
        return false;
      }
    });

    if (filtered.length > 0) {
      map.setFilter("neighbourhoods", [
        "match",
        ["get", "RegionID"],
        filtered.map((f) => f.properties.RegionID as string),
        true,
        false,
      ]);
    } else {
      map.setFilter("neighbourhoods", ["==", "RegionID", "null"]);
    }
  } else {
    map.setFilter("neighbourhoods", ["==", "RegionID", "null"]);
  }
};
