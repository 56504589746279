import React, { useState, useRef, useEffect } from "react";
import { useQuery } from "react-query";
import clsx from "clsx";
import qs from "query-string";
import orderBy from "lodash/orderBy";
import { useHistory, useLocation } from "react-router-dom";
import { useTracking } from "react-tracking";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import StarOutlined from "@material-ui/icons/StarOutlined";
import AccessTimeOutlined from "@material-ui/icons/AccessTimeOutlined";
import PhotoCameraOutlined from "@material-ui/icons/PhotoCameraOutlined";
import SchoolOutlined from "@material-ui/icons/SchoolOutlined";
import DoneOutlined from "@material-ui/icons/DoneOutlined";
import StayPrimaryPortrait from "@material-ui/icons/StayPrimaryPortraitOutlined";
import VideoCallOutlined from "@material-ui/icons/VideoCallOutlined";
import ChildCareOutlined from "@material-ui/icons/ChildCareOutlined";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Dialog from "@ui-kit/Dialog";
import Typography from "@ui-kit/Typography";
import { RATING_FILTERS, RATING_FILTER_IDS } from "@constants/rating-filters";
import { useCss } from "./styles";
import Button from "@ui-kit/Button";
import { useMapStore } from "@store/MapStore";
import { Models, API } from "@services/api";
import { TIME_FILTER_IDS, TIME_FILTERS } from "@constants/time-filters";
import { NewDesignIcons } from "@components/Icons";

import {
  Filters,
  FilterFeature,
  FilterAdditionalDesignation,
  SecondaryFilterParam,
} from "@models/common";
import { Spacer } from "@ui-kit/Spacer";
import { getFacilityPinColor } from "@selectors/facility";
import { generateCorporationLogo } from "@helpers/generateCorporationLogo";
import { FilterItem } from "./FilterItem";
import { useViewport } from "@hooks/useViewport";

type CountryChoice = "US" | "AUS";

export enum FiltersSections {
  Features = "features",
  WeekendCare = "weekendCare",
  Sponsored = "corporation",
  Tier = "tier",
}

const Section: React.FC<{
  title: string;
  id: string;
  expanded: boolean;
  onExpand: () => void;
  spacer?: boolean;
}> = ({ children, title, id, spacer = true, expanded, onExpand }) => {
  const css = useCss();
  const { isMobile } = useViewport();

  if (isMobile) {
    return (
      <ExpansionPanel
        expanded={expanded}
        TransitionProps={{
          timeout: 0,
        }}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={onExpand}
          data-test={`${id}-expansion`}
          id={`${id}-expansion`}
        >
          <Typography bolded>{title}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div>{children}</div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }

  return (
    <div data-test={`${id}-section`} id={`${id}-section`}>
      {spacer && (
        <>
          <Spacer size="large" />
          <Spacer size="medium" />
        </>
      )}
      <div css={css.sectionTitle}>
        <Typography bolded align="center" variant="h4">
          {title}
        </Typography>
      </div>
      <Spacer size="small" />
      <div>{children}</div>
    </div>
  );
};

const MapFilters: React.FC = () => {
  const { data: corporations } = useQuery(
    "corporations",
    API.corporations.getList,
  );
  const { trackEvent } = useTracking();
  const { isMobile } = useViewport();
  const history = useHistory();
  const { search } = useLocation();
  const [
    { filtersState, filters, filterIsActive },
    { closeFilters, clearFilters, setSearchString, setShowFindToClaimDialog },
  ] = useMapStore();
  const clearFilterPlaceholder = useRef<HTMLDivElement | null>(null);
  const [clearFiltersLength, setClearFiltersLength] = useState(350);
  const [activeProvidersCountry, setActiveProvidersCountry] =
    useState<CountryChoice>(() => {
      if (
        filters.secondary.localCorporations &&
        corporations?.find((c) => c.id === filters.secondary.localCorporations)
          ?.country === "AUS"
      ) {
        return "AUS";
      }

      return "US";
    });

  useEffect(() => {
    setTimeout(() => {
      if (clearFilterPlaceholder.current) {
        setClearFiltersLength(clearFilterPlaceholder.current.clientWidth);
      }
    }, 100);
  }, []);

  const [openedSection, setOpenedSection] = useState<FiltersSections | null>(
    () => {
      if (filtersState.defaultSection) {
        setTimeout(() => {
          const s = document.getElementById(
            `${filtersState.defaultSection}-expansion`,
          );

          if (s?.scrollIntoView) {
            s.scrollIntoView();
          }
        });
        return filtersState.defaultSection;
      } else if (
        filters.secondary.features ||
        filters.secondary.rating ||
        filters.secondary.opensBy ||
        filters.secondary.closesAfter ||
        filters.secondary.additionalDesignation
      ) {
        return FiltersSections.Features;
      } else if (filters.secondary.weekendCare) {
        return FiltersSections.WeekendCare;
      } else if (filters.secondary.tier) {
        return FiltersSections.Tier;
      }
      {
        return null;
      }
    },
  );

  const css = useCss();

  function filter(filters: Filters) {
    const params = qs.parse(search);

    history.push({
      search: qs.stringify({
        lat: params.lat,
        lng: params.lng,
        ...filters.primary,
        ...filters.secondary,
      }),
    });
  }

  function handleCorporationFilter(id: number) {
    if (filters.primary.corporation === id) {
      return;
    }

    trackEvent({
      action: "Click filter",
      type: "corporation",
      value: id,
    });

    setSearchString("");
    filter({
      primary: {},
      secondary: {
        ...filters.secondary,
        localCorporations: id,
      },
    });
  }

  function handleLocalCorporationFilter() {
    trackEvent({
      action: "Click filter",
      type: "corporation",
      value: "all",
    });

    filter({
      primary: {},
      secondary: {
        ...filters.secondary,
        localCorporations: true,
      },
    });
  }

  function handleSchoolTypeFilter(id: Models.FacilityTypeID) {
    if (filters.secondary.facilityType?.includes(id)) {
      closeFilters();
      return;
    }

    trackEvent({
      action: "Click filter",
      type: "facilityType",
      value: id,
    });

    filter({
      primary: { ...filters.primary },
      secondary: {
        ...filters.secondary,
        facilityType: [...(filters.secondary.facilityType || []), id],
      },
    });
  }

  function handleRatingFilter(id: RATING_FILTER_IDS) {
    trackEvent({
      action: "Click filter",
      type: "rating",
      value: id,
    });

    filter({
      primary: { ...filters.primary },
      secondary: {
        ...filters.secondary,
        rating: id,
      },
    });
  }

  function handleAdditionalDesignationFilter(id: FilterAdditionalDesignation) {
    trackEvent({
      action: "Click filter",
      type: "additionalDesignation",
      value: id,
    });

    filter({
      primary: { ...filters.primary },
      secondary: {
        ...filters.secondary,
        additionalDesignation: id,
      },
    });
  }

  function handleFeaturesFilter(id: FilterFeature) {
    if (filters.secondary.features?.includes(id)) {
      closeFilters();
      return;
    }

    trackEvent({
      action: "Click filter",
      type: "features",
      value: id,
    });

    filter({
      primary: { ...filters.primary },
      secondary: {
        ...filters.secondary,
        features: [...(filters.secondary.features || []), id],
      },
    });
  }

  function handleTimeFilter(
    type: SecondaryFilterParam.OpensBy | SecondaryFilterParam.ClosesAfter,
    id: string,
  ) {
    trackEvent({
      action: "Click filter",
      type: "time",
      value: id,
    });

    filter({
      primary: { ...filters.primary },
      secondary: {
        ...filters.secondary,
        [type]: id,
      },
    });
  }

  function handlePlaydateFilter(id: Models.WeekDay | "all") {
    if (filters.secondary.weekendCare === id) {
      closeFilters();
      return;
    }

    trackEvent({
      action: "Click filter",
      type: "pdo",
      value: id,
    });

    filter({
      primary: { ...filters.primary },
      secondary: {
        ...filters.secondary,
        weekendCare: id,
      },
    });
  }

  function handleSectionExtend(section: FiltersSections) {
    if (openedSection === section) {
      setOpenedSection(null);
    } else {
      setOpenedSection(section);
      setTimeout(() => {
        document
          .getElementById(`${section}-expansion`)
          ?.scrollIntoView({ block: "center" });
      }, 0);
    }
  }

  return (
    <Dialog
      open
      onClose={closeFilters}
      title="Filters"
      data-test="filters-modal"
      tier={Models.SubscriptionTier.Pro}
    >
      {filterIsActive.current && (
        <>
          <div
            css={css.clearContent}
            style={{
              width: clearFiltersLength,
            }}
          >
            <div className="inner">
              <Button
                fullWidth
                color="primary"
                variant="contained"
                data-test="clear-filters-button"
                size="large"
                fab
                onClick={clearFilters}
              >
                Clear filters
              </Button>
            </div>
          </div>
          <div css={css.clearPlaceholder} ref={clearFilterPlaceholder}>
            <div className="inner">
              <Button fullWidth size="large">
                Clear filters
              </Button>
            </div>
          </div>
        </>
      )}
      <div css={css.root}>
        <List css={css.filtersList}>
          <Section
            title="Parent Pass"
            onExpand={() => handleSectionExtend(FiltersSections.WeekendCare)}
            expanded={openedSection === FiltersSections.WeekendCare}
            id={FiltersSections.WeekendCare}
            spacer={false}
          >
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              size="large"
              name="facility-filter-booking"
              onClick={() => handlePlaydateFilter("all")}
            >
              Find Parent Pass facilities near me
            </Button>
          </Section>

          <Section
            title="Search by features"
            onExpand={() => handleSectionExtend(FiltersSections.Features)}
            expanded={openedSection === FiltersSections.Features}
            id={FiltersSections.Features}
          >
            <div css={css.filtersList}>
              {[
                {
                  id: Models.FacilityTypeID.DaycareCenter,
                  name: "Childcare centers near me",
                },
                {
                  id: Models.FacilityTypeID.PreSchool,
                  name: "Preschools near me",
                },
                {
                  id: Models.FacilityTypeID.PrivateSchool,
                  name: "Private schools near me",
                },
                {
                  id: Models.FacilityTypeID.Oshc,
                  name: "After school care near me",
                },
              ].map((type) => (
                <ListItem
                  key={type.id}
                  data-test={`facility-filter-${type.id}`}
                  onClick={() => {
                    handleSchoolTypeFilter(type.id);
                  }}
                  disableGutters
                >
                  <NewDesignIcons.MapPin
                    fill={getFacilityPinColor(type.id)}
                    css={css.icon}
                  />
                  <Typography>{type.name}</Typography>

                  {filters.secondary.facilityType?.includes(type.id) && (
                    <DoneOutlined css={css.filterSelected} />
                  )}
                </ListItem>
              ))}

              <Spacer size="medium" />

              <FilterItem
                id="education"
                onClick={() => handleFeaturesFilter("education")}
                Icon={SchoolOutlined}
                label="Montessori, Reggio or Waldorf"
                isActive={filters.secondary.features?.includes("education")}
              />

              <FilterItem
                id="language"
                onClick={() => handleFeaturesFilter("language")}
                Icon={SchoolOutlined}
                label="Language immersion"
                isActive={filters.secondary.features?.includes("language")}
              />

              <FilterItem
                id="nursery"
                onClick={() => handleAdditionalDesignationFilter("nursery")}
                Icon={SchoolOutlined}
                label="Nursery schools"
                isActive={
                  filters.secondary[
                    SecondaryFilterParam.AdditionalDesignation
                  ] === "nursery"
                }
              />

              <FilterItem
                id="cooperative"
                onClick={() =>
                  handleAdditionalDesignationFilter("cooperative-school")
                }
                Icon={SchoolOutlined}
                label="Cooperative schools"
                isActive={
                  filters.secondary[
                    SecondaryFilterParam.AdditionalDesignation
                  ] === "cooperative-school"
                }
              />

              <FilterItem
                id="faith"
                onClick={() => handleFeaturesFilter("faith")}
                Icon={AllInclusiveIcon}
                label="Faith-based schools"
                isActive={filters.secondary.features?.includes("faith")}
              />

              <Spacer size="medium" />

              <FilterItem
                id="vr"
                onClick={() => handleFeaturesFilter("vr")}
                Icon={NewDesignIcons.Vr}
                label="Virtual school tour available"
                isActive={filters.secondary.features?.includes("vr")}
              />

              <FilterItem
                id="media"
                onClick={() => handleFeaturesFilter("media")}
                Icon={PhotoCameraOutlined}
                label="School photos available"
                isActive={filters.secondary.features?.includes("media")}
              />

              <FilterItem
                id="tuition"
                onClick={() => handleFeaturesFilter("tuition")}
                Icon={NewDesignIcons.Tuition}
                label="Tuition transparency"
                isActive={filters.secondary.features?.includes("tuition")}
              />

              <FilterItem
                id="meals"
                onClick={() => handleFeaturesFilter("meals")}
                Icon={NewDesignIcons.Meal}
                label="School offers a meal plan"
                isActive={filters.secondary.features?.includes("meals")}
              />

              <Spacer size="medium" />

              <FilterItem
                id="mobileParentApp"
                onClick={() => handleFeaturesFilter("mobileParentApp")}
                Icon={StayPrimaryPortrait}
                label="Parent app available"
                isActive={filters.secondary.features?.includes(
                  "mobileParentApp",
                )}
              />

              <FilterItem
                id="videoStreaming"
                onClick={() => handleFeaturesFilter("videoStreaming")}
                Icon={VideoCallOutlined}
                label="Live video available"
                isActive={filters.secondary.features?.includes(
                  "videoStreaming",
                )}
              />

              <FilterItem
                id="backUp"
                onClick={() => handleFeaturesFilter("backUp")}
                Icon={ChildCareOutlined}
                label="Backup care / Flexible care"
                isActive={filters.secondary.features?.includes("backUp")}
              />

              <Spacer size="medium" />

              {TIME_FILTERS.map((t) => {
                return (
                  <FilterItem
                    key={t.id}
                    id={t.id}
                    onClick={() => {
                      handleTimeFilter(
                        t.id === TIME_FILTER_IDS.After6PM
                          ? SecondaryFilterParam.ClosesAfter
                          : SecondaryFilterParam.OpensBy,
                        t.id,
                      );
                    }}
                    Icon={AccessTimeOutlined}
                    label={t.name}
                    isActive={
                      t.id === filters.secondary.opensBy ||
                      t.id === filters.secondary.closesAfter
                    }
                  />
                );
              })}

              <Spacer size="medium" />

              {RATING_FILTERS.map((r) => {
                return (
                  <FilterItem
                    key={r.id}
                    id={r.id}
                    onClick={() => handleRatingFilter(r.id)}
                    Icon={StarOutlined}
                    label={r.name}
                    isActive={r.id === filters.secondary.rating}
                  />
                );
              })}
            </div>
          </Section>

          <Section
            title="Search by school tier"
            onExpand={() => handleSectionExtend(FiltersSections.Tier)}
            expanded={openedSection === FiltersSections.Tier}
            id={FiltersSections.Tier}
          >
            <div css={css.filtersList}>
              <Typography>
                Higher performing schools join our Pro Tier, while top
                performing schools graduate to the Ivy Tier.
              </Typography>
              <Spacer size="small" />

              <div css={css.tiersContainer}>
                <div
                  css={css.tier}
                  className="pro"
                  onClick={() => {
                    filter({
                      primary: { ...filters.primary },
                      secondary: {
                        ...filters.secondary,
                        tier: Models.SubscriptionTier.Pro,
                      },
                    });
                  }}
                >
                  School tier: PRO
                </div>
                <div
                  css={css.tier}
                  className="ivy"
                  onClick={() => {
                    filter({
                      primary: { ...filters.primary },
                      secondary: {
                        ...filters.secondary,
                        tier: Models.SubscriptionTier.Ivy,
                      },
                    });
                  }}
                >
                  School tier: IVY
                </div>
              </div>
            </div>
          </Section>

          <Section
            title="Find providers near me"
            id={FiltersSections.Sponsored}
            expanded={openedSection === FiltersSections.Sponsored}
            onExpand={() => handleSectionExtend(FiltersSections.Sponsored)}
          >
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              size="large"
              css={css.localSponsorFilter}
              onClick={handleLocalCorporationFilter}
              fab
            >
              Show all
            </Button>

            <RadioGroup
              name="providers-country"
              value={activeProvidersCountry}
              onChange={(event) =>
                setActiveProvidersCountry(event.target.value as CountryChoice)
              }
              css={css.materialRadioGroupRoot}
            >
              <FormControlLabel
                value="US"
                control={<Radio color="primary" />}
                label="United States"
              />
              <FormControlLabel
                value="AUS"
                control={<Radio color="primary" />}
                label="Australia"
              />
            </RadioGroup>

            <div css={css.sponsorFiltersContainer}>
              {orderBy(
                corporations?.filter(
                  (b) =>
                    b.country === activeProvidersCountry && b.displayInFilters,
                ),
                (b) => b.name?.toLowerCase(),
              ).map((s) => {
                const img = s.logo?.rectangle?.cloudinaryId ? (
                  <img src={s.logo.rectangle.original} alt={s.name} />
                ) : (
                  generateCorporationLogo(s)
                );

                return (
                  <div
                    css={css.sponsorFilter}
                    onClick={() => handleCorporationFilter(s.id)}
                    className={clsx({
                      active: filters.secondary.localCorporations === s.id,
                    })}
                    data-test={`facility-filter-${s.id}`}
                    key={s.id}
                  >
                    {img}
                  </div>
                );
              })}
            </div>
          </Section>

          {isMobile && (
            <>
              <Spacer size="medium" />
              <Button
                variant="outlined"
                color="secondary"
                fullWidth
                size="large"
                css={css.mainFilterButton}
                onClick={() => {
                  setShowFindToClaimDialog(true);
                  closeFilters();
                }}
              >
                Facility search
              </Button>
            </>
          )}
        </List>
      </div>
    </Dialog>
  );
};

export default MapFilters;
