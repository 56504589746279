import keyBy from "lodash/keyBy";

import { Models } from "@services/api";

export type TuitionWithdrawal = {
  id: Models.WithdrawalPolicyNoticePeriod;
  name: string;
};

export const TUITION_WITHDRAWAL: TuitionWithdrawal[] = [
  {
    id: Models.WithdrawalPolicyNoticePeriod.NotRequired,
    name: "none required",
  },
  {
    id: Models.WithdrawalPolicyNoticePeriod.Weeks1,
    name: "1 week",
  },
  {
    id: Models.WithdrawalPolicyNoticePeriod.Weeks2,
    name: "2 weeks",
  },
  {
    id: Models.WithdrawalPolicyNoticePeriod.Weeks3,
    name: "3 weeks",
  },
  {
    id: Models.WithdrawalPolicyNoticePeriod.Weeks4,
    name: "4 weeks",
  },
  {
    id: Models.WithdrawalPolicyNoticePeriod.Months1,
    name: "1 month",
  },
];

export const tuitionWithdrawalMap = keyBy(TUITION_WITHDRAWAL, "id");
