import React from "react";

import Typography from "@ui-kit/Typography";
import { useDeepDivePanelStore } from "@hooks";

export const StudentTeacherRatio: React.FC<{
  gutterBottom?: boolean;
}> = ({ gutterBottom = false }) => {
  const [{ facility }] = useDeepDivePanelStore();

  if (!facility.teacherStudentRatio || facility.teacherStudentRatio < 5) {
    return null;
  }

  return (
    <Typography gutterBottom={gutterBottom}>
      <Typography variant="inherit" bolded>
        Student:teacher ratio:
      </Typography>{" "}
      {facility.teacherStudentRatio > 30
        ? "more than 30:1"
        : `${Math.round(facility.teacherStudentRatio)}:1`}
    </Typography>
  );
};
