import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  cancellationSeparator: (theme) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
    marginBottom: 20,
  }),

  actions: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 30,

    "& button": {
      width: 140,
    },
  },

  flowContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "100%",
    position: "relative",
    width: "100%",
  },
});
