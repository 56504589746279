import keyBy from "lodash/keyBy";

import { Models } from "@services/api";

export type ComunityType = {
  id: Models.CommunityType;
  name: string;
};

export const COMUNITY_TYPES: ComunityType[] = [
  {
    id: Models.CommunityType.City,
    name: "City",
  },
  {
    id: Models.CommunityType.Suburb,
    name: "Suburb",
  },
  {
    id: Models.CommunityType.Town,
    name: "Town",
  },
  {
    id: Models.CommunityType.Rural,
    name: "Rural",
  },
];

export const comunityTypesMap = keyBy(COMUNITY_TYPES, "id");
