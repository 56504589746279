import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  root: {
    bottom: 25,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    pointerEvents: "none",
    position: "absolute",
    right: 70,
    top: 10,
    width: 230,
    zIndex: 1,
  },

  layerToggler: (theme) => ({
    alignItems: "center",
    background: "white",
    border: `1px solid ${theme.palette.text.primary}`,
    borderRadius: 7,
    color: theme.palette.text.primary,
    cursor: "pointer",
    display: "flex",
    padding: "5px 20px",
    pointerEvents: "all",

    "& svg": {
      color: theme.palette.primary.main,
      height: 20,
      marginRight: 5,
      width: 20,
    },

    "&:hover": {
      backgroundColor: "#f2f2f2",
    },

    "& + $layerToggler": {
      marginTop: 10,
    },
  }),

  sponsored: (theme) => ({
    backgroundColor: theme.palette.yellow.main,
    borderRadius: 7,
    boxShadow: "0px 5px 21px 0px rgba(0, 0, 0, 0.13)",
    pointerEvents: "all",

    "& .header": {
      alignItems: "center",
      backgroundColor: theme.palette.text.primary,
      borderTopLeftRadius: 7,
      borderTopRightRadius: 7,
      color: theme.palette.yellow.main,
      cursor: "pointer",
      display: "flex",
      fontWeight: 700,
      height: 40,
      justifyContent: "space-between",
      padding: 10,
      userSelect: "none",

      "&:not(.opened)": {
        borderBottomLeftRadius: 7,
        borderBottomRightRadius: 7,
      },
    },

    "& .body": {
      maxHeight: 0,
      overflow: "auto",
      scrollbarColor: `${theme.palette.text.primary} ${theme.palette.yellow.main}`,
      scrollbarWidth: "thin",
      transformOrigin: "top",

      "&.opened": {
        maxHeight: "calc(100vh - 345px)",
      },

      "&::-webkit-scrollbar": {
        width: 11,
      },

      "&::-webkit-scrollbar-track": {
        background: theme.palette.yellow.light,
      },

      "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.text.primary,
        border: `3px solid ${theme.palette.yellow.light}`,
        borderRadius: 6,
      },

      "& .item": {
        color: theme.palette.text.primary,
        cursor: "pointer",
        fontSize: 14,
        fontWeight: 600,
        padding: 10,

        "&.hovered": {
          backgroundColor: theme.palette.divider,
        },

        "&.active": {
          fontSize: 16,
        },

        "&.disabled": {
          color: theme.palette.text.disabled,
          cursor: "default",
        },
      },
    },
  }),

  sponsorPopover: {
    left: "auto !important",
    position: "fixed",
    right: 305,
    top: "70px !important",
    transition: "none !important",
    width: 300,

    "&.filterActive": {
      top: "110px !important",
    },
  },

  directionsControl: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 10,

    "& button + button": {
      marginLeft: 10,
    },

    "& button": {
      backgroundColor: "white",
      borderColor: "rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186)",
      borderRadius: "100%",
      borderStyle: "solid",
      borderWidth: 2,
      cursor: "pointer",
      display: "block",
      height: 50,
      outline: "none",
      padding: 0,
      pointerEvents: "all",
      width: 50,

      "&:hover": {
        backgroundColor: "#f2f2f2",
      },

      "& svg.direction-icon": {
        height: "1.4em",
        width: "1.4em",
      },
    },
  },

  countries: (theme) => ({
    backgroundColor: theme.palette.text.primary,
    display: "flex",
    alignItems: "center",
    padding: "0 10px 5px 10px",
  }),

  countryIcon: {
    width: 35,
    height: 25,
    cursor: "pointer",
    marginRight: 20,

    "&.active": {
      width: 45,
      height: 38,
    },
  },
});
