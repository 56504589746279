import { createUseStyles } from "react-jss";

import { ITheme } from "@theme";

const styles = (theme: ITheme) => ({
  root: {
    color: theme.palette.text.primary,
    marginRight: 0,
    minWidth: 36,
  },
});

export default createUseStyles<ITheme, keyof ReturnType<typeof styles>>(
  styles,
  { name: "CustomListItemIcon" },
);
