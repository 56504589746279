import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  cancellationTip: (theme) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
    color: theme.palette.error.main,
    fontWeight: 600,
    lineHeight: 1.5,
    paddingTop: 20,
  }),

  cancellationTipTitle: (theme) => ({
    color: theme.palette.text.primary,
    marginBottom: 10,
  }),

  actions: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 30,

    "& button": {
      width: 140,
    },
  },

  confirmationPanel: {
    background: "#f8f8f8",
    lineHeight: 1.5,
    padding: 10,
  },

  flowContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "100%",
    position: "relative",
    width: "100%",
  },

  checkRow: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    marginLeft: -9,
  },
});
