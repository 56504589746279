import keyBy from "lodash/keyBy";

import { Models } from "@services/api";

export type SuppliesFromHome = {
  id: Models.SuppliesFromHome;
  name: string;
  order: number;
};

export const SUPPLIES_FROM_HOME: SuppliesFromHome[] = [
  {
    id: Models.SuppliesFromHome.FoodAndDrink,
    name: "Food and drink",
  },
  {
    id: Models.SuppliesFromHome.AnExtraOutfit,
    name: "An extra outfit",
  },
  {
    id: Models.SuppliesFromHome.DiapersAndPullUps,
    name: "Diapers, pull-ups",
  },
  {
    id: Models.SuppliesFromHome.WipesAndDiaperRashCream,
    name: "Wipes, diaper-rash cream",
  },
  {
    id: Models.SuppliesFromHome.CribSheetAndBlanket,
    name: "Crib sheet, blanket",
  },
  {
    id: Models.SuppliesFromHome.NaptimeComfortItem,
    name: "Naptime comfort item",
  },
].map((s, i) => ({ ...s, order: i }));

export const SuppliesFromHomeMap = keyBy(SUPPLIES_FROM_HOME, "id");
