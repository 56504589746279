import React from "react";
import clsx from "clsx";

import { useCss } from "./styles";
import Typography from "@ui-kit/Typography";
import { useDeepDivePanelStore } from "@hooks";
import { useUserStore } from "@store/UserStore";
import Button from "@ui-kit/Button";
import { Overview } from "@components/Overview";
import { Section } from "@components/DeepDivePanel/Section";
import { Spacer } from "@ui-kit/Spacer";
import { Models } from "@services/api";
import { isFacilityOwner } from "@selectors";

export const FacilityOverview: React.FC = () => {
  const [{ facility }] = useDeepDivePanelStore();
  const [{ user }] = useUserStore();
  const css = useCss();

  if (
    !facility.bulletinBoardHeading &&
    !facility.bulletinBoardOverview &&
    !facility.overview &&
    !facility.overviewHeadline &&
    !isFacilityOwner(facility, user)
  ) {
    return null;
  }

  return (
    <Section borderTop>
      <Typography variant="h5" bolded paragraph>
        Facility Overview
      </Typography>

      {!facility.overview &&
        !facility.overviewHeadline &&
        isFacilityOwner(facility, user) && (
          <div>Tell parents about your philosophy, staff, curriculum, etc</div>
        )}

      {!!facility.overviewHeadline && (
        <div
          css={css.overviewHeadline}
          className={clsx({
            withOverview:
              facility.overview !== null && facility.overview !== "",
          })}
          data-test="overviewHeadline-display"
        >
          {facility.overviewHeadline}
        </div>
      )}

      {!!facility.overview && (
        <Overview text={facility.overview} data-test="overview-display" />
      )}

      {!isFacilityOwner(facility, user) && (
        <>
          {(facility.bulletinBoardHeading ||
            facility.bulletinBoardOverview) && (
            <>
              {(facility.overview || facility.overviewHeadline) && (
                <Spacer size="sm" />
              )}
              <div css={css.buttons}>
                <Button
                  variant="outlined"
                  color={
                    facility.subscriptionTier === Models.SubscriptionTier.Ivy
                      ? "secondary"
                      : "black"
                  }
                  size="large"
                  link={`/map/${facility.id}/bulletin-board`}
                >
                  View bulletin board
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </Section>
  );
};
