import { Interpolation } from "@emotion/react";

import { Models } from "@services/api";
import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = ({
  tier,
}: {
  tier: Models.SubscriptionTier;
}): Record<Classes, Interpolation<ITheme>> => ({
  root: (theme) => ({
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    zIndex: 100,

    [theme.mediaRequests.notMobile]: {
      left: 0,
      position: "absolute",
      top: 0,
      transition: "all .5s",
      width: 420,
      zIndex: 1,

      "&.collapsed": {
        left: -420,
      },
    },

    [theme.mediaRequests.mobile]: {
      width: "100vw",
    },
  }),

  header: (theme) => ({
    alignItems: "center",
    display: "flex",
    flex: "0 0 auto",
    padding: "10px 15px",

    [theme.mediaRequests.notMobile]: {
      backgroundColor: "rgba(200, 200, 200, .2)",
    },
  }),

  body: (theme) => ({
    WebkitOverflowScrolling: "touch",
    height: "100%",

    [theme.mediaRequests.notMobile]: {
      overflowX: "hidden",
      overflowY: "scroll",
    },

    [theme.mediaRequests.mobile]: {
      overflow: "hidden",

      "&.open": {
        overflowY: "scroll",
      },
    },
  }),

  favoriteIcon: {
    marginRight: 8,
  },

  actionButton: {
    width: "calc(50% - 7px)",
  },

  drawer: (theme) => ({
    bottom: 0,
    height: "auto",
    left: 0,
    maxHeight: "100%",
    position: "fixed",
    right: 0,
    transition: theme.transitions.create("top", {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    }),
    zIndex: 5,
  }),

  drawerOpen: {
    top: 0,
    zIndex: 1500,
  },

  drawerClose: {
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    overflow: "visible",
    top:
      tier === Models.SubscriptionTier.Community
        ? "calc(100% - 185px)"
        : tier === Models.SubscriptionTier.Pro
        ? "calc(100% - 210px)"
        : "calc(100% - 200px)",
  },

  ddpToggler: (theme) => ({
    backgroundColor: "white",
    boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.3)",
    color: theme.palette.text.secondary,
    cursor: "pointer",
    height: 53,
    position: "absolute",
    right: -25,
    top: 10,
    width: 25,

    "& .arrow": {
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%) rotate(90deg)",

      "&.collapsed": {
        transform: "translateY(-50%) rotate(-90deg)",
      },
    },
  }),
});
