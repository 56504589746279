import React from "react";

import { createCss } from "../styles";
import Typography from "@ui-kit/Typography";
import { Spacer } from "@ui-kit/Spacer";
import { Models } from "@services/api";

interface DescriptionSectionProps {
  bookingConfig: Models.BookingConfig;
}

export const DescriptionSection: React.FC<DescriptionSectionProps> = ({
  bookingConfig,
}) => {
  const css = createCss();

  if (
    !bookingConfig.description &&
    !bookingConfig.descriptionHeadline &&
    !bookingConfig.descriptionFile?.original
  ) {
    return null;
  }

  return (
    <>
      <Spacer size="md" />

      <Typography variant="h5" bolded align="center" css={css.sectionTitle}>
        Service description
      </Typography>

      <Spacer size="sm" />

      {!!bookingConfig?.descriptionHeadline && (
        <Typography
          bolded
          gutterBottom={
            !!bookingConfig.description ||
            !!bookingConfig.descriptionFile?.original
          }
        >
          {bookingConfig.descriptionHeadline}
        </Typography>
      )}

      {!!bookingConfig?.description && (
        <Typography gutterBottom={!!bookingConfig.descriptionFile?.original}>
          {bookingConfig.description}
        </Typography>
      )}

      {!!bookingConfig.descriptionFile?.original && (
        <a
          target="_blank"
          href={bookingConfig.descriptionFile.original}
          rel="noreferrer"
        >
          click to view
        </a>
      )}
    </>
  );
};
