import isNil from "lodash/isNil";

import { Models } from "@services/api";

export class FacilityDocumentsInfo {
  private _requiredEnrollmentDocuments: Models.RequiredDocumentsID[] = [];
  private _requiredEnrollmentDocumentsDescription: string | null = null;
  private _enrollmentPacketFile: Models.UploadedFile = { original: "" };
  private _parentHandbookFile: Models.UploadedFile = { original: "" };
  private _firstDayChecklist: Models.FirstDayChecklist[] = [];
  private _firstDayChecklistDescription: string | null = null;
  private _suppliesFromHome: Models.SuppliesFromHome[] = [];
  private _suppliesFromHomeDescription: string | null = null;
  private _schoolCalendar: boolean | null = null;
  private _holidays: Models.HolidayScheduleID[] = [];
  private _schoolCalendarFile: Models.UploadedFile = { original: "" };
  private _holidaysDescription: string | null = null;

  get requiredEnrollmentDocuments(): Models.RequiredDocumentsID[] {
    return this._requiredEnrollmentDocuments;
  }
  set requiredEnrollmentDocuments(v: Models.RequiredDocumentsID[]) {
    this._requiredEnrollmentDocuments = v;
  }

  get requiredEnrollmentDocumentsDescription(): string | null {
    return this._requiredEnrollmentDocumentsDescription;
  }
  set requiredEnrollmentDocumentsDescription(v: string | null | undefined) {
    this._requiredEnrollmentDocumentsDescription = !isNil(v) ? v : null;
  }

  get enrollmentPacketFile(): Models.UploadedFile {
    return this._enrollmentPacketFile;
  }
  set enrollmentPacketFile(v: Models.UploadedFile) {
    this._enrollmentPacketFile = v;
  }

  get parentHandbookFile(): Models.UploadedFile {
    return this._parentHandbookFile;
  }
  set parentHandbookFile(v: Models.UploadedFile) {
    this._parentHandbookFile = v;
  }

  get firstDayChecklist(): Models.FirstDayChecklist[] {
    return this._firstDayChecklist;
  }
  set firstDayChecklist(v: Models.FirstDayChecklist[]) {
    this._firstDayChecklist = v;
  }

  get firstDayChecklistDescription(): string | null {
    return this._firstDayChecklistDescription;
  }
  set firstDayChecklistDescription(v: string | null | undefined) {
    this._firstDayChecklistDescription = !isNil(v) ? v : null;
  }

  get suppliesFromHome(): Models.SuppliesFromHome[] {
    return this._suppliesFromHome;
  }
  set suppliesFromHome(v: Models.SuppliesFromHome[]) {
    this._suppliesFromHome = v;
  }

  get suppliesFromHomeDescription(): string | null {
    return this._suppliesFromHomeDescription;
  }
  set suppliesFromHomeDescription(v: string | null | undefined) {
    this._suppliesFromHomeDescription = !isNil(v) ? v : null;
  }

  get schoolCalendar(): boolean | null {
    return this._schoolCalendar;
  }
  set schoolCalendar(v: boolean | null | undefined) {
    this._schoolCalendar = !isNil(v) ? v : null;
  }

  get holidays(): Models.HolidayScheduleID[] {
    return this._holidays;
  }
  set holidays(v: Models.HolidayScheduleID[]) {
    this._holidays = v;
  }

  get schoolCalendarFile(): Models.UploadedFile {
    return this._schoolCalendarFile;
  }
  set schoolCalendarFile(v: Models.UploadedFile) {
    this._schoolCalendarFile = v;
  }

  get holidaysDescription(): string | null {
    return this._holidaysDescription;
  }
  set holidaysDescription(v: string | null | undefined) {
    this._holidaysDescription = !isNil(v) ? v : null;
  }

  public toDto(): Models.FacilityDocumentsInfo {
    return {
      requiredEnrollmentDocuments: this.requiredEnrollmentDocuments,
      requiredEnrollmentDocumentsDescription:
        this.requiredEnrollmentDocumentsDescription || undefined,
      enrollmentPacketFile: this.enrollmentPacketFile,
      parentHandbookFile: this.parentHandbookFile,
      firstDayChecklist: this.firstDayChecklist,
      firstDayChecklistDescription:
        this.firstDayChecklistDescription || undefined,
      suppliesFromHome: this.suppliesFromHome,
      suppliesFromHomeDescription:
        this.suppliesFromHomeDescription || undefined,
      holidays: this.holidays,
      schoolCalendarFile: this.schoolCalendarFile,
      holidaysDescription: this.holidaysDescription,
      schoolCalendar: this.schoolCalendar,
    };
  }

  static fromDto(dto: Models.FacilityDocumentsInfo): FacilityDocumentsInfo {
    const documentsInfo = new FacilityDocumentsInfo();

    documentsInfo.requiredEnrollmentDocuments = dto.requiredEnrollmentDocuments;
    documentsInfo.requiredEnrollmentDocumentsDescription =
      dto.requiredEnrollmentDocumentsDescription;
    documentsInfo.enrollmentPacketFile = dto.enrollmentPacketFile;
    documentsInfo.parentHandbookFile = dto.parentHandbookFile;
    documentsInfo.firstDayChecklist = dto.firstDayChecklist;
    documentsInfo.firstDayChecklistDescription =
      dto.firstDayChecklistDescription;
    documentsInfo.suppliesFromHome = dto.suppliesFromHome;
    documentsInfo.suppliesFromHomeDescription = dto.suppliesFromHomeDescription;
    documentsInfo.holidays = dto.holidays;
    documentsInfo.schoolCalendar = dto.schoolCalendar;
    documentsInfo.schoolCalendarFile = dto.schoolCalendarFile;
    documentsInfo.holidaysDescription = dto.holidaysDescription;

    return documentsInfo;
  }
}
