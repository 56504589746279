import keyBy from "lodash/keyBy";

import { Models } from "@services/api";

export const paymentPeriodTypes = [
  {
    id: Models.TuitionTypeID.Hourly,
    shortTitle: "Hourly",
    title: "Hourly rate",
  },
  {
    id: Models.TuitionTypeID.Daily,
    shortTitle: "Daily",
    title: "Daily rate",
  },
  {
    id: Models.TuitionTypeID.Weekly,
    shortTitle: "Weekly",
    title: "Weekly tuition",
  },
  {
    id: Models.TuitionTypeID.BiWeekly,
    shortTitle: "Bi-weekly",
    title: "Bi-weekly tuition",
  },
  {
    id: Models.TuitionTypeID.Monthly,
    shortTitle: "Monthly",
    title: "Monthly tuition",
  },
  {
    id: Models.TuitionTypeID.Annual,
    shortTitle: "Annual",
    title: "Annual tuition",
  },
  {
    id: Models.TuitionTypeID.Free,
    shortTitle: "Free of charge",
    title: "Free of charge",
  },
];

export const paymentPeriodTypesMap = keyBy(paymentPeriodTypes, "id");
