/* global ProtoExtends JSX */
import React, { forwardRef } from "react";
import { css } from "@emotion/css";
import clsx from "clsx";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import ListItemText from "@material-ui/core/ListItemText";
import Select, { SelectProps } from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";

import Typography from "@ui-kit/Typography";

type Item = {
  [key: string]: any;
  id: string | number;
};

type MultipleSelectProps<T = Item> = ProtoExtends<
  SelectProps,
  {
    items: T[];
    label?: string;
    itemLabelProp: string;
    onChange?: (
      event: React.ChangeEvent<{
        value: T[];
      }>,
    ) => void;
    value?: T[];
    renderValue?: (selected: T[]) => React.ReactNode;
    controlClassname?: string;
    error?: string | React.ReactElement;
  }
>;

const MultipleSelect = forwardRef(
  <T extends Item>(
    {
      items,
      label,
      itemLabelProp,
      required,
      controlClassname,
      value,
      error,
      name,
      renderValue,
      onChange,
      ...props
    }: React.PropsWithChildren<MultipleSelectProps<T>>,
    ref: MultipleSelectProps["ref"],
  ) => {
    return (
      <FormControl
        margin="normal"
        fullWidth
        className={clsx(controlClassname, {
          "form-error": !!error,
        })}
        error={!!error}
      >
        <InputLabel htmlFor="select-multiple-checkbox">
          <Typography variant="inherit" required={required}>
            {label}
          </Typography>
        </InputLabel>
        <Select
          data-test={name ? `${name}-select` : undefined}
          {...props}
          name={name}
          multiple
          MenuProps={{
            autoFocus: false,
            classes: {
              list: css({
                maxHeight: "70vh",
              }),
            },
            variant: "menu",
          }}
          ref={ref}
          value={value as any as SelectProps["value"]}
          onChange={onChange as any as SelectProps["onChange"]}
          input={<Input id="select-multiple-checkbox" />}
          renderValue={(selected) => {
            if (renderValue) {
              return renderValue(selected as T[]);
            } else {
              return (selected as Item[])
                .map((s) => s[itemLabelProp] as string)
                .join(", ");
            }
          }}
        >
          {items.map((item) => (
            <MenuItem
              data-test={item.id}
              key={item[itemLabelProp]}
              value={item as any}
            >
              <Checkbox
                color="primary"
                checked={(value as Item[]).map((v) => v.id).includes(item.id)}
              />
              <ListItemText primary={item[itemLabelProp]} />
            </MenuItem>
          ))}
        </Select>

        {!!error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  },
);

MultipleSelect.displayName = "MultipleSelect";

export default MultipleSelect as <T extends Item>(
  props: React.PropsWithChildren<MultipleSelectProps<T>>,
) => JSX.Element;
