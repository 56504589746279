import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const useCss = (): Record<Classes, Interpolation<ITheme>> => ({
  root: {
    backgroundColor: "transparent",
    display: "flex",
    maxWidth: 400,
    minWidth: 250,
    position: "absolute",
    zIndex: 999,

    "&.bottom-left": {
      transform: "translate(10px, calc(-100% - 10px))",
    },

    "&.top-left": {
      transform: "translate(10px, 10px)",
    },

    "&.top-right": {
      transform: "translate(calc(-100% - 10px), 10px)",
    },

    "&.bottom-right": {
      transform: "translate(calc(-100% - 10px), calc(-100% - 10px))",
    },
  },

  icon: {
    minWidth: 30,

    "& svg": {
      fontSize: "1.3rem",
    },
  },

  list: {
    backgroundColor: "white",
    boxShadow: "0 2px 4px rgba(0,0,0,0.2),0 -1px 0px rgba(0,0,0,0.02)",
    maxWidth: "100%",
    padding: "5px 10px",
    position: "relative",

    "&.hasPhoto": {
      maxWidth: "calc(100% - 80px)",
    },
  },

  listItem: {
    height: 24,
    padding: 0,
    whiteSpace: "nowrap",
  },

  facilityCardName: {
    alignItems: "center",
    display: "flex",

    "& span": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },

  galleryBlock: {
    boxShadow:
      "0 2px 5px -5px rgba(0,0,0,0.2), 0 5px 5px -5px rgba(0,0,0,0.2), -5px 0 5px -1px rgba(0,0,0,0.2)",
    height: 80,
    width: 80,
    zIndex: 1,
  },

  galleryImg: {
    alignItems: "center",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    cursor: "pointer",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    width: "100%",

    "& img": {
      width: "50%",
    },
  },

  pinIcon: {
    height: 22,
    marginLeft: 1,
    width: 18,
  },

  verifiedSign: {
    flexShrink: 0,
    height: 20,
    marginLeft: 2,
    marginRight: 10,
    width: 20,
  },

  tier: {
    marginLeft: 5,
  },

  noWrap: {
    whiteSpace: "nowrap",
  },
});
