import React, { useState, Fragment } from "react";
import { useForm, FormProvider } from "react-hook-form";
import cloneDeep from "lodash/cloneDeep";
import isFunction from "lodash/isFunction";

import Button from "@ui-kit/Button";
import { createCss } from "./styles";
import Typography from "@ui-kit/Typography";
import { Spacer } from "@ui-kit/Spacer";
import { FACILITY_API } from "@services/api";
import { goToFormError } from "@helpers/goToFormError";
import { useNotificationStore } from "@store/NotificationStore";
import { ClaimStepProps } from "./types";
import { AxiosError } from "axios";
import { ServerError } from "@models/common";

export const ClaimStep: React.FC<ClaimStepProps> = ({
  facilityView,
  setFacilityView,
  onPrev,
  onNext,
  onError,
  processErrors,
  children,
  title,
  barText,
  loadingNext,
}) => {
  const [, { setUnknownErrorNotification, setNotification }] =
    useNotificationStore();
  const [view, setView] = useState(cloneDeep(facilityView));
  const methods = useForm();
  const { trigger, formState, handleSubmit, setError, watch } = methods;
  const css = createCss();

  const submit = () => {
    void trigger().then(async (isValid) => {
      if (isValid) {
        if (await checkValidation()) {
          setFacilityView(view);

          if (isFunction(onNext)) {
            onNext();
          } else {
            onNext.action();
          }
        }
      } else {
        goToFormError();
        onError?.();
      }
    });
  };

  const checkValidation = async () => {
    let isValid = false;

    await handleSubmit(async () => {
      try {
        await FACILITY_API.facilitiesByIdUpdate({
          dryRun: true,
          facility: view,
          id: view.id,
        });
        isValid = true;
      } catch (e) {
        const error: AxiosError<{ errors: ServerError[] }> = e;
        let errorHandled = false;

        const errors = error?.response?.data?.errors;

        if (errors) {
          if (isFunction(processErrors)) {
            if (processErrors(errors, setError, watch)) {
              errorHandled = true;
              goToFormError();
            }
          } else if (Array.isArray(processErrors)) {
            for (const proc of processErrors) {
              if (proc(errors, setError, watch)) {
                errorHandled = true;
              }
            }
            goToFormError();
          } else {
            setNotification({
              message: errors[0].title,
              type: "error",
            });
            return;
          }
        }

        if (!errorHandled) {
          setUnknownErrorNotification();
          throw error;
        }
      }
    })();

    return isValid;
  };

  return (
    <Fragment>
      {!!barText && (
        <div css={css.barContainer}>
          <div css={css.barContent}>
            <Typography bolded variant="h5">
              {barText}
            </Typography>
          </div>
        </div>
      )}
      {!!title && (
        <Fragment>
          <Typography bolded variant="h4" align="center">
            {title}
          </Typography>
          <Spacer size="medium" />
        </Fragment>
      )}
      <FormProvider {...methods}>
        {children(view, setView, methods)}
      </FormProvider>

      <div css={css.footer}>
        {!!onPrev ? (
          <Button
            variant="outlined"
            color="primary"
            className="small-button"
            size="large"
            onClick={onPrev}
            data-test="prev-btn"
          >
            Back
          </Button>
        ) : (
          <div />
        )}

        <Button
          variant="contained"
          color="primary"
          className="small-button"
          size="large"
          data-test="next-btn"
          onClick={submit}
          loading={formState.isSubmitting || loadingNext}
        >
          {isFunction(onNext) ? "Next" : onNext.label}
        </Button>
      </div>
    </Fragment>
  );
};
