import React from "react";
import orderBy from "lodash/orderBy";

import Typography from "@ui-kit/Typography";
import { useDeepDivePanelStore } from "@hooks";
import { weekdaysMap } from "@constants/weekdays";

interface SchoolDaysProps {
  gutterBottom?: boolean;
}

export const SchoolDays: React.FC<SchoolDaysProps> = ({
  gutterBottom = true,
}) => {
  const [{ facility }] = useDeepDivePanelStore();

  if (!facility.operatingInfo.schoolDays?.length) {
    return null;
  }

  return (
    <Typography gutterBottom={gutterBottom} data-test="school-days-display">
      <Typography variant="inherit" bolded>
        School days:
      </Typography>{" "}
      {orderBy(
        facility.operatingInfo.schoolDays.map((d) => weekdaysMap[d]),
        "order",
      )
        .map((d) => d.nameShort)
        .join(" ")}
    </Typography>
  );
};
