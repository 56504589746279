import { Models } from "@services/api";

export const HolidaysNamesMap: {
  [key in Models.HolidayScheduleID]: string;
} = {
  [Models.HolidayScheduleID.IndependenceDay]: "Independence day",
  [Models.HolidayScheduleID.LaborDay]: "Labor day",
  [Models.HolidayScheduleID.ColumbusDay]: "Columbus day",
  [Models.HolidayScheduleID.VeteransDay]: "Veterans day",
  [Models.HolidayScheduleID.ThanksgivingDay]: "Thanksgiving day",
  [Models.HolidayScheduleID.DayAfterThanksgiving]: "Day after Thanksgiving",
  [Models.HolidayScheduleID.ThanksgivingRecess]: "Thanksgiving recess",
  [Models.HolidayScheduleID.WinterrRecess]: "Winter recess",
  [Models.HolidayScheduleID.ChristmasEve]: "Christmas eve",
  [Models.HolidayScheduleID.ChristmasDay]: "Christmas day",
  [Models.HolidayScheduleID.NewYear]: "New Year's day",
  [Models.HolidayScheduleID.Mlk]: "MLK",
  [Models.HolidayScheduleID.PresidentsDay]: "Presidents day",
  [Models.HolidayScheduleID.SpringRecess]: "Spring recess",
  [Models.HolidayScheduleID.EasterRecess]: "Easter recess",
  [Models.HolidayScheduleID.MemorialDay]: "Memorial day",
  [Models.HolidayScheduleID.RoshHashanah]: "Rosh Hashanah",
  [Models.HolidayScheduleID.YomKippur]: "Yom Kippur",
  [Models.HolidayScheduleID.Sukkos]: "Sukkos",
  [Models.HolidayScheduleID.Purim]: "Purim",
  [Models.HolidayScheduleID.Pesach]: "Pesach",
  [Models.HolidayScheduleID.Shavuos]: "Shavuos",

  [Models.HolidayScheduleID.QueensBirthday]: "Queen’s Birthday",
  [Models.HolidayScheduleID.BoxingDay]: "Boxing Day",
  [Models.HolidayScheduleID.BoxingDayAdditional]:
    "Additional day for Boxing Day",
  [Models.HolidayScheduleID.ChristmasDayAdditional]:
    "Additional day for Christmas Day",
  [Models.HolidayScheduleID.AnzacDay]: "Anzac Day",
  [Models.HolidayScheduleID.EasterSaturday]: "Easter Saturday",
  [Models.HolidayScheduleID.EasterSunday]: "Easter Sunday",
  [Models.HolidayScheduleID.EasterMonday]: "Easter Monday",
  [Models.HolidayScheduleID.EasterTuesday]: "Easter Tuesday",
  [Models.HolidayScheduleID.GoodFriday]: "Good Friday",
  [Models.HolidayScheduleID.AustraliaDay]: "Australia Day",
  [Models.HolidayScheduleID.Term1Holidays]: "Term 1 holidays",
  [Models.HolidayScheduleID.Term2Holidays]: "Term 2 holidays",
  [Models.HolidayScheduleID.Term3Holidays]: "Term 3 holidays",
  [Models.HolidayScheduleID.Term4Holidays]: "Term 4 holidays",
  [Models.HolidayScheduleID.MelbourneCupDay]: "Melbourne Cup Day",
  [Models.HolidayScheduleID.RoyalQueenslandShow]: "Royal Queensland Show",
  [Models.HolidayScheduleID.WesternAustraliaDay]: "Western Australia Day",
  [Models.HolidayScheduleID.AdelaideCupDay]: "Adelaide Cup Day",
  [Models.HolidayScheduleID.RoyalHobartRegatta]: "Royal Hobart Regatta",
  [Models.HolidayScheduleID.EightHoursDay]: "Eight Hours Day",
  [Models.HolidayScheduleID.Agfest]: "AGFEST",
  [Models.HolidayScheduleID.RoyalHobartShow]: "Royal Hobart Show",
  [Models.HolidayScheduleID.RecreationDay]: "Recreation Day",
  [Models.HolidayScheduleID.CanberraDay]: "Canberra Day",
  [Models.HolidayScheduleID.ReconciliationDay]: "Reconciliation Day",
  [Models.HolidayScheduleID.MayDay]: "May Day",
  [Models.HolidayScheduleID.BorroloolaShowDay]: "Borroloola Show Day",
  [Models.HolidayScheduleID.AliceSpringsShowDay]: "Alice Springs Show Day",
  [Models.HolidayScheduleID.TennantCreekShowDay]: "Tennant Creek Show Day",
  [Models.HolidayScheduleID.KatherineShowDay]: "Katherine Show Day",
  [Models.HolidayScheduleID.DarwinShowDay]: "Darwin Show Day",
  [Models.HolidayScheduleID.PicnicDay]: "Picnic Day",
  [Models.HolidayScheduleID.LabourDay]: "Labour day",
};
