import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  title: (theme) => ({
    borderBottom: `1px solid ${theme.palette.text.disabled}`,
    color: theme.palette.error.main,
    fontSize: 20,
    fontWeight: 400,
    padding: "20px 0",
    textAlign: "center",
    textTransform: "uppercase",
  }),

  messageBlock: {
    fontSize: 16,
    margin: "20px 0",
  },

  flowContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "100%",
    position: "relative",
    width: "100%",
  },

  button: {
    marginTop: 20,
  },
});
