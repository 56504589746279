import React, { useState, useRef, useEffect } from "react";
import clsx from "clsx";

import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";

import useStyles from "./styles.jss";
import { OverviewProps } from "./props";
import { useDeepDivePanelStore } from "@hooks/useDeepDivePanelStore";

export const Overview: React.FC<OverviewProps> = ({
  text,
  italic,
  image,
  classes,
  "data-test": dataTest,
  height = 70,
  color = "default",
  ...props
}) => {
  const [{ facility }] = useDeepDivePanelStore();
  const defaultClasses = useStyles({ height, color });

  const [opened, setOpened] = useState(false);
  const [overviewHeight, setOverviewHeight] = useState<number | null>(null);
  const overviewContentRef = useRef<HTMLDivElement | null>(null);
  const hasMore = overviewHeight !== null && overviewHeight > height;

  useEffect(() => {
    setOpened(false);
  }, [facility.id]);

  useEffect(() => {
    if (overviewContentRef.current !== null) {
      setOverviewHeight(overviewContentRef.current.clientHeight);
    }
  }, [overviewContentRef.current, text]);

  if (!text) {
    return null;
  }

  return (
    <div
      className={clsx(defaultClasses.root, classes?.root, {
        hasMore,
        opened,
      })}
      {...props}
    >
      <div
        className={clsx(defaultClasses.overview, classes?.overview, {
          italic,
          opened,
        })}
      >
        <div ref={overviewContentRef} data-test={dataTest}>
          {image}
          {text}
        </div>
      </div>

      {hasMore && (
        <span
          className={clsx(defaultClasses.overviewMoreTrigger, "g-link", {
            opened,
          })}
          onClick={() => setOpened((prev) => !prev)}
        >
          {opened ? "Show less" : "Show more"}{" "}
          {opened ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </span>
      )}
    </div>
  );
};
