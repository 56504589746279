import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import Typography from "@ui-kit/Typography";
import { OutlinedInput } from "@ui-kit/InputFields";
import { Models } from "@services/api";
import { required, minLength } from "@validators";

export type FacilityOverviewFormProps = {
  facilityView: Models.Facility;
  setFacilityView: React.Dispatch<React.SetStateAction<Models.Facility>>;
  isClaim?: boolean;
};

export interface FormFields {
  headline: string;
  overview: string;
}

const FacilityOverviewForm: React.FC<FacilityOverviewFormProps> = ({
  facilityView,
  setFacilityView,
  isClaim,
}) => {
  const { errors, control } = useFormContext<FormFields>();

  return (
    <div data-test="facility-overview-form">
      <Typography>Add a headline</Typography>

      <OutlinedInput
        value={facilityView.overviewHeadline || ""}
        onChange={(event) => {
          setFacilityView((prev) => ({
            ...prev,
            overviewHeadline: event.target.value,
          }));
        }}
        multiline
        placeholder="What's most important to know?"
        rowsMax="6"
        data-test="overview-headline-field"
        maxChars={50}
      />

      <Typography required={isClaim}>Add an overview</Typography>

      <Controller
        render={({ onChange, ...rest }) => (
          <OutlinedInput
            {...rest}
            onChange={(event) => {
              setFacilityView((prev) => ({
                ...prev,
                overview: event.target.value,
              }));
              onChange(event);
            }}
            error={errors.overview?.message}
            multiline
            placeholder="Tell parents about your philosophy, staff, curriculum, etc"
            rowsMax="6"
            data-test="overview-field"
            maxChars={750}
          />
        )}
        control={control}
        name="overview"
        defaultValue={facilityView.overview || ""}
        rules={{
          ...required(),
          ...minLength(50),
        }}
      />
    </div>
  );
};

export default FacilityOverviewForm;
