export const getBoundsPadding = (
  dppOpened: boolean,
  isMobile: boolean,
): { top: number; left: number; right: number; bottom: number } => {
  const padding = isMobile ? 50 : 150;
  const leftPadding = isMobile || !dppOpened ? padding : padding + 420;
  const topPadding = isMobile ? padding + 80 : padding;

  return {
    top: topPadding,
    left: leftPadding,
    right: padding,
    bottom: padding,
  };
};
