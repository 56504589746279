import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const useCss = (): Record<Classes, Interpolation<ITheme>> => ({
  action: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 45,

    "& button": {
      minWidth: 100,
    },

    "&.justifyCenter": {
      justifyContent: "center",
    },
  },

  divider: {
    display: "block",
    margin: "30px auto",
    width: 300,
  },
});
