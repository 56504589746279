import React from "react";

import { studentBodyMap } from "@constants/students-body";
import Typography from "@ui-kit/Typography";
import { useDeepDivePanelStore } from "@hooks";

export const StudentBody: React.FC = () => {
  const [{ facility }] = useDeepDivePanelStore();

  if (!facility.studentBody) {
    return null;
  }

  return (
    <Typography data-test="student-body-display" gutterBottom>
      <Typography variant="inherit" bolded>
        Student body:
      </Typography>{" "}
      {studentBodyMap[facility.studentBody].name}
    </Typography>
  );
};
