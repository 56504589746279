import React from "react";

import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

import { UserLocationErrorProps } from "./types";
import Typography from "@ui-kit/Typography";

export const UserLocationError: React.FC<UserLocationErrorProps> = ({
  onClose,
}) => {
  return (
    <Snackbar
      open
      onClose={onClose}
      TransitionComponent={(props) => <Slide {...props} direction="up" />}
      className="g-snackbar"
      ContentProps={{
        "aria-describedby": "user-location-error",
      }}
      message={
        <Typography id="user-location-error" display="inline">
          We do not have permission to use your location
        </Typography>
      }
      action={
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      }
    />
  );
};
