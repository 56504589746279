import React from "react";

import { useCss } from "./styles";
import Typography from "@ui-kit/Typography";
import { useDeepDivePanelStore } from "@hooks";
import { useUserStore } from "@store/UserStore";
import Button from "@ui-kit/Button";
import { Section } from "@components/DeepDivePanel/Section";
import { Models } from "@services/api";
import { isFacilityOwner } from "@selectors";

export const FacilityControls: React.FC = () => {
  const [{ facility }] = useDeepDivePanelStore();
  const [{ user }] = useUserStore();
  const css = useCss();

  if (!isFacilityOwner(facility, user)) {
    return null;
  }

  const color =
    facility.subscriptionTier === Models.SubscriptionTier.Ivy
      ? "secondary"
      : "black";

  return (
    <Section borderTop>
      <Typography variant="h5" bolded paragraph>
        Facility Control Panel
      </Typography>

      <div css={css.buttons}>
        <Button
          color={color}
          variant="contained"
          fullWidth
          size="medium"
          link={{
            pathname: `/dashboard/facilities/${facility.id}/reservations`,
          }}
        >
          Reservation history
        </Button>
      </div>
      <div css={css.buttons}>
        <Button
          color={color}
          variant="contained"
          fullWidth
          size="medium"
          link={`/map/${facility.id}/edit`}
          disabled={!facility.isEditable}
        >
          School profile
        </Button>
        <Button
          color={color}
          variant="contained"
          fullWidth
          name="edit-facility-overview"
          size="medium"
          link={`/map/${facility.id}/edit/overview`}
          disabled={!facility.isEditable}
        >
          School overview
        </Button>
      </div>
      <div css={css.buttons}>
        <Button
          color={color}
          variant="contained"
          fullWidth
          name="edit-school-photos"
          size="medium"
          link={`/map/${facility.id}/edit/photos`}
          disabled={!facility.isEditable}
        >
          School photos
        </Button>
        <Button
          color={color}
          variant="contained"
          fullWidth
          name="edit-bulletin-board"
          size="medium"
          link={`/map/${facility.id}/edit/bulletin-board`}
          disabled={!facility.isEditable}
        >
          Bulletin board
        </Button>
      </div>
      <div css={css.buttons}>
        <Button
          color={color}
          variant="contained"
          fullWidth
          name="edit-virtual-tour"
          size="medium"
          link={`/map/${facility.id}/edit/virtual-tour`}
          disabled={!facility.isEditable}
        >
          School tours
        </Button>
        <Button
          color={color}
          variant="outlined"
          fullWidth
          size="medium"
          link={{
            pathname: "/support",
            search: `?facilityId=${facility.id}`,
          }}
          name="support-options"
        >
          Contact support
        </Button>
      </div>
    </Section>
  );
};
