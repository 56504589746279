import React, { useCallback, Fragment, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Dialog from "@ui-kit/Dialog";
import { useCss } from "./styles";
import Button from "@ui-kit/Button";
import { Spacer } from "@ui-kit/Spacer";
import { useDeepDivePanelStore } from "@hooks";
import { isPublicSchool } from "@selectors";
import { Models } from "@services/api";

const Page: React.FC = () => {
  const [{ facility, Components }] = useDeepDivePanelStore();
  const { search } = useLocation();
  const history = useHistory();
  const css = useCss();

  useEffect(() => {
    Components?.EditOperationalDetails.preload();
    Components?.EditTuitionAndFees.preload();
    Components?.EditMealsAndNutrition.preload();
    Components?.EditFeaturesAndAmenities.preload();
    Components?.EditProgramsAndServices.preload();
    Components?.EditDocumentsPage.preload();
    Components?.EditFacilityInfoForm.preload();
  }, []);

  const close = useCallback(() => {
    history.replace({
      pathname: `/map/${facility.id}`,
      search,
    });
  }, [history, search]);

  return (
    <Dialog
      open
      onClose={close}
      data-test="edit-facility-modal"
      tier={facility.subscriptionTier}
      title="Suggest an edit"
    >
      <div css={css.root}>
        <Button
          color={
            facility.subscriptionTier === Models.SubscriptionTier.Ivy
              ? "secondary"
              : "black"
          }
          size="large"
          variant="outlined"
          fullWidth
          fab
          data-test="edit-contact-info-button"
          onClick={() => {
            history.push({
              pathname: `/map/${facility.id}/edit/contact-info`,
              search,
            });
          }}
        >
          Contact information
        </Button>

        <Spacer size="medium" />

        <Button
          color={
            facility.subscriptionTier === Models.SubscriptionTier.Ivy
              ? "secondary"
              : "black"
          }
          size="large"
          variant="outlined"
          fullWidth
          fab
          data-test="edit-operating-details-button"
          onClick={() => {
            history.push({
              pathname: `/map/${facility.id}/edit/operating-details`,
              search,
            });
          }}
        >
          Operating details
        </Button>

        {!isPublicSchool(facility) && (
          <Fragment>
            <Button
              color={
                facility.subscriptionTier === Models.SubscriptionTier.Ivy
                  ? "secondary"
                  : "black"
              }
              size="large"
              variant="outlined"
              fullWidth
              fab
              data-test="edit-tuition-button"
              onClick={() => {
                history.push({
                  pathname: `/map/${facility.id}/edit/tuition-and-fees`,
                  search,
                });
              }}
            >
              Tuition & fees
            </Button>

            <Button
              color={
                facility.subscriptionTier === Models.SubscriptionTier.Ivy
                  ? "secondary"
                  : "black"
              }
              size="large"
              variant="outlined"
              fullWidth
              fab
              data-test="edit-meals-and-nutrition-button"
              onClick={() => {
                history.push({
                  pathname: `/map/${facility.id}/edit/meals-and-nutrition`,
                  search,
                });
              }}
            >
              Meals & nutrition
            </Button>

            <Spacer size="medium" />

            <Button
              color={
                facility.subscriptionTier === Models.SubscriptionTier.Ivy
                  ? "secondary"
                  : "black"
              }
              size="large"
              variant="outlined"
              fullWidth
              fab
              data-test="edit-features-button"
              onClick={() => {
                history.push({
                  pathname: `/map/${facility.id}/edit/features-and-amenities`,
                  search,
                });
              }}
            >
              Features & amenities
            </Button>

            <Button
              color={
                facility.subscriptionTier === Models.SubscriptionTier.Ivy
                  ? "secondary"
                  : "black"
              }
              size="large"
              variant="outlined"
              fullWidth
              fab
              data-test="edit-programs-and-services"
              onClick={() => {
                history.push({
                  pathname: `/map/${facility.id}/edit/programs-and-services`,
                  search,
                });
              }}
            >
              Programs & services
            </Button>

            <Button
              color={
                facility.subscriptionTier === Models.SubscriptionTier.Ivy
                  ? "secondary"
                  : "black"
              }
              size="large"
              variant="outlined"
              fullWidth
              fab
              data-test="edit-documents"
              onClick={() => {
                history.push({
                  pathname: `/map/${facility.id}/edit/documents`,
                  search,
                });
              }}
            >
              Checklists & documents
            </Button>
          </Fragment>
        )}
      </div>
    </Dialog>
  );
};

export default Page;
