import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTracking } from "react-tracking";

import { useDeepDivePanelStore } from "@hooks/useDeepDivePanelStore";
import { useUserStore } from "@store/UserStore";
import { Models } from "@services/api";
import { useEnrollmentResponseStore } from "@store/EnrollmentResponseStore";
import { QuitDialog } from "@components/QuitDialog";
import { Amplitude } from "@services/amplitude";

type CloseWarningProps = {
  onClose: () => void;
  facilityView: Models.Facility;
};

export const CloseWarning: React.FC<CloseWarningProps> = ({
  onClose,
  facilityView,
}) => {
  const { trackEvent } = useTracking();
  const [{ user }] = useUserStore();
  const [{ facility }, { saveFacility }] = useDeepDivePanelStore();
  const [fetching, setFetching] = useState(false);
  const history = useHistory();
  const { search } = useLocation();
  const [{ inquiryId }, { resetAfterClaimCallback }] =
    useEnrollmentResponseStore();

  return (
    <QuitDialog
      onClose={onClose}
      onQuit={async () => {
        if (user) {
          setFetching(true);
          await saveFacility(facilityView);
        }

        trackEvent({
          action: "Close Page",
        });

        if (!!inquiryId) {
          history.push({
            pathname: `/map/${facility.id}/service-inquiry/${inquiryId}/response`,
            search,
          });
        } else {
          resetAfterClaimCallback();
          history.push({
            pathname: `/map/${facility.id}`,
            search,
          });
        }
      }}
      quitLoading={fetching}
      tier={facility.subscriptionTier}
    />
  );
};
