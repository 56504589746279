import { HelmetProps } from "react-helmet";
import { globalConfig } from "@constants";

import appInfographicImage from "@images/mobile_services.png";

const PAGE_DESCRIPTION =
  "Research Infant to K schools. Find best fit for your family.";

export default {
  link: [
    {
      href: "https://api.tiles.mapbox.com/mapbox-gl-js/v1.12.0/mapbox-gl.css",
      rel: "stylesheet",
    },
    {
      href: `${globalConfig.UI_URL}/map`,
      rel: "canonical",
    },
  ],
  meta: [
    {
      content: PAGE_DESCRIPTION,
      name: "description",
    },
    {
      content: "Parent Day Out",
      property: "og:site_name",
    },
    {
      content: "Parent Day Out",
      property: "og:title",
    },
    {
      content: PAGE_DESCRIPTION,
      property: "og:description",
    },
    {
      content: appInfographicImage,
      property: "og:image",
    },
    {
      content: "Parent Day Out",
      name: "twitter:title",
    },
    {
      content: PAGE_DESCRIPTION,
      name: "twitter:description",
    },
    {
      content: appInfographicImage,
      name: "twitter:image",
    },
  ],
  title: "Infant to K schools | Parent Day Out",
} as HelmetProps;
