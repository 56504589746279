import React, { useState, useRef, useEffect } from "react";
import { Redirect } from "react-router-dom";
import qs from "query-string";
import { useQuery } from "react-query";
import { Divider } from "@ui-kit/Divider";

import useStyles from "./styles";
import { useLocation, useHistory } from "react-router-dom";
import Button from "@ui-kit/Button";
import Typography from "@ui-kit/Typography";
import { useDeepDivePanelStore } from "@hooks";
import { ServiceSession } from "./ServiceSession";
import Dialog from "@ui-kit/Dialog";
import { Spacer } from "@ui-kit/Spacer";
import { Models, API } from "@services/api";
import { PoliciesSection } from "./PoliciesSection";
import { BookingRulesSection } from "./BookingRulesSection";
import { EligibilitySection } from "./EligibilitySection";
import { DescriptionSection } from "./DescriptionSection";
import { QUERIES } from "@constants/queries";

interface ReservationProgramProps {
  bookingConfig: Models.BookingConfig;
}

const ReservationProgram: React.FC<ReservationProgramProps> = ({
  bookingConfig,
}) => {
  const [{ facility }] = useDeepDivePanelStore();
  const { search } = useLocation();

  const topSectionPlaceholderRef = useRef<HTMLDivElement | null>(null);
  const topSectionContentRef = useRef<HTMLDivElement | null>(null);
  const [topSectionWidth, setTopSectionWidth] = useState(375);
  const [topSectionHeight, setTopSectionHeight] = useState(75);

  const classes = useStyles();

  useEffect(() => {
    if (topSectionContentRef.current) {
      setTopSectionHeight(topSectionContentRef.current.clientHeight - 16);
    }

    if (topSectionPlaceholderRef.current) {
      setTopSectionWidth(topSectionPlaceholderRef.current.clientWidth);
    }
  }, [bookingConfig?.id]);

  const renderTopSection = () => {
    return (
      <>
        <Spacer size="sm" />

        <Typography variant="h5" bolded paragraph align="center">
          {bookingConfig?.serviceName}
        </Typography>

        <Button
          variant="contained"
          color={
            facility.subscriptionTier === Models.SubscriptionTier.Ivy
              ? "yellow"
              : "success"
          }
          fullWidth
          size="large"
          name="book"
          link={`/map/${facility.id}/PDO/reservation`}
        >
          Book reservation
        </Button>
        <Divider className="divider" />
      </>
    );
  };

  if (!facility.bookingActive) {
    return (
      <Redirect
        to={{
          pathname: `/map/${facility.id}`,
          search,
        }}
      />
    );
  }

  return (
    <div data-test="reservation-program">
      <div className={classes.topSection}>
        <div
          className="content"
          style={{ width: topSectionWidth }}
          ref={topSectionContentRef}
        >
          {renderTopSection()}
        </div>
        <div
          className="placeholder"
          ref={topSectionPlaceholderRef}
          style={{ height: topSectionHeight }}
        />
      </div>

      <Spacer size="md" />

      <Button
        variant="outlined"
        color={
          facility.subscriptionTier === Models.SubscriptionTier.Ivy
            ? "secondary"
            : "black"
        }
        fullWidth
        size="large"
        name="viewFacilityPhotos"
        link={`/map/${facility.id}/photos`}
      >
        View facility photos
      </Button>
      <Spacer size="sm" />
      <Button
        variant="outlined"
        color={
          facility.subscriptionTier === Models.SubscriptionTier.Ivy
            ? "secondary"
            : "black"
        }
        fullWidth
        size="large"
        name="viewSchoolTour"
        link={`/map/${facility.id}/virtual-tour`}
      >
        View school tour
      </Button>

      <Spacer size="md" />

      <Typography
        variant="h5"
        bolded
        align="center"
        className={classes.sectionTitle}
      >
        Service schedule
      </Typography>

      <Spacer size="sm" />

      {bookingConfig?.sessions
        .filter((s) => s.config.isActive)
        .map((s, i, arr) => (
          <React.Fragment key={s.id}>
            <ServiceSession session={s} key={s.id} />
            {i < arr.length - 1 && <Spacer size="xs" />}
          </React.Fragment>
        ))}

      <DescriptionSection bookingConfig={bookingConfig} />
      <EligibilitySection bookingConfig={bookingConfig} />
      <BookingRulesSection bookingConfig={bookingConfig} />
      <PoliciesSection bookingConfig={bookingConfig} />
    </div>
  );
};

const Page: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const classes = useStyles();
  const [{ facility }] = useDeepDivePanelStore();
  const { data: bookingConfig } = useQuery(
    [QUERIES.BOOKING_CONFIG, facility.id],
    () =>
      API.booking.configFindOne({
        id: facility.id,
      }),
  );

  if (!bookingConfig) {
    return null;
  }

  return (
    <Dialog
      open
      onClose={() => {
        history.push({
          pathname: `/map/${facility.id}`,
          search: qs.stringify({
            ...qs.parse(search),
            programType: undefined,
          }),
        });
      }}
      data-test="pdo-dialog"
      tier={facility.subscriptionTier}
      title={facility.name}
      classes={{
        paper: classes.paper,
      }}
    >
      <div className={classes.container}>
        <ReservationProgram bookingConfig={bookingConfig} />
      </div>
    </Dialog>
  );
};

export default Page;
