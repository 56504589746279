import React, { useState, Fragment, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useLocation, useHistory, Redirect } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import cloneDeep from "lodash/cloneDeep";

import Dialog from "@ui-kit/Dialog";
import { useCss } from "./styles";
import Typography from "@ui-kit/Typography";
import MediaUploader from "@components/PhotoUploader";
import { useDeepDivePanelStore } from "@hooks";
import SuccessStep from "@components/SuccessStep";
import Button from "@ui-kit/Button";
import { isAdmin, orderSchoolMedia } from "@selectors";
import { Models } from "@services/api";
import { Spacer } from "@ui-kit/Spacer";
import { must } from "@utils/must";
import { AddMediaView } from "./AddMediaView";
import { MediaType } from "./AddMediaView/types";
import { mapMediaCategoryName } from "./mapMediaCategoryName";
import { ShadowDivider } from "@components/ShadowDivider";
import { VideoThumbnail } from "@components/VideoThumbnail";
import { processVideoSource, shouldOpenInNewTab } from "./processVideoSource";
import { Lightbox } from "@ui-kit/Lightbox";
import { normalizeUrlDomain } from "@utils/normalizeUrlDomain";
import { LinksForm } from "./LinksForm";
import { useSaveState } from "@hooks/useSaveState";
import { useUserStore } from "@store/UserStore";

const SuccessMessagesMap = {
  create: "Added",
  delete: "Removed",
  update: "Updated",
};

const PhotoGallery: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const [{ facility }, { saveFacility }] = useDeepDivePanelStore();
  const [facilityView, setFacilityView] = useState(cloneDeep(facility));
  const [{ user }] = useUserStore();
  const methods = useForm();
  const { handleSubmit } = methods;
  const [editViewOpened, setEditViewOpened] = useState<MediaType | null>(null);
  const [showSuccessfulView, setShowSuccessfulView] = useState<
    keyof typeof SuccessMessagesMap | null
  >(null);
  const [newMediaLabel, setNewMediaLabel] = useState<Models.MediaCategory | "">(
    "",
  );
  const [newMedia, setNewMedia] = useState<Models.Media | null>(null);
  const [fetching, setFetching] = useSaveState(false);
  const [lightBoxItem, setLightBoxItem] = useState<Models.Media | null>(null);
  const css = useCss();

  useEffect(() => {
    setFacilityView(cloneDeep(facility));
  }, [facility]);

  if (!facility.isEditable && !isAdmin(user)) {
    return <Redirect to={{ pathname: `/map/${facility.id}`, search }} />;
  }

  const save = handleSubmit(async () => {
    await saveFacility(facilityView);
  });

  const removeFile = async (id?: number) => {
    const index = facilityView.media.findIndex((m) => m.id === id);
    facilityView.media.splice(index, 1);

    setFetching(true);
    await save();
    setShowSuccessfulView("delete");
    setFetching(false);
  };

  const renderVideosSection = (
    title: string,
    media: { id: Models.MediaCategory; items: Models.Media[] }[],
  ) => {
    if (!media.some((t) => !!t.items.length)) {
      return null;
    }

    return (
      <Fragment>
        <Spacer size="medium" />

        <Typography variant="h4" bolded align="center" css={css.sectionTitle}>
          {title}
        </Typography>

        {media
          .map((c) => {
            if (c.items.length) {
              return (
                <div key={c.id} id={`gallery-category-${c.id}`}>
                  <Typography
                    variant="h5"
                    align="center"
                    paragraph
                    css={css.categoryLabel}
                  >
                    {mapMediaCategoryName[c.id]}
                  </Typography>

                  {c.items.map((m) => (
                    <div
                      key={m.id}
                      css={css.videoWrapper}
                      data-test={`${m.category}-video`}
                    >
                      <VideoThumbnail
                        file={m.file}
                        onClick={() => {
                          if (
                            !shouldOpenInNewTab(
                              processVideoSource(m.file.original),
                            )
                          ) {
                            setLightBoxItem(m);
                          } else {
                            window.open(
                              normalizeUrlDomain(m.file.original),
                              "_blank",
                            );
                          }
                        }}
                        onDelete={async () => {
                          await removeFile(m.id);
                        }}
                      />
                    </div>
                  ))}
                </div>
              );
            }
          })
          .filter(Boolean)}
      </Fragment>
    );
  };

  const videos = orderSchoolMedia(facility, "video");
  const testimonials = orderSchoolMedia(facility, "testimonials");
  const photos = orderSchoolMedia(facility);
  const orderedMedia = [
    ...photos.map((p) => p.items).flat(1),
    ...videos.map((v) => v.items).flat(1),
    ...testimonials.map((t) => t.items).flat(1),
  ];

  return (
    <Dialog
      open
      onClose={() => {
        history.push({
          pathname: `/map/${facility.id}`,
          search,
        });
      }}
      protectQuit={!!editViewOpened && !showSuccessfulView}
      data-test="facility-gallery"
      title="Manage facility multimedia"
      loading={fetching}
      tier={facility.subscriptionTier}
    >
      {showSuccessfulView ? (
        <div css={css.container}>
          <SuccessStep
            text={SuccessMessagesMap[showSuccessfulView]}
            content={
              <Fragment>
                <ShadowDivider />
                <div css={css.doneAction}>
                  <Button
                    variant="contained"
                    color="primary"
                    name="done"
                    size="large"
                    onClick={() => setShowSuccessfulView(null)}
                  >
                    Done
                  </Button>
                </div>
              </Fragment>
            }
          />
        </div>
      ) : (
        <div css={css.container}>
          {editViewOpened ? (
            <FormProvider {...methods}>
              <AddMediaView
                onCancel={() => setEditViewOpened(null)}
                onSave={async () => {
                  const m = cloneDeep(must(newMedia));

                  m.category = newMediaLabel as Models.MediaCategory;
                  facilityView.media.push(m);

                  await save();

                  setEditViewOpened(null);
                  setNewMediaLabel("");
                  setNewMedia(null);
                  setShowSuccessfulView("create");
                }}
                label={newMediaLabel}
                onLabelChange={setNewMediaLabel}
                onChange={setNewMedia}
                media={newMedia}
                type={editViewOpened}
              />
            </FormProvider>
          ) : (
            <Fragment>
              {!!lightBoxItem && (
                <Lightbox
                  sources={orderedMedia
                    .map(
                      (m) =>
                        processVideoSource(m.file.original).content as string,
                    )
                    .filter(Boolean)}
                  onClose={() => setLightBoxItem(null)}
                  slide={
                    orderedMedia
                      .filter(
                        (m) => !!processVideoSource(m.file.original).content,
                      )
                      .findIndex((m) => m.id === lightBoxItem.id) + 1 || 1
                  }
                />
              )}

              <LinksForm onSuccess={() => setShowSuccessfulView("update")} />

              <Spacer size="medium" />

              <Button
                color="primary"
                size="large"
                variant="outlined"
                fullWidth
                name="add-media"
                onClick={() => setEditViewOpened("photo")}
              >
                <AddIcon />
                Add more
              </Button>

              {!!photos.some((p) => !!p.items.length) && (
                <Fragment>
                  <Spacer size="medium" />

                  <Typography
                    variant="h4"
                    bolded
                    align="center"
                    css={css.sectionTitle}
                  >
                    School photos
                  </Typography>
                  <Spacer size="small" />

                  {orderSchoolMedia(facility)
                    .map((c) => {
                      if (c.items.length) {
                        return (
                          <div key={c.id} id={`gallery-category-${c.id}`}>
                            {c.id !== "profile" && (
                              <Typography
                                variant="h5"
                                align="center"
                                paragraph
                                css={css.categoryLabel}
                              >
                                {mapMediaCategoryName[c.id]}
                              </Typography>
                            )}

                            {c.items.map((m) => (
                              <div
                                css={css.photoWrapper}
                                data-test={`${m.category}-photo`}
                                data-id={m.category}
                                key={m.id || "new"}
                              >
                                <MediaUploader
                                  classes={{
                                    root: "uploaderRoot",
                                  }}
                                  minHeight={600}
                                  minWidth={800}
                                  maxSize={5}
                                  model={m}
                                  onClick={() => {
                                    setLightBoxItem(m);
                                  }}
                                  onUpload={async (media) => {
                                    const index = facility.media.findIndex(
                                      (originalMedia) =>
                                        originalMedia.id === m.id,
                                    );

                                    setFetching(true);
                                    facility.media[index] = media;
                                    await saveFacility(facility);
                                    setShowSuccessfulView("update");
                                    setFetching(false);
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        );
                      }
                    })
                    .filter(Boolean)}
                </Fragment>
              )}

              {renderVideosSection("School videos", videos)}
              {renderVideosSection("Parent testimonials", testimonials)}
            </Fragment>
          )}
        </div>
      )}
    </Dialog>
  );
};

export default PhotoGallery;
