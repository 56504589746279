import React, { Fragment } from "react";
import clsx from "clsx";
import { useHistory, useLocation } from "react-router-dom";

import Button from "@ui-kit/Button";
import SuccessStep from "@components/SuccessStep";
import DividerImg from "@images/divider_shadow.png";
import { useDeepDivePanelStore } from "@hooks";
import { useCss } from "./styles";
import { Models } from "@services/api";
import { SuccessProps } from "./types";

export const Success: React.FC<SuccessProps> = ({ viewPath, onDone }) => {
  const history = useHistory();
  const { search } = useLocation();
  const [{ facility }] = useDeepDivePanelStore();
  const css = useCss();

  return (
    <SuccessStep
      text="Submitted"
      content={
        <Fragment>
          <img src={DividerImg} alt="divider" css={css.divider} />
          <div
            css={css.action}
            className={clsx({
              justifyCenter: !viewPath,
            })}
          >
            {!facility.accountId ? (
              <Button
                variant="outlined"
                color={
                  facility.subscriptionTier === Models.SubscriptionTier.Ivy
                    ? "secondary"
                    : "black"
                }
                size="large"
                data-test="claim-button"
                onClick={() => {
                  history.push({
                    pathname: `/map/${facility.id}/claim`,
                    search,
                  });
                }}
              >
                Is this your school?
              </Button>
            ) : (
              <Button
                variant="outlined"
                color={
                  facility.subscriptionTier === Models.SubscriptionTier.Ivy
                    ? "secondary"
                    : "black"
                }
                size="large"
                data-test="done-button"
                onClick={onDone}
              >
                Done
              </Button>
            )}
            {!!viewPath && (
              <Button
                variant="contained"
                color={
                  facility.subscriptionTier === Models.SubscriptionTier.Ivy
                    ? "secondary"
                    : "black"
                }
                data-test="view-changes-button"
                size="large"
                onClick={() => {
                  history.push({
                    pathname: viewPath,
                    search,
                  });
                }}
              >
                View changes
              </Button>
            )}
          </div>
        </Fragment>
      }
    />
  );
};
