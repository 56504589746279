import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  actions: {
    alignItems: "flex-end",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 30,

    "& button": {
      width: 140,
    },
  },

  placeholder: {
    alignItems: "center",
    display: "flex",
    height: 60,
    justifyContent: "center",
    marginTop: 30,
  },

  ageWarning: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    paddingRight: 10,

    "& .icon": {
      marginRight: 10,
    },
  },

  childItem: (theme) => ({
    paddingLeft: 0,

    "&.addChild": {
      color: theme.palette.primary.main,
    },
  }),

  age: {
    color: "inherit",
    paddingRight: 12,
  },

  flowContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "100%",
    position: "relative",
    width: "100%",
  },
});
