import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const useCss = (): Record<Classes, Interpolation<ITheme>> => ({
  checkRow: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    marginLeft: -9,
  },

  mealPlanWrapper: {
    display: "flex",
    justifyContent: "space-between",

    "& > div": {
      width: "calc(50% - 8px)",
    },
  },
});
