import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";
import { Models } from "@services/api";

export const useCss = ({
  tier,
}: {
  tier: Models.SubscriptionTier;
}): Record<Classes, Interpolation<ITheme>> => ({
  listItem: (theme) => ({
    alignItems: "center",
    display: "flex",

    "&:not(:last-child)": {
      [theme.mediaRequests.notMobile]: {
        marginBottom: 12,
      },

      [theme.mediaRequests.mobile]: {
        marginBottom: 8,
      },
    },
  }),

  verifiedSign: (theme) => ({
    flexShrink: 0,
    marginRight: 10,

    [theme.mediaRequests.notMobile]: {
      height: tier === Models.SubscriptionTier.Community ? 20 : 25,
      width: tier === Models.SubscriptionTier.Community ? 20 : 25,
    },

    [theme.mediaRequests.mobile]: {
      height: 20,
      width: 20,
    },
  }),

  facilityName: (theme) => ({
    alignItems: "center",
    display: "flex",

    [theme.mediaRequests.mobile]: {
      paddingRight: 30,
    },

    "& span": {
      fontWeight: tier === Models.SubscriptionTier.Community ? 600 : 700,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",

      [theme.mediaRequests.notMobile]: {
        fontSize: tier === Models.SubscriptionTier.Community ? 22 : 24,
      },

      [theme.mediaRequests.mobile]: {
        fontSize: 19,
      },
    },
  }),

  mobileToggler: (theme) => ({
    right: 6,
    top:
      tier === Models.SubscriptionTier.Community
        ? 0
        : tier === Models.SubscriptionTier.Ivy
        ? 15
        : 20,
    transform: "none",

    "& button": {
      color: theme.palette.text.primary,
    },
  }),

  expandHighlightsButton: (theme) => ({
    [theme.mediaRequests.notMobile]: {
      fontSize: 34,
    },
  }),

  directionsControl: (theme) => ({
    float: "none",
    margin: 0,
    position: "absolute",
    right: "auto !important",
    zIndex: 1,

    [theme.mediaRequests.notMobile]: {
      bottom: "20px !important",
      left: 430,
    },

    [theme.mediaRequests.mobile]: {
      bottom: "auto !important",
      left: 10,
      top: -180,
    },

    "& div": {
      background: "transparent",
      boxShadow: "none !important",
      margin: "0 !important",

      "& button + button": {
        marginTop: 10,
      },

      "& button": {
        backgroundColor: "white",
        borderColor: "rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186)",
        borderRadius: "100%",
        borderStyle: "solid",
        borderWidth: 2,
        cursor: "pointer",
        display: "block",
        height: 50,
        outline: "none",
        padding: 0,
        width: 50,

        "&:hover": {
          backgroundColor: "#f2f2f2",
        },

        "& svg.direction-icon": {
          height: "1.4em",
          width: "1.4em",
        },
      },
    },
  }),

  icon: (theme) => ({
    color: theme.palette.text.primary,
    height: 22,
    marginRight: 14,
    width: 22,
  }),

  pinIcon: {
    height: 26,
    marginLeft: 2,
    marginRight: 14,
    width: 20,
  },

  galleryBlock: {
    height: 100,
    width: 145,
  },

  rightSide: {
    position: "absolute",
    right: 0,
    top: 0,
  },

  galleryCounter: (theme) => ({
    alignItems: "center",
    backgroundColor: "#e0e0e0",
    borderRadius: 2,
    color: theme.palette.text.primary,
    display: "flex",
    fontSize: 12,
    height: 16,
    justifyContent: "center",
    padding: "3px 5px",
    position: "absolute",
    right: 5,
    top: 5,
    width: 16,
  }),

  galleryImg: {
    alignItems: "center",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    borderRadius: 12,
    cursor: "pointer",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    width: "100%",
  },

  galleryPlaceholder: (theme) => ({
    alignItems: "center",
    borderRadius: 12,
    borderStyle: "solid",
    borderWidth: 2,
    cursor: "pointer",
    display: "flex",
    fontSize: 22,
    fontWeight: 700,
    height: "100%",
    justifyContent: "center",
    lineHeight: "24px",
    textAlign: "center",
    width: "100%",

    "&.pink": {
      borderColor: theme.palette.pink.main,
      color: theme.palette.pink.main,
    },

    "&.blue": {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },

    "&.green": {
      borderColor: theme.palette.success.main,
      color: theme.palette.success.main,
    },

    "&.black": {
      borderColor: theme.palette.text.primary,
      color: theme.palette.text.primary,
      fontSize: 18,
    },
  }),

  list: {
    position: "relative",
  },

  tier: {
    marginTop: 10,
    width: "calc(33% - 8px)",
  },

  tiersContainer: {
    display: "flex",
    justifyContent: "space-between",
  },

  cameraIcon: {
    backgroundColor: "white",
    borderRadius: 2,
    height: 20,
    left: 5,
    position: "absolute",
    top: 5,
    width: 20,

    "& svg": {
      height: 20,
      width: 20,
    },
  },

  streetViewLink: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: 4,
    textAlign: "center",
  },
});
