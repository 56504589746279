import React from "react";
import isBoolean from "lodash/isBoolean";

import Button from "@ui-kit/Button";
import PaymentForm from "@components/PaymentForm";
import { createCss } from "./styles";
import { useReservationStore } from "@hooks";
import { useDeepDivePanelStore } from "@hooks/useDeepDivePanelStore";
import {
  StripeProvider,
  useStripeContext,
} from "@components/PaymentForm/StripeProvider";
import { useUserStore } from "@store/UserStore";
import Typography from "@ui-kit/Typography";
import { Models } from "@services/api";
import { BookingConfig } from "@models/BookingConfig";

const PaymentStep: React.FC = () => {
  const [{ user }] = useUserStore();
  const [{ facility, bookingConfig }] = useDeepDivePanelStore();
  const [
    ,
    { setFetching, prevStep, nextStep, setPaymentSourceId, setPaymentLast4 },
  ] = useReservationStore();
  const { createPaymenMethod, fetching, mode } = useStripeContext();
  const css = createCss();

  async function savePayment() {
    try {
      setFetching(true);

      if (mode === "view") {
        nextStep({ type: "PAYMENT_SELECT" });
      } else {
        const response = await createPaymenMethod({
          saveSource: Boolean(user),
        });

        if (response) {
          if (!user && !isBoolean(response)) {
            setPaymentSourceId(response.payment_method);
            setPaymentLast4(response.last4);
          }

          nextStep({ type: "PAYMENT_SELECT" });
        }
      }
    } finally {
      setFetching(false);
    }
  }

  return (
    <div css={css.flowContainer}>
      <div>
        <PaymentForm />

        <Typography bolded gutterBottom>
          Cancellation policy
        </Typography>
        <Typography>
          {BookingConfig.getCancellationDescription(
            bookingConfig?.cancellationPeriodHours,
          )}
        </Typography>
      </div>

      <div css={css.actions}>
        <Button
          variant="outlined"
          color={
            facility.subscriptionTier === Models.SubscriptionTier.Ivy
              ? "secondary"
              : "black"
          }
          fullWidth
          size="large"
          onClick={prevStep}
          name="prev"
        >
          Back
        </Button>

        <Button
          variant="contained"
          color={
            facility.subscriptionTier === Models.SubscriptionTier.Ivy
              ? "secondary"
              : "black"
          }
          fullWidth
          size="large"
          name="next"
          onClick={savePayment}
          disabled={fetching}
        >
          Review
        </Button>
      </div>
    </div>
  );
};

const PaymentStepWrapper: React.FC = (props) => {
  return (
    <StripeProvider>
      <PaymentStep {...props} />
    </StripeProvider>
  );
};

export default PaymentStepWrapper;
