import React, { useMemo, Fragment, useCallback } from "react";
import { useQuery } from "react-query";
import numeral from "numeral";
import clsx from "clsx";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@ui-kit/ListItemIcon";
import ListItemText from "@ui-kit/ListItemText";
import StarIcon from "@material-ui/icons/Star";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { SensorDoor } from "@components/Icons";

import { useCss } from "./styles";
import { schoolTypesMap } from "@constants/school-types";
import OperatingHours from "@components/DeepDivePanel/OperatingHours";
import Typography from "@ui-kit/Typography";
import { getFullGradeStringForCard } from "@helpers/grades-helpers";

import BudgeIcon from "@images/vbadge_flower_blue.svg";
import { getSponsorsLogo } from "@helpers/getSponsorsLogo";
import { Models, API } from "@services/api";
import { NewDesignIcons } from "@components/Icons";
import { must } from "@utils/must";

interface IProps {
  facility: Models.FacilityCard;
  coords: {
    x: number;
    y: number;
  };
}

const facilityTypesIcons = {
  [Models.FacilityTypeID.PreSchool]: NewDesignIcons.MapPinGreen,
  [Models.FacilityTypeID.HeadstartSchool]: NewDesignIcons.MapPinGreen,
  [Models.FacilityTypeID.DaycareCenter]: NewDesignIcons.MapPinPink,
  [Models.FacilityTypeID.PrivateSchool]: NewDesignIcons.MapPinBlue,
  [Models.FacilityTypeID.PublicSchool]: NewDesignIcons.MapPinPink,
  [Models.FacilityTypeID.CharterSchool]: NewDesignIcons.MapPinPink,
  [Models.FacilityTypeID.Oshc]: NewDesignIcons.MapPinBlack,
};

const FacilityCardComponent: React.FC<IProps> = ({ facility, coords }) => {
  const { data: corporations } = useQuery(
    "corporations",
    API.corporations.getList,
  );

  if (!facility) {
    return null;
  }

  const sponsoredLogo = useMemo(() => {
    if (facility.logo === Models.Logo.Corporation) {
      const corp = corporations?.find((c) => c.id === facility.corporationId);

      if (corp?.logo?.square?.original) {
        return corp.logo.square.original;
      }
    }

    return getSponsorsLogo("pin", facility.logo);
  }, [facility.logo]);

  const css = useCss();

  const getPosition = () => {
    const mapNode = document.getElementById("map");

    if (mapNode) {
      const mapWidth = mapNode.clientWidth;
      const mapHeight = mapNode.clientHeight;

      if (coords.x < mapWidth / 2 && coords.y < mapHeight / 2) {
        return "top-left";
      } else if (coords.x < mapWidth / 2 && coords.y >= mapHeight / 2) {
        return "bottom-left";
      } else if (coords.x >= mapWidth / 2 && coords.y < mapHeight / 2) {
        return "top-right";
      } else {
        return "bottom-right";
      }
    }
  };

  const getFacilityTypeIcon = useCallback(() => {
    const Icon = facilityTypesIcons[facility.facilityType];

    return <Icon />;
  }, [facility.facilityType]);

  return (
    <div
      css={css.root}
      className={getPosition()}
      style={{
        left: coords.x,
        top: coords.y,
      }}
    >
      {!!sponsoredLogo && (
        <div css={css.galleryBlock}>
          <div
            css={[
              css.galleryImg,
              {
                backgroundImage: `url(${sponsoredLogo})`,
              },
            ]}
          />
        </div>
      )}

      <List
        css={css.list}
        className={clsx({
          hasPhoto: !!sponsoredLogo,
        })}
      >
        <Fragment>
          <ListItem css={css.listItem}>
            <ListItemText
              primary={
                <Typography css={css.facilityCardName}>
                  {!!facility.accountId && (
                    <img
                      css={css.verifiedSign}
                      src={BudgeIcon}
                      alt="claimed-badge"
                    />
                  )}
                  <span>{facility.name}</span>
                </Typography>
              }
            />
          </ListItem>

          <ListItem css={css.listItem}>
            <ListItemIcon css={css.icon}>{getFacilityTypeIcon()}</ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body2">
                  {schoolTypesMap[facility.facilityType].title}
                </Typography>
              }
            />
          </ListItem>

          {!!facility.schedule?.length && (
            <ListItem css={css.listItem} data-test="operating-hours-display">
              <ListItemIcon css={css.icon}>
                <AccessTimeIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body2">
                    <OperatingHours facility={facility} />
                  </Typography>
                }
              />
            </ListItem>
          )}

          {!!facility.operatingInfoOngoingGradesTo && (
            <ListItem css={css.listItem} data-test="ongoing-grade-display">
              <ListItemIcon css={css.icon}>
                <SensorDoor />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body2">
                    {getFullGradeStringForCard(facility)}
                  </Typography>
                }
              />
            </ListItem>
          )}

          {Boolean(facility.reviewsSummary.count) && (
            <ListItem css={css.listItem} data-test="rating-display">
              <ListItemIcon css={css.icon}>
                <StarIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body2" css={css.noWrap}>
                    <Typography
                      variant="inherit"
                      display="inline"
                      color={
                        must(facility.reviewsSummary.gradeOverall) >= 6.95
                          ? "textPrimary"
                          : "error"
                      }
                      bolded
                    >
                      {numeral(facility.reviewsSummary.gradeOverall).format(
                        "0.[0]",
                      )}
                      <Typography variant="inherit" display="inline">
                        /10
                      </Typography>
                    </Typography>
                    <Typography variant="inherit" display="inline">
                      {` (${facility.reviewsSummary.count} ${
                        facility.reviewsSummary.count === 1
                          ? "rating"
                          : "ratings"
                      })`}
                    </Typography>
                  </Typography>
                }
              />
            </ListItem>
          )}

          <ListItem css={css.listItem}>
            <ListItemIcon css={css.icon}>
              <NewDesignIcons.TierBadge />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body2" css={css.noWrap}>
                  School tier:
                  <Typography
                    variant="inherit"
                    css={[css.tier, css.noWrap]}
                    bolded={
                      facility.subscriptionTier ===
                      Models.SubscriptionTier.Community
                    }
                    color={
                      facility.subscriptionTier ===
                      Models.SubscriptionTier.Community
                        ? "textPrimary"
                        : "textSecondary"
                    }
                  >
                    base
                  </Typography>{" "}
                  <Typography
                    variant="inherit"
                    css={[css.tier, css.noWrap]}
                    bolded={
                      facility.subscriptionTier === Models.SubscriptionTier.Pro
                    }
                    color={
                      facility.subscriptionTier === Models.SubscriptionTier.Pro
                        ? "textPrimary"
                        : "textSecondary"
                    }
                  >
                    pro
                  </Typography>{" "}
                  <Typography
                    variant="inherit"
                    css={[css.tier, css.noWrap]}
                    bolded={
                      facility.subscriptionTier === Models.SubscriptionTier.Ivy
                    }
                    color={
                      facility.subscriptionTier === Models.SubscriptionTier.Ivy
                        ? "textPrimary"
                        : "textSecondary"
                    }
                  >
                    ivy
                  </Typography>
                </Typography>
              }
            />
          </ListItem>
        </Fragment>
      </List>
    </div>
  );
};

export default FacilityCardComponent;
