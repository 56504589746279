import React from "react";
import NearMeIcon from "@material-ui/icons/NearMe";

import Dialog from "@ui-kit/Dialog";
import { createCss } from "./styles";
import Button from "@ui-kit/Button";
import { useMapStore } from "@store/MapStore";

import DryBarImage from "@images/DryBar.svg";
import EquinoxImage from "@images/equinox.svg";
import LululemonImage from "@images/lululemon.svg";
import SoulCycleImage from "@images/SoulCycle.svg";
import WholeFoodsImage from "@images/Whole-Foods-Market.svg";
import TraderJoeImage from "@images/Trader-Joes.svg";

const POIS = [
  {
    icon: DryBarImage,
    name: "Drybar",
  },
  {
    icon: EquinoxImage,
    name: "Equinox",
  },
  {
    icon: LululemonImage,
    name: "Lululemon",
  },
  {
    icon: SoulCycleImage,
    name: "SoulCycle",
  },
  {
    icon: TraderJoeImage,
    name: "Trader Joe's",
  },
  {
    icon: WholeFoodsImage,
    name: "Whole Foods",
  },
];

export const PointsOfInterestLegendDialog: React.FC = () => {
  const [{ showPoIs }, { setShowPoisLegendModal, setShowPoIs }] = useMapStore();
  const css = createCss();

  return (
    <Dialog
      open
      onClose={() => setShowPoisLegendModal(false)}
      title={
        <span css={css.title}>
          Activities near me <NearMeIcon />
        </span>
      }
      data-test="filters-modal"
    >
      <div css={css.root}>
        {POIS.map((p) => (
          <div css={css.poi} key={p.name}>
            {p.name}
            <div css={css.poiImage}>
              <img src={p.icon} alt={p.name} />
            </div>
          </div>
        ))}

        <Button
          fullWidth
          color="primary"
          variant="contained"
          size="large"
          css={css.actions}
          onClick={() => {
            setShowPoIs(!showPoIs);
            setShowPoisLegendModal(false);
          }}
        >
          {showPoIs ? "Hide activities" : "Show activities on map"}
        </Button>
      </div>
    </Dialog>
  );
};
