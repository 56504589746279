import keyBy from "lodash/keyBy";

import { Models } from "@services/api";

interface FacilityStatus {
  id: Models.FacilityStatus;
  name: string;
}

export const STATUSES: FacilityStatus[] = [
  {
    id: Models.FacilityStatus.Open,
    name: "Open",
  },
  {
    id: Models.FacilityStatus.OpeningSoon,
    name: "Opening soon",
  },
  {
    id: Models.FacilityStatus.OpeningQ1,
    name: "Opening Q1",
  },
  {
    id: Models.FacilityStatus.OpeningQ2,
    name: "Opening Q2",
  },
  {
    id: Models.FacilityStatus.OpeningQ3,
    name: "Opening Q3",
  },
  {
    id: Models.FacilityStatus.OpeningQ4,
    name: "Opening Q4",
  },
  {
    id: Models.FacilityStatus.OpeningThisYear,
    name: "Opening this year",
  },
  {
    id: Models.FacilityStatus.OpeningNextYear,
    name: "Opening next year",
  },
  {
    id: Models.FacilityStatus.TemporarilyClosed,
    name: "Temporarily closed",
  },
  {
    id: Models.FacilityStatus.PermanentlyClosed,
    name: "Permanently closed",
  },
  {
    id: Models.FacilityStatus.MovedLocation,
    name: "Moved",
  },
  {
    id: Models.FacilityStatus.NotExists,
    name: "Does not exist",
  },
  {
    id: Models.FacilityStatus.Duplicate,
    name: "Duplicate",
  },
];

export const statusesMap = keyBy(STATUSES, "id");
