import React, { useCallback, useRef } from "react";

export type EnrollmentResponseStore = {
  afterClaimCallback: (() => Promise<void>) | null;
};

export type EnrollmentResponseStoreActions = {
  setAfterClaimCallback: (
    callback: () => Promise<void>,
    id: number,
    text: string,
  ) => void;
  resetAfterClaimCallback: () => void;
};

const defaultState: EnrollmentResponseStore = {
  afterClaimCallback: null,
};

const EnrollmentResponseStoreContext = React.createContext<
  | [
      EnrollmentResponseStore & {
        responseText: string;
        inquiryId: number | null;
      },
      EnrollmentResponseStoreActions,
    ]
  | null
>(null);

export const EnrollmentResponseStoreProvider: React.FC = ({ children }) => {
  const [state, setState] = React.useState(defaultState);
  const responseTextRef = useRef("");
  const inquiryIdRef = useRef<number | null>(null);

  const setAfterClaimCallback: EnrollmentResponseStoreActions["setAfterClaimCallback"] =
    useCallback(
      (callback, id, text) => {
        responseTextRef.current = text;
        inquiryIdRef.current = id;
        setState((prev) => ({
          ...prev,
          afterClaimCallback: callback,
        }));
      },
      [setState],
    );

  const resetAfterClaimCallback: EnrollmentResponseStoreActions["resetAfterClaimCallback"] =
    useCallback(() => {
      responseTextRef.current = "";
      inquiryIdRef.current = null;
      setState((prev) => ({
        ...prev,
        afterClaimCallback: null,
      }));
    }, [setState]);

  return (
    <EnrollmentResponseStoreContext.Provider
      value={[
        {
          ...state,
          inquiryId: inquiryIdRef.current,
          responseText: responseTextRef.current,
        },
        {
          resetAfterClaimCallback,
          setAfterClaimCallback,
        },
      ]}
    >
      {children}
    </EnrollmentResponseStoreContext.Provider>
  );
};

export const useEnrollmentResponseStore = (): [
  EnrollmentResponseStore & {
    responseText: string;
    inquiryId: number | null;
  },
  EnrollmentResponseStoreActions,
] => {
  const store = React.useContext(EnrollmentResponseStoreContext);

  if (!store) {
    throw new Error(
      "useEnrollmentResponseStore must be used within a EnrollmentResponseStoreProvider",
    );
  }

  return store;
};
