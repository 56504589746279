import { Models } from "@services/api";

export const mapMediaCategoryName: {
  [key in Models.MediaCategory]?: string;
} = {
  [Models.MediaCategory.Classrooms]: "Classrooms & common areas",
  [Models.MediaCategory.Meals]: "Meals & mealtime",
  [Models.MediaCategory.Playground]: "Outdoor play",
  [Models.MediaCategory.Profile]: "Profile photo",
  [Models.MediaCategory.SecurityAndSafety]: "Security & safety",
  [Models.MediaCategory.Staff]: "Faculty & students",
  [Models.MediaCategory.StreetView]: "Street view",
  [Models.MediaCategory.InfantClassroom]: "Infant classroom",
  [Models.MediaCategory.ToddlerClassroom]: "Toddler classroom",
  [Models.MediaCategory.ClassroomPreK2]: "Pre K2 classroom",
  [Models.MediaCategory.ClassroomPreK3]: "Pre K3 classroom",
  [Models.MediaCategory.ClassroomPreK4]: "Pre K4 classroom",
  [Models.MediaCategory.ClassroomTk]: "TK classroom",
  [Models.MediaCategory.ClassroomK]: "K classroom",
  [Models.MediaCategory.SchoolAgeProgram]: "School-age program",
  [Models.MediaCategory.SummerCamp]: "Summer camp",
  [Models.MediaCategory.ServicePdo]: "Parent Pass service",
  [Models.MediaCategory.VideoAboutTheSchool]: "About the school",
  [Models.MediaCategory.VideoServicePdo]: "About Parent Pass service",
  [Models.MediaCategory.TestimonialAboutTheSchool]: "About the school",
  [Models.MediaCategory.TestimonialServicePdo]: "About Parent Pass service",
};
