import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import orderBy from "lodash/orderBy";
import List from "@material-ui/core/List";
import ListItem from "@ui-kit/ListItem";
import ListItemText from "@ui-kit/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import InfoIcon from "@material-ui/icons/Info";
import CircularProgress from "@material-ui/core/CircularProgress";

import Radio from "@material-ui/core/Radio";
import Button from "@ui-kit/Button";
import ChildProfile from "@components/ChildProfile";
import { FormFields } from "@components/ChildProfile/types";
import { createCss } from "./styles";
import { getAge } from "@helpers/get-age-from-date";
import { useReservationStore } from "@hooks";
import Typography from "@ui-kit/Typography";
import { getConfigAgeRange } from "@selectors/weekend-care-config";
import { CHILDREN_API, Models } from "@services/api";
import { NEW_CHILD } from "@mocks/children";
import { getChildFullName } from "@selectors";
import { useUserStore } from "@store/UserStore";
import { useDeepDivePanelStore } from "@hooks/useDeepDivePanelStore";
import { must } from "@utils/must";

const ChildSelectStep: React.FC = () => {
  const [{ facility, bookingConfig }] = useDeepDivePanelStore();
  const [{ child, fetching }, { nextStep, setChild, prevStep }] =
    useReservationStore();
  const [{ user }] = useUserStore();
  const methods = useForm<FormFields>();
  const { handleSubmit, reset } = methods;
  const [inited, setInited] = useState(false);
  const [newChildOpened, setNewChildOpened] = useState(false);
  const [selectedChild, setSelectedChild] = useState<number | "new" | null>(
    null,
  );
  const [childrenList, setChildrenList] = useState<Models.Child[]>([]);

  const css = createCss();

  useEffect(() => {
    void (async () => {
      if (child && !child.id) {
        if (user) {
          const {
            data: { data },
          } = await CHILDREN_API.childrenIndex();
          setChildrenList(data);
          setSelectedChild("new");
        }
        setNewChildOpened(true);

        setTimeout(() => {
          reset({
            birthday: child.birthday,
            firstName: child.firstName,
            gender: child.gender,
            lastName: child.lastName,
            photo: child.photo,
          });
        }, 100);
      } else {
        if (user) {
          const {
            data: { data },
          } = await CHILDREN_API.childrenIndex();

          setChildrenList(data);
          setNewChildOpened(!data.length);
          setSelectedChild(child?.id || null);
          if (!data.length) {
            setChild(NEW_CHILD);
          }
        } else {
          setNewChildOpened(true);
          setChild(NEW_CHILD);
        }
      }

      setInited(true);
    })();
  }, []);

  const selectNew = () => {
    if (selectedChild === "new") {
      return;
    }

    setSelectedChild("new");
    setNewChildOpened(true);
    setChild(NEW_CHILD);
  };

  const selectChild = (child: Models.Child) => {
    if (selectedChild === child.id) {
      return;
    }

    setSelectedChild(must(child.id));
    setNewChildOpened(false);
    setChild(child);
  };

  const submitForm = handleSubmit((values) => {
    const c = must(child);
    nextStep({
      child: newChildOpened
        ? {
            ...c,
            ...values,
          }
        : c,
      type: "CHILD_SELECT",
    });
  });

  return (
    <div css={css.flowContainer}>
      <FormProvider {...methods}>
        <div>
          <div css={css.ageWarning}>
            <InfoIcon color="primary" className="icon" />
            <Typography variant="body1">
              Ages served: {getConfigAgeRange(bookingConfig) || "---"}
            </Typography>
          </div>

          {!inited && (
            <div css={css.placeholder}>
              <CircularProgress />
            </div>
          )}

          {childrenList.length > 0 && user !== null && (
            <List>
              {orderBy(childrenList, "birthday", "desc").map((child) => (
                <ListItem
                  key={`child-${child.id}`}
                  role={undefined}
                  dense
                  button
                  divider
                  data-test={`child-${child.id}`}
                  onClick={() => selectChild(child)}
                  css={css.childItem}
                >
                  <Radio checked={selectedChild === child.id} color="primary" />
                  <ListItemText
                    primary={
                      <Typography variant="body1">
                        {getChildFullName(child)}
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction css={css.age}>
                    <Typography variant="inherit" display="block" align="right">
                      {getAge(child.birthday)?.str}
                    </Typography>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}

              <ListItem
                role={undefined}
                dense
                button
                data-test="child-new"
                onClick={selectNew}
                css={css.childItem}
                className="addChild"
              >
                <Radio checked={selectedChild === "new"} color="primary" />
                <ListItemText
                  primary={
                    <Typography variant="body1">Another child</Typography>
                  }
                />
              </ListItem>
            </List>
          )}

          {newChildOpened && <ChildProfile showPhoto showDocuments={false} />}
        </div>
        <div css={css.actions}>
          <Button
            variant="outlined"
            color={
              facility.subscriptionTier === Models.SubscriptionTier.Ivy
                ? "secondary"
                : "black"
            }
            fullWidth
            size="large"
            onClick={prevStep}
            name="prev"
          >
            Back
          </Button>
          <Button
            variant="contained"
            color={
              facility.subscriptionTier === Models.SubscriptionTier.Ivy
                ? "secondary"
                : "black"
            }
            fullWidth
            size="large"
            onClick={submitForm}
            disabled={fetching || (!newChildOpened && !child)}
            name="next"
          >
            Next
          </Button>
        </div>
      </FormProvider>
    </div>
  );
};

export default ChildSelectStep;
