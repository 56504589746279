import React, { useState } from "react";
import SchoolOutlinedIcon from "@material-ui/icons/SchoolOutlined";

import { useDeepDivePanelStore } from "@hooks";
import Button from "@ui-kit/Button";
import { isAdmin, isPublicSchool } from "@selectors";
import { NotClaimedSchoolPopover } from "@components/DeepDivePanel/NotClaimedSchoolPopover";
import { SupportAgent } from "@components/Icons";
import { Spacer } from "@ui-kit/Spacer";
import { Models } from "@services/api";
import { useUserStore } from "@store/UserStore";

export const PDOAction: React.FC = () => {
  const [{ facility }] = useDeepDivePanelStore();
  const [{ user }] = useUserStore();
  const [pdoAnchorEl, setPdoAnchorEl] = useState<HTMLElement | null>(null);

  if (isPublicSchool(facility)) {
    return null;
  }

  if (facility.bookingActive) {
    return (
      <>
        <Button
          variant="contained"
          color={
            facility.subscriptionTier === Models.SubscriptionTier.Ivy
              ? "yellow"
              : "black"
          }
          link={`/map/${facility.id}/PDO`}
          fullWidth
          name="bookService"
          size="large"
        >
          <SupportAgent />
          Access Your Parent Pass
        </Button>

        <Spacer size="sm" />
      </>
    );
  }

  if (!facility.accountId) {
    return (
      <>
        <NotClaimedSchoolPopover anchor={pdoAnchorEl} />

        <Button
          color={
            facility.subscriptionTier === Models.SubscriptionTier.Ivy
              ? "yellow"
              : "black"
          }
          size="large"
          variant="contained"
          fullWidth
          link={`/map/${facility.id}/claim`}
          onMouseEnter={(event) => setPdoAnchorEl(event.currentTarget)}
          onMouseLeave={() => setPdoAnchorEl(null)}
          data-test="claim-school-button"
          disabled={!facility.isEditable && !isAdmin(user)}
        >
          <SchoolOutlinedIcon />
          Is this your school?
        </Button>

        <Spacer size="sm" />
      </>
    );
  }

  return null;
};
