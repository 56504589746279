import React, { useMemo } from "react";

import Dialog from "@ui-kit/Dialog";
import Button from "@ui-kit/Button";
import Typography from "@ui-kit/Typography";
import { createCss } from "./styles";
import { TryToClaimDialogProps } from "./types";
import { Models } from "@services/api";
import { useDeepDivePanelStore } from "@hooks/useDeepDivePanelStore";

export const TryToClaimDialog: React.FC<TryToClaimDialogProps> = ({
  onClose,
  title,
  description,
  secondaryAction,
  primaryAction,
}) => {
  const [{ facility }] = useDeepDivePanelStore();
  const css = useMemo(() => {
    return createCss({
      secondaryAction,
    });
  }, [secondaryAction]);

  return (
    <Dialog
      open
      onClose={onClose}
      data-test="create-facility-form"
      tier={facility.subscriptionTier}
      title={title}
    >
      <div css={css.root}>
        <Typography align="center" gap>
          {description}
        </Typography>

        <div css={css.actions}>
          {!!secondaryAction && (
            <Button
              variant="outlined"
              color={
                facility.subscriptionTier === Models.SubscriptionTier.Ivy
                  ? "secondary"
                  : "black"
              }
              size="large"
              type="submit"
              onClick={secondaryAction.onClick}
            >
              {secondaryAction.label}
            </Button>
          )}

          <Button
            variant="contained"
            color={
              facility.subscriptionTier === Models.SubscriptionTier.Ivy
                ? "secondary"
                : "black"
            }
            size="large"
            type="submit"
            onClick={primaryAction.onClick}
          >
            {primaryAction.label}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
