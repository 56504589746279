import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  root: (theme) => ({
    [theme.mediaRequests.notMobile]: {
      width: 350,
    },
  }),

  title: {
    alignItems: "center",
    display: "inline-flex",

    "& svg": {
      marginLeft: 5,
    },
  },

  poi: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: "5px 0",

    "& img": {
      height: 30,
    },
  },

  poiImage: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    width: 80,
  },

  actions: {
    marginTop: 20,
  },
});
