import keyBy from "lodash/keyBy";

import { Models } from "@services/api";

export type FirstDayChecklist = {
  id: Models.FirstDayChecklist;
  name: string;
  order: number;
};

export const FIRST_DAY_CHECKLIST: FirstDayChecklist[] = [
  {
    id: Models.FirstDayChecklist.PayRequiredFeesAndDeposits,
    name: "Pay required fees and deposits",
  },
  {
    id: Models.FirstDayChecklist.SubmitRequiredDocuments,
    name: "Submit required documents",
  },
  {
    id: Models.FirstDayChecklist.BringChildPhotograph,
    name: "Bring child photograph",
  },
  {
    id: Models.FirstDayChecklist.BringFamilyPhotograph,
    name: "Bring family photograph",
  },
  {
    id: Models.FirstDayChecklist.PrepareAndLabelSupplies,
    name: "Prepare and label supplies",
  },
  {
    id: Models.FirstDayChecklist.PrepareAndLabelMeals,
    name: "Prepare and label meals",
  },
].map((s, i) => ({ ...s, order: i }));

export const FirstDayChecklistMap = keyBy(FIRST_DAY_CHECKLIST, "id");
