import React, { Fragment } from "react";
import numeral from "numeral";
import isNil from "lodash/isNil";

import Typography from "@ui-kit/Typography";
import { isPublicSchool } from "@selectors";
import { useDeepDivePanelStore } from "@hooks";

export const EnrollmentCapacity: React.FC = () => {
  const [{ facility }] = useDeepDivePanelStore();

  return (
    <Typography gutterBottom data-test="enrollment-capacity-display">
      <Typography variant="inherit" bolded>
        Enrollment capacity:
      </Typography>{" "}
      {isPublicSchool(facility) ? (
        <Fragment>
          {!isNil(facility.operatingInfo.enrollmentCapacity)
            ? facility.operatingInfo.enrollmentCapacity < 150
              ? "less than 150 students"
              : facility.operatingInfo.enrollmentCapacity > 1500
              ? "more than 1,500 students"
              : `${numeral(facility.operatingInfo.enrollmentCapacity).format(
                  "0,0",
                )} students`
            : "---"}
        </Fragment>
      ) : (
        <Fragment>
          {!isNil(facility.operatingInfo.enrollmentCapacity)
            ? `${numeral(facility.operatingInfo.enrollmentCapacity).format(
                "0,0",
              )} students`
            : "---"}
        </Fragment>
      )}
    </Typography>
  );
};
