import React, { useState, useEffect, Fragment } from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { History } from "history";

import { ROUTES } from "@constants";
import useStyles from "./styles";
import Dialog from "@ui-kit/Dialog";
import SuccessStep from "@components/SuccessStep";
import { NewDesignIcons } from "@components/Icons";
import { usePrevious } from "@hooks";
import { ReactGAEventWait } from "@helpers/ga";
import { useUserStore } from "@store/UserStore";
import { useDeepDivePanelStore } from "@hooks/useDeepDivePanelStore";

const Favorite: React.FC = () => {
  const [{ facility }] = useDeepDivePanelStore();
  const history = useHistory();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [shouldAddFavorite, setShouldAddFavorite] = useState(false);
  const [{ user, favorites }, { addFavoriteFacility, removeFavoriteFacility }] =
    useUserStore();
  const prevUser = usePrevious(user);
  const classes = useStyles();
  const facilityInFavorites = Boolean(
    favorites.find((f) => f.id === facility.id),
  );

  const isAuthLocation = (h: History) => {
    return (
      h.location.pathname === ROUTES.SIGNUP_FORM ||
      h.location.pathname === ROUTES.LOGIN_FORM
    );
  };

  useEffect(() => {
    if (!prevUser && user && shouldAddFavorite) {
      void (async () => {
        await addFavoriteFacility(facility.id);
        ReactGAEventWait({
          action: "Add",
          category: "Favorites",
          label: `${facility.id}`,
        });
        setShowConfirmation(true);
      })();
      setShouldAddFavorite(false);
    }
  }, [user]);

  useEffect(() => {
    if (shouldAddFavorite) {
      if (!isAuthLocation(history) && !user) {
        setShouldAddFavorite(false);
      }
    }
  }, [history.location.pathname]);

  return (
    <Fragment>
      {showConfirmation && (
        <Dialog
          open
          onClose={() => setShowConfirmation(false)}
          title="Add to favorites"
        >
          <div className={classes.confirmation}>
            <SuccessStep
              text="Added"
              onDone={() => setShowConfirmation(false)}
            />
          </div>
        </Dialog>
      )}
      <div
        className={classes.root}
        data-test="add-to-favorites"
        onClick={async () => {
          if (facilityInFavorites) {
            ReactGAEventWait({
              action: "Remove",
              category: "Favorites",
              label: `${facility.id}`,
            });
            void removeFavoriteFacility(facility.id);
          } else {
            if (user) {
              await addFavoriteFacility(facility.id);
              ReactGAEventWait({
                action: "Add",
                category: "Favorites",
                label: `${facility.id}`,
              });
            } else {
              setShouldAddFavorite(true);
              history.push(ROUTES.SIGNUP_FORM);
            }
          }
        }}
      >
        <NewDesignIcons.Heart
          className={clsx(classes.icon, {
            active: facilityInFavorites,
          })}
        />
      </div>
    </Fragment>
  );
};

export default Favorite;
