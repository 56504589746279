import React from "react";

import Typography from "@ui-kit/Typography";
import { Models } from "@services/api";

export const generateCorporationLogo = (
  corporation: Models.Corporation,
): JSX.Element => {
  return (
    <div
      css={{
        alignItems: "center",
        backgroundColor: "white",
        color: "black",
        display: "flex",
        height: "100%",
        justifyContent: "center",
        width: "100%",
      }}
      className="logo"
    >
      <Typography bolded>{corporation.name}</Typography>
    </div>
  );
};
