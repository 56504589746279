import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  indicatorsContainer: {
    display: "flex",
    justifyContent: "center",
  },

  indicator: (theme) => ({
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "calc(100% / 6)",

    "& .icon": {
      height: 35,
      marginBottom: 13,
      width: 35,
    },

    "& .status": {
      height: 20,
      width: 20,

      "&.check": {
        color: theme.palette.success.main,
      },

      "&.close": {
        color: theme.palette.error.main,
      },
    },
  }),

  vrImage: {
    cursor: "pointer",
    display: "block",
    height: 150,
    margin: "10px auto",
    width: 150,
  },

  vrImageContainer: {
    height: 130,
  },
});
