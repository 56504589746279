import React from "react";
import ListItem from "@material-ui/core/ListItem";
import { DateTime } from "luxon";
import isNumber from "lodash/isNumber";
import isNil from "lodash/isNil";

import { createCss } from "./styles";
import Typography from "@ui-kit/Typography";
import { RATINGS } from "@constants/ratings";
import { Models } from "@services/api";
import { must } from "@utils/must";

interface IProps {
  review: Models.FacilityReview;
  divider?: boolean;
}

const ReviewComponent: React.FC<IProps> = ({ review, divider }) => {
  const css = createCss();

  return (
    <ListItem divider={divider} disableGutters css={css.root}>
      <div css={{ width: "100%" }}>
        <div css={css.header}>
          <Typography
            display="inline"
            color="textSecondary"
            bolded
            css={css.name}
          >
            {review.author}
          </Typography>
          <Typography color="textSecondary" css={css.date}>
            {!isNil(review.createdAt) &&
              DateTime.fromISO(review.createdAt).toFormat("MMMM, yyyy")}
          </Typography>
        </div>

        {!isNil(review.headline) && (
          <Typography bolded paragraph>
            {review.headline}
          </Typography>
        )}

        <div css={css.details}>
          <div>
            {!isNil(review.comment) && (
              <Typography style={{ whiteSpace: "pre-wrap" }} paragraph>
                {review.comment}
              </Typography>
            )}

            {RATINGS.map((r) => {
              if (!isNumber(review[r.id as keyof Models.FacilityReview])) {
                return null;
              }

              return (
                <div css={css.detailedReview} key={r.id}>
                  <span>{r.name}</span>
                  <Typography
                    variant="inherit"
                    color={
                      must(review[r.id as keyof Models.FacilityReview]) >= 7
                        ? "inherit"
                        : "error"
                    }
                    italic
                    gutterBottom
                  >
                    <b>{review[r.id as keyof Models.FacilityReview]}</b>/10
                  </Typography>
                </div>
              );
            }).filter(Boolean)}
          </div>
        </div>
      </div>
    </ListItem>
  );
};

export default ReviewComponent;
