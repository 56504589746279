import React, { useMemo } from "react";

import Button from "@ui-kit/Button";
import Typography from "@ui-kit/Typography";
import { useCss } from "./styles";
import { useDeepDivePanelStore } from "@hooks";
import { Section } from "@components/DeepDivePanel/Section";
import { Spacer } from "@ui-kit/Spacer";
import { Models } from "@services/api";

export const WeekendCareSection: React.FC = () => {
  const [{ facility }] = useDeepDivePanelStore();
  const css = useMemo(() => {
    return useCss({
      tier: facility.subscriptionTier,
    });
  }, [facility.subscriptionTier]);

  if (!facility.bookingSupported || !facility.bookingActive) {
    return null;
  }

  const buttonsColor =
    facility.subscriptionTier === Models.SubscriptionTier.Ivy
      ? "yellow"
      : "success";

  return (
    <Section
      borderTop
      styles={{
        content: css.sectionContent,
        root: css.sectionRoot,
      }}
    >
      <Section.Title>Parent Pass Service</Section.Title>

      <Spacer size="sm" />

      <Typography paragraph>
        For 99% of parents, their most scarce commodity is time.
      </Typography>
      <Typography paragraph>
        But with Parent Pass, moms and dads can now create the time needed with
        a click of a button.
      </Typography>

      <Spacer size="sm" />

      <Button
        variant="contained"
        color={buttonsColor}
        link={`/map/${facility.id}/PDO`}
        fullWidth
        name="bookService"
        size="large"
      >
        Access Your Parent Pass
      </Button>
    </Section>
  );
};
