import React, { Fragment } from "react";

import { EditDocumentsView } from "@pages/DeepDivePanel/EditDocuments/view";
import { EditMealsAndNutritionView } from "@pages/DeepDivePanel/EditMealsAndNutrition/view";
import EditTuitionAndFees, {
  processServerErrors as processTuitionErrors,
} from "@pages/DeepDivePanel/EditTuitionAndFees/view";
import {
  EditVirtualTourView,
  processServerErrors as processVirtualTourErrors,
} from "@pages/DeepDivePanel/EditVirtualTour/view";
import { Models } from "@services/api";
import { ClaimStep } from "@pages/FacilityClaim/ClaimStep";
import Typography from "@ui-kit/Typography";
import { Spacer } from "@ui-kit/Spacer";

interface IProps {
  facilityView: Models.Facility;
  setFacilityView: React.Dispatch<React.SetStateAction<Models.Facility>>;
  onPrev: () => void;
  onNext: () => void;
  loading: boolean;
}

export const OverviewStep: React.FC<IProps> = ({
  facilityView,
  setFacilityView,
  onPrev,
  onNext,
  loading,
}) => {
  return (
    <ClaimStep
      onNext={{
        action: onNext,
        label: "Finish",
      }}
      loadingNext={loading}
      barText="Final step"
      onPrev={onPrev}
      facilityView={facilityView}
      setFacilityView={setFacilityView}
      processErrors={[processTuitionErrors, processVirtualTourErrors]}
    >
      {(view, setView) => (
        <Fragment>
          <Typography paragraph bolded variant="h5">
            Meals & nutrition
          </Typography>

          <EditMealsAndNutritionView
            facilityView={view}
            setFacilityView={setView}
            isClaim
            isShortClaim
          />

          <Spacer size="medium" />

          <Typography paragraph bolded variant="h5">
            Observed holidays
          </Typography>
          <EditDocumentsView
            facilityView={view}
            setFacilityView={setView}
            isClaim
            isShortClaim
          />

          <Spacer size="medium" />

          <Typography paragraph bolded variant="h5">
            Tuition & fees
          </Typography>
          <EditTuitionAndFees
            facilityView={view}
            setFacilityView={setView}
            isClaim
            isShortClaim
          />

          <Spacer size="medium" />

          <Typography bolded variant="h5">
            Virtual school tours
          </Typography>
          <EditVirtualTourView
            facilityView={view}
            setFacilityView={setView}
            isClaim
            isShortClaim
          />
        </Fragment>
      )}
    </ClaimStep>
  );
};
